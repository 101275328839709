import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-registration-type',
  templateUrl: './registration-type.component.html',
  styleUrls: ['./registration-type.component.css']
})
export class RegistrationTypeComponent implements OnInit {
  buttonValue:string;

  constructor(
    private router:Router,
    private userService:UserService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  registrationType(button){

    this.buttonValue=button.id;
    this.  userService.closeResgisterTypeComponent();

    if(this.buttonValue =='user'){
      this.router.navigate(['/register'],{skipLocationChange:true});
    }
    else if(this.buttonValue =='doctor'){
      this.router.navigate(['/doctor'],{skipLocationChange:true});
    }
    else if(this.buttonValue == 'clinic'){
      this.router.navigate(['/clinic'],{skipLocationChange:true});
    }
  }
}
