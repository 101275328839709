<app-header></app-header>

<div class="big-image">
  <div class="overlay">
    <h1>Diagnostic Center</h1>
    <p></p>
    <div class="test-search ">
      <input [(ngModel)]="service"
             [typeahead]="filteredService$"
             (typeaheadOnSelect)="selectedService($event)"
             [typeaheadMinLength]="2"
             [typeaheadItemTemplate]="serviceItemTemplate"
             [typeaheadOptionsLimit]="15"
             typeaheadOptionField="name"
             placeholder="What are you looking for?"
             class="searchTerm">
      <ng-template #serviceItemTemplate let-brand="item">
        <div class="ui-helper-clearfix">
          <div style="float:left;margin:5px 5px 0 0;font-size: 15px;word-break: normal;white-space: normal;">
            {{brand.name}}
          </div>
          <div style="font-size:12px;float:right;margin-top:10px">
            <b>{{brand.type | camelcase}}</b>
          </div>
        </div>
      </ng-template>
      <button type="submit" class="searchButton">
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
</div>
<div class="view-item">
  <div class="container-fluid">
    <a type="button" href="/diagnostic-center/cart" class="btn btn-primary btn-lg pull-right"><i class="fas fa-shopping-cart"></i> View Cart ({{cartCount}})</a>
  </div>
</div>

<section class="tabs-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-5 col-lg-2">
        <ul class="nav nav-tabs flex-column mb-3">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#tab-1">Offers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tab-2">Profiles</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tab-3">Tests</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-7 col-lg-10">
        <div class="tab-content">
          <div class="tab-pane active show" id="tab-1">
            <section id="services" class="services">
              <div class="thyro-info">
                <div class="container">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="features-title">
                        <h4 class="">Offers</h4>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="thyrocare-slide">
                      <div class="row">
                        <div *ngFor="let offer of offerData;" class="col-md-4 col-lg-4 col-sm-4">
                          <div class="thumbnail">
                            <div class="caption">
                              <a href="/diagnostic-center/offer/{{offer.code}}">
                                <h6>{{offer.name}}</h6>
                                <div class="listprice">
                                  <span>₹{{offer.rate.offerRate}}</span>
                                </div>
                                <div class="test-included ">
                                  <div class="test-included-details">
                                    <a type="button" class="test-included-list font-bold">{{offer.testCount}} tests included</a>
                                  </div>
                                </div>
                              </a>
                              <div class="product-item">
                                <a type="button" class="btn btn-primary btn-lg" *ngIf="!checkPackageInCart(offer.code)"
                                   (click)="handleAddToCart('offer', offer, alertTemplate, offerExistsTemplate);">
                                  <i class="fas fa-shopping-cart"></i> Add Package</a>
                                <div *ngIf="checkPackageInCart(offer.code)">
                                  <a type="button" href="diagnostic-center/cart" class="addedCartBtn btn-lg" >
                                    <i class="fas fa-check-circle"></i> Go to Cart</a>
                                  <a type="button" class="removeBtn btn-lg" (click)="removeFromCart(offer.code)">
                                    <i class="fas fa-times-circle"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="tab-pane" id="tab-2">
            <section id="services" class="services">
              <div class="thyro-info">
                <div class="container">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="features-title">
                        <h4 class="">Profiles</h4>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="thyrocare-slide">
                      <div class="row">
                        <div *ngFor="let profile of packageData;" class="col-md-4 col-lg-4 col-sm-4">
                          <div class="thumbnail">
                            <a href="/diagnostic-center/profile/{{profile.code}}">
                              <img src="{{profile.imageLocation}}" onerror="src='assets/images/thyro_logo.png'" height="240px" alt="{{profile.code}}">
                            </a>
                            <div class="caption">
                              <a href="/diagnostic-center/profile/{{profile.code}}">
                                <h6>{{profile.name}}</h6>
                                <div class="listprice">
                                  <span>₹{{profile.rate.offerRate}}</span>
                                </div>
                                <div class="test-included ">
                                  <div class="test-included-details">
                                    <a type="button" class="test-included-list font-bold">{{profile.testCount}} tests included</a>
                                  </div>
                                </div>
                              </a>
                              <div class="product-item">
                                <a type="button" class="btn btn-primary btn-lg" *ngIf="!checkProfileInCart(profile.code) && !checkProfileIsIncluded(profile.code)"
                                   (click)="handleAddToCart('profile', profile, alertTemplate, offerExistsTemplate);">
                                  <i class="fas fa-shopping-cart"></i> Add Profile</a>
                                <a *ngIf="checkProfileIsIncluded(profile.code) && !checkProfileInCart(profile)" type="button" class="includedBtn btn-sm pull-right">
                                  <i class="fas fa-check-circle"></i> Included</a>
                                <div *ngIf="checkProfileInCart(profile.code)">
                                  <a type="button" href="diagnostic-center/cart" class="addedCartBtn btn-lg" >
                                    <i class="fas fa-check-circle"></i> Go to Cart</a>
                                  <a type="button" class="removeBtn btn-lg" (click)="removeFromCart(profile.code)">
                                    <i class="fas fa-times-circle"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="tab-pane" id="tab-3">
            <div class="top-test-booked">
              <div class="features-title">
                <h4 class="">Tests</h4>
              </div>
              <div class="row">
                <div *ngFor="let test of testData" class="col-md-3 col-lg-3 col-sm-3">
                  <div class="top-test-list">
                    <div data-index="0" class="top-test-card" tabindex="-1" aria-hidden="false" >
                      <div class="top-test">
                        <a href="/diagnostic-center/test/{{test.code}}">
                          <div class="top-test-name">{{test.name}}</div>
                          <div class="top-test-desc">Category : {{test.category}}</div>
                        </a>
                        <div class="row">
                          <div class="col-md-3">
                            <div>
                              <span class="top-test-price">₹{{test.rate.offerRate}}</span>
                            </div>
                          </div>
                          <div class="col-md-9 product-item">
                            <a type="button" *ngIf="!checkTestInCart(test.code) && !checkTestIsIncluded(test.code)" (click)="handleAddToCart('test', test, alertTemplate, offerExistsTemplate);" class="btn btn-primary btn-sm pull-right">
                              <i class="fas fa-shopping-cart"></i> Add Test</a>
                            <a *ngIf="checkTestIsIncluded(test.code) && !checkTestInCart(test)" type="button" class="includedBtn btn-sm pull-right">
                              <i class="fas fa-check-circle"></i> Included</a>
                            <div *ngIf="checkTestInCart(test.code)" class="pull-right">
                              <a type="button" href="diagnostic-center/cart" class="addedCartBtn btn-sm">
                                <i class="fas fa-check-circle"></i> Go to Cart</a>
                              <a type="button" class="removeBtn btn-sm" (click)="removeFromCart(test.code)">
                                <i class="fas fa-times-circle"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #alertTemplate>
  <div class="modal-content">
    <div class="modal-header">
      <h4>Alert</h4>
    </div>
    <div class="modal-body">
      The diagnostic test that you have selected is also available in one or more diagnostic test packages that you have already selected. Hence this test will not be listed in your cart.
    </div>
    <div class="modal-footer text-center">
      <a (click)="closeModal()" class="closeModal btn btn-primary" data-dismiss="modal" aria-hidden="true">Ok</a>
    </div>
  </div>
</ng-template>

<ng-template #offerExistsTemplate>
  <div class="modal-content">
    <div class="modal-header">
      <h4>Alert</h4>
    </div>
    <div class="modal-body">
      You can book only one package at a time. You have already added one package to cart.
    </div>
    <div class="modal-footer text-center">
      <a (click)="closeModal()" class="closeModal btn btn-primary" data-dismiss="modal" aria-hidden="true">Ok</a>
    </div>
  </div>
</ng-template>

<app-footer lat="17.4611" long="78.355797"></app-footer>
