<!----
<div class="alert  alert-dismissible"  *ngIf="banner" >
  <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
  <div class="d-flex"  id="my-content">
    
    <div class="p-1 mt-2" style="width: 50px;height: 50px;" ><img src="https://lh3.googleusercontent.com/iT7S1KExMRklCABLMu6qLtllA3zz-Vn0sw70ZSXQIi8Vh5zLRzl94uM_w5fW__pRwgxe=s180-rw" class="rounded img-fluid" alt="Cinque Terre" width="70" height="70" > </div>
    <div class="p-3 " style=" font-size: small;" >Try Gigadocs app now.</div>
    <div class="p-2  "><button class="btn btn-primary btn-xs" (click)="getLink()">Get App</button></div>
  </div> 
  
</div>

  <div class="alert  alert-dismissible" id="my-content" *ngIf="banner"  >
    <a href="#" class="close" data-dismiss="alert" aria-label="close" (click)="closeBanner()">&times;</a>
    <div class="row p-1">
      <div class="col-2">
        <img src="https://lh3.googleusercontent.com/iT7S1KExMRklCABLMu6qLtllA3zz-Vn0sw70ZSXQIi8Vh5zLRzl94uM_w5fW__pRwgxe=s180-rw" class="rounded img-fluid" alt="Cinque Terre" width="70" height="70" >
      </div>
      <div class="col-8">
          <div class="row">
            <div class="col-10">
              <span class="text-small">Try Gigadocs app now.</span>
            </div>
            <div class="col-2">
              <button class="btn btn-primary btn-xs" (click)="getLink()">Get App</button>
            </div>
          </div>       
      </div>     
    </div>    
  </div> -->

  <div class="smartbanner" id="smartabanner" *ngIf="banner" >
    <div class="smartbanner-container">
        <a href="javascript: void(0)" id="smb-close" class="smartbanner-close" (click)="closeBanner()">&times;</a>
        <span class="smartbanner-icon"><img src="https://lh3.googleusercontent.com/iT7S1KExMRklCABLMu6qLtllA3zz-Vn0sw70ZSXQIi8Vh5zLRzl94uM_w5fW__pRwgxe=s180-rw" class="rounded img-fluid"alt="Gigadocs" width="70" height="70" >
    </span>
        <div class="smartbanner-info">
            <div class="smartbanner-title">Try Gigadocs app now</div>
        </div>
        <a href="javascript: void(0)" target="_blank" class="smartbanner-button">
            <span class="smartbanner-button-text" (click)="getLink()">Get it</span>
        </a>
    </div>
</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>