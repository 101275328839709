import { Component, OnInit, TemplateRef  } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Validators, FormControl, FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { UserService } from './../service/user.service';
@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.css']
})
export class SubscriptionPlansComponent implements OnInit {
  lat:any;
  long:any;
  modalRef: BsModalRef;
  getQuoteForm: FormGroup;
  submitted: boolean;
  isLoading: boolean;
  getQuoteSent: boolean = false;
  getQuoteSentError: boolean = false;
  ipAddress:any;
  myJson:any = [];
  ipJson:any = [];
  list=[];
  name: string;
  city: string;
  coords=[];
  area:any = [];
  data: any = [];
  location:any;
  userVisitId:any;
  isSelected=true;
  address:any;
  objectKeys = Object.keys;
  // plansJSON=[
  //   {
  //     "FIELD1": "user",
  //     "silver": "yes",
  //     "gold": "no",
  //     "platinum": "yes"
  //   },
  //   {
  //     "FIELD1": "patient",
  //     "silver": "1",
  //     "gold": "2",
  //     "platinum": "3"
  //   },
  //   {
  //     "FIELD1": "doctor",
  //     "silver": "yes",
  //     "gold": "2",
  //     "platinum": "no"
  //   }
  //  ]
  plansJSON:any = [];
  featureJSON:any =[];
  planObj:any;
  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private userService:UserService,
  ) { 
    this.getQuoteForm = this.fb.group({
      name: new FormControl('',[ Validators.required , Validators.minLength(3)]),
      phone: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}') , Validators.maxLength(10) , Validators.minLength(10)]],
      email:new FormControl('',[Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      city:new FormControl('', [Validators.required , Validators.pattern("^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$")]),
      clinicname: new FormControl('',[ Validators.required]),
      plans: new FormControl('',[ Validators.required]),
      speciality: new FormControl('')
    })
  }
  
  
  ngOnInit(): void {
    this.getSubscritionPlansDetails();
    var details = {
      metaTitle: 'Subscription plans | Gigadocs',
      metaDescription: 'Online consultation through video consultation. Virtual consultation with doctors online. Best quality video consultation. Receive online prescription through online consultation. Online Doctor Consultations can be booked through online appointment booking. Consult a doctor online.',
      ogTitle: 'Video Consultation, Virtual Consultation and Online Consultation | Gigadocs',
      ogDescription: 'Online consultation through video consultation. Virtual consultation with doctors online. Best quality video consultation. Receive online prescription through online consultation. Online Doctor Consultations can be booked through online appointment booking. Consult a doctor online.',
    };
    this.userService.setDynamicMetaData(details);
    this.getClientIP();
    if(!localStorage.getItem('geolocation')) {
      this.userService.getIpLookUp().subscribe(response => {
        let storeLocation = {
          name: response.city,
          city: response.city,
          latitude: response.latitude.toFixed(6),
          longitude: response.longitude.toFixed(6)
        };
        localStorage.setItem('geolocation', JSON.stringify(storeLocation));
        let re = /\s+/g;
        this.name = storeLocation.name.toLowerCase().replace(re, "-");
        this.city = storeLocation.city.toLowerCase().replace(re, "-");
        this.lat = storeLocation.latitude;
        this.long = storeLocation.longitude;
        this.area = storeLocation.name;
      });
    } else{
      let geolocation = JSON.parse(localStorage.getItem('geolocation'));
      let re = /\s+/g;
      this.name = geolocation.name.toLowerCase().replace(re, "-");
      this.city = geolocation.city.toLowerCase().replace(re, "-");
      this.lat = geolocation.latitude;
      this.long = geolocation.longitude;
      this.area = geolocation.name;
      this.location = this.city;
    }
  }
 
  openGetQuoteModal(template: TemplateRef<any>) {
    this.submitted = false;
    this.getQuoteForm.reset();
    this.getQuoteSent = false;
    this.modalRef = this.modalService.show(template);
    this.formVisitGetQuote();
 }

 get quoteForm(){
  return this.getQuoteForm.controls;
}
closeGetQuoteModalComponent() {
  this.modalRef.hide()
}
pageVisitGetQuote(){
  var details = {
    ip:this.ipAddress,
    latitude:this.lat,
    longitude:this.long,
    location:this.location,
  }
  this.userService.getQuotePageVisit(details).subscribe(data => {
    
    if(data['details']['status'] == 1){
      this.userVisitId = data['details']['id'];
      return this.userVisitId;
    }
  })
 
}
formVisitGetQuote(){
  var details = {
    userVisitId:this.userVisitId,
    formvisit:1,
    formsubmit:0
  }
  this.userService.getQuoteFormVisit(details).subscribe(data => {
  })
}


submitGetQuote(){
  this.submitted = true;
  if(this.getQuoteForm.valid){
    this.isLoading = true;
    var details = {
            userVisitId:this.userVisitId,
            formsubmit:1,
            name:this.getQuoteForm.value.name,
            email:this.getQuoteForm.value.email,
            mobile:this.getQuoteForm.value.phone,
            city:this.getQuoteForm.value.city,
            speciality:this.getQuoteForm.value.speciality,
            clinic:this.getQuoteForm.value.clinicname,
            plan:this.getQuoteForm.value.plans
          }
    this.userService.getQuoteRequest(details).subscribe(data => {
      this.isLoading = null;
      if(data['details']['status'] == 1){
        this.getQuoteSent = true;
        setTimeout(() => {
          this.closeGetQuoteModalComponent()
        }, 3000)
      }else{
        this.getQuoteSentError = true;
      }
    //  this.getQuoteSent = false;
    })
    //this.getQuoteForm.reset();
  }
  
}

getClientIP() {
  this.userService.getIpAddress().subscribe(data => {
      this.ipAddress = data.ip;
      this.ipJson = {"ip":data.ip};
      this.pageVisitGetQuote();
     return this.ipAddress;
    })
}

getSubscritionPlansDetails() {
  this.userService.getSubscriptionPlans().subscribe(data => {
    if(data.status == 1){
      this.featureJSON = data.details;
      this.plansJSON = data.plans;
    }
  })

}
}
