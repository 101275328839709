import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../service/user.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";
import * as moment from "moment";
import {NgxSpinnerService} from "ngx-spinner";
import {WindowRefService} from "../window-ref.service";

declare var $: any;
@Component({
  selector: 'app-requestpayment',
  templateUrl: './requestpayment.component.html',
  styleUrls: ['./requestpayment.component.css']
})
export class RequestpaymentComponent implements OnInit {

  @ViewChild('otpVerify ') otpVerify;
  @ViewChild('transactionSuccess') transactionSuccess;
  //@ViewChild('transactionFailed') transactionFailed;
  appointmentId: any;
  appointmentData = [];
  appointmentForm: FormGroup;
  otpVerficationForm: FormGroup;
  aptTime: any;
  aptDate: any;
  appointmentConfirmationSpinner: boolean;
  loadingText = "";
  noError: boolean = true;
  contentLoaded = false;
  specialization: any;
  specialities: any;
  experience: number;
  loadingSpinner: boolean = false;
  login: boolean = false;
  vcButtonText = 'Pay and Book';
  userPoints: number;
  isFree: any = 0;
  points: number;
  amount: number;
  consultationFee: number;
  pointsAmountFee: number;
  usedPoints: boolean = false;
  clicked = false;
  sendingOtp: boolean;
  otpSent: boolean;
  submitted = false;
  otperror: boolean = false;
  phoneNo: any;
  patientDetails: { mobile: any; otp: any; };
  public errorMsg;
  errorInfo: boolean;
  otpMsg: boolean = true;
  orderIdGen: any;
  payment_id: any;
  signature: any;
  order_id: string;
  isPaid = 0;

  constructor(
    private activatedroute:ActivatedRoute,
    private router:Router,
    private userService:UserService,
    private spinner: NgxSpinnerService,
    private winRef: WindowRefService,
    private ngzone: NgZone,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.appointmentForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
    });
    this.otpVerficationForm = this.fb.group({
      otp: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
  }

  ngOnInit(): void {
    this.userService.setDocTitle('Request Payment - Gigadocs');
    if(this.userService.userValue) {
      this.login = true;
      this.userService.getPoints().subscribe(response => {
        if (response.details) {
          this.points = response.details.points;
          this.userPoints = this.points;
          this.userService.updatePoints(this.points);
        }
      });
    }
    this.activatedroute.params.subscribe(params => {
      this.appointmentId = params['appointmentId'];
      this.userService.getAppointmentDetails(this.appointmentId).subscribe(response => {
        if (response.details) {
          this.appointmentData = response.details[0];
          this.isPaid = this.appointmentData['isPaid'];
          this.contentLoaded = true;
          this.specialization = this.appointmentData['doctorSpeciality'];
          this.specialities = this.appointmentData['doctorSpeciality'];
          this.consultationFee = this.appointmentData['doctorFee'];
          let experince = this.appointmentData['doctorExperience'];
          if (experince != 0) {
            let date = new Date();
            let year = date.getFullYear();
            this.experience = year - experince;
          } else {
            this.experience = 0;
          }
          this.aptDate = this.datePipe.transform(this.appointmentData['appointmentDate'],"dd MMM yyyy");
          this.aptTime = this.datePipe.transform(this.appointmentData['appointmentDate'] + ' ' + this.appointmentData['appointmentTime'], 'shortTime');
        }
      });
    });
  }

  onSubmit() {
    this.submitted = true;
    this.loadingSpinner = true;
    this.otperror = false;
    this.otpFormReset();
    this.clicked = true;
    var contact = {
      mobile: this.appointmentData['userMobile'],
    };
    this.mobileSubmit(contact);
  }

  resendOtp() {
    this.otpSent = null;
    this.sendingOtp = true;
    var details = {
      mobile: this.appointmentData['userMobile']
    }
    this.userService.verifyUser(details).subscribe((res: any) => {
      this.sendingOtp = null;
      if (res.status == 200) {
        this.otpVerficationForm.reset();
        this.otpSent = true;
      }
    })
  }

  otpFormReset() {
    this.otpSent = null;
    this.sendingOtp = null;
    this.otpVerficationForm.reset();
  }

  mobileSubmit(userDetails) {
    this.userService.verifyUser(userDetails).subscribe((res: any) => {
      if (res.status == 200) {
        this.clicked = false;
        this.loadingSpinner = false;
        $("#otpModal").modal('show');
      }
    });
  }

  otpSubmit() {
    if (this.otpVerficationForm.valid) {
      let number = this.appointmentData['userMobile'];
      let code = this.otpVerficationForm.value.otp;
      this.patientDetails = {
        mobile: number,
        otp: code,
      };
      this.userService.otpVerfication(this.patientDetails).subscribe((res: any) => {
          if (res.status === 200) {
            this.otpVerficationForm.reset();
            this.otpVerify.nativeElement.click();
            if (this.usedPoints) {
              if (this.pointsAmountFee == 0) {
                this.amount = this.pointsAmountFee;
                this.updateAppointment();
              } else {
                this.amount = this.pointsAmountFee;
                this.createRzpayOrder(this.amount);
              }
            } else {
              this.amount = this.consultationFee;
              this.createRzpayOrder(this.amount);
            }
          } else {
            this.otpVerficationForm.reset();
            this.otpMsg = false;
            this.otperror = true;
            setTimeout(() => {
              this.otperror = false;
              this.otpMsg = true;
            }, 2500);
          }
        },
        err => {
          this.errorInfo = true;
          this.errorMsg = err
        }
      );
    } else {
      return;
    }
  }

  updateAppointment() {
    this.appointmentConfirmationSpinner = true;
    this.spinner.show();
    this.loadingText = "Almost there..confirming your appointment"
    setTimeout(() => {
      this.spinner.hide();
    }, 7000);

    let details = {
      appointmentId: this.appointmentId,
      razorpay_order_id: this.order_id,
      razorpay_payment_id: this.payment_id,
      razorpay_signature: this.signature,
      isOnline: 1,
      points: this.usedPoints ? 1 : 0
    }

    this.userService.updateAppointment(details).subscribe(response => {
        if (response.details.status) {
          this.newParams();
          sessionStorage.setItem('appointmentId', this.appointmentId);
          this.appointmentForm.reset();
          this.otpVerficationForm.reset();
          this.submitted = false;
          if(this.userService.userValue) {
            if (response['details']['wallet']) {
              let remainPoints = this.points - response['details']['wallet'];
              this.userService.updatePoints(remainPoints);
            }
          } else {
            this.userService.verifyOtpLogin(this.patientDetails.mobile, this.patientDetails.otp).subscribe(res => {
              this.userService.afterLogin(res);
            });
          }
          this.router.navigate(['doctordetails/appointmentsuccess/', this.appointmentId]);
        }
      },
      error => {
        this.errorMsg = error;
        this.noError = false;
      }
    );
  }

  createRzpayOrder(data) {
    var details = {
      amount: this.amount
    }
    this.userService.createOrderID(details).subscribe(res => {
      this.orderIdGen = res['details']['orderId'];
      this.payWithRazor(this.orderIdGen);
    })
    //let orderidGen="order_Fx7ENZJpFUk3i7";
    //this.payWithRazor(orderidGen);
  }

  payWithRazor(val) {
    let patientMobile = this.appointmentData['userMobile'];
    let patientEmail = this.appointmentData['userEmail'];
    const options: any = {
      key: this.userService.paymentKey,
      amount: this.amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'GigaDocs', // company name or product name
      description: 'Virtual Consultation',  // product description
      image: 'https://www.gigadocs.com/assets/new/images/logo.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      prefill: {
        "email": patientEmail,
        "contact": patientMobile,
        "method": ""
      },
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      this.payment_id = response['razorpay_payment_id'];
      this.signature = response['razorpay_signature'];
      this.order_id = response['razorpay_order_id'];
      if (this.payment_id != null && this.signature != null) {
        $("#paymentStatus").modal('show');
        this.ngzone.run(() => {
          setTimeout(() => {
            $("#paymentStatus").modal('hide');
            this.updateAppointment();
          }, 5000);
        })
      }
    });
    options.modal.ondismiss = (() => {
      $("#paymentFailed").modal('show');
      this.ngzone.run(() => {
        //this.transactionMsg=true;
        //this.Noerror=false;
      })
    });
    const razorpay = new this.winRef.nativeWindow.Razorpay(options);
    razorpay.open();
  }

  onPointsChange(e, points, fee) {
    if(e.target.checked) {
      let wallet = points / 4;
      let walletRemain = wallet - fee;
      if (walletRemain >= 0) {
        this.pointsAmountFee = 0;
        this.vcButtonText = "Book Free Consultation";
      } else {
        let remainFee = fee - wallet;
        this.pointsAmountFee = remainFee;
        this.vcButtonText = "Pay ₹" + remainFee + " and Book";
      }
      this.usedPoints = true;
    } else {
      this.usedPoints = false;
      this.vcButtonText = "Pay and Book";
    }
  }

  newParams() {
    let details = {
      doctor: this.appointmentData['doctorName'],
      city: this.appointmentData['clinicCity'],
      area: this.appointmentData['clinicArea'],
      clinicName: this.appointmentData['clinicName'],
      address: this.appointmentData['clinicAddress'],
      specialization: this.specialization,
      exp: this.experience,
      date: this.aptDate,
      time: this.aptTime
    }
    this.activatedroute.queryParams.subscribe(params => {
      this.userService.changeParams(details)
    })
  }

  get form() {
    return this.appointmentForm.controls;
  }

  terms() {
    this.router.navigateByUrl('home/terms');
  }

}
