import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './header-features/login/login.component';
import { HomeComponent } from './home/home.component';
import { ClinicregComponent } from './clinicreg/clinicreg.component';
import { DoctorregComponent } from './doctorreg/doctorreg.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DoctordetailsComponent } from './doctordetails/doctordetails.component';
import { MapViewComponent } from './map-view/map-view.component';
import { AppointmentdetailsComponent } from './appointmentdetails/appointmentdetails.component';
import { OtpComponent } from './otp/otp.component';
import { AppointmentsuccessComponent } from './appointmentsuccess/appointmentsuccess.component';
import { VCresultsComponent } from './vcresults/vcresults.component';
import { AuthGuard } from './helpers';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordGuard } from './helpers/reset-password.guard';
import { ClinicdetailsComponent } from "./clinicdetails/clinicdetails.component";
import { ClinicspaginationComponent } from "./clinicspagination/clinicspagination.component";
import { ImmunizationscheduleComponent } from "./immunizationschedule/immunizationschedule.component";
import {HowitworksComponent} from "./howitworks/howitworks.component";
import {StoreComponent} from "./store/store.component";
import {LogoutComponent} from "./logout/logout.component";
import {RescheduleComponent} from "./reschedule/reschedule.component";
import {TermsComponent} from "./terms/terms.component";
import {PrivacyComponent} from "./privacy/privacy.component";
import {VcsuccessComponent} from "./vcsuccess/vcsuccess.component";
import {ThyrocareComponent} from "./thyrocare/thyrocare.component";
import {TestdetailsComponent} from "./testdetails/testdetails.component";
import {ThyrocarecartComponent} from "./thyrocarecart/thyrocarecart.component";
import {ThyrocaresuccessComponent} from "./thyrocaresuccess/thyrocaresuccess.component";
import {RequestpaymentComponent} from "./requestpayment/requestpayment.component";
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';

const routes: Routes = [
  { path:'', component:HomeComponent },
  { path:'home', component:HomeComponent },
  { path:'plans', component: SubscriptionPlansComponent },
  { path:'register', component:RegisterComponent },
  { path:'login', component:LoginComponent },
  { path:'store', component:StoreComponent },
  { path:'store/practice', component:StoreComponent },
  { path:'home/logout', component: LogoutComponent },
  { path:'home/terms', component: TermsComponent },
  { path:'home/privacy', component: PrivacyComponent },
  { path:'home/**', component:HomeComponent },
  { path:'home/secure_reset/:code', component:ResetPasswordComponent },
  { path:'resetpassword', component:ResetPasswordComponent, canActivate:[ResetPasswordGuard] },
  { path:'clinic', component:ClinicregComponent },
  { path:'doctor', component:DoctorregComponent },
  { path:'doctordetails/appointmentsuccess/:appointmentId',component:AppointmentsuccessComponent },
  { path:'doctordetails/appointmentsuccess/oncall/:docId',component:AppointmentsuccessComponent },
  { path:'doctors/videoconsultation/:appointmentId', component: RequestpaymentComponent },
  { path:'doctors/:city/:docName', component:DoctordetailsComponent },
  { path:'doctordetails/reschedule/:appointmentId', component: RescheduleComponent },
  { path:'clinicdetails/:city/:id', component: ClinicdetailsComponent },
  { path:'appointment/:docUrl',component:AppointmentdetailsComponent },
  { path:'map',component:MapViewComponent },
  { path:'home/howitworks', component: HowitworksComponent },
  { path:'home/immunizationschedule', component: ImmunizationscheduleComponent },

  { path:'diagnostic-center', component: ThyrocareComponent },
  { path:'diagnostic-center/cart', component: ThyrocarecartComponent },
  { path:'diagnostic-center/success/:appointmentId', component: ThyrocaresuccessComponent },
  { path:'diagnostic-center/:type/:code', component: TestdetailsComponent },

  { path:'virtualconsultation/doctors', component:VCresultsComponent },
  { path:'virtualconsultation/:specs/doctors', component:VCresultsComponent },
  { path:'virtualconsultation/:specs/doctors/:pageNo', component:VCresultsComponent },
  { path:'virtualconsultation/doctors/:pageNo', component:VCresultsComponent },
  { path:'virtualconsultation/review/:channel', component:VcsuccessComponent },

  { path:':city/:specs/doctors', component: PaginationComponent },
  { path:':city/:specs/doctors/:pageNo', component: PaginationComponent },
  { path:':city/:specs/:name/doctors', component: PaginationComponent },
  { path:':city/:specs/:name/doctors/:pageNo', component: PaginationComponent },

  { path:':city/:specs/clinics', component: ClinicspaginationComponent },
  { path:':city/:specs/clinics/:pageNo', component: ClinicspaginationComponent },
  { path:':city/:specs/:area/clinics', component: ClinicspaginationComponent },
  { path:':city/:specs/:area/clinics/:pageNo', component: ClinicspaginationComponent },

  { path:':city', component:PaginationComponent },
  { path:':city/:pageNo', component: PaginationComponent },
  { path:':city/:specs', component:PaginationComponent },
  { path:':city/:specs/:name', component:PaginationComponent },
  { path:':city/:specs/:pageNo', component:PaginationComponent },
  { path:':city/:specs/:name/:pageNo', component:PaginationComponent },

  { path:'**', component:HomeComponent },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
