<footer>
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-md-3" *ngIf="citySiteMap != undefined && citySiteMap.length > 0">
          <h4>By Location</h4>
          <ul>
            <li *ngFor="let cities of citySiteMap">
              <a href="javascript:void(0)" routerLink="/{{cities['url']}}">{{cities['lable']}}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3" *ngIf="specialitySiteMap != undefined && specialitySiteMap.length > 0">
          <h4>By Speciality</h4>
          <ul>
            <li *ngFor="let specalities of specialitySiteMap">
              <a href="javascript:void(0)" routerLink="/{{specalities['url']}}">
                {{specalities['lable']}}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-3" *ngIf="serviceSiteMap != undefined && serviceSiteMap.length > 0">
          <h4>By Service</h4>
          <ul>
            <li *ngFor="let services of serviceSiteMap">
              <a href="javascript:void(0)" routerLink="/{{services['url']}}">{{services['lable']}}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <h4>Useful Links</h4>
          <ul>
            <li><a href="#aboutUs">About Us</a></li>
            <li><a href="/blog">Blogs</a></li>
            <li><a href="/diagnostic-center">Diagnostic Center</a></li>
            <li><a href="javascript:void(0)" (click)="gotoVaccination()">Immunization Schedule</a></li>
            <li><a href="javascript:void(0)" (click)="userService.openDemoModalComponent()">Request Demo</a></li>
            <li><a href="https://www.youtube.com/channel/UCS8m_BcR-wdrh8cbv-ObeGw" target="_blank">Videos</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <nav class="navbar navbar-expand-sm bg-dark">
      <a class="navbar-brand" href="#">
        <img src="assets/images/logo.png"  alt="GigaDocs"/>
      </a>
      <ul class="navbar-nav">
          <li class="nav-item">
              <a class="nav-link text-secondary" href="#features">Features</a>
          </li>
          <li class="nav-item">
              <a class="nav-link text-secondary" href="/blog">Blogs</a>
          </li>
          <li class="nav-item">
              <a class="nav-link text-secondary" href="#whyUs">Why Us</a>
          </li>
      </ul>
      <ul class="navbar-nav">
          <li class="nav-item">
              <a class="nav-link text-secondary" href="tel:+917219274442">PHONE +91 7219274442</a>
          </li>
          <li class="nav-item">
              <a class="nav-link text-secondary" href="mailto:info@gigadocs.com">E-MAIL - info@gigadocs.com</a>
          </li>
      </ul>
      <div class="row">
          <span>
              <a target="_blank" href="https://www.facebook.com/gigadocssofttech/" class="fa fa-facebook" style="width: 35px;"></a>
          </span>
          <span>
              <a target="_blank" href="https://twitter.com/gigadocs/" class="fa fa-twitter"></a>
          </span>
          <span>
              <a target="_blank" href="https://www.linkedin.com/company/gigadocs/" class="fa fa-linkedin"></a>
          </span>
          <span>
              <a target="_blank" href="https://www.youtube.com/channel/UCS8m_BcR-wdrh8cbv-ObeGw" class="fa fa-youtube"></a>
          </span>
          <span>
              <a target="_blank" href="https://www.instagram.com/gigadocssofttech/" class="fa fa-instagram"></a>
          </span>
      </div>
  </nav>
  </div>
</footer>
<!--<div class="bg-danger" *ngIf="check()"></div>-->
