import { Component, OnInit } from '@angular/core';
import {UserService} from "../service/user.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private Userservice:UserService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.Userservice.redirectlogout().subscribe(response => {
      localStorage.removeItem('user');
      sessionStorage.clear();
      this.Userservice.userSubject.next(null);
      this.router.navigate(['']);
      this.spinner.hide();
    });
  }

}
