import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../service/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Calendar} from "primeng/calendar";
import {DatePipe} from "@angular/common";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.css']
})
export class RescheduleComponent implements OnInit {

  @ViewChild('calendar') calendar: Calendar;
  appointmentId: any;
  docId: number;
  clinicId: number;
  appointmentData: any;
  isSlot: any;
  slotType: string;
  doctorName: string;
  aptDate: any;
  aptTime: any;

  /* for slots */
  hours: any;
  date: any;
  time: any;
  inClinic: boolean = false;
  isOnline: boolean = false;
  morningSlots: any = [];
  afternoonSlots: any = [];
  eveningSlots: any = [];
  morningSlotsLength: any;
  afternoonSlotsLength: any;
  eveningSlotsLength: any;
  selectedIndex: any;
  selectedTime: any;
  todayApply: boolean = true;
  tomorrowApply: boolean = false;
  dateHighlight: boolean = false;
  datePicker: Date;
  minDate: Date;
  maxDate: Date;
  btnDisable: boolean = true;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  userEmailId: any;
  waitSpinner: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private activatedroute: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.userService.setDocTitle('Reschedule Appointment - Gigadocs');
    let today = new Date();
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.minDate = new Date(today);
    this.maxDate = new Date(today.setDate(today.getDate() + 14));
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' });
    this.activatedroute.params.subscribe(params => {
      this.appointmentId = params['appointmentId'];
      this.getAppointmentDetails(this.appointmentId);
    })
  }

  getAppointmentDetails(appointmentId) {
    this.userService.getAppointmentDetails(appointmentId).subscribe(response => {
      this.appointmentData = response.details[0];
      if (response.details[0]) {
        this.isSlot = this.appointmentData.isOnline;
        if (this.isSlot == 1) {
          this.slotType = 'vc';
        } else {
          this.slotType = 'inclinic';
        }
        this.doctorName = this.appointmentData.doctorName;
        this.aptDate = new Date(this.appointmentData.appointmentDate).toDateString().slice(4, 15);
        this.aptTime = this.timeConversion([this.appointmentData.appointmentTime]);
        sessionStorage.setItem('slotType', this.slotType);
        this.docId = this.appointmentData.doctorId;
        this.clinicId = this.appointmentData.clinicId;
        this.userEmailId = this.appointmentData.userEmail
        if(!this.userEmailId){
         this.userEmailId = '';
        }
        this.clearArraydata();
        this.availableSlots(this.date, this.slotType);
        setTimeout(() => {
          this.calendar.value = null;
        }, 100);
      }
    });
  }

  availableSlots(date: any, slot: any) {
    this.userService.getClinicslots(this.docId, this.clinicId, date, slot).subscribe((response) => {
      if (response['details']['status']==false){
        this.morningSlotsLength = 0;
        this.afternoonSlotsLength = 0;
        this.eveningSlotsLength = 0;
      }else{
        this.morningSlotsLength = response['details'][0]['morning']['length'];
        this.afternoonSlotsLength = response['details'][0]['afternoon']['length'];
        this.eveningSlotsLength = response['details'][0]['evening']['length'];
      }
      this.waitSpinner = false;
      this.morningSlots = this.timeConversion(response['details'][0]['morning']);
      this.afternoonSlots = this.timeConversion(response['details'][0]['afternoon']);
      this.eveningSlots = this.timeConversion(response['details'][0]['evening']);
    })
  }

  timeConversion(slot: any = []) {
    while (slot.length >= 1) {
      let convertedTime: any[] = [];
      for (let i = 0; i < slot['length']; i++) {
        this.time = slot[i].split(":", 3);
        var ampm = this.time[0] >= 12 ? 'PM' : 'AM';
        ampm == 'PM' && this.time[0] == 12 ? this.hours = 12 : this.hours = this.time[0] % 12;
        this.hours < 10 ? this.hours = '0' + this.hours : this.hours;
        convertedTime.push(this.hours + ':' + this.time[1] + ' ' + ampm);
      }
      return convertedTime;
    }
  }

  today() {
    this.calendar.value = null;
    this.datePicker = null;
    this.clearArraydata();
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowApply = false;
    this.todayApply = true;
    this.availableSlots(this.date, this.slotType);
  }

  tomorrow() {
    this.calendar.value = null;
    this.datePicker = null;
    this.clearArraydata();
    let today = new Date();
    this.date = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayApply = false;
    this.tomorrowApply = true;
    this.availableSlots(this.date, this.slotType);
  }

  selectedDate(event: any) {
    this.clearArraydata();
    this.dateHighlight = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayApply = false;
    this.tomorrowApply = false;
    this.availableSlots(this.date, this.slotType);
  }

  Time(index, time) {
    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.aptDate = new Date(this.appointmentData.appointmentDate).toDateString().slice(4, 15);
      this.aptTime = this.timeConversion([this.appointmentData.appointmentTime]);
      this.btnDisable = true;
    } else {
      this.selectedIndex = index;
      this.selectedTime = time;
      this.aptDate = new Date(this.date).toDateString().slice(4, 15);
      this.aptTime = time;
      this.btnDisable = false;
    }
  }

  slotTimeConversion(time) {
    let convertedTime: any[] = [];
    let slot = time.split(":", 3);
    var ampm = slot[1].split(" ", 2);
    if (ampm[1] == "AM" && slot[0] < 12) {
      this.hours = parseInt(slot[0]);
    }
    if (ampm[1] == "PM" && slot[0] < 12) {
      this.hours = parseInt(slot[0]) + 12;
    }
    if (ampm[1] == "PM" && slot[0] == 12) {
      this.hours = parseInt(slot[0]) - 12;
    }
    convertedTime.push(this.hours + ':' + slot[1]);
    return convertedTime[0];
  }

  clearArraydata() {
    this.btnDisable = true;
    this.morningSlots = [];
    this.afternoonSlots = [];
    this.eveningSlots = [];
    this.morningSlotsLength = 0;
    this.afternoonSlotsLength = 0;
    this.eveningSlotsLength = 0;
    this.waitSpinner = true;
    this.todayApply = true;
    this.tomorrowApply = false;
    this.selectedIndex = -1;
    this.selectedTime = null;
    this.dateHighlight = false;
  }

  confirmAppointment() {
    let slotTime = this.slotTimeConversion(this.selectedTime);
    let details = {
      date: this.date,
      time: slotTime,
      appointmentId: this.appointmentId,
      email: this.userEmailId      
    }
    this.userService.updateAppointment(details).subscribe(response => {
      if (response.details) {
        sessionStorage.setItem('appointmentId', this.appointmentId);
        this.router.navigate(['doctordetails/appointmentsuccess/', this.appointmentId]);
      }
    });
  }

}
