<app-header></app-header>

<section class="feature">
  <div class="terms-banner-strip">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center">Terms & Conditions Of Service</h2>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="terms-content">
        <p>
          These Terms of Service together with the applicable policies which are incorporated herein by way of reference (“these Terms”) govern the access or use of the website <strong><a href="" target="_blank">www.gigadocs.com </a></strong>(“the Website”), the application known as ‘Gigadocs’ available for download on your communication device (“Application” or “App”) together with all the content, tools, facilities and services made available through the Website or the App (collectively “the Service”). Therefore, please read these Terms & Conditions very carefully.
        </p>
        <p>
          By clicking the box indicating acceptance of these Terms, you are indicating that you have read and understood these Terms and agree that these Terms constitute a legal and binding agreement between you and <strong> Gigadocs Softtech Private Limited, 3rd Floor, Sri MRLS Hub, Jai hind enclave road, Madhapur, Hyderabad, Telangana - 500081</strong>(“we”, “us” or “our”). If you do not understand the Terms or if these Terms or any part of these Terms is not acceptable to you, please do not access or use the Service or any part thereof. In these Terms, “you” / “yours” / “yourself” denotes the natural person who accesses/uses the Service or any part thereof.
        </p>
        <p>
          We reserve the right to update or modify these Terms at any time in our sole discretion without any prior notice to you. If we update or modify these Terms, we will post the revised version of these Terms on the Website / App and such revision shall become effective immediately on such posting. It is your responsibility to review these Terms periodically for updates / modifications. Your continued access or use of any of the Service after any revision becomes effective, shall be deemed to constitute your acceptance and agreement to be bound by the revised Terms.
        </p>
        <h3>1.  Eligibility & Registration</h3>
        <p>
          <strong>1.1.</strong>
          The access/use of the Service is meant only for persons who are competent to contract as per Indian law. If you are not ‘competent to contract’ as per Indian law (i.e. if you have not attained the age of majority according to the law to which you are subject, if you are not of sound mind or if you are disqualified from contracting by any law to which you are subject), you should not register or access/use the Service or any part thereof. If the law applicable to you does not allow you to receive the Services in your territory, you should not use this Service. If you are the parent/legal guardian/authorized person acting on behalf of a patient, you should disclose the same to us and by registering with us or accessing / using the Service on behalf of your ward, you affirm that you have the full and proper authority to represent the patient and accept these Terms on his/her behalf.
        </p>
        <p>
          <strong>1.2.</strong>
          To avail of the all the features of the Service, you must download the App from ‘Google Play’ or ‘Apple Store’ (which are third party applications, the use and access of which will be subject to Google’s Terms of Service or Apple’s Terms and Conditions respectively) and install the App onto your communication device. To install and/or use the App, you will need a communication device that meets certain technical requirements, which may change from time to time, a working internet connection and compatible software, for all of which you are solely responsible.
        </p>
        <p>
          <strong>1.3.</strong>
          Once you successfully install the App on your communication device, you have to provide certain information about yourself as requested when you attempt to register with us and start using the Service. You may be required to authenticate your identity using a one-time password (“OTP”) as a confirmation code, which will be transmitted to your mobile number provided to us when you seek to register with us through the short message service (“SMS”). If you cannot provide the OTP when required to do so or the authentication through the OTP fails, you may not be able to register with us and/or avail of the Service. However, you may still be able to access and use the Service always subject to these Terms.
        </p>
        <p>
          <strong>1.4.</strong>
          When you register with us and/or access or use the Service, you confirm that you have provided accurate, current and complete information about yourself and that you will keep all such information current, complete and accurate at all times for as long as you use the Service. All the data and information that you provide or that is collected from your access/use of the Service shall be treated and protected in accordance with our <a href="home/privacy" target="_blank"><u>Privacy Policy</u></a>, which is deemed a part of these Terms.
        </p>
        <p>
          <strong>1.5.</strong>
          Your registration with us and/or your right to access the Service is personal to you and you may not transfer this to anyone else. You may not use anyone else’s account at any time. You understand that anyone with access to your communication device may use your account and therefore, you should ensure that you have appropriate security measures on your communication device to prevent unauthorized use of your account with the Service. You will be solely responsible for any and all activities that occur under your account with us and we will not be liable for any loss or damage that may occur as a result of someone else using the Service through your account with us or obtaining your personal information from your communication device, either with or without your knowledge. If you become aware of any unauthorized use of your account with us, please notify us by writing to support@gigadocs.com with the title “Unauthorized Use of Account” and we will initiate action to stop further access under the said account.
        </p>
        <p>
          <strong>1.6.</strong>
          We reserve the right to terminate your registration and / or refuse to register you or to provide you with access to the Service, without notice, if we have reasonable cause to believe that you are in violation of or are likely to violate these Terms.
        </p>
        <h3>2.  Grant of License & Reservation of Rights</h3>
        <p>
          <strong>2.1.</strong>
          We grant you a non-exclusive, personal, non-transferable, revocable, right and license, subject always to these Terms to access/use the Service solely for your personal, non-commercial use.
        </p>
        <p>
          <strong>2.2.</strong>
          Except for the limited rights and license granted to you by these Terms, all rights in the Service (including all intellectual property rights therein or underlying the same, including in the Website and the App) are reserved by us.
        </p>
        <h3>3.  Nature of Service & Limitations</h3>
        <h4>3.1.  No Doctor-Patient Relationship between Us</h4>
        <p>
          <strong>3.1.1.</strong>
          We do not practice medicine and do not provide medical consultation. We merely provide a technology platform and infrastructure that enables you to connect with participating medical practitioners listed on the Service and to use the messaging or communication facilities of the Service that are designed to enable you to communicate with others (‘Communication Tools”) to consult with a medical practitioner recommended to you.
        </p>
        <p>
          <strong>3.1.2.</strong>
          Medical practitioners listed on the Service have signed / accepted contracts with us and represented to us that they are duly qualified to practice medicine in India and are enrolled on the Medical Register in one of the States in India. While we make reasonable enquiries to confirm the veracity of this representation made to us by the medical practitioners, we shall not be responsible for any misrepresentation or fraud in this regard. We do not recommend or endorse any medical practitioners or make any representations or warranties with respect to the quality of the medical services a medical practitioner may provide to you.
        </p>
        <p>
          <strong>3.1.3.</strong>
          The use of the Service does not create a doctor/patient relationship between you and us. All medical consultation shall be provided to you by the concerned medical practitioner directly and as such, the medical practitioner you consult with shall be solely responsible for compliance with all requirements applicable to his or her professional services provided to you and liable to you for all medical consultation, medical advice, diagnosis or treatment recommended or provided to you. We shall not be responsible or liable for any defect or deficiency in the medical consultation, diagnosis or treatment recommended to you by any medical practitioner.
        </p>
        <p>
          <strong>3.1.4.</strong>
          Any diet plans or advice or information that may be provided on or through the Service is for information only, does not constitute a medical opinion or advice or a diagnosis or treatment and is not a substitute for professional medical advice, diagnosis or treatment.
        </p>
        <p>
          <strong>3.1.5.</strong>
          We are not responsible for the accuracy of the results of any Self – Assessment Tests made available as part the Service. These tests are solely for information purposes to suggest probable risks to users having a particular health issue and the results of the tests do not constitute a medical opinion or diagnosis. You should consult a medical professional before acting on the results of any such tests.
        </p>
        <h4>3.2.  Not for Medical Emergencies & Not a replacement for your Primary Care Physician</h4>
        <p>
          <strong>3.2.1.</strong>
          The Service we provide is NOT for use for medical emergencies, for life threatening conditions or for when you need acute care. You should not disregard or delay to seek medical advice from your physician based on anything that appears or is provided to you by or through the Service.
        </p>
        <p>
          <strong>3.2.2.</strong>
          The consultation provided to you by medical practitioners through the Communication Tools are not intended to replace your relationship with your primary care physician and you should continue to consult with your primary care physician and other health care professionals as needed / recommended. You should seek emergency help or follow-up care when recommended by a medical practitioner or when you are of the opinion that such help or care is prudent in your sole opinion.
        </p>
        <h4>3.3.  Limitations of Online Consultation and Informed Consent</h4>
        <p>
          <strong>3.3.1.</strong>
          When you seek consultation with any medical practitioner using the Communication Tools made available through the Service, you understand the limitations and potential risk of online consultations, including but not limited to the inability to carry out a physical examination or other extensive investigations; delays or failure arising from the disruption of communication devices or Communication Tools; the insufficient quality of information transmitted online, lack of access to your medical records or medical history amongst others and the inherent risks of breach of privacy associated with sharing your sensitive personal information or medical information through the Communication Tools. You take full responsibility for ensuring that any information you submit is complete and accurate and understand that the medical practitioner relies solely on information provided by you. We shall not be responsible for any errors in transmission or for the corruption or compromise of any information communicated over telecommunication services. Therefore, before sharing any information or acting on any information or advice offered to you by or through the Service, you should confirm that you have understood and accept the associated limitations and risks and agree that you are not using the Service as a substitute for consultation with your primary care physician.
        </p>
        <p>
          <strong>3.3.2.</strong>
          The transmission, storage and/or retrieval of data and information and the provision of the Service through the internet is subject to a variety of factors beyond our reasonable control, including any interruption or unavailability of third-party telecommunication services used to host / provide the Service. In no event will we be liable for any failure or interruption of the Service, including without limitation those failures or interruptions resulting in part or entirely from circumstances beyond our reasonable control.
        </p>
        <p>
          <strong>3.3.3.</strong>
          Without prejudice to the above, a medical practitioner may, in his or her sole discretion and professional judgment, determine that the online consultations through the Service are not appropriate for some or all of your needs and, accordingly, may elect not to provide services to you through the Service.
        </p>
        <h4>3.4.  No Sale or Offer to Sell Drugs /Medicines</h4>
        <p>
          <strong>3.4.1.</strong>
          We do not distribute, sell, stock, exhibit or offer for sale any drugs or medicinal preparations and merely allow you to use the functionality offered by the Service to transmit orders for drugs / medicinal preparations to third party pharmacies through us and pay for your purchases through us. If you are the customer of a third party online pharmacy that has in turn referred you to one of our participating medical practitioners for a consultation, you hereby consent and authorize the concerned medical practitioner and us to transmit any prescription/s that may be issued to you pursuant to your consultation, to the said third party online pharmacy. All offers for sale, orders and the sale of drugs / medicinal preparations shall be between you and the concerned third party pharmacy and we shall not be a party to the said transaction. Therefore, we shall not be responsible or liable for any defect or deficiency in the goods or the service provided to you by a pharmacy.
        </p>
        <p>
          <strong>3.4.2.</strong>
          Pharmacies who we deal with have represented to us that they are duly qualified and licensed to sell drugs and medicinal preparations as per applicable law in India. While we make reasonable enquiries to confirm the veracity of this representation made to us by the pharmacies, we shall not be responsible for any misrepresentation or fraud in this regard. We do not recommend or endorse any pharmacy or make any representations or warranties with respect to the quality of the drugs or medicinal preparation they may sell to you or their services in relation to the sale/purchase transaction.
        </p>
        <p>
          <strong>3.4.3.</strong>
          Medical practitioners may prescribe medications when medically indicated in their sole professional judgment. In the event that a medical practitioner does prescribe a medication, it will be his/her duty to comply with all applicable laws and we advise them to prescribe a medication as determined appropriate in his/her sole discretion and professional judgment. You agree that any prescriptions that you acquire from a medical practitioner by/through the Service shall be solely for your personal use. You agree to fully and carefully read and follow all instructions provided to you by the medical practitioner and all product information and labels and to contact a physician or pharmacist if you have any questions regarding the prescription.
        </p>
        <p>
          <strong>3.4.4.</strong>
          The third party pharmacy may require you to produce clear and valid prescriptions as required by law for the sale of drugs / medicinal preparations to you.
        </p>
        <h4>3.5.  No Diagnostic / Medical / Clinical Tests or Service</h4>
        <p>
          <strong>3.5.1.</strong>
          We do not provide diagnostic or medical or clinical test services. You may use the features available through the Service to book appointments with third party diagnostic laboratories / centers who deal with us. The medical / clinical test or diagnostic services are provided to you directly by the concerned laboratories or centers. We make no representations or claims, including as to the accuracy or quality, of the tests or results obtained by you from diagnostic laboratories / centers and shall not be responsible or liable for any defect or deficiency in the services provided to you by a diagnostic laboratory/ center.
        </p>
        <p>
          <strong>3.5.2.</strong>
          Diagnostic laboratories / centers who we deal with have represented to us that they are duly certified / licensed and have the necessary equipment, qualified personnel, experience and expertise to provide their services to you in India. While we make reasonable enquiries to confirm the veracity of this representation made to us by the diagnostic laboratories / centers, we shall not be responsible for any misrepresentation or fraud in this regard.
        </p>
        <h3>4.  Use of Service</h3>
        <p>
          <strong>4.1.</strong>
          You agree to use the Service (including the Communication Tools) only in accordance with these Terms and all applicable laws. You may not use the Service in any manner that could damage, disable, overburden or impair our computer systems or interfere with any other party’s use and enjoyment of any Service. You should not attempt to gain unauthorized access to any Service. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the Service.
        </p>
        <p>
          <strong>4.2.</strong>
          Without prejudice to the above, you agree not to host, display, upload, modify, publish, transmit, update or share any information that belongs to another person and to which you do not have any right to is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling,
          or otherwise unlawful in any manner whatever harm minors in any way infringes any patent, trademark, copyright, or other proprietary rights violates any law for the time being in force deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature impersonates another person contains software viruses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality of any computer resource; or threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.
        </p>
        <p>
          <strong>4.3.</strong>
          You are solely responsible for all information you transmit or share using the Communication Tools and we are not obliged to monitor the same. However, we reserve the right to review any information or materials you share using the Communication Tools and to remove (without notice to you) any information or materials that we find, in our sole discretion, violates any applicable law or these Terms. To this extent, you hereby waive doctor-patient confidentiality and expressly authorize us to review all information you transmit or share using the Communication Tools. In the event of non-compliance with the Terms, we may (without notice to you), immediately terminate your registration with us and/or prevent your access or use of the Service.
        </p>
        <h3>5.  Payment for Service</h3>
        <p>
          <strong>5.1.</strong>
          Some parts of the Service are chargeable as may be specified from time to time. We will tell you about applicable charges for any paid Service and the payment terms presented to you when you use or order a paid Service are deemed part of these Terms of Use. As a convenience to you, we also collect payments due to medical practitioners, pharmacies, and diagnostic laboratories / centers for their services that you avail or book through the Service. Subject to receipt of the payments from you, we will be responsible to pass on the payments due to these third parties. We reserve the right to change our charges and payment terms at any time provided however that the new charges will not be applicable to orders accepted before the change is notified. If you do not pay for paid Service on time, we reserve the right to suspend or cancel your order for the said Service or to not provide you with the ordered Service. We shall not be responsible for any Service that is not provided to you if you fail to pay the applicable charges for the same. Charges paid are non-refundable except in our sole discretion.
        </p>
        <p>
          <strong>5.2.</strong>
          We use a third-party payment gateway for processing payments due to us. The processing of payments will be subject to the terms, conditions, and privacy policies of the payment gateway, in addition to these Terms. We do not control and are not liable for the security or performance of the payment gateway. You agree to pay us, through the payment gateway, all charges at the rates then in effect for any purchase in accordance with the applicable payment terms. We reserve the right to correct, or to instruct our payment gateway to correct, any errors or mistakes, even if payment has already been requested or received.
        </p>
        <h3>6.  Your Data and Information</h3>
        <p>
          <strong>6.1.</strong>
          When you register with us and/or access the Service, you will be required to provide certain personal data and information about yourself to us. When you access or use the Service, we also collect and store certain information and data about you and your access/use. Our <a href="home/privacy" target="_blank"><u>Privacy Policy</u></a> explains what personal information and data we collect and how we treat your personal data and information and protect your privacy. The terms of our <a href="home/privacy" target="_blank"><u>Privacy Policy</u></a> are deemed incorporated in these Terms and forms a part of these Terms. Therefore, please read our <a href="home/privacy" target="_blank"><u>Privacy Policy</u></a> carefully. When you access or use the Service, you consent to the collection and use of your personal data and information in accordance with our <a href="home/privacy" target="_blank"><u>Privacy Policy</u></a>.
        </p>
        <p>
          <strong>6.2.</strong>
          Notwithstanding anything contained in these Terms, you hereby permit us to store all information you transmit or share using the Communication Tools (including your patient / medical records) and to share the same with medical practitioners who you consult with to enable them to render their medical services to you. You hereby permit us to transit prescriptions for medicines with pharmacies, including online medical pharmacies that refer you to us, to enable them to service your order for the drugs and medicinal preparations ordered by you. Subject to the above, all your medical / patient records pertaining to the services you avail through the Service shall remain your property.
        </p>
        <p>
          <strong>6.3.</strong>
          While placing an order for sale of Drugs / Medicinal Preparations with us, you fully authorize us to share your personal details like Name, Address, Phone Number etc. as well as the Medicine Prescription to third party online pharmacy for the purpose of processing your order. The same Third-Party Online Pharmacies may, in turn, store/use this information for their internal purposes. Also, on placing an order with us, you authorize the third-party online pharmacies to contact you via Phone/ SMS / Email for the purpose of fulfilling your order.
        </p>
        <p>
          <strong>6.4.</strong>
          We are requesting for your email address, so we can send you invoices for the transactions you make on Gigadocs and also to help you refer your friends and family.
        </p>
        <h3>7.  Intellectual Property Rights</h3>
        <p>
          We/our licensors own all copyright, trademarks, domain names and other intellectual property rights in or related to the Service and all its various components. Except for your personal data and information, all information, content, and material contained on the Website, the App and/or Service is our intellectual property. No information, content or material from any part of the Service may be copied, reproduced, republished, uploaded, recorded, posted, transmitted or distributed in any way (including by using any automated programs, software, or any other method of screen scraping), except for your personal and non-commercial use, without our express written permission.
        </p>
        <h3>8.  Changes to Service</h3>
        <p>
          We reserve the sole and complete discretion with respect to the operation of the Service and we may add, modify, suspend or discontinue any functionality or feature of the Service or any of the services provided by us without any notice to you.
        </p>
        <h3>9.  Third Party Links and Advertisements</h3>
        <p>
          <strong>9.1.</strong>
          The Service may contain links to third party websites/applications and third-party advertisements, that are not maintained or controlled by us. Third party links and advertisements are provided for your convenience only and does not imply endorsement or any representation or warranty by us to content, goods or services that may be offered by these third parties.
        </p>
        <p>
          <strong>9.2.</strong>
          When you click on any of these third-party links, you are leaving the Service and will be subject to the terms of use and privacy and security policies of the third-party site. You link to any such third-party website /applications entirely at your own risk.
        </p>
        <h3>10. Warranty Disclaimer</h3>
        <p>
          You acknowledge and agree that your use of the Service is solely at your own risk, and that you assume full responsibility for all risks associated therewith. All information, products or services contained on or provided through the Service are provided “as is”, without any warranty of any kind, express or implied. We do not make any warranty or representation as to the quality, quantity, potency, purity, standard, nature or manner of performance of any of the information, products or services contained on or provided through the Service. We hereby disclaim, to the maximum extent permitted by applicable law, all representations and warranties, express or implied, statutory or otherwise, including but not limited to warranties of merchantability, fitness for any particular purpose, title, non-infringement, reliability, accuracy, completeness, timeliness or that the availability of the Service will be uninterrupted, timely, secure, or error free.
        </p>
        <h3>11. Limitation & Exclusion of Liability</h3>
        <p>
          To the fullest extent permitted by law, in no event will we be liable for any direct, indirect, special, incidental, punitive, exemplary, or consequential damages, whether or not we have been warned of the possibility of such damages. In any event, our total aggregate liability to you, if any, will not exceed the charges paid to us by you (if any) for the particular Service in questions.
        </p>
        <h3>12. Termination</h3>
        <p>
          We reserve the right to suspend and/or terminate your access to the Service with or without notice if you breach these Terms. Upon suspension or termination, your right to procure the Service shall immediately cease and we reserve the right to remove or delete your information that is available with us, including but not limited to account details.
        </p>
        <h3>13. Notices</h3>
        <p>
          All notices to us shall be in writing and shall be made via e-mail to info@gigadocs.com or such other email address as notified in these Terms and all notices to you shall be made through the Service, via e-mail to the e-mail address you provide at the time of your registration or to your communication device registered with us.
        </p>
        <h3>14. Governing Law and Jurisdiction:</h3>
        <p>
          The interpretation of these Terms and the resolution of any dispute relating to your access or use of the Service shall be governed by the laws of India. If any action or other proceeding is brought on or in connection with these Terms or your access or use of the Service, the venue of such action shall be exclusively at the city of Hyderabad in the state of Telangana, to the exclusion of any other court that may otherwise have had such jurisdiction in the matter.
        </p>
        <h3>15. Miscellaneous:</h3>
        <p>
          If any term or provision of these Terms shall be declared invalid by a court of competent jurisdiction, then such provision will be interpreted, construed, or reformed to the extent reasonably required to render it valid, enforceable, and consistent with the original intent underlying such provision and the remainder of these Terms shall remain in full force and effect, according to its terms. The failure to exercise or enforce any right or provision of these Terms does not constitute a waiver of such right or provision. Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such clause. Your right to use the Service is personal to you and you shall not assign or transfer in any manner any of your rights, obligations or interest in these Terms or your subscription to any third party. We may freely assign our rights and obligations hereunder. Should there be any conflict between these Terms and any other notice/communication, the terms and conditions set forth in these Terms shall prevail, to the extent of such conflict. These Terms (including the <a href="home/privacy" target="_blank"><u>Privacy Policy</u></a> and other notices on the Website / the App, which are incorporated and deemed part of these Terms), constitute the entire agreement between us and supersedes all prior agreements regarding the subject matter hereof. When you use the Service and provide any information on or through the Service; or when you click any of the buttons available on the Website or the App; or when you send an e-mail to us, you are communicating with us through electronic records. You hereby consent that such electronic records, whether sent by you or automatically generated by the computer system when you click on any of the buttons available on the Website or the App or the Service, shall be deemed to be communications sent in writing by you.
        </p>
        <h3>16. Grievance Redressal:</h3>
        <p>
          If you have any concern or grievance with respect to the Service, please e-mail us at support@gigadocs.com and we will study the matter and take such action as we deem appropriate under the circumstances.
        </p>
      </div>
    </div>
  </div>
</section>

<app-footer lat="17.4611" long="78.355797"></app-footer>
