<app-header></app-header>

<div class="page-content">

  <div class="container">
    <div class="row">
      <div class="container">
        <h1 class="text-center text-info">Vaccination Schedule Chart</h1>
      </div>
      <div class="container">
        <h5 class="text-center text-success text-heading">Please enter the Date of Birth of your kid to generate the complete Vaccination Chart</h5>
      </div>
    </div>
    <div class="container row">
      <div class="col-md-12">
        <form [formGroup]="immunizationSchedule">
          <div class="flex-row">
            <div class="form-group col-md-5 required">
              <input type="text" formControlName="dob" class="form-control" bsDatepicker [bsConfig]="bsConfig" placeholder="Select Date of Birth" placement="bottom"
                     [minDate]="minDate" [maxDate]="maxDate" [ngClass]="{ 'is-invalid': submitted && form.dob.errors }"/>
              <div *ngIf="submitted && form.dob.errors" class="invalid-feedback">
                <div *ngIf="form.dob.errors.required">Date Of Birth is required</div>
              </div>
            </div>
            <div class="form-group col-md-3">
              <button class="btn btn-primary" type="button" (click)="generateChart()">Generate Chart</button>
            </div>
            <div class="form-group col-md-3" *ngIf="chart">
              <a href="setReminderModal" data-toggle="modal" data-target="#setReminderModal" id="try" data-dismiss="modal" (click)="clearForm()">
                <button class="setreminder btn btn-primary">Set Reminder</button>
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="limiter">
        <div class="container-table100">
          <div class="wrap-table100">
            <div class="table100 ver1 m-b-110">
              <table data-vertable="ver1">
                <thead>
                <tr class="row100 head">
                  <th class="column100 column1" data-column="column1">Age</th>
                  <th class="column100 column2" data-column="column2">Vaccines</th>
                  <th class="column100 column3" data-column="column3">Scheduled Date</th>
                </tr>
                </thead>
                <tbody>
                <tr class="row100">
                  <td class="column100 column1" data-column="column1">Birth</td>
                  <td class="column100 column2" data-column="column2">
                    OPV 0<br>
                    BCG<br>
                    Hep-B 1
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_1'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">6 Weeks</td>
                  <td class="column100 column2" data-column="column2">
                    IPV 1 / OPV<br>
                    DPT / DTaP 1<br>
                    Hib 1<br>
                    Hep-B 2<br>
                    Rotavirus 1<br>
                    PCV 1
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_2'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">10 Weeks</td>
                  <td class="column100 column2" data-column="column2">
                    IPV 2 / OPV<br>
                    DPT / DTaP 2<br>
                    Hib 2<br>
                    Rotavirus 2<br>
                    PCV 2
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_3'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">14 Weeks</td>
                  <td class="column100 column2" data-column="column2">
                    IPV 3 / OPV <br>
                    DPT / DTaP 3 <br>
                    Hib 3 <br>
                    Rotavirus 3 <br>
                    PCV 3 <br>
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_4'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">6 Months</td>
                  <td class="column100 column2" data-column="column2">
                    OPV 1 <br>
                    Hep-B 3 <br>
                    Dental Hygiene Awareness
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_5'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">9 Months</td>
                  <td class="column100 column2" data-column="column2">
                    OPV 2 <br>
                    MMR 1
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_6'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">12 Months</td>
                  <td class="column100 column2" data-column="column2">
                    Typhoid Conjugate Vaccine <br>
                    Hep-A 1</td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_7'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">15 Months</td>
                  <td class="column100 column2" data-column="column2">
                    MMR 2 <br>
                    Varicella 1 <br>
                    PCV booster
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_8'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">18 Months</td>
                  <td class="column100 column2" data-column="column2">
                    IPV 1 / OPV Booster <br>
                    DTwP / DTaP 1 Booster <br>
                    Hib 1 Booster <br>
                    Hep-A 2
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_9'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">2 Years</td>
                  <td class="column100 column2" data-column="column2">
                    Typhoid Conjugate
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_10'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">3 Years</td>
                  <td class="column100 column2" data-column="column2">
                    Varicella 2
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_11'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">5 Years</td>
                  <td class="column100 column2" data-column="column2">
                    OPV 3 <br>
                    DPT / DTaP 2 Booster
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_12'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1">10 Years</td>
                  <td class="column100 column2" data-column="column2">
                    Tdap/Td <br>
                    HPV (2-Doses)
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_13'] | ordinal}}
                  </td>
                </tr>

                <tr class="row100">
                  <td class="column100 column1" data-column="column1"> >15 Years</td>
                  <td class="column100 column2" data-column="column2">
                    HPV (3-Doses)
                  </td>
                  <td class="column100 column3" data-column="column3" *ngIf="vaccineData['id'] > 0">
                    {{vaccineData['scheduled_date_14'] | ordinal}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div id="setReminderModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
    <div class="modal-dialog modal-reminder modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" #closeReminder class="close" (click)="clearForm()" data-dismiss="modal" aria-hidden="true">
            &times;
          </button>
          <h5 class="modal-title">Set Reminder</h5>
        </div>
        <div class="modal-body">
          <div class="login-modal-wrap">
            <div class="alert alert-success" *ngIf="validNumber">
              OTP Sent Successfully
            </div>
            <div *ngIf="sendingOtp" class="alert alert-warning">
              <span class="spinner-border spinner-border-sm"></span>
              Please wait, Processing...
            </div>
            <div class="alert alert-danger" *ngIf="otpInvalid">
              Incorrect OTP
            </div>
            <div class="alert alert-success" *ngIf="otpVerified">
              OTP has been verified successfully
            </div>
            <div class="alert alert-success" *ngIf="message">
              {{message}}
            </div>
            <form id="login-form" [formGroup]="mobileVerificationForm">
              <div class="form-group required" *ngIf="!setReminder">
                <label class="control-label">Mobile</label>
                <input type="text" class="form-control" placeholder="Enter Mobile Number" formControlName="mobile"
                       required="required" [ngClass]="{ 'is-invalid': (submitted) && login.mobile.errors }">
                <div *ngIf="(submitted) && login.mobile.errors" class="invalid-feedback">
                  <div *ngIf="login.mobile.errors.required">Mobile is required</div>
                  <div *ngIf="login.mobile.errors?.pattern" class="text-danger">
                    Invalid Mobile number
                  </div>
                </div>
              </div>
              <div class="form-group required" *ngIf="isShow">
                <label class="control-label">OTP</label>
                <input type="text" class="form-control" placeholder="Enter code" formControlName="loginOtp"
                       required="required" [ngClass]="{ 'is-invalid': (submitted) && login.loginOtp.errors }">
                <div *ngIf="(submitted) && login.loginOtp.errors" class="invalid-feedback">
                  <div *ngIf="login.loginOtp.errors.required">OTP is required</div>
                  <div *ngIf="login.loginOtp.errors?.pattern" class="text-danger">
                    Invalid OTP
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="setReminder">
                <label>Mobile : {{mobile}}</label>
              </div>
              <div class="form-group required" *ngIf="otpSignup">
                <label class="control-label">Full Name</label>
                <input type="text" class="form-control" placeholder="Enter Full Name" formControlName="name"
                       required="required" [ngClass]="{ 'is-invalid': (submitted) && login.name.errors }">
                <div *ngIf="(submitted) && login.name.errors" class="invalid-feedback">
                  <div *ngIf="login.name.errors.required">Full Name is required</div>
                </div>
              </div>
              <div class="form-group" *ngIf="otpSignup">
                <label class="control-label">Email</label>
                <input type="email" class="form-control" placeholder="Enter Email" formControlName="email">
                <small class="text-danger" *ngIf="login.email.touched && login.email.errors?.email">Please enter a valid email address</small>
              </div>
              <div class="form-group required" *ngIf="otpSignup">
                <label class="control-label">Password</label>
                <input type="password" class="form-control" placeholder="Enter Password" formControlName="password"
                       required="required" [ngClass]="{ 'is-invalid': (submitted) && login.password.errors }">
                <div *ngIf="(submitted) && login.password.errors" class="invalid-feedback">
                  <div *ngIf="login.password.errors.required">Password is required</div>
                  <div *ngIf="login.password.errors?.minlength" class="text-danger">
                    Password atleast 6 characters
                  </div>
                </div>
              </div>
              <div class="form-group required" *ngIf="otpSignup || loggedIn">
                <label class="control-label">Child Name</label>
                <input type="text" class="form-control" placeholder="Enter Child Name" formControlName="childname"
                       required="required" [ngClass]="{ 'is-invalid': (submitted) && login.childname.errors }">
                <div *ngIf="(submitted) && login.childname.errors" class="invalid-feedback">
                  <div *ngIf="login.childname.errors.required">Child Name is required</div>
                </div>
              </div>
              <div class="form-group" *ngIf="otpSignup || loggedIn">
                <label>Child Gender</label><br>
                <label class="ui-radiobutton"><input type="radio" value="male" id="male" formControlName="gender"> Male</label>
                <label class="ui-radiobutton"><input type="radio" value="female" id="female" formControlName="gender"> Female</label>
                <label class="ui-radiobutton"><input type="radio" value="other" id="other" formControlName="gender"> Other</label>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <a *ngIf="isShow && otpCount < 4" href="javascript: void(0)" (click)="sendingReminderOTP()">Resend OTP</a>
          <div class="row align-items-center">
            <div class="col-sm-7" *ngIf="sendOTP">
              <input type="button" class="btn btn-primary" (click)="sendingReminderOTP()" value="Send OTP">
            </div>
            <div class="col-sm-7" *ngIf="logIn">
              <input type="button" class="btn btn-primary" (click)="setReminderVerification()" value="Verify OTP">
            </div>
            <div class="col-sm-7" *ngIf="loggedIn && setReminder">
              <input type="button" class="btn btn-primary" (click)="setVaccinationReminder()" value="Set Reminder">
            </div>
            <div class="col-sm-7" *ngIf="otpSignup && setReminder">
              <input type="button" class="btn btn-primary" (click)="setVaccinationReminder('signup')" value="Sign Up & Set Reminder">
            </div>
            <div class="col-sm-5">
              <button type="button" #closeReminder class="btn btn-default pull-right closeBtn" data-dismiss="modal" aria-hidden="true">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<app-footer lat="17.4611" long="78.355797"></app-footer>
