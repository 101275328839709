import { ActivatedRoute,Router } from '@angular/router';
import { Component, OnInit, ViewChild, OnDestroy, HostListener} from '@angular/core';
import { UserService } from '../service/user.service';
import { DatePipe } from '@angular/common';
import {Observable, Observer, of, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import {CamelcasePipe} from "../camelcase.pipe";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../models";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {Calendar} from "primeng/calendar";
//import { query } from '@angular/animations';

declare const inclinic:any;
declare const video:any;
declare const initMap:any;

@Component({
  selector: 'app-vcresults',
  templateUrl: './vcresults.component.html',
  styleUrls: ['./vcresults.component.css'],
})
export class VCresultsComponent implements OnInit, OnDestroy {

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow
  @ViewChild('calendarcall') calendarCall: Calendar;
  center: google.maps.LatLngLiteral;
  markers:any=[];
  datePicker: Date;
  datePickerVC: Date;
  city: any;
  specName:any;
  specs: any;
  city1: any;
  private areas: Subscription;
  private spec: Subscription;
  brand: string;
  area:any;
  speciality:any;
  list=[];
  filteredSpeciality: any[];
  name: string;
  data: any;
  myJson:any = [];
  isArea:boolean = false;
  pageNumber:number
  pageNo:number = 1;
  location: any;
  lat: number;
  longitude: number;
  SResults: any = [];
  resultsDisp: any =[]
  pageResults: any =[]
  address:any = [];
  docName: string;
  doc: any;
  docDetails:boolean=false;
  showSlider:boolean=false;
  clinicTab:boolean;
  mobileWidth:number  = 1024;
  results:boolean=true;
  apply:boolean=true;
  apply1:boolean=false;
  workingHours=[];
  docId: any;
  docService: any;
  clinicInfo:any=[];
  j:any;
  clinicInfo1:any=[];
  clinicName: any;
  clinicAddress: any;
  clinicCity: any;
  clinicArea:any;
  selectedIndex:any;
  inClinic: any;
  isOnline: any;
  isOnCall: any = '0';
  isSubscribed: any;
  selectedInClinic: any;
  selectedIsOnline: any;
  VC: boolean;
  todayDate;
  tomorrowDate;
  clinicId: any;
  slotType: any;
  date: any;
  morningSlots: any=[];
  afternoonSlots: any=[];
  eveningSlots: any=[];
  emptyresults:any[];
  minDate: Date;
  maxDate: Date;
  morningSlotsLength: any;
  afternoonSlotsLength: any;
  eveningSlotsLength: any;
  selectedTime: any;
  docUrl: any;
  appointmentDate: string;
  areaString: any;
  hours: any;
  time: any;
  contentLoaded=false;
  eveningSlotsLengthVC: any;
  eveningSlotsVC: any[];
  morningSlotsLengthVC: any;
  afternoonSlotsLengthVC: any;
  morningSlotsVC: any[];
  afternoonSlotsVC: any[];
  applyVC: boolean;
  apply1VC: boolean;
  count: any;
  vcSpinner: boolean;
  clickedVC=false;
  clickedInclinic=false;
  vcColor:boolean;
  public href: string = "";
  scrHeight:any;
  scrWidth:any;
  notaResponsiveButton: boolean;
  responsiveButton: boolean;
  dateHighlight: boolean=false;
  vcWorkingHours = [];
  specialityInHeader: any;
  clinicFee: any;
  urlparameters: any;
  resultsFound: boolean = false;
  resultsNotFound: boolean = false;
  errorMsg: any;
  submitted: boolean = false;
  user: User;
  message: any = '';
  notLoggedin:boolean=true;
  isLoggedin=false;
  title: string;
  todayCall: boolean = true;
  tomorrowCall: boolean = false;
  dateHighlightCall: boolean = false;
  datePickerCall: Date;
  sortOrder: string = '';
  isFilters: boolean = false;
  filterNear: boolean = true;
  filterFee: boolean = false;
  userToken: string;
  btnDisable: boolean = true;
  showClinic: number;
  showOnline: number;
  mapDisplay: boolean = true;
  width: number = window.outerWidth;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  inclinicWaitSpinner: boolean;
  vcWaitSpinner: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.screenDimensions();
  }

  constructor(
    private activatedroute:ActivatedRoute,
    private router:Router,
    private Userservice:UserService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private camelcase: CamelcasePipe
  ) {
    this.loginCheck();
    this.getScreenSize();
  }

  loginCheck() {
    if (this.Userservice.userValue) {
      this.notLoggedin = false;
      this.userToken = this.Userservice.dashboardURL + 'user/dashboard/?token=' +this.Userservice.userValue['details']['userId'];
      this.isLoggedin = true;
    }
  }

  ngOnInit(): void {
    this.getSpecs();
    var details = {
      metaTitle: 'Video Consultation, Virtual Consultation and Online Consultation | Gigadocs',
      metaDescription: 'Online consultation through video consultation. Virtual consultation with doctors online. Best quality video consultation. Receive online prescription through online consultation. Online Doctor Consultations can be booked through online appointment booking. Consult a doctor online.',
      ogTitle: 'Video Consultation, Virtual Consultation and Online Consultation | Gigadocs',
      ogDescription: 'Online consultation through video consultation. Virtual consultation with doctors online. Best quality video consultation. Receive online prescription through online consultation. Online Doctor Consultations can be booked through online appointment booking. Consult a doctor online.',
    };
    this.Userservice.setDynamicMetaData(details);
    this.emptyresults = Array(5);
    this.vcSpinner = false;
    let today = new Date();
    this.todayDate = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowDate = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.minDate = new Date(today);
    this.maxDate = new Date(today.setDate(today.getDate() + 14));
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' });
    if(localStorage.getItem('vcfee')) {
      this.sortOrder = 'asc';
      this.isFilters = true;
      this.filterFee = true;
    }
    this.params();
  }

  inclinic() {
    $('#selection').css({'left': '218px'});
    $('#inclinic').css({'color': 'white'});
    $('#vc').css({'color': 'black'});
    this.clickedInclinic = true;
    this.clickedVC = false;
    this.vcColor = false;
    this.vcSpinner = true;
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
    this.VC = false;
    this.clinicTab = true;
    this.selectedIndex = -1;
    this.datePicker = null;
    this.dateHighlight = false;
    this.apply = true;
    this.apply1 = false;
    let date = this.todayDate;
    let slot = 'inclinic';
    this.availableSlots(date, slot);
  }

  video() {
    $('#selection').css({'left': '0px'});
    $('#vc').css({'color': 'white'});
    $('#inclinic').css({'color': 'black'});
    this.clickedVC = true;
    this.clickedInclinic = false;
    this.vcSpinner = true
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide()
    }, 500);
    this.clinicTab = false;
    this.VC = true;
    this.selectedIndex = -1
    this.datePickerVC = null;
    this.dateHighlight = false;
    this.applyVC = true;
    this.apply1VC = false;
    let date = this.todayDate;
    let slot = 'vc';
    this.availableSlotsVC(date, slot);
  }

  toggle() {
    $(this).toggleClass('shown');
    $('.rs-container').toggle()
  }

  screenDimensions() {
    if (this.scrWidth <= this.mobileWidth && this.scrWidth >= 767) {
      this.results = true;
      this.mapDisplay = false;
    } else {
      this.mapDisplay = true;
      this.results = true;
      this.docDetails = false;
    }
    if (this.scrWidth < 766) {
      this.notaResponsiveButton = false;
      this.responsiveButton = true;
    } else {
      this.notaResponsiveButton = true;
      this.responsiveButton = false;
      $('.sr-card').removeClass('selected');
    }
  }

  params() {
    this.contentLoaded = false;
    this.activatedroute.params.subscribe(params => {
      this.urlparameters = params;
      if (params !== null && params['pageNo']) {
        this.pageNo = params['pageNo'];
      } 
      else {
        this.pageNo = 1;
      }
      if (params['specs']) {
        let re = /\s+/g;
        this.speciality = this.camelcase.transform(params['specs']);
        this.specialityInHeader = this.speciality;
        this.specs = this.speciality;
        this.specName = this.specialityInHeader.toLowerCase().replace(re, "-");
      } 
      if (this.width <= this.mobileWidth) {
        this.mapDisplay = false;
      } else {
        this.mapDisplay = true;
      }
      this.vcResults();
    })
  }

  vcResults() {
    if (localStorage.getItem('vcfee')) {
      this.sortOrder = 'asc';
    }
    this.Userservice.getVCDocList(this.specs, this.pageNo - 1, this.sortOrder).subscribe((response) => {
      if (response == null) {
        this.resultsNotFound = true;
        this.resultsFound = false;
      } else {
        this.count = response['count'];
        this.pageResults = response;
        this.resultsDisp.splice(0, this.resultsDisp.length);
        if (this.pageResults) {
          this.resultsFound = true;
          this.resultsNotFound = false;
          let date = new Date();
          let year = date.getFullYear();
          for (let i = 0; i < this.pageResults['details']['length']; i++) {
            if (this.pageResults['details'][i]['doctorExperience'] != 0) {
              let exp = year - this.pageResults['details'][i]['doctorExperience'];
              this.pageResults['details'][i]['doctorExperience'] = exp;
            } else {
              this.pageResults['details'][i]['doctorExperience'] = 0;
            }
            this.resultsDisp.push(this.pageResults['details'][i]);
            this.address.push({
              clinicId: this.pageResults['details'][i]['clinicId'],
              clinicName: this.pageResults['details'][i]['clinicName'],
              fullAddress: this.pageResults['details'][i]['clinicAddress'].replace('#', '') + ','
                + this.pageResults['details'][i]['clinicArea'] + ','
                + this.pageResults['details'][i]['clinicCity']
            })
            let languages = [];
            if(this.pageResults['details'][i]['doctorLanguage'].length > 0) {
              languages = this.pageResults['details'][i]['doctorLanguage'].map(item => item.name);
              this.pageResults['details'][i]['doctorLanguage'] = languages;
            }
          }
        }
        this.contentLoaded = true;
      }
    },
    error => {
      this.errorMsg = error;
      if (this.errorMsg) {
        this.resultsNotFound = true;
        this.resultsFound = false;
      }
    })
  }

  doctorProfile(index) {
    let i = index;
    let location = this.pageResults['details'][i]['clinicCity'];
    this.city = location.toLowerCase();
    this.docUrl = this.pageResults['details'][i]['doctorUrl'];
    this.router.navigate(['/doctors/', this.city, this.docUrl])
  }

  getSpecs() {
    this.Userservice.getSpec().subscribe(result => {
      this.data = result;
      let input = this.data.details;
      for (let i = 0; i < input.length; i++) {
        if (input[i].type == 'doctor') {
          this.myJson = {"name": input[i].name, "type": input[i].type, "url": input[i].name_url, "city": input[i].city};
        } else {
          this.myJson = {"name": input[i].name, "type": input[i].type};
        }
        this.list.push(this.myJson);
      }
    })
  }

  filteredSpeciality$ = new Observable((observer: Observer<string>) => {
    observer.next(this.speciality);
  }).pipe(
    switchMap(v =>
      of(this.list.filter(item => (item.name && item.name.toLowerCase().indexOf(v.toLowerCase()) !== -1)))
    )
  );

  selectedSpec(evt:any) {
    this.docDetails = false;
    let re = /\s+/g;
    let name = evt.item.name;
    this.specName = name.toLowerCase().replace(re, "-");
    if (evt.item.type == 'doctor' && evt.item.url) {
      this.router.navigateByUrl('doctors/' + evt.item.city.toLowerCase().replace(re, "-") + '/' + evt.item.url);
    } else {
      this.router.navigateByUrl('virtualconsultation/' + this.specName + '/doctors');
    }
  }

  resetDisplay() {
    this.showSlider = false;
    this.clinicTab = false;
    this.VC = false;
    this.datePicker = null;
    this.apply = true;
    this.applyVC = true;
    this.apply1 = false;
    this.selectedIndex = -1;
    this.selectedTime = null;
    this.datePickerVC = null;
    this.apply1VC = false;
    this.clickedVC = true;
    this.clickedInclinic = false;
    this.dateHighlight = false;
  }

  doctorDetails(i, clinicId, showClinic = 0, showOnline = 0) {
    this.resetDisplay();
    this.clearArraydata();
    this.inClinic = this.resultsDisp[i]['isInClinic'];
    this.isOnline = this.resultsDisp[i]['isOnline'];
    this.isOnCall = this.resultsDisp[i]['doctorOnCall'];
    this.docId = this.resultsDisp[i]['doctorId'];
    this.docUrl = this.resultsDisp[i]['doctorUrl'];
    this.docName = this.resultsDisp[i]['doctorName'];
    this.docService = this.resultsDisp[i]['doctorService'];
    this.clinicCity = this.resultsDisp[i]['clinicCity'];
    this.showClinic = showClinic;
    this.showOnline = showOnline;
    this.j = 0;
    this.clinicInfo.splice(0, this.clinicInfo.length);
    this.workingHours.splice(0, this.workingHours.length);
    if (this.scrWidth > 767) {
      $('.sr-card').removeClass('selected');
      $('.sr-card').eq(i).closest('.sr-card').addClass('selected');
    }
    if (this.scrWidth <= this.mobileWidth) {
      this.results = false;
      this.mapDisplay = false;
      this.Details(clinicId);
      this.docDetails = true;
    } else {
      this.Details(clinicId);
      this.mapDisplay = false;
      this.docDetails = true;
    }
    window.scrollTo(0,0);
  }

  Details(clinicId) {
    this.Userservice.clinicDetails(this.docId).subscribe((response) => {
      this.clinicInfo = response['details'];
      let index = this.clinicInfo.findIndex((element, index) => {
        if (element.id === clinicId) {
          return true;
        }
      })
      this.clinicName = this.clinicInfo[index]['name'];
      this.clinicFee = this.clinicInfo[index]['fee'];
      this.clinicAddress = this.clinicInfo[index]['address'] + ',' + this.clinicInfo[index]['area'] + ',' + this.clinicInfo[index]['city'];
      this.workingHours = this.clinicInfo[index]['working_hours'];
      this.vcWorkingHours = this.clinicInfo[index]['vc_working_hours'];
      this.clinicId = this.clinicInfo[index]['id'];
      this.isSubscribed = this.clinicInfo[index]['is_subscribed'];
      this.selectedInClinic = this.clinicInfo[index]['isInClinic'];
      this.selectedIsOnline = this.clinicInfo[index]['isOnline'];
      this.inClinic = this.clinicInfo[index]['isInClinic'];
      this.isOnline = this.clinicInfo[index]['isOnline'];
      this.isOnCall = this.clinicInfo[index]['onCall'];
      this.showSlots();
    })
  }

  handleChange(k) {
    this.resetDisplay();
    this.clinicId = k;
    this.clinicInfo1 = this.clinicInfo;
    for (let i = 0; i < this.clinicInfo1['length']; i++) {
      if (this.clinicId == this.clinicInfo1[i]['id']) {
        this.j = i;
        this.clinicName = this.clinicInfo[i]['name'];
        this.clinicFee = this.clinicInfo[this.j]['fee'];
        this.clinicAddress = this.clinicInfo[i]['address'] + ',' + this.clinicInfo[i]['area'] + ',' + this.clinicInfo[i]['city'];
        this.workingHours = this.clinicInfo[this.j]['working_hours'];
        this.vcWorkingHours = this.clinicInfo[this.j]['vc_working_hours'];
        this.clinicId = this.clinicInfo[i]['id'];
        this.isSubscribed = this.clinicInfo[i]['is_subscribed'];
        this.selectedInClinic = this.clinicInfo[i]['isInClinic'];
        this.selectedIsOnline = this.clinicInfo[i]['isOnline'];
        this.showClinic = this.clinicInfo[i]['showClinicSlots'];
        this.showOnline = this.clinicInfo[i]['showOnlineSlots'];
        this.inClinic = this.clinicInfo[i]['isInClinic'];
        this.isOnline = this.clinicInfo[i]['isOnline'];
        this.isOnCall = this.clinicInfo[i]['onCall'];
      }
    }
    $('#vc').css({'color': 'white'});
    $('#inclinic').css({'color': 'black'});
    $('#selection').css({'left': '0px'});
    this.showSlots();
  }

  showSlots() {
    if (this.isSubscribed == "1") {
      if ((this.selectedInClinic && this.showClinic) && (this.selectedIsOnline && this.showOnline)) {
        this.showSlider = true;
        this.vcColor = true;
        this.VC = true;
        if (this.selectedIsOnline && this.showOnline) {
          let slot = 'vc';
          this.date = this.todayDate;
          this.availableSlotsVC(this.date, slot)
        }
      } else if(this.selectedIsOnline && this.showOnline) {
        this.vcColor = true;
        this.VC = true;
        let slot = 'vc';
        this.date = this.todayDate;
        this.availableSlotsVC(this.date, slot);
      } else {
        this.clinicTab = true;
        this.VC = false;
        this.selectedInClinic == "1" ? this.slotType = 'inclinic' : this.selectedIsOnline == "1" ? this.slotType = 'vc' : this.slotType = "null";
        this.date = this.todayDate;
        this.availableSlots(this.date, this.slotType);
      }
    } else {
      this.showSlider = false;
      this.clinicTab = false;
      this.VC = false;
    }
  }

  availableSlots(date:any,slot:any) {
    this.Userservice.getClinicslots(this.docId, this.clinicId, date, slot).subscribe((response) => {
      if (slot == "inclinic") {
        if (response['details']['status']==false){
          this.morningSlotsLength = 0;
          this.afternoonSlotsLength = 0;
          this.eveningSlotsLength = 0;
        }else{
          this.morningSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningSlotsLength = response['details'][0]['evening']['length'];
        }
        this.inclinicWaitSpinner = this.vcWaitSpinner = false;
        this.morningSlots = this.timeConversion(response['details'][0]['morning']);
        this.afternoonSlots = this.timeConversion(response['details'][0]['afternoon']);
        this.eveningSlots = this.timeConversion(response['details'][0]['evening']);
      } else if (slot == "vc") {
        if (response['details']['status']==false){
          this.morningSlotsLength = 0;
          this.afternoonSlotsLength = 0;
          this.eveningSlotsLength = 0;
        }else{
          this.morningSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningSlotsLength = response['details'][0]['evening']['length'];
        }
        this.inclinicWaitSpinner = this.vcWaitSpinner = false;
        this.morningSlots = this.timeConversion(response['details'][0]['morning']);
        this.afternoonSlots = this.timeConversion(response['details'][0]['afternoon']);
        this.eveningSlots = this.timeConversion(response['details'][0]['evening']);
      }
    })
  }

  availableSlotsVC(date:any,slot:any) {
    this.Userservice.getClinicslots(this.docId, this.clinicId, date, slot).subscribe((response) => {
      if (slot == "vc") {
        if (response['details']['status']==false){
          this.morningSlotsLengthVC = 0;
          this.afternoonSlotsLengthVC = 0;
          this.eveningSlotsLengthVC = 0;
        }else{
          this.morningSlotsLengthVC = response['details'][0]['morning']['length'];
          this.afternoonSlotsLengthVC = response['details'][0]['afternoon']['length'];
          this.eveningSlotsLengthVC = response['details'][0]['evening']['length'];
        }
        this.inclinicWaitSpinner = this.vcWaitSpinner = false;
        this.morningSlotsVC = this.timeConversion(response['details'][0]['morning']);
        this.afternoonSlotsVC = this.timeConversion(response['details'][0]['afternoon']);
        this.eveningSlotsVC = this.timeConversion(response['details'][0]['evening']);
      }
    })
  }

  timeConversion(slot:any=[]) {
    while (slot.length >= 1) {
      let convertedTime: any[] = [];
      for (let i = 0; i < slot['length']; i++) {
        this.time = slot[i].split(":", 3);
        let ampm = this.time[0] >= 12 ? 'PM' : 'AM';
        ampm == 'PM' && this.time[0] == 12 ? this.hours = 12 : this.hours = this.time[0] % 12;
        this.hours < 10 ? this.hours = '0' + this.hours : this.hours;
        convertedTime.push(this.hours + ':' + this.time[1] + ' ' + ampm);
      }
      return convertedTime;
    }
  }

  checkSlotType(slot1:string,slot2:string,date:any) {
    if (slot2 === '0') {
      this.availableSlotsVC(date, slot1)
    } else {
      if (this.selectedInClinic == "1") {
        this.availableSlots(date, slot1)
      } else if (this.selectedIsOnline == "1") {
        this.availableSlots(date, slot2)
      }
    }
  }

  today(slot1:string,slot2:string) {
    this.clearArraydata();
    this.vcSpinner = true;
    // this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 500);
    this.datePicker = null;
    this.datePickerVC = null;
    this.selectedIndex = -1;
    if (slot2 != '0') {
      this.apply = true;
      this.apply1 = false;
    } else {
      this.applyVC = true;
      this.apply1VC = false;
    }
    this.date = this.todayDate;
    this.checkSlotType(slot1, slot2, this.date);
  }

  todayOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowCall = false;
    this.todayCall = true;
  }

  tomorrow(slot1:string,slot2:string) {
    this.clearArraydata();
    this.vcSpinner = true
    // this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 500);
    this.datePicker = null;
    this.datePickerVC = null;
    this.selectedIndex = -1;
    if (slot2 != '0') {
      this.apply1 = true;
      this.apply = false;
    } else {
      this.applyVC = false;
      this.apply1VC = true;
    }
    this.date = this.tomorrowDate;
    this.checkSlotType(slot1, slot2, this.date);
  }

  tomorrowOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    let today = new Date();
    this.date = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = true;
  }

  Time(index,time) {
    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.btnDisable = true;
    } else {
      this.selectedIndex = index;
      this.selectedTime = time;
      this.btnDisable = false;
    }
  }

  CallTime(index, time, slot) {
    this.slotType = slot;
    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.btnDisable = true;
    } else {
      this.selectedIndex = index;
      this.selectedTime = time;
      this.btnDisable = false;
    }
  }

  selectedDate(event:any,slot1:string,slot2:string) {
    this.clearArraydata();
    if (slot2 != '0') {
      this.datePickerVC = null;
      this.apply = false;
      this.apply1 = false;
    } else {
      this.datePicker = null;
      this.applyVC = false;
      this.apply1VC = false;
    }
    this.dateHighlight = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.checkSlotType(slot1, slot2, this.date);
  }

  selectedOnCallDate(event: any, slot: string) {
    this.dateHighlightCall = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = false;
  }

  confirmAppointment(slot1:string,slot2:string) {
    if (this.selectedTime != null) {
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
      }, 3000);
      this.appointmentDate = this.date;
      if (this.selectedTime != null && this.appointmentDate != null) {
        if (slot2 === '0') {
          this.slotType = slot1;
        } else {
          if (this.selectedInClinic == "1") {
            this.slotType = slot1;
          } else if (this.selectedIsOnline == "1") {
            this.slotType = slot2;
          }
        }
        this.router.navigate(['appointment/', this.docUrl], {
          queryParams: {
            clinicId: this.clinicId,
            slot: this.slotType,
            on: this.appointmentDate,
            at: this.selectedTime
          }
        })
      }
    } else {
      return false;
    }
  }

  onCallAppointment() {
    if (this.selectedTime != null) {
      this.router.navigate(['appointment/', this.docUrl], {
        queryParams: {
          doctorId: this.docId,
          clinicId: this.clinicId,
          on: this.date,
          at: this.selectedTime,
          slot: this.slotType
        }
      })
    } else {
      return false;
    }
  }

  clearArraydata() {
    this.btnDisable = true;
    this.morningSlots = [];
    this.afternoonSlots = [];
    this.eveningSlots = [];
    this.morningSlotsLength = 0;
    this.afternoonSlotsLength = 0;
    this.eveningSlotsLength = 0;
    this.inclinicWaitSpinner = true;    
    
    this.morningSlotsVC = [];
    this.afternoonSlotsVC = [];
    this.eveningSlotsVC = [];
    this.morningSlotsLengthVC = 0;
    this.afternoonSlotsLengthVC = 0;
    this.eveningSlotsLengthVC = 0;
    this.vcWaitSpinner = true;
  }

  pgcontrolsroute(pgN) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (this.specName){
      this.router.navigateByUrl('virtualconsultation/' + this.specName + '/doctors/' + pgN);
    }else{
    this.router.navigateByUrl('virtualconsultation/doctors/' + pgN);
    }
  }

  getFilterResults(type, order) {
    this.isFilters = true;
    if (type == 'fee') {
      this.filterFee = !this.filterFee;
      if (this.filterFee) {
        localStorage.setItem('vcfee', 'asc');
        this.sortOrder = order;
      } else {
        this.isFilters = false;
        localStorage.removeItem('vcfee');
        this.sortOrder = '';
      }
      this.params();
    }
    if (type == 'near') {
      this.isFilters = false;
      this.filterFee = false;
      localStorage.removeItem('vcfee');
      this.sortOrder = '';
      this.params();
    }
  }

  clearFilters() {
    localStorage.removeItem('vcfee');
    this.sortOrder = '';
    this.isFilters = false;
    this.filterNear = true;
    this.filterFee = false;
    this.params();
  }

  howitworks() {
    this.router.navigateByUrl('home/howitworks');
  }

  openRequestDemoModal() {
    this.Userservice.openDemoModalComponent();
  }

  logout() {
    this.Userservice.logout();
    this.notLoggedin = true;
    this.isLoggedin = false;
  }

  closeDetails() {
    this.docDetails = false;
    this.results = true;
  }

  viewContact(row) {
    $("#view-contact-"+row).hide();
    $("#Contact-options-"+row).show();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('userId1');
  }

}
