import {Component, OnInit, Input, SimpleChanges, Output, EventEmitter, OnChanges} from '@angular/core';
import { UserService } from '../service/user.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sitemaps',
  templateUrl: './sitemaps.component.html',
  styleUrls: ['./sitemaps.component.css']
})
export class SitemapsComponent implements OnInit, OnChanges {
  @Input('latitude') lat: any;
  @Input('longitude') long: any;
  @Input('city') city: string;
  specialities: any[]=[];
  services: any[]=[];
  resultsFound: boolean = false;
  errorMessage: boolean;

  constructor(
    private Userservice: UserService,
    private activatedroute: ActivatedRoute
  ) { }

  ngOnChanges(element:SimpleChanges){
    var re = /[-]/g;
    this.activatedroute.params.subscribe(params => {
      if(params['name']) {
        let area = params['name'].replace(re, ' ');
        this.city = area;
        this.latLong(area);
      } else {
        let city = params['city'].replace(re, ' ');
        this.city = city;
        this.latLong(city);
      }
    });
  }

  ngOnInit(): void {
    //this.getTopSpecialities();
  }

  latLong(city) {
    this.Userservice.getCoords(city).subscribe(response => {
      if (response.details.length > 0) {
        let record = response.details.filter(item => item.name.toLowerCase() == city && item.city.toLowerCase() == this.city.toLowerCase());
        if (record.length > 0) {
          this.lat = record[0]['latitude'];
          this.long = record[0]['longitude'];
          this.getTopSpecialities();
        }
      }
    });
  }

  getTopSpecialities() {
    this.Userservice.noResults(this.lat, this.long).subscribe(response => {
      this.specialities = response.details.specialities;
      this.services = response.details.services;
      this.resultsFound = true;
    })
  }

}
