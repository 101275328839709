import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../customFiles/must-match.validator';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  loading=false;
  show=false;

  constructor(
    private Userservice:UserService,
    private formBuilder:FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      accountType :['USER', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required,Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/), Validators.minLength(6)]],
      referralCode: ['']
    });
  }

  get form() {
    return this.registerForm.controls;
  }

  onSubmit(){
    if (this.registerForm.invalid) {
      return;
    }
    this.loading=true;
    let number = localStorage.getItem('mobile');
    var details ={
      mobile:number,
      name: this.registerForm.value.name,
      accountType: this.registerForm.value.accountType,
      password: this.registerForm.value.password,
      email:this.registerForm.value.email,
      referralCode: this.registerForm.value.referralCode
    };

    this.Userservice.register(details).subscribe(response => {
      if(response.status){
        this.toastr.success('', response.message + ' ' + response.points, {
          timeOut: 3000
        });
        this.Userservice.afterLogin(response);
        this.registerForm.reset();
        if (this.Userservice.userValue) {
          let userToken = this.Userservice.userValue['details']['userId'];
          window.location.href = this.Userservice.dashboardURL + 'user/profile/?token=' + userToken;
        }
      }
    },
    error => {
      this.toastr.error('', error, {
        timeOut: 3000
      });
      this.loading = false;
    });
  }

  terms() {
    this.router.navigateByUrl('home/terms');
  }

}
