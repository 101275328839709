import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../service/user.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-immunizationschedule',
  templateUrl: './immunizationschedule.component.html',
  styleUrls: ['./immunizationschedule.component.css']
})

export class ImmunizationscheduleComponent implements OnInit {

  @ViewChild('closeReminder') closeReminder;
  immunizationSchedule: FormGroup;
  mobileVerificationForm: FormGroup;
  submitted: boolean = false;
  vaccineData = [];
  kidDob: any;
  validNumber: boolean;
  sendingOtp: boolean;
  otpInvalid: boolean;
  isShow: boolean = false;
  loggingIn: boolean = false;
  sendOTP: boolean = true;
  logIn: boolean = false;
  setReminder: boolean = false;
  otpResponse: any;
  loginSendOtp: Subscription;
  loggedIn: boolean = false;
  mobile: any;
  kidsData: any = [];
  otpVerified: boolean = false;
  otpSignup: boolean = false;
  chart: boolean = false;
  datePicker: Date;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  immuneId: any;
  message: any;
  minDate = new Date();
  maxDate: Date;
  otpCount: number = 0;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    var details = {
      metaTitle: 'Get immunization chart | Gigadocs',
      metaDescription: 'Vaccination Schedule with detailed Immunization chart complete with immunization and vaccine schedules. Complete vaccinations required for children along with schedules and reminders. Scheduled Vaccination Chart. Immunization vaccines and vaccination schedules for children up to 15 years. Including Polio Vaccine, Rotavirus, Hepatitis and DTaP vaccine.',
      ogTitle: 'Immunization Schedule Chart | Gigadocs',
      ogDescription: 'Get your child\'s complete vaccination schedule. Get alerts in advance when vaccination is due. An App that takes care of your child\'s complete immunization schedule. A digital record of all the completed vaccinations and the next upcoming vaccinations due.',
      metaKeywords: 'immunization schedules, vaccines, vaccine preventable diseases, Disease, Preventable Diseases, immunization chart, kids vaccination chart'
    };
    this.userService.setDynamicMetaData(details);
    let today = new Date();
    this.maxDate = new Date(today);
    this.minDate.setDate(this.minDate.getDate());
    this.minDate.setFullYear(this.minDate.getFullYear() - 15);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' });
    this.immunizationSchedule = this.formBuilder.group({
      dob: ['', Validators.required]
    });

    this.mobileVerificationForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}'/*"^[0-9]*$"*/),
        Validators.minLength(10), Validators.maxLength(10)]],
      loginOtp: ['', [Validators.required, Validators.pattern('[0-9]\\d{5}')]],
      name: ['', [Validators.required]],
      email: ['', [Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      childname: ['', [Validators.required]],
      gender: [''],
    });
  }

  generateChart() {
    this.submitted = true;
    if(this.form.dob.valid) {
      this.submitted = false;
      let dob = this.immunizationSchedule.value.dob;
      this.kidDob = this.datePipe.transform(dob,"yyyy-MM-dd");
      let data = {
        dob: this.kidDob
      };
      this.userService.getImmunizationSchedule(data).subscribe(response => {
        if (response.status) {
          this.vaccineData = response.details;
          this.immuneId = response.details.id;
          this.chart = true;
        }
      });
    }
  }

  sendingReminderOTP(){
    this.submitted = true;
    this.validNumber = null;
    if(this.login.mobile.valid) {
      this.submitted = false;
      this.sendOTP = false;
      this.logIn = true;
      this.sendingOtp = true;
      this.isShow = true;
      let data = {
        mobile: this.mobileVerificationForm.value.mobile
      };
      this.loginSendOtp = this.userService.sendReminderOTP(data).subscribe(response => {
        this.otpResponse = response;
        if (this.otpResponse.details.status) {
          this.otpCount += 1;
          this.validNumber = true;
          setTimeout(() => {
            this.validNumber = false;
          }, 3000);
          this.isShow = true;
          this.sendingOtp = false;
        } else {
          this.validNumber = false;
        }
      })
    }
  }

  setReminderVerification() {
    this.submitted = true;
    this.mobile = this.mobileVerificationForm.value.mobile;
    let data = {
      mobile: this.mobileVerificationForm.value.mobile,
      otp: this.mobileVerificationForm.value.loginOtp
    }
    if (this.login.mobile.valid && this.login.loginOtp.valid) {
      this.submitted = false;
      this.sendingOtp = true;
      this.userService.verifyReminderOTP(data).subscribe(response => {
        if(response == null) {
          this.sendingOtp = false;
          this.otpInvalid = true;
          setTimeout(() => {
            this.otpInvalid = false;
          }, 3000);
        } else if(response.details.status) {
          this.loggingIn = null;
          this.sendingOtp = false;
          this.otpVerified = true;
          if(response.details.userStatus == 1) {
            this.loggedIn = true;
            this.isShow = false;
            this.logIn = false;
            this.setReminder = true;
            setTimeout(() => {
              this.otpVerified = false;
            }, 3000);
          } else if(response.details.userStatus == 0) {
            this.loggedIn = false;
            this.isShow = false;
            this.logIn = false;
            this.setReminder = true;
            this.otpSignup = true;
            setTimeout(() => {
              this.otpVerified = false;
            }, 3000);
          }
        }
      })
    }
  }

  setVaccinationReminder(method = '') {
    this.submitted = true;
    if (method == 'signup') {
      if (this.mobile && this.login.name.valid && this.login.password.valid && this.login.childname.valid) {
        this.submitted = false;
        let details = {
          name: this.mobileVerificationForm.value.name,
          email: this.mobileVerificationForm.value.email,
          password: this.mobileVerificationForm.value.password,
          mobile: this.mobile,
          chartId: this.immuneId,
          patientName: this.mobileVerificationForm.value.childname,
          gender: this.mobileVerificationForm.value.gender,
          dob: this.kidDob
        };
        this.userService.setReminder(details).subscribe(response => {
          if (response.status) {
            this.message = 'Immunization schedule saved successfully, you will receive notification 2 days before the scheduled vaccination date through SMS';
            this.userService.verifyOtpLogin(this.mobile, this.mobileVerificationForm.value.loginOtp).subscribe(loginResponse => {
              if(loginResponse['status'] == 1){
                this.userService.afterLogin(loginResponse);
                setTimeout(() => {
                  this.message = false;
                  this.immunizationSchedule.reset();
                  this.mobileVerificationForm.reset();
                  let userToken = this.userService.userValue['details']['userId'];
                  window.location.href = this.userService.dashboardURL + 'user/familymember/?token=' + userToken;
                }, 3000);
              }
            })
          }
        })
      }
    } else {
      if (this.mobile && this.login.childname.valid) {
        this.submitted = false;
        let details = {
          mobile: this.mobile,
          chartId: this.immuneId,
          patientName: this.mobileVerificationForm.value.childname,
          gender: this.mobileVerificationForm.value.gender,
          dob: this.kidDob,
          password: ''
        };
        this.userService.setReminder(details).subscribe(response => {
          if (response.status) {
            this.message = 'Immunization schedule saved successfully, you will receive notification 2 days before the scheduled vaccination date through SMS';
            this.userService.verifyOtpLogin(this.mobile, this.mobileVerificationForm.value.loginOtp).subscribe(loginResponse => {
              if(loginResponse['status'] == 1){
                this.userService.afterLogin(loginResponse);
                setTimeout(() => {
                  this.message = false;
                  this.immunizationSchedule.reset();
                  this.mobileVerificationForm.reset();
                  let userToken = this.userService.userValue['details']['userId'];
                  window.location.href = this.userService.dashboardURL + 'user/familymember/?token=' + userToken;
                }, 3000);
              }
            })
          }
        })
      }
    }
  }

  get form() {
    return this.immunizationSchedule.controls;
  }

  get login() {
    return this.mobileVerificationForm.controls;
  }

  clearForm() {
    /*this.validNumber = false;
    this.sendOTP = true;
    this.isShow = false;
    this.submitted = false;
    this.sendingOtp = false;
    this.otpVerified = false;
    this.otpSignup = false;
    this.logIn = false;
    this.loggedIn = false;
    this.loggingIn = false;
    this.setReminder = false;
    this.mobileVerificationForm.reset();*/
  }

}
