<app-header></app-header>

<div class="booking-success">
  <div class="row">
    <div id="appointment_details" class="col-md-12">
      <div class="page-content">
        <div class="booking-result-wrap">
          <div class="container">
            <div class="confirmation-wrap">
              <div class="booking-confirmation-title">
                <h3>Your order has been successfully booked.</h3>
                <p>You have earned 100 points on this order.</p>
                <p>Thank you for booking an order through Gigadocs!</p>
              </div>
              <div class="container mt-4 text-center" *ngIf="datetime==null">
                <label>Appointment ID:</label>
                <h5>{{appointmentId}}</h5>
              </div>
              <div class="br-wrap" *ngIf="datetime!=null">
                <div class="br-col">
                  <label>Appointment ID:</label>
                  <h5>{{appointmentId}}</h5>
                </div>
                <div class="br-col">
                  <label>Order ID:</label>
                  <h5>{{refOrderId}}</h5>
                </div>
                <div class="br-col">
                  <label>Appointment Date/Time:</label>
                  <h5 >{{datetime}}</h5>
                </div>
                <div class="br-col">
                  <label>Address:</label>
                  <h5 >{{address}}</h5>
                </div>
              </div>
            </div>
            <div class="text-center" *ngIf="isNew == '1'">
              <a class="btn-my-appointments btn-primary text-white" (click)='createPassword()'>Create Password</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="my-appointments">
  <div class="row">
    <div class="col-md-7">
      <div class="container">
        <div class="show-appointments">
          <div class="row">
            <div class="col-md-5">
              <img class="img-responsive"  src="../../assets/images/myappointment3.png" alt="vc-steps" height="200" width="250">
            </div>
            <div class="col-md-7">
              <div class="list-myappointments">
                <h3>View the list of the all my Lab Tests </h3>
                <div class="text-center float-left">
                  <a href="{{url}}" class="btn-my-appointments">Lab Tests</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="container">
        <div class="support-link">
          <div class="row">
            <div class="col-md-5">
              <img class="img-responsive"  src="../../assets/images/contactus.png" alt="vc-steps" height="200" width="200">
            </div>
            <div class="col-md-7">
              <div class="support-phone">
                <h3>Contact Us @</h3>
                <div class="support-call">
                  <a href="tel:+917219274442"> CALL: +91 7219274442</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer lat="17.4611" long="78.355797"></app-footer>
