  <div class="sitemap">
    <div class="row">
      <div class="col-md-6">
        <div class="sitemap-img">
          <img src="assets/images/sitemap.png" class="img-responsive">
        </div>
      </div>
      <div class="col-md-6">
        <div class="listing-product">
          <div class="row">
            <div class="col-md-12">
              <p class="vcHeading">Find the available online consultant?</p>
              <p class="vcHeading">Don’t delay your health concerns.</p>
              <p class="vcSub">“Gigadocs brings you video consultations. Now meet a specialist within the privacy of your home.”</p>
              <div class="form-group buttonDiv">
                <a [routerLink]="['/virtualconsultation/doctors']">
                  <button class="sitebutton"><i class="fa fa-video-camera"></i> Consult Now</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="jumbotron" *ngIf="resultsFound">
    <div class="row" *ngIf="specialities.length > 0">
      <div class="col-md-12">
        <div class="row">
          <strong><h1 class="searchPageHeader">Find Nearest Doctor in Top Specialities in {{city | camelcase}}</h1></strong>
        </div>
        <br>
        <div class="row">
          <div class="col-md-4" *ngFor="let speciality of specialities">
            <a href="javascript:void(0)" routerLink="/{{city}}/{{speciality.name | reversecase}}" class="btn btn-primary searchButton">
              {{speciality.name | camelcase}}
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="row" *ngIf="services.length > 0">
      <div class="col-md-12">
        <div class="row">
          <strong><h1 class="searchPageHeader">Find Nearest Doctor in Top Services in {{city | camelcase}}</h1></strong>
        </div>
        <br>
        <div class="row">
          <div class="col-md-4" *ngFor="let service of services">
            <a href="javascript:void(0)" routerLink="/{{city}}/{{service.name | reversecase}}" class="btn btn-primary searchButton">
              {{service.name | camelcase}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
