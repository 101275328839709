import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { BsModalService , BsModalRef} from 'ngx-bootstrap/modal';


declare var $:any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  private mobileVerify: Subscription;
  mobileVerficationForm: FormGroup;
  @ViewChild('closeNewRegist ') closeNewRegist ;
  show:boolean=true;
  error: boolean=false;
  mobileSubmitted:boolean
  sendingOtp: boolean;

  constructor(
    private router: Router,
    private Userservice:UserService,
    private formBuilder:FormBuilder,
    private modalService: BsModalService
              ) {
    this.mobileVerficationForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}'/*"^[0-9]*$"*/),
                  Validators.minLength(10), Validators.maxLength(10)]],
});
  }

  ngOnInit(): void {
  }

  get form() {
    return this.mobileVerficationForm.controls;
  }

  mobileSubmit(){
    this.mobileSubmitted = true;
    if(this.mobileVerficationForm.invalid){
      return;
    }
    else{
      this.sendingOtp = true;
      localStorage.setItem('mobile',this.mobileVerficationForm.value.mobile);
      let number=localStorage.getItem('mobile');
      var details={
        mobile:number,
      };
      this.mobileVerify=this.Userservice.mobileVerfication(details).subscribe((res:any) => {
        this.sendingOtp = null;
        let statuscode=res.body.details.status;
        if(res.status==200 && statuscode=='1'){
          sessionStorage.setItem('loginType', 'new');
          this.closeNewRegist.nativeElement.click();
          this.mobileVerficationForm.reset();
          this.Userservice.openOtpModalComponent();
        }
        else if(res.status==200 && statuscode!='1'){
          localStorage.removeItem('mobile');
          this.show=false;
          this.error = true;
          this.mobileVerficationForm.reset();
        }
      },
      err=>{
        // console.log(err);
      }
      );
    }
  }

  clearForm(){
    this.error=false;
    this.show=true;
    this.mobileVerficationForm.reset();
    this.mobileSubmitted = null;
    this.sendingOtp = null;
    //this.otpMsg=true;
    //this.otperror=false;
  }

}
