import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormControl, FormBuilder, FormGroup  } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { UserService } from '../service/user.service';
import { MustMatch } from '../customFiles/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPassForm:FormGroup;
  userId: any;
  emailCode = '';
  submitted: boolean;
  isLoading: boolean;
  passwordUpdated: boolean;
  constructor(private fb: FormBuilder, private UserService:UserService, private router:Router, private activatedroute: ActivatedRoute) {
    this.resetPassForm = this.fb.group({
      newPassword: new FormControl('', [Validators.required, Validators.pattern(/^(?!\s*$).+/), Validators.minLength(6)]),
      reenterPassword: new FormControl('', [Validators.required, Validators.pattern(/^(?!\s*$).+/)])
      },
      {validator: MustMatch('newPassword', 'reenterPassword')}
      )
  }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      if (params['code']) {
        this.emailCode = params['code'];
      }
    });
    this.userId = sessionStorage.getItem('userId');
  }
  get passForm(){
     return this.resetPassForm.controls
  }
  resetPassword(){
    this.submitted = true;
    if(this.resetPassForm.valid){
      this.isLoading = true;
      this.UserService.updatePassword(this.userId, this.resetPassForm.get('newPassword').value, this.emailCode).subscribe((data:any) => {
        this.isLoading = null;
        if(data.status == 200 && data['body']['details']['status'] == 1){
          this.passwordUpdated = true;
          setTimeout(() => {
            this.router.navigate(['']);
            sessionStorage.removeItem('userId')
          }, 2000)
        }
      })
    }
  }
  ngOnDestroy(){
    sessionStorage.removeItem('userId')
  }

}
