<app-header></app-header>
<div class="subheader subheader-filter">
  <div class="container-fluid">
      <div class="col-md-3">
        <h4 class="pg-title">How It Works!</h4>
      </div>
  </div>
</div>
<div class="how-banner-strip">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="strip-clinic-img">
          <img src="assets/images/how2.png" class="img-responsive">
        </div>
      </div>
      <div class="col-md-6">
        <h1>How it works?</h1>
      </div>
    </div>
    </div>
</div>
<div class="appointment-process">
  <div class="container">
    <h3>Appointment Booking process</h3>
    <div class="row">
      <div class="col-md-12">
        <div class="main-timeline">
          <a href="javascript:void(0)" class="timeline">
            <div class="timeline-icon"><i class="fa fa-user-md" aria-hidden="true"></i></div>
            <div class="timeline-content">
              <h3 class="title">SELECT A DOCTOR</h3>
              <p class="description">
                User can Search for doctors as per Location, Symptoms and Specialty. View the complete profile of any
                doctor from the list and select a doctor of their choice.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" class="timeline">
            <div class="timeline-icon"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></div>
            <div class="timeline-content">
              <h3 class="title">SELECT A SLOT</h3>
              <p class="description">
                User can view all the available slot and select a time slot that is convenient to them without having to
                step out from the comfort and safety of their home.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" class="timeline">
            <div class="timeline-icon"><i class="fa fa-address-card-o" aria-hidden="true"></i></div>
            <div class="timeline-content">
              <h3 class="title">ENTER PATIENT DETAILS</h3>
              <p class="description">
                Users can enter all the relevant details of the Patient during the appointment booking process to enable
                the Doctor to understand the purpose of the appointment in advance.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" class="timeline">
            <div class="timeline-icon"><i class="fa fa-credit-card" aria-hidden="true"></i></div>
            <div class="timeline-content">
              <h3 class="title">COMPLETE THE PAYMENT PROCESS</h3>
              <p class="description">
                Gigadocs uses secure payment gateways to complete the appointment booking process for Video
                Consultation. The appointment slot will be confirmed immediately after the payment process is
                completed.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" class="timeline">
            <div class="timeline-icon"><i class="fa fa-check-square-o" aria-hidden="true"></i></div>
            <div class="timeline-content">
              <h3 class="title">APPOINTMENT CONFIRMATION</h3>
              <p class="description">
                Once the payment is successful, the selected appointment slot will be booked and a confirmation for the
                appointment is provided and sent.
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>
<div class="video-process">
  <div class="container">
    <h3>Video Consultation process</h3>
    <div class="row">
      <div class="col-md-12">
        <div class="main-timeline">
          <a href="javascript:void(0)" class="timeline">
            <div class="timeline-icon"><i class="fa fa-download" aria-hidden="true"></i></div>
            <div class="timeline-content">
              <h3 class="title">DOWNLOAD THE APP</h3>
              <p class="description">
                For best results, its advisable to attend the video consultation through the Gigadocs App available on
                both the Android and IOS platform. The links are provided below.​
                Download the Gigadocs app from -
              </p>
              <ul>
                <li><a href="https://apple.co/2W2iG4V">IOS App</a></li>
                <li><a href="https://bit.ly/33AQoRC">Android App</a></li>
              </ul>
            </div>
          </a>
          <a href="javascript:void(0)" class="timeline">
            <div class="timeline-icon"><i class="fa fa-calendar" aria-hidden="true"></i></div>
            <div class="timeline-content">
              <h3 class="title">OPEN APPOINTMENT DETAILS</h3>
              <p class="description">
                To join the video consultation, user needs to go to 'My Appointments' tab in their Gigadocs Account.
                Once the Doctor is ready, the Doctor will initiate the call.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" class="timeline">
            <div class="timeline-icon"><i class="fa fa-video-camera" aria-hidden="true"></i></div>
            <div class="timeline-content">
              <h3 class="title">CLICK ON VIDEO CONSULATION ICON</h3>
              <p class="description">
                Once the Doctor is ready, 'Join Video Call' button will be enabled. Clicking on the ‘Join Video Call’ button
                will navigate the user automatically to the Video Call session with the Doctor for the consultation.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" class="timeline">
            <div class="timeline-icon"><i class="fa fa-phone" aria-hidden="true"></i></div>
            <div class="timeline-content">
              <h3 class="title">CONTACT US</h3>
              <p class="description">
                Gigadocs is dedicated in providing you with the most pleasant experience while consulting a doctor.
                Hence Gigadocs has a dedicated Support facilities to assist you with any issue or if any further queries
                arise.
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>
<app-footer lat="17.4611" long="78.355797"></app-footer>
