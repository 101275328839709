<app-header></app-header>

<div #alert *ngIf="errorMessage" class="alert alert-danger "><h6 class="text center"><i class="fas fa-exclamation-circle text-danger mr-2"></i>{{errorNotification}}</h6></div>

<div class="page-content">
  <div class="banner-strip">
    <div class="strip-clinic-img">
      <img src="assets/images/clinicImage.png">
    </div>
    <div class="banner-strip-content">
      <div class="social">
        <span><i class="ic-share"></i>  Share profile</span>
        <div class="social-links">
          <share-buttons [theme]="'circles-dark'"
                         [include]="['facebook','twitter','whatsapp','sms','email']"
                         [show]="5"
                         [title]="'Share Clinic Profile'"
                         [tags]="'profile'"
          ></share-buttons>
        </div>
      </div>
    </div>
  </div>
  <div class="doctor-profile">
    <div class="container container-fluid-sm">
      <div class="row mt-row">
        <div class="col-md-3 mt-value hidden-xs">
          <div class="card-p nopad">
            <div class="doc-img">
              <img width="100%" src="{{clinicImage}}" onerror="src='assets/images/clinic-logo-2.png'"/>
            </div>
            <div class="doc-info">
              <h4>About</h4>
              <div *ngIf="clinicDescription">
                {{(readMore) ? clinicDescription : clinicDescription | slice:0:120}}<span *ngIf="!readMore">...</span>
                <a class="more-button" (click)="readMore=!readMore">{{!readMore ? 'Show more' : 'Show less' }}</a>
              </div>
            </div>
          </div>
          <div class="gigadoc-mobile-app">
            <h5>Get Gigadocs Mobile App</h5>
            <div *ngIf="sendLink" class="alert alert-warning">
              <span class="spinner-border spinner-border-sm"></span>
              Please wait, Processing...
            </div>
            <div class="alert alert-success" *ngIf="sentLink">
              App Link Shared Successfully
            </div>
            <form id="send-app-link" [formGroup]="sendAppLinkForm">
              <div class="form-group required">
                <input type="text" class="form-control" placeholder="Enter Mobile Number" formControlName="mobile"
                       required="required" [ngClass]="{ 'is-invalid': (submitted) && applink.mobile.errors }">
                <div *ngIf="(submitted) && applink.mobile.errors" class="invalid-feedback">
                  <div *ngIf="applink.mobile.errors.required">Mobile is required</div>
                  <div *ngIf="applink.mobile.errors?.pattern" class="text-danger">
                    Invalid Mobile number
                  </div>
                </div>
              </div>
              <button class="btn btn-first" (click)="sendAppLink()">Send App Link</button>
            </form>
          </div>
          <!--<div class="other-suggestions gigadoc-mobile-app">
            <h5>Suggested Links</h5>
            <ul>
              <li *ngFor="let relatedSpeciality of relatedSpecialityArray">
                <a href="javascript:void(0)" routerLink="/{{relatedSpeciality['url']}}">{{relatedSpeciality['lable']}}</a>
              </li>
            </ul>
          </div>-->
        </div>
        <div class="col-md-9 mt-value">
          <div class="doc-intro">
            <div  class="doc-intro-img visible-xs">
              <img width="100%" src="{{clinicImage}}"  onerror="src='assets/images/clinic-logo-2.png'" alt="Image"/>
            </div>
            <div class="doc-intro-content">
              <h2>
                {{clinicData.clinicName}} <span class="verified"><i class="ic-verified"></i> <span class="hidden-xs">Verified</span></span>
              </h2>
              <p *ngIf="clinicData.clinicYestablishYear!='0000'">Year Of Establishment : {{clinicData.clinicYestablishYear}}</p>
              <p *ngIf="clinicData.clinicYestablishYear=='0000'">Year Of Establishment : N/A</p>
              <!--<div class="star-rating">
                <span class="star-rate"><span style="width: 60%"></span></span>
                <span class="doc-feedback">(453 Feedbacks)</span>
              </div>-->
            </div>
          </div>
          <div class="doc-info visible-xs">
            <h4>About</h4>
            <div *ngIf="clinicData.clinicDescription != null && clinicData.clinicDescription != ''">
              {{(readMore) ? clinicData.clinicDescription : clinicData.clinicDescription | slice:0:120}}<span *ngIf="!readMore">...</span>
              <a class="more-button" (click)="readMore=!readMore">{{!readMore ? 'Show more' : 'Show less' }}</a>
            </div>
          </div>
          <div class="doc-specification">
            <div class="card-p">
              <div class="row">
                <div class="col-sm-6">
                  <div class="address">
                    <h5><i class="fa fa-map-marker" aria-hidden="true"></i>  Location</h5>
                    <p>{{clinicData.clinicAddress}}</p>
                    <p>{{clinicData.clinicArea}}, {{clinicData.clinicCity}}</p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="contact-info">
                    <h5><i class="fa fa-phone" aria-hidden="true"></i> Contact us</h5>
                    <a *ngIf="clinicData.clinicContactNumber" href='tel:{{clinicData.contactNumber}}'>{{clinicData.clinicContactNumber}}</a>
                    <p *ngIf="clinicData.clinicContactNumber==''"> N/A</p>
                    <div class="clinic-contact-options" *ngIf="clinicData.clinicContactNumber">
                      <ul class="conatct-details">
                        <li class="phone">
                          <a *ngIf="clinicData.clinicContactCall == '1'" href="tel:{{clinicData.clinicContactNumber}}"><i class="fa fa-phone" aria-hidden="true"></i></a>
                        </li>
                        <li class="whatsapp">
                          <a *ngIf="clinicData.clinicContactWhatsapp == '1'" href="https://api.whatsapp.com/send?phone={{clinicData.clinicContactNumber}}"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                        </li>
                        <li class="message">
                          <a *ngIf="clinicData.clinicContactSms == '1'" href="sms:{{clinicData.clinicContactNumber}}"><i class="fa fa-comments-o" aria-hidden="true"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="doc-profile-tabs">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#clinics">Doctors ({{clinicData.doctors.length}})</a>
              </li>
              <!--<li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#reviews">Reviews (230)</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#awards">Awards & Acheivements</a>
              </li>-->
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="clinics" role="tabpanel" >
                <div class="doc-clinics">
                  <div class="dc-row" *ngFor= "let doctorInfo of clinicData.doctors; let i = index">
                    <div class="row">
                      <div class="col-lg-2 col-md-3 col-xs-4">
                        <div class="dc-img">
                          <img class="img-fluid" *ngIf="(doctorInfo.gender | lowercase) == 'male'" src="{{doctorInfo.image}}" onerror="src='assets/images/doctor-male-default.png'"/>
                          <img class="img-fluid" *ngIf="(doctorInfo.gender | lowercase)== 'female'" src="{{doctorInfo.image}}" onerror="src='assets/images/doctor-female1.png'"/>
                          <img class="img-fluid" *ngIf="doctorInfo.gender==''" src="{{doctorInfo.image}}" onerror="src='assets/images/user.png'">
                        </div>
                      </div>
                      <div class="col-lg-7 col-md-9 col-xs-8">
                        <div class="dc-clinic-info">
                          <a routerLink="/doctors/{{clinicData.clinicCity | reversecase}}/{{doctorInfo.url}}" class="sr-title">
                            <h4>Dr. {{doctorInfo.name | camelcase}}</h4>
                          </a>
                          <p>
                            <i class="fa fa-stethoscope"></i>
                            <span *ngIf="doctorInfo.specialization.length>0">{{doctorInfo.specialization | commapipe}}</span>
                            <span *ngIf="doctorInfo.specialization.length==0">N/A</span>
                          </p>
                          <p>
                            <i class="fa fa-graduation-cap"></i>
                            <span *ngIf="doctorInfo.education!='' && doctorInfo.education!=null">{{doctorInfo.education}}</span>
                            <span *ngIf="doctorInfo.education=='' || doctorInfo.education==null">N/A</span>
                          </p>
                          <p>
                            <i class="fa fa-briefcase" aria-hidden="true"> </i>
                            <span *ngIf="doctorInfo.exp > 0">{{doctorInfo.exp}} Years of experience</span>
                            <span *ngIf="doctorInfo.exp==0">N/A</span>
                          </p>
                          <p>
                            <i class="fa fa-language" aria-hidden="true"></i>
                            <span *ngIf="doctorInfo.language.length > 0">{{doctorInfo.language | commapipe}}</span>
                            <span *ngIf="doctorInfo.language.length==0">N/A</span>
                          </p>
                        </div>
                        <div class="sr-working-hours">
                          <div *ngIf="doctorInfo.isOnline && doctorInfo.vc_working_hours.length > 0">
                            <h4>Video Consultation:</h4>
                            <div *ngFor="let days of doctorInfo.vc_working_hours" class="working-hour-row">
                              <label>{{days['days']}}</label>
                              <span>{{days['time']}}</span>
                            </div>
                          </div>
                          <div *ngIf="doctorInfo.working_hours.length > 0">
                            <h4>In-Clinic Visit:</h4>
                            <div *ngFor="let days of doctorInfo.working_hours" class="working-hour-row">
                              <label>{{days['days']}}</label>
                              <span>{{days['time']}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-9 offset-tab-3 col-xs-">
                        <div class="sr-consulation-fees">
                          <h5><i class="fa fa-inr"></i>{{doctorInfo.fee}}</h5>
                        </div>
                        <a *ngIf="clinicData.clinicSubscription=='1' && doctorInfo.onCall=='1'" data-toggle="modal"
                             data-target="#slotModal" class="btn btn-second btn-on-request trigger-btn" (click)="getPopupSlots(doctorInfo.url)"
                          >Available On Request</a>
                        <a *ngIf="clinicData.clinicSubscription=='1' && ((doctorInfo.showClinicSlots && doctorInfo.isInClinic) || (doctorInfo.showOnlineSlots && doctorInfo.isOnline))" data-toggle="modal"
                             data-target="#slotModal" class="btn btn-second btn-book-appointment trigger-btn" (click)="getPopupSlots(doctorInfo.url, doctorInfo.showClinicSlots, doctorInfo.showOnlineSlots)"
                          >Book Appointment</a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-9  col-xs-">
                        <div class="services-clinic">
                          <h5><i class="fa fa-stethoscope" aria-hidden="true"></i> Services/related-specialities</h5>
                          <ul class="servicesList">
                            <li *ngFor="let service of doctorInfo.service.slice(0,6)">{{service | camelcase}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="doctorInfo.service.length >= 7">
                      <div class="col-md-12 text-right more-services">
                        <a routerLink="/doctors/{{clinicData.clinicCity | reversecase}}/{{doctorInfo.url}}" class="abt-more">
                          More<i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="tab-pane fade" id="reviews" role="tabpanel">
                Reviews & Feedbacks
              </div>
              <div class="tab-pane fade" id="awards" role="tabpanel">
                Awards & Acheivements
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="slotModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-slot">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row">
            <div class="col-md-8">
              <h5 class="modal-title">Dr. {{doctorName | camelcase}}</h5>
            </div>
            <div class="col-md-4">
              <button type="button" #close class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div id="virtual-slots" class="vc-input-group" *ngIf="isOnline && showOnline">
            <h6>Video Consultation</h6>
            <div class="sr-clinic-appointment">
              <ul class="appointment-dates">
                <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayApplyVC}" (click)="todayVC('vc')">Today</a></li>
                <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowApplyVC}" (click)="tomorrowVC('vc')">Tomorrow</a></li>
                <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate':dateHighlightVC}">
                  <i class="ic-calendar mr-2"></i>
                  <input type="text" readonly #calendarvc [(ngModel)]="datePickerVC" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedVCDate($event, 'vc')"
                         id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                </li>
              </ul>
              <div class="timeslot-tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" *ngIf="morningVCSlotsLength != 0">
                    <a class="nav-link" [ngClass]="{' active' : (morningVCSlotsLength != 0)}" data-toggle="tab" (click)="selectedVCIndex=-1;selectedVCTime=null" href="#morningVC">
                      <i class="ic-morning"></i> <span>Morning</span>
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="afternoonVCSlotsLength != 0">
                    <a class="nav-link" [ngClass]="{' active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength != 0)}" data-toggle="tab" (click)="selectedVCIndex=-1;selectedVCTime=null" href="#afternoonVC">
                      <i class="ic-afternoon"></i> <span>Afternoon</span>
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="eveningVCSlotsLength != 0">
                    <a class="nav-link" [ngClass]="{' active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0 && eveningVCSlotsLength != 0)}" data-toggle="tab" (click)="selectedVCIndex=-1;selectedVCTime=null" href="#eveningVC">
                      <i class="ic-evening"></i> <span>Evening</span>
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent" *ngIf = "(morningVCSlotsLength !=0 || afternoonVCSlotsLength !=0 || eveningVCSlotsLength !=0) && !vcSpinner">
                  <div class="tab-pane" id="morningVC" [ngClass]="{' fade show active' : (morningVCSlotsLength != 0)}" *ngIf="morningVCSlotsLength != 0" role="tabpanel">
                    <div style=" display:inline-block;" *ngFor="let time of morningVCSlots;let i=index">
                      <button [ngClass]="{'selected':i==selectedVCIndex}" (click)="VCTime(i,time,'vc')">{{time}}</button>
                    </div>
                  </div>
                  <div class="tab-pane" id="afternoonVC" [ngClass]="{' fade show active' : (morningVCSlotsLength == 0)}" *ngIf="afternoonVCSlotsLength != 0" role="tabpanel">
                    <div  style=" display:inline-block;" *ngFor="let time of afternoonVCSlots;let i=index">
                      <button [ngClass]="{'selected':i==selectedVCIndex}" (click)="VCTime(i,time,'vc')" >{{time}}</button>
                    </div>
                  </div>
                  <div class="tab-pane" id="eveningVC" [ngClass]="{' fade show active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0)}" *ngIf="eveningVCSlotsLength != 0" role="tabpanel">
                    <div  style=" display:inline-block;" *ngFor="let time of eveningVCSlots;let i=index">
                      <button [ngClass]="{'selected':i==selectedVCIndex}" (click)="VCTime(i,time,'vc')">{{time}}</button>
                    </div>
                  </div>
                </div>
                <div class="tab-content" *ngIf="morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0 && eveningVCSlotsLength == 0 && !vcSpinner">NO SLOTS AVAILABLE</div>
                <div class="fa-1x tab-content" *ngIf="(morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0 && eveningVCSlotsLength == 0) && vcSpinner">
                  <p><i class="fas fa-circle-notch fa-spin" style ="color: #4a85ec;margin-right: 10px;"></i> Please wait...</p>
                </div>
              </div>
            </div>
          </div>
          <div id="inclinic" class="clinic-input-group" *ngIf="inClinic && showClinic">
            <h6>In-Clinic Visit</h6>
            <div class="sr-clinic-appointment">
              <ul class="appointment-dates">
                <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayApply}" (click)="today('inclinic')">Today</a></li>
                <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowApply}"(click)="tomorrow('inclinic')">Tomorrow</a></li>
                <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate': dateHighlight}">
                  <i class="ic-calendar mr-2"></i>
                  <input type="text" readonly #calendar [(ngModel)]="datePicker" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedDate($event, 'inclinic')"
                         id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                </li>
              </ul>
              <div class="timeslot-tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" *ngIf="morningSlotsLength != 0">
                    <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#morning">
                      <i class="ic-morning"></i> <span>Morning</span>
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="afternoonSlotsLength != 0">
                    <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && afternoonSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#afternoon">
                      <i class="ic-afternoon"></i> <span>Afternoon</span>
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="eveningSlotsLength != 0">
                    <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#evening">
                      <i class="ic-evening"></i> <span>Evening</span>
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent"  *ngIf = "(morningSlotsLength !=0 || afternoonSlotsLength !=0 || eveningSlotsLength !=0) && !inclinicSpinner">
                  <div class="tab-pane" id="morning" [ngClass]="{' fade show active' : (morningSlotsLength != 0)}" *ngIf="morningSlotsLength != 0" role="tabpanel">
                    <div style=" display:inline-block;" *ngFor="let time of morningSlots;let i=index">
                      <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time,'inclinic')">{{time}}</button>
                    </div>
                    <div *ngIf="morningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                  </div>
                  <div class="tab-pane" id="afternoon" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength != 0)}" *ngIf="afternoonSlotsLength != 0" role="tabpanel">
                    <div  style=" display:inline-block;" *ngFor="let time of afternoonSlots;let i=index">
                      <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time,'inclinic')">{{time}}</button>
                    </div>
                    <div *ngIf="afternoonSlotsLength === 0">NO SLOTS AVAILABLE</div>
                  </div>
                  <div class="tab-pane" id="evening" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength != 0)}" *ngIf="eveningSlotsLength != 0" role="tabpanel">
                    <div  style=" display:inline-block;" *ngFor="let time of eveningSlots;let i=index">
                      <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time,'inclinic')">{{time}}</button>
                    </div>
                    <div *ngIf="eveningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                  </div>
                </div>
                <div class="tab-content" *ngIf="(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0) && !inclinicSpinner">NO SLOTS AVAILABLE</div>
                <div class="fa-1x tab-content" *ngIf = "(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0) && inclinicSpinner">
                  <p><i class="fas fa-circle-notch fa-spin" style ="color: #4a85ec;margin-right: 10px;"></i> Please wait...</p>
                </div>
              </div>
            </div>
          </div>
          <div id="oncall" class="clinic-input-group" *ngIf="isOnCall=='1'">
            <h6>Available On Request</h6>
            <div class="sr-clinic-appointment">
              <ul class="appointment-dates">
                <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayCall}" (click)="todayOnCall('oncall')">Today</a></li>
                <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowCall}" (click)="tomorrowOnCall('oncall')">Tomorrow</a></li>
                <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate': dateHighlightCall}">
                  <i class="ic-calendar mr-2"></i>
                  <input type="text" readonly #calendarcall [(ngModel)]="datePickerCall" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedOnCallDate($event, 'oncall')"
                         id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                </li>
              </ul>
              <div class="timeslot-tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#slottime">
                      <i class="ic-datetime"></i> <span>Preferred Time</span>
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="slottime" role="tabpanel">
                    <div style="display:inline-block;">
                      <button [ngClass]="{'selected':0==selectedCallIndex}" (click)="CallTime(0, '12:00 AM - 04:00 AM', 'oncall')">12:00 AM - 04:00 AM</button>
                      <button [ngClass]="{'selected':1==selectedCallIndex}" (click)="CallTime(1, '04:00 AM - 08:00 AM', 'oncall')">04:00 AM - 08:00 AM</button>
                      <button [ngClass]="{'selected':2==selectedCallIndex}" (click)="CallTime(2, '08:00 AM - 12:00 PM', 'oncall')">08:00 AM - 12:00 PM</button>
                      <button [ngClass]="{'selected':3==selectedCallIndex}" (click)="CallTime(3, '12:00 PM - 04:00 PM', 'oncall')">12:00 PM - 04:00 PM</button>
                      <button [ngClass]="{'selected':4==selectedCallIndex}" (click)="CallTime(4, '04:00 PM - 08:00 PM', 'oncall')">04:00 PM - 08:00 PM</button>
                      <button [ngClass]="{'selected':5==selectedCallIndex}" (click)="CallTime(5, '08:00 PM - 12:00 AM', 'oncall')">08:00 PM - 12:00 AM</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button *ngIf="isOnCall=='0'" class="btn btn-primary" [disabled]="btnDisable" (click)="confirmAppointment()">Book Appointment</button>
          <button *ngIf="isOnCall=='1'" class="btn btn-primary" [disabled]="btnDisable" (click)="onCallAppointment()">Request Appointment</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer lat="17.4611" long="78.355797" (data)="RelatedSpecialty($event)"></app-footer>
