import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormControl, FormBuilder, FormGroup  } from '@angular/forms'
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.css']
})
export class RequestDemoComponent implements OnInit {
  requestDemoForm: FormGroup;
  submitted: boolean;
  isLoading: boolean;
  demoRequestSent: boolean;

  constructor(public bsModalRef: BsModalRef , private fb: FormBuilder, private userService:UserService) {
    this.requestDemoForm = this.fb.group({
      name: new FormControl('',[ Validators.required , Validators.minLength(3)]),
      phone: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}') , Validators.maxLength(10) , Validators.minLength(10)]],
      email:new FormControl('',Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')),
      city:new FormControl('', [Validators.required , Validators.pattern("^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$")])
    })
  }

  ngOnInit(): void {
  }

  get demoForm(){
    return this.requestDemoForm.controls;
  }

  submitRequestDemo(){
    this.submitted = true;
    if(this.requestDemoForm.valid){
      this.isLoading = true;
      var details = {
        name:this.requestDemoForm.value.name,
        email:this.requestDemoForm.value.email,
        mobile:this.requestDemoForm.value.phone,
        city:this.requestDemoForm.value.city
      }
      this.userService.requestDemo(details).subscribe(data => {
        this.isLoading = null;
        if(data['details']['status'] == 1){
          this.demoRequestSent = true;
          setTimeout(() => {
            this.userService.closeDemoModalComponent()
          }, 3000)
        }
      })
    }
  }

}
