import { Component, OnInit, OnDestroy, ViewChild, Output,EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from '../service/user.service';
import { Router } from "@angular/router";
import {Observable, Observer, of, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {CamelcasePipe} from "../camelcase.pipe";

declare var $: any;
@Component({
  selector: 'app-secondaryheader',
  templateUrl: './secondaryheader.component.html',
  styleUrls: ['./secondaryheader.component.css']
})

export class SecondaryheaderComponent implements OnInit, OnChanges , OnDestroy{
  @Output() notify =new EventEmitter();

  @Input('location') locationInHeader:string;
  @Input('area') areaInHeader:string;
  @Input('speciality') specialityInHeader:string;
  private mobileVerify: Subscription;
  private otpVerify: Subscription;
  mobileVerficationForm: FormGroup;
  otpVerficationForm: FormGroup;
  loginForm: FormGroup;
  buttonValue:string;
  error: boolean=false;
  loginError: boolean=false;
  otperror: boolean=false;
  show:boolean=true;
  otpMsg:boolean=true;
  notLoggedin:boolean=true;
  isLoggedin=false;
  @ViewChild('closelogin') closelogin;
  @ViewChild('closeregist') closeregist;
  @ViewChild('closeNewRegist ') closeNewRegist ;
  @ViewChild('closeVerify ') closeVerify;

  private areas: Subscription;
  private spec: Subscription;
  searchicon:string = '<strong><p class="ic-search"></p></strong>';
  brand: string;
  data: any;
  area:any;
  speciality:any;
  myJson:any;
  list=[];
  filteredAreas:any[];
  filteredSpeciality: any[];
  name: string;
  specs:string;
  city: string;
  coords=[];
  resultsFound:boolean=true;
  NotFound:boolean=false;
  showLocation=false;
  showSpeciality=false;
  public errorMsg:any;
  lat: any;
  long: any;
  docUrl: any;
  areaName: string;
  selectedarea:string;
  latLong: any;
  message:string;
  subscription: Subscription;
  userToken: string;
  userDetails: any;
  previous_values: any;
  new_values: any;

  constructor(
    private https:HttpClient,
    private Userservice:UserService,
    private router: Router,
    private formBuilder:FormBuilder,
    private http:HttpClient,
    private toastr: ToastrService,
    private camelcase: CamelcasePipe
  ) {
    this.loginCheck()
  }

  loginCheck() {
    if (this.Userservice.userValue) {
      this.notLoggedin = false;
      // this.userToken = this.Userservice.dashboardURL + 'user/dashboard/?token=' +this.Userservice.userValue['details']['userId'];
      this.isLoggedin = true;
    }
  }

  ngOnChanges(element:SimpleChanges){
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.onChangeHeaderValue();
    this.getAreas();
    this.search();
    this.updateUserRole();
    // <!----Mobile Verification form--->
    this.mobileVerficationForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}'/*"^[0-9]*$"*/),
        Validators.minLength(10), Validators.maxLength(10)]],
    });

    // <!----OTP Verification Form--->
    this.otpVerficationForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6), Validators.maxLength(6)]],
    });

    // Login Form
    this.loginForm = this.formBuilder.group({
      'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,3}))$/)])],
      'password': [null, Validators.required],
    });
  }

  updateUserRole(){
    if(this.Userservice.userValue) {
        this.Userservice.roleChange().subscribe(response => {
          if (response) {
            this.userDetails = response['details']
            this.previous_values = JSON.parse(localStorage.getItem('user'))
            this.new_values = this.previous_values['user']
            if (this.userDetails['roleStatus'] == 'Yes'){            
              // Update the role changes in dictionary
              this.new_values['details']['userId'] = this.userDetails['userId']
              this.new_values['details']['userTypeId'] = this.userDetails['userTypeId']
              this.new_values['details']['role'] = this.userDetails['role']
              this.new_values['details']['roleCategory'] = this.userDetails['roleCategory']
            }
            this.new_values['details']['name'] = this.userDetails['name']
            this.new_values['details']['email'] = this.userDetails['email']
            this.new_values['details']['gender'] = this.userDetails['gender']
            this.new_values['details']['mobile'] = this.userDetails['mobile']
            this.new_values['details']['alternativeMobile'] = this.userDetails['alternativeMobile']
            // update the observable value
            this.Userservice.afterLogin(this.new_values)
            this.userToken = this.Userservice.dashboardURL + 'user/dashboard/?token=' +this.Userservice.userValue['details']['userId'];
          }
        });
      }
  }

  onChangeHeaderValue() {
    if (this.areaInHeader != '' && this.areaInHeader != this.locationInHeader) {
      this.area = this.camelcase.transform(this.areaInHeader)
    } else {
      this.area = this.camelcase.transform(this.locationInHeader)
    }
    this.speciality = this.camelcase.transform(this.specialityInHeader);
  }

  search() {
    let query = this.locationInHeader;
    let city = this.locationInHeader;
    if (this.areaInHeader) {
      query = this.areaInHeader;
    }
    this.Userservice.getCoords(query).subscribe(response => {
      if (response.details.length > 0) {
        let record = response.details.filter(item => item.name.toLowerCase() == query.toLowerCase() && item.city.toLowerCase() == city.toLowerCase());
        if (record.length > 0) {
          var re = /\s+/g;
          this.lat = record[0].latitude;
          this.long = record[0].longitude;
          this.city = record[0].city.toLowerCase().replace(re, "-");
          this.selectedarea = record[0].name.toLowerCase().replace(re, "-");
        }
      } else {
        this.subscription = this.Userservice.currentMessage.subscribe(message => this.message = message);
      }
    });
  }

  getAreas() {
    this.Userservice.getArea().subscribe(result => {
      this.data = result;
      this.list = [];
      let input = this.data.details;
      for (let i = 0; i < input.length; i++) {
        this.myJson = {"name": input[i].name, "city": input[i].city};
        this.list.push(this.myJson);
      }
    })
  }

  filteredArea$ = new Observable((observer: Observer<string>) => {
    observer.next(this.area);
  }).pipe(
    switchMap((query: string) => {
      return of(this.list.filter(item => (item.name && item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        || item.city && item.city.toLowerCase().indexOf(query.toLowerCase()) !== -1)))
    })
  );

  selectedArea(evt:any) {
    let areaName1 = evt.item.name.toLowerCase();
    var re = /\s+/g;
    this.area = evt.item.name;
    this.name = areaName1.replace(re, "-");
    this.specs = this.speciality.toLowerCase().replace(re, "-");
    this.Userservice.getCoords(evt.item.name).subscribe(response => {
      if (response.details.length > 0) {
        let record = response.details.filter(item => item.name.toLowerCase() == evt.item.name.toLowerCase() && item.city.toLowerCase() == evt.item.city.toLowerCase());
        if (record.length > 0) {
          this.lat = record[0].latitude;
          this.long = record[0].longitude;
          this.city = record[0].city.toLowerCase().replace(re, "-");
          this.selectedarea = record[0].name.toLowerCase().replace(re, "-");
          if (this.city && this.selectedarea && this.specs && this.city != this.selectedarea) {
            this.getResults(this.city, this.specs, this.selectedarea, null, null);
          } else if (this.city == this.selectedarea && this.specs) {
            this.getResults(this.city, this.specs, null, null, null);
          }
        }
      }
    });
  }

  filteredSpeciality$ = new Observable((observer: Observer<string>) => {
    observer.next(this.speciality);
  }).pipe(
    switchMap((query: string) => {
      if(query) {
        let data = this.data.details.filter(item => item.name == this.area);
        if(data.length > 0) {
          return this.https.get(
            this.Userservice.gigaUrl + 'search/autosuggestions' + '?keyword=' + query + '&latitude=' + data[0].latitude + '&longitude=' + data[0].longitude,
            this.Userservice.jsonHeader
          ).pipe(map((res: any) => {
            const response = res;
            let filtered: any[] = [];
            let result1 = Object.keys(response.details).map(function (key) {
              return response.details[key];
            });
            let str = 'doctor';
            if (str.indexOf(query.toLowerCase()) !== -1) {
              filtered.push({"id": 0, "name": "Doctor", "type": "type"});
            }
            filtered.push({"id": 0, "name": "Doctor Search with Keyword : " + query, "type": "doctor", "speciality": "doctor"});
            filtered.push({"id": 0, "name": "Clinic Search with Keyword : " + query, "type": "clinic", "area": "clinic"});
            result1.forEach(item => {
              item.forEach(i => {
                if (i.type == 'doctor') {
                  filtered.push({"id": i.id, "name": i.name, "type": i.type, "url": i.url, "speciality": i.speciality});
                } else if (i.type == 'clinic') {
                  filtered.push({"id": i.id, "name": i.name, "type": i.type, "area": i.area});
                } else {
                  filtered.push({"id": i.id, "name": i.name, "type": i.type});
                }
              });
            });
            this.filteredSpeciality = filtered;
            return this.filteredSpeciality;
          }));
        }
      }
      return of([]);
    })
  );

  selectedSpec(evt:any) {
    var re = /\s+/g;
    this.speciality = evt.item.name;
    this.specs = evt.item.name.toLowerCase().replace(re, "-");
    if (evt.item.type == 'doctor' && evt.item.url) {
      this.docUrl = evt.item.url;
    }
    if (evt.item.type !== "doctor" && evt.item.type !== "clinic") {
      if (this.city, this.specs, this.selectedarea && this.city != this.selectedarea) {
        this.getResults(this.city, this.specs, this.selectedarea, null, null);
      } else if (this.city == this.selectedarea && this.specs) {
        this.getResults(this.city, this.specs, null, null, null);
      }
    } else {
      this.getResults(this.city, this.specs, this.selectedarea, evt.item.type, evt.item.id);
    }
  }

  getResults(city,spec,area,type,id) {
    if (type == null) {
      if (city != area && city && spec && area) {
        this.router.navigateByUrl(city + '/' + spec + '/' + area);
      } else if (area == null && city && spec) {
        this.router.navigateByUrl(city + '/' + spec);
      }
    } else {
      if (type == 'clinic' && area && area != city && spec.indexOf('clinic-search-with') != -1) {
        this.router.navigateByUrl(city + '/' + spec.substring(29) + '/' + area + '/clinics');
      } else if (type == 'clinic' && spec.indexOf('clinic-search-with') != -1) {
        this.router.navigateByUrl(city + '/' + spec.substring(29) + '/clinics');
      } else if (type == 'clinic') {
        this.router.navigateByUrl('clinicdetails/' + city + '/' + id);
      } else if (type == 'doctor' && area && area != city && spec.indexOf('doctor-search-with') != -1) {
        this.specs = spec.substring(29);
        this.router.navigateByUrl(city + '/' + this.specs + '/' + area + '/doctors');
      } else if (type == 'doctor' && spec.indexOf('doctor-search-with') != -1) {
        this.specs = spec.substring(29);
        this.router.navigateByUrl(city + '/' + this.specs + '/doctors');
      } else if (type == 'doctor') {
        this.router.navigateByUrl('doctors/' + city + '/' + this.docUrl);
      }
    }
  }

  onClearArea() {
    this.area = null;
    this.city = null;
    this.selectedarea = null;
  }

  onClearSpec() {
    this.speciality = null
    this.specs = null;
  }

  logout() {
    this.Userservice.logout();
    this.notLoggedin = true;
    this.isLoggedin = false;
  }

  get form() {
    return this.mobileVerficationForm.controls;
  }

  get otp() {
    return this.otpVerficationForm.controls;
  }

  get login() {
    return this.loginForm.controls;
  }

  signInUser() {
    let details = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    }
    this.loginForm.reset();
    this.Userservice.signIn(details).subscribe(res => {
      if (this.Userservice.userValue) {
        this.closelogin.nativeElement.click();
      } else {
        this.loginError = true;
        setTimeout(() => {
          this.loginError = false;
        }, 5000);
      }
    });
  }

  //<!---On submission of mobile number--->
  mobileSubmit() {
    if (this.mobileVerficationForm.invalid) {
      return;
    } else {
      localStorage.setItem('mobile', this.mobileVerficationForm.value.mobile);
      let number = localStorage.getItem('mobile');
      let details = {
        mobile: number,
      };
      this.mobileVerify = this.Userservice.mobileVerfication(details).subscribe((res: any) => {
        let statuscode = res.body.details.status;
        if (res.status == 200 && statuscode == '1') {
          this.closeNewRegist.nativeElement.click();
          this.mobileVerficationForm.reset();
          $("#verifyModal").modal('show');
        } else if (res.status == 200 && statuscode != '1') {
          localStorage.clear();
          this.show = false;
          this.error = true;
          this.mobileVerficationForm.reset();
        }
      });
    }
  }

  //<!----On submission of Otp--->
  otpSubmit() {
    if (this.otpVerficationForm.valid) {
      let number = localStorage.getItem('mobile');
      let code = this.otpVerficationForm.value.otp;
      let details = {
        mobile: number,
        otp: code,
      };
      this.otpVerify = this.Userservice.otpVerfication(details).subscribe((res: any) => {
        if (res.status === 200) {
          this.closeVerify.nativeElement.click();
          this.otpVerficationForm.reset();
          $("#registModal").modal('show');
        } else {
          this.otpVerficationForm.reset();
          this.otpMsg = false;
          this.otperror = true;
        }
      });
    } else {
      return;
    }
  }

  //<!---On selecting registration type--->
  registrationType(button) {
    this.buttonValue = button.id;
    this.closeregist.nativeElement.click();
    if (this.buttonValue == 'user') {
      this.router.navigate(['/register'], {skipLocationChange: true});
    } else if (this.buttonValue == 'doctor') {
      this.router.navigate(['/doctor'], {skipLocationChange: true});
    } else if (this.buttonValue == 'clinic') {
      this.router.navigate(['/clinic'], {skipLocationChange: true});
    }
  }

  clearForm() {
    this.error = false;
    this.show = true;
    this.otpMsg = true;
    this.otperror = false;
  }

  ngOnDestroy(): void {
    this.updateUserRole()
  }

  openRequestDemoModal() {
    this.Userservice.openDemoModalComponent();
  }

}
