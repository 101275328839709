import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { UserService } from '../../service/user.service';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OTPmodalComponent } from '../otpmodal/otpmodal.component';
import { Subscription } from 'rxjs';
import {location} from "ngx-bootstrap/utils/facade/browser";

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgotPasswordForm:FormGroup;
  isShow = true;
  @ViewChild('closelogin') closelogin;
  @ViewChild('closeFPassword') closeFPassword;
  otpResponse: any;
  loginSuccess: any;
  loginError: boolean;
  submitted: boolean;
  submitted1: boolean;
  submitted2: boolean;
  validNumber: boolean;
  sendingOtp: boolean;
  otpSent: boolean;
  hideResendBtn: boolean = true;
  otpCount: number = 0;
  loginLoading: boolean;
  otpInvalid: boolean;
  signinSuccess: boolean;
  userId: string;
  msg: string;
  erroMsg: any;
  isloading1:boolean;
  errStatus = false;
  mobileNumber:string;
  userType: string;
  submitClicked: boolean;
  loggingIn: boolean;
  message: boolean=false;
  @Input('nextpage') landingpage:string; 
  @Input('scrWidth') scrWidth:number; 
  @Input() modalId:string;
  modalOpen = '#';
  loginSendOtp: Subscription;

  constructor(
    private Userservice:UserService,
    private formBuilder:FormBuilder,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.loginForm = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,3}))$/)])],
      'password': ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      'loginOtp': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6), Validators.maxLength(6)]]
    });

    this.forgotPasswordForm = this.formBuilder.group({
      mobile: new FormControl('',  [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,3}))$/)])
    })
  }

  ngOnInit(): void {
    // opening the login modal automatically when user wants to get into prescriptions page.
    if (this.landingpage == 'prescriptions' && this.Userservice.userValue == null){
      if (this.scrWidth < 992){
        // auto collapse the navbar to login modal be visible
        $('#navbarNav').collapse('show')
      }
      $("#loginModal").modal('show')
    }
  }

  get login() {
    return this.loginForm.controls;
  }

  signInUser(){
    this.submitted = true;
    this.submitted1 = null;
    var details={
      email:this.loginForm.value.email,
      password:this.loginForm.value.password
    }
    if(this.login.email.valid && this.login.password.valid){
      this.loggingIn = true;
      this.Userservice.signIn(details).subscribe(res=>{
        this.loggingIn = null;
        if(this.Userservice.userValue){
          this.message = true;
          if (this.router.url.indexOf('/appointment/') > -1 || this.router.url.indexOf('/diagnostic-center/cart') > -1  || this.router.url.indexOf('videoconsultation') > -1) {
            this.closelogin.nativeElement.click();
            window.location.reload();
          } else if((this.landingpage!='' && this.landingpage == 'prescriptions') && this.Userservice.userValue['details']['role'] == 'USER'){
            window.location.href = this.Userservice.dashboardURL + 'user/prescriptions/?token=' +this.Userservice.userValue['details']['userId'];       
          } else {
            let userToken = this.Userservice.userValue['details']['userId'];
           window.location.href = this.Userservice.dashboardURL + 'user/dashboard/?token=' + userToken;
          }
        }
        else{
          this.loginError=true;
          setTimeout(() => {
            this.loginError=false;
          }, 5000);
        }

    },
    error=>{
      console.log(error);
    })
    }
  }

  loginOtpSuccess(){
    this.submitted2 = true;
    this.otpInvalid = null
    if(this.otpResponse['status'] == 1 && this.login.email.valid && this.login.loginOtp.valid){
      this.loginLoading = true;
      this.validNumber = null;
       this.Userservice.verifyOtpLogin(this.loginForm.value.email, this.loginForm.value.loginOtp).subscribe(res =>{
         this.loginSuccess = res;
         this.loginLoading = false;
         if(this.loginSuccess['status'] == 1){
           this.Userservice.afterLogin(res)
           this.signinSuccess = true;
           this.message = true;
           if (this.router.url.indexOf('/appointment/') > -1 || this.router.url.indexOf('/diagnostic-center/cart') > -1  || this.router.url.indexOf('videoconsultation') > -1) {
             this.closelogin.nativeElement.click();
             window.location.reload();
           } else if((this.landingpage!='' && this.landingpage == 'prescriptions') && this.Userservice.userValue['details']['role'] == 'USER'){
            window.location.href = this.Userservice.dashboardURL + 'user/prescriptions/?token=' +this.Userservice.userValue['details']['userId'];       
          } else {
             let userToken = this.Userservice.userValue['details']['userId'];
             window.location.href = this.Userservice.dashboardURL + 'user/dashboard/?token=' + userToken;
           }
         }
         else{
           this.otpInvalid = true
         }
       })
    }
  }

    loginWithOtp(otpMethod){
      this.submitted1 = true;
      this.submitted = null;
      this.validNumber = null;
      if(this.login.email.valid){
        this.sendingOtp = true;
        if(otpMethod == 'send'){
          this.isShow = false;
        }
        this.loginSendOtp = this.Userservice.sendOtpForLogin(this.loginForm.value.email).subscribe(response =>{
          this.sendingOtp = false;
          this.otpSent = true;
          setTimeout(() => {
            this.otpSent=false;
          }, 3000);
          this.otpResponse = response;
          this.otpCount += 1;
          if(this.otpCount >= 3) {
            this.hideResendBtn = false;
          }
          if(this.otpResponse['status'] == 0){
            this.validNumber = false
          }
          else if(this.otpResponse['status'] == 1){
            this.validNumber = true;
          }
        })
      }
    }

    loginWithPassword(){
      this.submitted = null;
      this.submitted1 = null;
      this.submitted2 = null;
      this.sendingOtp = null;
      this.validNumber = null;
      this.loginLoading = null;
      this.signinSuccess = null;
      this.otpInvalid = null;
      this.isShow = true;
      this.otpCount = 0;
      this.hideResendBtn = true;
    }

    loginFormReset(){
      this.loginForm.reset();
      this.loginWithPassword();
    }

    resetPassword(){
      this.closelogin.nativeElement.click();
      this.loginForm.reset();
      this.errStatus = false;
    }

    fPassword(){
      this.submitClicked = true;
      if(this.forgotPasswordForm.valid){
        this.isloading1 = true;
        this.Userservice.forgotPassword(this.forgotPasswordForm.get('mobile').value).subscribe((data: any) =>  {
          this.isloading1 = null;
          sessionStorage.setItem('mobileNumber', this.forgotPasswordForm.get('mobile').value)
          if(data.status == 200){
            if (data.body.details.label == "email") {
              this.erroMsg = data.body.details.label;
              setTimeout(() => {
                this.closeFPassword.nativeElement.click();
                this.forgotPasswordForm.reset();
                this.erroMsg = null;
              }, 5000);
            } else {
              sessionStorage.setItem('loginType', 'old')
              this.closeFPassword.nativeElement.click();
              this.Userservice.openOtpModalComponent();
              sessionStorage.setItem('userId1', data['body']['details']['userId']);
              this.msg = sessionStorage.getItem('userId1');
            }
          }
          else if(data.status == 204){
            this.erroMsg = 204;
            setTimeout(() => {
              this.erroMsg = null;
            }, 5000);
          }
        })
      }
    }

    resetFPassword() {
      this.forgotPasswordForm.reset();
      this.erroMsg = null;
      this.submitClicked = null;
    }

    get forgotPasswordValue(){
      return this.forgotPasswordForm.controls;
    }

}
