import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class ThyrocareService {

  apiKey: string;
  token: string;
  apiUrl: string;
  username = "9581710006";

  constructor(
    private https:HttpClient,
    private userService: UserService,
  ) {
    this.apiUrl = this.userService.thyroApi;
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  loginThyrocare(): Observable<any> {
    let rawData = {
      username: "9581710006",
      password: "B39560",
      portalType: "",
      userType: "DSA",
      Usertypeid: null
    }
    return this.https.post(
      this.apiUrl + 'Login/Login',
      rawData,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map(this.extractData)
    );
  }

  getOffers(): Observable<any> {
    let rawData = {
      apiKey: this.apiKey,
      ProductType: "OFFER"
    }
    return this.https.post(
      this.apiUrl + 'productsmaster/Products',
      rawData,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map(this.extractData)
    );
  }

  getPackages(): Observable<any> {
    let rawData = {
      apiKey: this.apiKey,
      ProductType: "PROFILE"
    }
    return this.https.post(
      this.apiUrl + 'productsmaster/Products',
      rawData,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map(this.extractData)
    );
  }

  getTests(): Observable<any> {
    let rawData = {
      apiKey: this.apiKey,
      ProductType: "TEST"
    }
    return this.https.post(
      this.apiUrl + 'productsmaster/Products',
      rawData,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map(this.extractData)
    );
  }

  getPincodeAvailability(pincode): Observable<any> {
    let rawData = {
      apiKey: this.apiKey,
      Pincode: pincode
    }
    return this.https.post(
      this.apiUrl + 'TechsoApi/PincodeAvailability',
      rawData,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map(this.extractData)
    );
  }

  getSlots(pincode, date): Observable<any> {
    let rawData = {
      apiKey: this.apiKey,
      Pincode: pincode,
      Date: date
    }
    return this.https.post(
      this.apiUrl + 'TechsoApi/GetAppointmentSlots',
      rawData,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map(this.extractData)
    );
  }

  viewDSACart(apiData): Observable<any> {
    apiData.ApiKey = this.apiKey;
    apiData.Mobile = this.username;
    console.log(apiData);
    return this.https.post(
      this.apiUrl + 'CartMaster/DSAViewCartDTL',
      apiData,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map(this.extractData)
    );
  }

  bookAppointment(apiData): Observable<any> {
    return this.https.post(
      this.apiUrl + 'BookingMaster/DSABooking',
      apiData,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map(this.extractData)
    );
  }

}
