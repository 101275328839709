import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UserService } from '../service/user.service';
import * as $ from 'jquery';
import { User } from '../models';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  user: User;
  otpVerficationForm: FormGroup;
  data:any;
  city: any;
  name: any;
  time: any;
  public errorMsg;
  transactionMsg:boolean=true;
  constructor(
    private formBuilder:FormBuilder,
    private Userservice:UserService
  ) {this.user = this.Userservice.userValue;}

  ngOnInit(): void {
   /* $('.btn-book-appointment').on('click', function(e){
      if ($(window).width() > 767) {
        e.preventDefault();
        $('.sr-card').removeClass('selected');
        $(this).closest('.sr-card').addClass('selected');
        $('#sr-map').addClass('d-none');
        $('#sr-doctor-details').removeClass('d-none');
      }
    });
    this.Userservice.currentParams.subscribe(response=>{
      console.log(response);
      this.data=response;
      this.city=this.data['city']
      this.name=this.data['clinicName']
      this.time=this.data['time']
      
    })
    this.otpVerficationForm = this.formBuilder.group({
     
      otp:['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6), Validators.maxLength(6)]],
});*/

  }
  
 /* get otp() { 
    return this.otpVerficationForm.controls; 
  }

submit(){
  let code =  this.otpVerficationForm.value.otp;
  console.log(code);
  
}*/
logout() {
  this.Userservice.logout();
}
/*
inclinic(){
         var x = document.getElementById("inclinic");
				var y = document.getElementById("video");
				var z = document.getElementById("selection");
	        	x.style.left= "0px";
	        	y.style.left= "700px";
	        	z.style.left= "0px";

}
video(){
  var x = document.getElementById("inclinic");
				var y = document.getElementById("video");
				var z = document.getElementById("selection");
	        	x.style.left= "-600px";
	        	y.style.left= "0px";
	        	z.style.left= "231px";
}
creatOrder(){
  var details={
    amount:5000,
  }
  console.log(details);
  
    this.Userservice.createOrderID(details).subscribe(res=>{
      console.log(res);
      
    },
    error => {
      this.errorMsg =error;
      
      //this.errorMsg=errorMessage.split
    })
}*/
}
