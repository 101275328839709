<ngx-spinner *ngIf="appointmentConfirmationSpinner"
             bdColor="#fff"
             size="medium"
             color="#FA030E "
             type="ball-pulse"
>
  <p style="font-size: 20px; color:#FA030E ">{{loadingText}}</p>
</ngx-spinner>

<app-header></app-header>

<div class="subheader subheader-filter">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-sm-4">
        <h4 class="pg-title">Request Payment</h4>
      </div>
      <div class="col-sm-8 text-right text-left-sm"></div>
    </div>
  </div>
</div>

<div class="booking-success" *ngIf="isPaid">
  <div class="row">
    <div id="appointment_details" class="col-md-12">
      <div class="page-content">
        <div class="booking-result-wrap">
          <div class="container">
            <div class="confirmation-wrap">
              <div class="booking-confirmation-title">
                <i class="fa fa-check-circle-o fa-5x" aria-hidden="true"></i>
                <h3>Your payment has been done</h3>
                <p>Thank you for booking an appointment through Gigadocs!</p>
              </div>
              <div class="container mt-4 text-center">
                <label>Appointment ID:</label>
                <h5>{{appointmentId}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-content" *ngIf="noError && !isPaid">
  <div class="banner-strip book-appointment-strip">
    <div class="strip-clinic-img">
      <img src="assets/images/clinicImage.png">
    </div>
    <div class="banner-strip-content">
      <div class="book-appointment-doc">
        <div class="container">
          <div class="bd-img" *ngIf=!contentLoaded>
            <ngx-skeleton-loader
              appearance="circle"
              animation="pulse"
              [theme]="{background: '#dedfe1' ,width: '120px', height: '120px'}">
            </ngx-skeleton-loader>
          </div>
          <div class="bd-img" *ngIf=contentLoaded>
            <img class="img-fluid" *ngIf="(appointmentData['doctorGender']| lowercase) == 'male'" src="{{appointmentData['doctorImage']}}"
                 onerror="src='assets/images/doctor-male-default.png'" alt="{{appointmentData['doctorName']}}"/>
            <img class="img-fluid" *ngIf="(appointmentData['doctorGender']| lowercase) == 'female'" src="{{appointmentData['doctorImage']}}"
                 onerror="src='assets/images/doctor-female1.png'" alt="{{appointmentData['doctorName']}}"/>
            <img width="100%" *ngIf="appointmentData['doctorGender'] == ''"  src="{{appointmentData['doctorImage']}}"
                 onerror="src='assets/images/doctor-male-default.png'" alt="{{appointmentData['doctorName']}}"/>
          </div>
          <div class="bd-basic-info">
            <div>
              <h3>Dr. {{appointmentData['doctorName'] | camelcase}}</h3>
              <p>{{specialization}}<span class="ml-1">({{experience}} Years of Experience)</span></p>
              <h5><i class="ic-inr"></i>{{appointmentData['doctorFee']}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="appointment-content">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="appointment-datetime card-p">
            <div class="row align-items-center">
              <div class="col-sm-10 col-xs-10">
                <h4 class="head-2"><i class="ic-datetime"></i> Appointment Date/Time:</h4>
                <h3>{{aptTime}}, {{aptDate}}</h3>
              </div>
            </div>
          </div>
          <div class="appointment-doc-info">
            <h4 class="head-2">Doctor Info:</h4>
            <div class="row">
              <div class="col-sm-6">
                <h6><i class="ic-doctor-title"></i> Specialities</h6>
                <p *ngIf=!contentLoaded>
                  <ngx-skeleton-loader
                    appearance="rectangle"
                    animation="pulse"
                    [theme]="{background: '#dedfe1' ,width: '200px', height: '50px'}">
                  </ngx-skeleton-loader>
                </p>
                <p *ngIf=contentLoaded>{{specialities | commapipe}}</p>
              </div>
            </div>
          </div>
          <div class="appointment-clinic-info">
            <h4 class="head-2">Clinic Details:</h4>
            <div class="row">
              <div class="col-sm-8">
                <h5>{{appointmentData['clinicName']}}</h5>
                <p *ngIf=!contentLoaded>
                  <ngx-skeleton-loader
                    appearance="rectangle"
                    animation="pulse"
                    [theme]="{background: '#dedfe1' ,width: '200px', height: '50px'}">
                  </ngx-skeleton-loader>
                </p>
                <p>{{appointmentData['clinicAddress']}}</p>
              </div>
              <div class="col-sm-4">
                <div class="appointment-clinic-map"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="appointment-pt-form">
            <div class="card-p">
              <h4 class="head-2">Patient/Visitor Details:</h4>
              <form [formGroup]="appointmentForm" >
                <div class="form-group required">
                  <label class="control-label">Name</label>
                  <input type="text" formControlName="name" class="form-control" value="{{appointmentData['patientName']}}" readonly />
                </div>
                <div class="form-group required">
                  <label class="control-label">Mobile Number</label>
                  <input type="text" formControlName="mobile" class="form-control" value="{{appointmentData['userMobile']}}" readonly />
                </div>
                <div class="form-group">
                  <label>Email Address</label>
                  <input type="text" formControlName="email" class="form-control"  value="{{appointmentData['userEmail']}}" readonly />
                </div>
                <div class="form-group" *ngIf="login && points > 0" >
                  <div class="form-check-inline">
                    <label class="form-check-label" >
                      <input type="checkbox" (change)="onPointsChange($event, points, consultationFee)" class="form-check-input">{{points}} <span style="font-weight: 400"> allow reward points to book appointment.</span>
                    </label>
                  </div>
                </div>
                <div class="form-group" *ngIf="!login" >
                  <span>Please <app-login *ngIf="!login" [modalId]="'loginModal'"></app-login> to <b>Redeem</b> earned points</span>
                </div>
                <div class="form-group">
                  <button class="btn btn-secondary" type="button" data-toggle="modal" (click)="onSubmit()"  [disabled]="clicked" >
                    {{vcButtonText}}
                    <i class="pi pi-spin pi-spinner" style="font-size: 1rem" *ngIf="loadingSpinner"></i>
                  </button>
                </div>
                <p>By clicking Book Appointment, you agree to our <a (click)="terms();">Terms and Conditions.</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" #otpVerify id="otpModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="otp-modal-wrap">
          <img src="assets/images/ic-otp.png" alt="OTP">
          <h4>Verify your mobile</h4>
          <p *ngIf="otpMsg">Enter an OTP Code sent your mobile number</p>
          <div #alert *ngIf="sendingOtp" class="alert alert-warning">
            <div class="spinner-border spinner-border-sm"></div>
            Please wait..
          </div>
          <div #alert *ngIf="otpSent" class="alert alert-success">OTP successfully sent</div>
          <div #alert *ngIf="otperror" class="alert alert-danger">Invalid OTP</div>
          <div #alert *ngIf="errorInfo" class="alert alert-danger">{{errorMsg}}</div>
          <form id="otp-form" [formGroup]="otpVerficationForm" >
            <div class="form-group">
              <input type="text" maxlength='6' id="otp" placeholder="******" formControlName="otp"/>
            </div>
            <button class="btn btn-second" (click)="otpSubmit()" [disabled]="!otpVerficationForm.valid">Submit</button>
          </form>
          <p>
            If you don’t receive a code?
            <a href="javascript:void(0)" (click)="resendOtp()">Resend</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div  id="paymentStatus" #transactionSuccess class="modal fade" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100"><i class="fas fa-check-circle text-success mr-1"></i>Success</h4>
      </div>
      <div class="modal-body">
        <p class="text-center text-success">Transaction Successful</p>
      </div>
      <div class="modal-footer">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        <p>Please wait for a while,we are confirming your appointment details.</p>
      </div>
    </div>
  </div>
</div>

<div id="paymentFailed" #transactionFailed class="modal fade" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title w-100 "><i class="fas fa-exclamation-circle text-danger mr-1"></i>Alert!</h3>
      </div>
      <div class="modal-body">
        <p class="text-center text-danger">Your transaction has failed. Please go back and try again.</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger btn-block" data-dismiss="modal" routerLink="/home">OK</button>
      </div>
    </div>
  </div>
</div>

<app-footer lat="17.4611" long="78.355797"></app-footer>
