import { Component, OnInit } from '@angular/core';
import {UserService} from "../service/user.service";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    var details = {
      metaTitle: 'Terms and Conditions | Gigadocs',
      metaDescription: 'Gigadocs Terms and Conditions',
      ogTitle: 'Terms and Conditions | Gigadocs',
      ogDescription: 'Gigadocs Terms and Conditions'
    };
    this.userService.setDynamicMetaData(details);
  }

}
