import { Component, OnInit } from '@angular/core';
import {UserService} from "../service/user.service";

@Component({
  selector: 'app-howitworks',
  templateUrl: './howitworks.component.html',
  styleUrls: ['./howitworks.component.css']
})
export class HowitworksComponent implements OnInit {

  constructor(private  userService: UserService) { }

  ngOnInit(): void {
    var details = {
      metaTitle: 'How it Works | Gigadocs',
      metaDescription: 'A Quick run through the process of Searching a doctor online, booking an appointment online, and have a Doctor consultation online.',
      ogTitle: 'How it Works | Gigadocs',
      ogDescription: 'A Quick run through the process of Searching a doctor online, booking an appointment online, and have a Doctor consultation online.',
      metaKeywords: 'Doctor’s appointment, online appointment, Video Consultation, Doctor Consultation, Chat with a doctor, Health history in One App, Book Doctor Appointments'
    };
    this.userService.setDynamicMetaData(details);
  }

}
