


    <div class="modal-header"> 
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="bsModalRef.hide()">&times;</button>          
        <h4 class="modal-title">Do You Need a Demo?</h4>
    </div>
    <div class="modal-body">
        <div class="login-modal-wrap">
            <form id="login-form" [formGroup]="requestDemoForm" class="mx-2">
                <div class="alert alert-warning" *ngIf="isLoading">
                    <span class="spinner-border spinner-border-sm"></span>
                     Please wait, Processing...
                </div>
                <div *ngIf="demoRequestSent" class="alert alert-success">
                    Thanks! We will contact you.
                </div>
                <div class="form-group required">
                    <label class="control-label">Name</label>
                    <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" 
                           [ngClass]="{ 'is-invalid': submitted && demoForm.name.errors }">
                    <div *ngIf="submitted && demoForm.name.errors">
                        <small *ngIf="demoForm.name.errors.required" class="text-danger">Name is required</small>
                        <small *ngIf="demoForm.name.errors?.minlength" class="text-danger">Name should be minimum 3 characters</small>
                    </div>       
                </div>
                <div class="form-group required">
                    <label class="control-label">Mobile</label>
                    <input type="text" class="form-control" placeholder="Enter Mobile Number" formControlName="phone"
                           [ngClass]="{'is-invalid': submitted && demoForm.phone.errors}">
                    <div *ngIf="submitted && demoForm.phone.errors">
                        <small *ngIf="demoForm.phone.errors.required" class="text-danger">Mobile number is required</small>
                        <small *ngIf="demoForm.phone.errors?.minlength || demoForm.phone.errors?.maxlength || demoForm.phone.errors.pattern" class="text-danger">
                            Enter a valid mobile number
                        </small>
                    </div>         
                </div>
                <div class="form-group">
                    <label class="control-label">Email</label>
                    <input type="text" class="form-control" placeholder="Enter Email" formControlName="email"
                        [ngClass]="{'is-invalid': submitted && demoForm.email.errors}">
                    <div *ngIf="submitted && demoForm.email.errors">
                        <small *ngIf="demoForm.email.errors.pattern" class="text-danger">Enter a valid email</small>
                    </div>    
                </div>
                <div class="form-group required">
                    <label class="control-label">City</label>
                    <input type="text" class="form-control" placeholder="Enter City" formControlName='city'
                           [ngClass]="{'is-invalid': submitted && demoForm.city.errors}">
                    <div *ngIf="submitted && demoForm.city.errors">
                        <small *ngIf="demoForm.city.errors.required" class="text-danger">City name is required</small>
                        <small *ngIf="demoForm.city.errors.pattern" class="text-danger">Enter a valid city</small>
                    </div>             
                </div>
                <div class="form-group">
                    <div class="row align-items-center">
                        <div class="col-sm-7">
                            <input type="button" class="btn btn-primary pl-5 pr-5" value="Contact Me" [disabled]="isLoading" (click)="submitRequestDemo()">
                        </div>
                    </div>
                </div>
            </form>                 
        </div>
    </div>                    

