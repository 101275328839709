
<!--  verify OTP modal -->

			<div class="modal-header">
				<button type="button" #closeVerify class="close" data-dismiss="modal" aria-hidden="true" (click)="bsModalRef.hide()">
					&times;
				</button>
				<h4 class="modal-title">Verify Mobile</h4>
			</div>
			<div class="modal-body">
				<div class="verify-modal-wrap">
					<form id="verify-form" [formGroup]="otpVerficationForm">
						<div class="row">
							<div class="col-10">
								<p>We have sent an OTP to your mobile number. Please verify!</p>
								<div #alert *ngIf="otperror" class="alert alert-danger">Invalid OTP</div>
                <div *ngIf="sendingOtp" class="alert alert-warning">
                  <span class="spinner-border spinner-border-sm"></span>
                  Please wait, Processing...
                </div>
                <div class="alert alert-success" *ngIf="otpSent">
                  OTP Sent Successfully
                </div>
                <div class="alert alert-success" *ngIf="otpVerified">
                  OTP has been verified successfully
                </div>
							</div>
							<div class="col-2">
								<img src="assets/images/ic-otp.png" class="img-center " alt="Pharmacy"/>
							</div>
						</div>
						<div class="form-group required">
							<label class="font-weight-bold control-label">OTP Code</label>
							<input type="text" class="form-control" placeholder="Enter OTP" formControlName="otp"
									 name="otp" required="required"  [ngClass]="{ 'is-invalid': otpSubmitted && otp.otp.errors }">
							<div *ngIf = "otpSubmitted && otp" class="text-danger">
							   <small  *ngIf="otp.otp.errors?.required">OTP required</small>
							   <small *ngIf="otp.otp.errors?.pattern || otp.otp.errors?.minlength || otp.otp.errors?.maxlength">
								   Invalid OTP
								</small>
							</div>
						</div>
						<div class="form-group">
							<button class="btn btn-primary pl-4 pr-4" data-toggle="modal"  [disabled]="isloading2"
									(click)="otpSubmit(userType)">
								Submit
								<span *ngIf="isloading2" class="spinner-border spinner-border-sm"></span>
							</button>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer" *ngIf="isShow">
				<p>Didn't get an OTP?</p>
				<a href="javascript:void(0)" (click)="resendOtp(userType)">Resend OTP</a>
			</div>



