<app-header></app-header>
<div class="subheader subheader-filter">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-sm-4">
        <h4 class="pg-title">Reschedule Appointment</h4>
      </div>
    </div>
  </div>
</div>
<div class="booking-reschedule">
  <div class="row">
    <div class="col-md-8">
      <div class="page-content">
        <div class="booking-reschedule-wrap">
          <div class="container">
            <div class="confirmation-wrap">
              <div class="reschedule-appointments">
                <div class="row">
                  <div class="col-md-5">
                    <img class="img-responsive"  src="assets/images/reschedule1.png" alt="vc-steps" height="150" width="200">
                  </div>
                  <div class="col-md-7">
                    <div class="reschedule">
                      <h2>Reschedule the Appointment</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div id="inclinic" class="reschedule-input-group">
                <h5>Select a Slot</h5>
                <div class="sr-clinic-appointment">
                  <ul class="appointment-dates">
                    <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayApply}" (click)="today()">Today</a></li>
                    <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowApply}" (click)="tomorrow()">Tomorrow</a></li>
                    <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate':dateHighlight}">
                      <i class="ic-calendar mr-2"></i>
                      <input type="text" readonly #calendar [(ngModel)]="datePicker" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedDate($event)"
                             id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                    </li>
                  </ul>
                  <div class="timeslot-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" *ngIf="morningSlotsLength != 0">
                        <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#morningVC">
                          <i class="ic-morning"></i> <span>Morning</span>
                        </a>
                      </li>
                      <li class="nav-item" *ngIf="afternoonSlotsLength != 0">
                        <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && afternoonSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#afternoonVC">
                          <i class="ic-afternoon"></i> <span>Afternoon</span>
                        </a>
                      </li>
                      <li class="nav-item" *ngIf="eveningSlotsLength != 0">
                        <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#eveningVC">
                          <i class="ic-evening"></i> <span>Evening</span>
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent"  *ngIf = "(morningSlotsLength !=0 || afternoonSlotsLength !=0 || eveningSlotsLength !=0) && !waitSpinner">
                      <div class="tab-pane" id="morningVC" [ngClass]="{' fade show active' : (morningSlotsLength != 0)}" *ngIf="morningSlotsLength != 0" role="tabpanel">
                        <div style=" display:inline-block;" *ngFor="let time of morningSlots;let i=index">
                          <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time)">{{time}}</button>
                        </div>
                        <div *ngIf="morningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                      </div>
                      <div class="tab-pane" id="afternoonVC" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength != 0)}"  *ngIf="afternoonSlotsLength != 0" role="tabpanel">
                        <div  style=" display:inline-block;" *ngFor="let time of afternoonSlots;let i=index">
                          <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time)" >{{time}}</button>
                        </div>
                        <div *ngIf="afternoonSlotsLength === 0">NO SLOTS AVAILABLE</div>
                      </div>
                      <div class="tab-pane" id="eveningVC" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength != 0)}" *ngIf="eveningSlotsLength != 0" role="tabpanel">
                        <div  style=" display:inline-block;" *ngFor="let time of eveningSlots;let i=index">
                          <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time)">{{time}}</button>
                        </div>
                        <div *ngIf="eveningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                      </div>
                      <div *ngIf="morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0">NO SLOTS AVAILABLE</div>
                    </div>
                    <div class="tab-content" *ngIf="(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0) && !waitSpinner">NO SLOTS AVAILABLE</div>
                    <div class="fa-1x tab-content" *ngIf = "(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0 ) && waitSpinner">
                      <p><i class="fas fa-circle-notch fa-spin" style ="color: #4a85ec;margin-right: 10px;"></i> Please wait...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="reschedule-details">
        <div class="container">
          <div class="reschedule-form">
            <h4>Appointment Details</h4>
            <form id="getVcAppointment" style="margin-top: 40px;">
              <div class="form-group">
                <h5 class="content">Doctor Name</h5>
                <label>{{doctorName}}</label>
              </div>
              <div class="form-group">
                <h5 class="content">Date</h5>
                <label class="appointmentDateView">{{aptDate}}</label>
              </div>
              <div class="form-group">
                <h5 class="content">Time</h5>
                <label class="appointmentTimeView">{{aptTime}}</label>
              </div>
              <div class="form-group">
                <button [disabled]="btnDisable" (click)="confirmAppointment()" class="btn appointmentRescheduleBtn">Confirm Appointment</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer lat="17.4611" long="78.355797"></app-footer>
