import { ActivatedRoute,Router} from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UserService } from '../service/user.service';
import { DatePipe } from '@angular/common';
import { MapInfoWindow, GoogleMap } from '@angular/google-maps';
import { NgxSpinnerService } from "ngx-spinner";
import { HostListener } from "@angular/core";
import * as $ from 'jquery';
import {CamelcasePipe} from "../camelcase.pipe";
import {User} from "../models";
import {Calendar} from "primeng/calendar";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {
  @Output() notify =new EventEmitter();
  @ViewChild(GoogleMap, {static: false}) map: GoogleMap
  @ViewChild(MapInfoWindow, {static: false}) info: MapInfoWindow
  @ViewChild('calendarcall') calendarCall: Calendar;
  center: google.maps.LatLngLiteral
  markers: any = [];
  datePicker: Date;
  datePickerVC: Date;
  city: any;
  specs: any;
  selectedarea: any;
  city1: any;
  specs1: any;
  area1: any;
  brand: string;
  area: any;
  speciality: any;
  list = [];
  name: string;
  data: any;
  isArea: boolean = false;
  isCity: boolean = false;
  pageNumber: number
  pageNo: any = 1;
  location: any;
  lat: number;
  longitude: number;
  SResults: any = [];
  resultsDisp: any = []
  pageResults: any = []
  address: any = [];
  docName: string;
  doc: any;
  mapDisplay: boolean;
  docDetails: boolean = false;
  showSlider: boolean = false;
  clinicTab: boolean;
  width: number = window.outerWidth;
  mobileWidth: number = 1024;
  results: boolean = true;
  apply: boolean = true;
  apply1: boolean = false;
  dateHighlight: boolean = false;
  workingHours = [];
  vcWorkingHours = [];
  docId: any;
  docService: any;
  clinicInfo: any = [];
  j: any;
  clinicInfo1: any = [];
  clinicName: any;
  clinicAddress: any;
  clinicCity: any;
  clinicArea: any;
  selectedIndex: any;
  inClinic: any;
  isOnline: any;
  isOnCall: any = '0';
  isSubscribed: any;
  selectedInClinic: any;
  selectedIsOnline: any;
  VC: boolean;
  todayDate;
  tomorrowDate;
  clinicId: any;
  slotType: any;
  date: any;
  morningSlots: any = [];
  afternoonSlots: any = [];
  eveningSlots: any = [];
  contentLoaded = false;
  emptyresults: any[];
  minDate: Date;
  maxDate: Date;
  morningSlotsLength: any;
  afternoonSlotsLength: any;
  eveningSlotsLength: any;
  selectedTime: any;
  docUrl: any;
  appointmentDate: string;
  areaString: any;
  hours: any;
  time: any;
  eveningSlotsLengthVC: any;
  eveningSlotsVC: any[];
  morningSlotsLengthVC: any;
  afternoonSlotsLengthVC: any;
  morningSlotsVC: any[];
  afternoonSlotsVC: any[];
  applyVC: boolean;
  apply1VC: boolean;
  resultsFound: boolean = false;
  resultsNotFound: boolean = false;
  public errorMsg: any;
  vcSpinner: boolean;
  clickedVC = false;
  clickedInclinic = false;
  vcColor: boolean;
  title: string;
  titleLocation: any;
  urlparameters: any;
  cityinUrl: any;
  specinUrl: any;
  areainUrl: any;
  notaResponsiveButton: boolean;
  responsiveButton: boolean;
  scrHeight: any;
  scrWidth: any;
  type: any;
  isDoctors: boolean = false;
  onlyCity: boolean = false;
  notLoggedin: boolean = true;
  isLoggedin = false;
  errorMessage: boolean;
  relatedSpecialityArray: any=[];
  sortOrder: string = '';
  isFilters: boolean = false;
  filterNear: boolean = true;
  filterFee: boolean = false;
  clinicFee: any;
  submitted: boolean = false;
  user: User;
  message: any = '';
  todayCall: boolean = true;
  tomorrowCall: boolean = false;
  dateHighlightCall: boolean = false;
  datePickerCall: Date;
  btnDisable: boolean = true;
  showClinic: number;
  showOnline: number;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  inclinicWaitSpinner: boolean;
  vcWaitSpinner: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.screenDimensions();
  }

  constructor(
    private activatedroute: ActivatedRoute,
    private router: Router,
    private Userservice: UserService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private camelcase: CamelcasePipe
  ) {
    this.getScreenSize();
    if (this.Userservice.userValue) {
      this.notLoggedin = false;
      this.isLoggedin = true;
    }
  }

  ngOnChanges(element:SimpleChanges){
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.emptyresults = Array(5);
    this.vcSpinner = false;
    let today = new Date();
    this.todayDate = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowDate = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.minDate = new Date(today);
    this.maxDate = new Date(today.setDate(today.getDate() + 14));
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' });
    if(localStorage.getItem('fee')) {
      this.sortOrder = 'asc';
      this.isFilters = true;
      this.filterFee = true;
    }
    this.params();
  }

  logout() {
    this.Userservice.logout();
    this.notLoggedin = true;
    this.isLoggedin = false;
  }

  inclinic() {
    $('#selection').css({'left': '218px'});
    $('#inclinic').css({'color': 'white'});
    $('#vc').css({'color': 'black'});
    this.clickedInclinic = true;
    this.clickedVC = false;
    this.vcColor = false;
    this.vcSpinner = true;
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
    this.VC = false;
    this.clinicTab = true;
    this.selectedIndex = -1;
    this.datePicker = null;
    this.dateHighlight = false;
    this.apply = true;
    this.apply1 = false;
    let date = this.todayDate;
    let slot = 'inclinic';
    this.availableSlots(date, slot);
  }

  video() {
    $('#selection').css({'left': '0px'});
    $('#vc').css({'color': 'white'});
    $('#inclinic').css({'color': 'black'});
    this.clickedVC = true;
    this.clickedInclinic = false;
    this.vcSpinner = true
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide()
    }, 500);
    this.clinicTab = false;
    this.VC = true;
    this.selectedIndex = -1
    this.datePickerVC = null;
    this.dateHighlight = false;
    this.applyVC = true;
    this.apply1VC = false;
    let date = this.todayDate;
    let slot = 'vc';
    this.availableSlotsVC(date, slot);
  }

  toggle() {
    $(this).toggleClass('shown');
    $('.rs-container').toggle();
  }

  screenDimensions() {
    if (this.scrWidth <= this.mobileWidth && this.scrWidth >= 767) {
      this.mapDisplay = false;
      this.results = true;
      this.listView()
    } else {
      this.mapDisplay = true;
      this.results = true;
      this.docDetails = false;
    }
    if (this.scrWidth < 766) {
      this.notaResponsiveButton = false;
      this.responsiveButton = true;
    } else {
      this.notaResponsiveButton = true;
      this.responsiveButton = false;
      $('.sr-card').removeClass('selected');
    }
  }

  params() {
    var details = {
      metaTitle: '',
      metaDescription: '',
      ogTitle: '',
      ogDescription: ''
    };
    this.activatedroute.params.subscribe(params => {
      this.urlparameters = params;
      this.contentLoaded = false;
      const substring = "-";
      var re = /[-]/g;
      this.cityinUrl = params['city'];
      this.city = this.cityinUrl.includes(substring) == true ? this.cityinUrl.replace(re, ' ') : this.cityinUrl;
      this.city1 = this.camelcase.transform(this.city);
      if (params['specs']) {
        this.specinUrl = params['specs'];
        this.specs = this.specinUrl.includes(substring) == true ? this.specinUrl.replace(re, ' ') : this.specinUrl;
        this.specs1 = this.specs.charAt(0).toUpperCase() + this.specs.slice(1);
      }
      if (params['pageNo'] && isNaN(params['pageNo'])) {
        this.specinUrl = params['pageNo'];
        this.specs = this.specinUrl.includes(substring) == true ? this.specinUrl.replace(re, ' ') : this.specinUrl;
        this.specs1 = this.specs.charAt(0).toUpperCase() + this.specs.slice(1);
      }
      if (!isNaN(params['pageNo'])) {
        this.pageNo = params['pageNo'];
      }
      this.areainUrl = params['name'];
      if (!isNaN(this.areainUrl)) {
        this.pageNo = params['name'];
      }
      if (this.areainUrl && isNaN(this.areainUrl)) {
        this.selectedarea = this.areainUrl.includes(substring) == true ? this.areainUrl.replace(re, ' ') : this.areainUrl;
        this.area1 = this.camelcase.transform(this.selectedarea);
      }
      if (this.width <= this.mobileWidth) {
        this.mapDisplay = false;
        this.results = true;
      } else {
        this.mapDisplay = true;
        this.results = true;
      }
      this.docDetails = false;
      if (this.areainUrl && isNaN(this.areainUrl) && this.specs) {
        this.area1 = this.selectedarea.charAt(0).toUpperCase() + this.selectedarea.slice(1);
        this.selectedarea = this.areainUrl.includes(substring) == true ? this.areainUrl.replace(re, ' ') : this.areainUrl;
        this.area1 = this.camelcase.transform(this.selectedarea);
        details.metaTitle = this.specs1 + ' in ' + this.area1 + ' - Book an Appointment - View Fees | Gigadocs';
        details.metaDescription = this.specs1 + ' in ' + this.area1 + ', ' + this.city1 + ', Instant appointment booking, View fees... Gigadocs'
        details.ogTitle = this.specs1 + ' in ' + this.area1;
        details.ogDescription = this.specs1 + ' in ' + this.area1 + ', Instant appointment booking, View fees - Gigadocs';
      } else if(this.city && this.specs) {
        this.city1 = this.city.charAt(0).toUpperCase() + this.city.slice(1);
        details.metaTitle = this.specs1 + ' in ' + this.city1 + ' - Book an Appointment - View Fees | Gigadocs';
        details.metaDescription = this.specs1 + ' in ' + this.city1 + ', Instant appointment booking, View fees... Gigadocs'
        details.ogTitle = this.specs1 + ' in ' + this.city1;
        details.ogDescription = this.specs1 + ' in ' + this.city1 + ', Instant appointment booking, View fees - Gigadocs';
      } else if (this.city) {
        this.onlyCity = true;
        this.city1 = this.city.charAt(0).toUpperCase() + this.city.slice(1);
        details.metaTitle = 'Doctors in ' + this.city1 + ' - Book an Appointment - View Fees | Gigadocs';
        details.metaDescription = 'Doctors in ' + this.city1 + ', Instant appointment booking, View fees... Gigadocs'
        details.ogTitle = 'Doctors in ' + this.city1;
        details.ogDescription = 'Doctors in ' + this.city1 + ', Instant appointment booking, View fees - Gigadocs';
      }
      this.area = this.camelcase.transform(this.area1) || this.camelcase.transform(this.city1);
      if (this.area1) {
        this.area1 = this.area1 + ',';
      }
      this.speciality = this.camelcase.transform(this.specs);
      this.Userservice.setDynamicMetaData(details);
      this.checkParams();
    })
  }

  checkParams() {
    if (!this.selectedarea && !this.pageNo) {
      this.location = this.city;
      this.pageNo = 1;
      this.isCity = true;
    } else if (this.selectedarea && !this.pageNo) {
      if (this.selectedarea.length > 2) {
        this.location = this.selectedarea;
        this.pageNo = 1;
        this.isArea = true;
      } else if (this.selectedarea.length < 3) {
        this.location = this.city;
        this.pageNo = this.selectedarea;
        this.selectedarea = null;
        this.isCity = true;
      }
    } else if (this.selectedarea && this.pageNo) {
      this.location = this.selectedarea;
      this.isArea = true;
    } else {
      this.location = this.city;
    }
    this.latLong();
  }

  latLong() {
    this.Userservice.getCoords(this.location).subscribe(response => {
      if (response.details.length > 0) {
        let record = response.details.filter(item => item.name.toLowerCase() == this.location && item.city.toLowerCase() == this.city);
        if (record.length > 0) {
          this.resultsFound = true;
          this.lat = record[0]['latitude'];
          this.longitude = record[0]['longitude'];
          this.updatedResults(this.pageNo);
          this.center = {
            lat: this.lat,
            lng: this.longitude
          }
        }
      }
    });
  }

  updatedResults(pgNo) {
    this.resultsDisp.splice(0, this.resultsDisp.length);
    this.address.splice(0, this.address.length);
    if (this.router.url.indexOf('/doctors') > -1) {
      this.isDoctors = true;
    }
    if (this.onlyCity) {
      this.isDoctors = true;
      this.specs = 'doctor';
    }
    if (localStorage.getItem('fee')) {
      this.sortOrder = 'asc';
    }
    this.Userservice.getSearchresults(this.specs, this.lat, this.longitude, pgNo - 1, this.isDoctors, this.onlyCity, this.sortOrder).subscribe((response) => {
      if (response == null) {
        this.resultsNotFound = true;
        this.resultsFound = false;
      } else {
        this.pageResults = response;
        this.resultsDisp.splice(0, this.resultsDisp.length);
        if (this.pageResults) {
          this.resultsFound = true;
          this.resultsNotFound = false;
          let date = new Date();
          let year = date.getFullYear();
          for (let i = 0; i < this.pageResults['details']['length']; i++) {
            if (this.pageResults['details'][i]['doctorExperience'] != 0) {
              let exp = year - this.pageResults['details'][i]['doctorExperience'];
              this.pageResults['details'][i]['doctorExperience'] = exp;
            } else {
              this.pageResults['details'][i]['doctorExperience'] = 0;
            }
            this.resultsDisp.push(this.pageResults['details'][i]);
            this.address.push({
              clinicId: this.pageResults['details'][i]['clinicId'],
              clinicName: this.pageResults['details'][i]['clinicName'],
              fullAddress: this.pageResults['details'][i]['clinicAddress'].replace('#', '') + ','
                + this.pageResults['details'][i]['clinicArea'] + ','
                + this.pageResults['details'][i]['clinicCity']
            })
            let languages = [];
            if(this.pageResults['details'][i]['doctorLanguage'].length > 0) {
              languages = this.pageResults['details'][i]['doctorLanguage'].map(item => item.name);
              this.pageResults['details'][i]['doctorLanguage'] = languages;
            }
          }
        }
        this.contentLoaded = true;
      }
    },
    error => {
      this.errorMsg = error;
      if (this.errorMsg) {
        this.resultsNotFound = true;
        this.resultsFound = false;
      }
    })
  }

  resetDisplay() {
    this.showSlider = false;
    this.clinicTab = false;
    this.VC = false;
    this.datePicker = null;
    this.selectedIndex = -1;
    this.selectedTime = null;
    this.apply = true;
    this.applyVC = true;
    this.apply1 = false;
    this.datePickerVC = null;
    this.apply1VC = false;
    this.clickedVC = true;
    this.clickedInclinic = false;
    this.dateHighlight = false;
  }

  doctorDetails(i, clinicId, showClinic = 0, showOnline = 0) {
    this.resetDisplay();
    this.clearArraydata();
    let clinicInfo = this.resultsDisp[i]['clinics'][0];
    this.inClinic = clinicInfo['isInClinic'];
    this.isOnline = clinicInfo['isOnline'];
    this.isOnCall = clinicInfo['onCall'];
    this.docId = this.resultsDisp[i]['doctorId'];
    this.docUrl = this.resultsDisp[i]['doctorUrl'];
    this.docName = this.resultsDisp[i]['doctorName'];
    this.docService = this.resultsDisp[i]['doctorService'];
    this.clinicCity = this.resultsDisp[i]['clinicCity'];
    this.showClinic = showClinic;
    this.showOnline = showOnline;
    this.j = 0;
    this.clinicInfo.splice(0, this.clinicInfo.length);
    this.workingHours.splice(0, this.workingHours.length);
    if (this.scrWidth > 767) {
      $('.sr-card').removeClass('selected');
      $('.sr-card').eq(i).closest('.sr-card').addClass('selected');
    }
    if (this.scrWidth <= this.mobileWidth) {
      this.mapDisplay = false;
      this.results = false;
      this.Details(clinicId);
      this.docDetails = true;
    } else {
      this.Details(clinicId);
      this.mapDisplay = false;
      this.docDetails = true;
    }
    window.scrollTo(0,0);
  }

  Details(clinicId) {
    this.Userservice.clinicDetails(this.docId).subscribe((response) => {
      this.clinicInfo = response['details'];
      let index = this.clinicInfo.findIndex((element, index) => {
        if (element.id === clinicId) {
          return true;
        }
      })
      this.clinicName = this.clinicInfo[index]['name'];
      this.clinicFee = this.clinicInfo[index]['fee'];
      this.clinicAddress = this.clinicInfo[index]['address'] + ',' + this.clinicInfo[index]['area'] + ',' + this.clinicInfo[index]['city'];
      this.workingHours = this.clinicInfo[index]['working_hours'];
      this.vcWorkingHours = this.clinicInfo[index]['vc_working_hours'];
      this.clinicId = this.clinicInfo[index]['id'];
      this.isSubscribed = this.clinicInfo[index]['is_subscribed'];
      this.selectedInClinic = this.clinicInfo[index]['isInClinic'];
      this.selectedIsOnline = this.clinicInfo[index]['isOnline'];
      this.inClinic = this.clinicInfo[index]['isInClinic'];
      this.isOnline = this.clinicInfo[index]['isOnline'];
      this.isOnCall = this.clinicInfo[index]['onCall'];
      this.showSlots();
    })
  }

  handleChange(k) {
    this.resetDisplay();
    this.clinicId = k;
    this.clinicInfo1 = this.clinicInfo;
    for (let i = 0; i < this.clinicInfo1['length']; i++) {
      if (this.clinicId == this.clinicInfo1[i]['id']) {
        this.j = i;
        this.clinicName = this.clinicInfo[i]['name'];
        this.clinicFee = this.clinicInfo[this.j]['fee'];
        this.clinicAddress = this.clinicInfo[i]['address'] + ',' + this.clinicInfo[i]['area'] + ',' + this.clinicInfo[i]['city'];
        this.workingHours = this.clinicInfo[this.j]['working_hours'];
        this.vcWorkingHours = this.clinicInfo[this.j]['vc_working_hours'];
        this.clinicId = this.clinicInfo[i]['id'];
        this.isSubscribed = this.clinicInfo[i]['is_subscribed'];
        this.selectedInClinic = this.clinicInfo[i]['isInClinic'];
        this.selectedIsOnline = this.clinicInfo[i]['isOnline'];
        this.showClinic = this.clinicInfo[i]['showClinicSlots'];
        this.showOnline = this.clinicInfo[i]['showOnlineSlots'];
        this.inClinic = this.clinicInfo[i]['isInClinic'];
        this.isOnline = this.clinicInfo[i]['isOnline'];
        this.isOnCall = this.clinicInfo[i]['onCall'];
      }
    }
    $('#vc').css({'color': 'white'});
    $('#inclinic').css({'color': 'black'});
    $('#selection').css({'left': '0px'});
    this.showSlots();
  }

  showSlots() {
    if (this.isSubscribed == "1") {
      if ((this.selectedInClinic && this.showClinic) && (this.selectedIsOnline && this.showOnline)) {
        this.showSlider = true;
        this.vcColor = true;
        this.VC = true;
        if (this.selectedIsOnline && this.showOnline) {
          let slot = 'vc';
          this.date = this.todayDate;
          this.availableSlotsVC(this.date, slot)
        }
      } else if(this.selectedIsOnline && this.showOnline) {
        this.vcColor = true;
        this.VC = true;
        let slot = 'vc';
        this.date = this.todayDate;
        this.availableSlotsVC(this.date, slot);
      } else {
        this.clinicTab = true;
        this.VC = false;
        this.selectedInClinic == "1" ? this.slotType = 'inclinic' : this.selectedIsOnline == "1" ? this.slotType = 'vc' : this.slotType = "null";
        this.date = this.todayDate;
        this.availableSlots(this.date, this.slotType);
      }
    } else {
      this.showSlider = false;
      this.clinicTab = false;
      this.VC = false;
    }
  }

  clearArraydata() {
    this.btnDisable = true;
    this.morningSlots = [];
    this.afternoonSlots = [];
    this.eveningSlots = [];
    this.morningSlotsLength = 0;
    this.afternoonSlotsLength = 0;
    this.eveningSlotsLength = 0;
    this.inclinicWaitSpinner = true;
    
    this.morningSlotsVC = [];
    this.afternoonSlotsVC = [];
    this.eveningSlotsVC = []; 
    this.morningSlotsLengthVC = 0;
    this.afternoonSlotsLengthVC =0;
    this.eveningSlotsLengthVC = 0;
    this.vcWaitSpinner = true;
    
    
       
    
  }

  availableSlots(date: any, slot: any) {
    this.Userservice.getClinicslots(this.docId, this.clinicId, date, slot).subscribe((response) => {
      if (slot == "inclinic") {
        if (response['details']['status']==false){
          this.morningSlotsLength = 0;
          this.afternoonSlotsLength = 0;
          this.eveningSlotsLength = 0;
        }else{
          this.morningSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningSlotsLength = response['details'][0]['evening']['length'];
        }
        this.inclinicWaitSpinner = this.vcWaitSpinner = false;
        this.morningSlots = this.timeConversion(response['details'][0]['morning']);
        this.afternoonSlots = this.timeConversion(response['details'][0]['afternoon']);
        this.eveningSlots = this.timeConversion(response['details'][0]['evening']);
      } else if (slot == "vc") {
        if (response['details']['status']==false){
          this.morningSlotsLength = 0;
          this.afternoonSlotsLength = 0;
          this.eveningSlotsLength = 0;
        }else{
          this.morningSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningSlotsLength = response['details'][0]['evening']['length'];
        }
        this.inclinicWaitSpinner = this.vcWaitSpinner = false;
        this.morningSlots = this.timeConversion(response['details'][0]['morning']);
        this.afternoonSlots = this.timeConversion(response['details'][0]['afternoon']);
        this.eveningSlots = this.timeConversion(response['details'][0]['evening']);
      }
    })
  }

  availableSlotsVC(date: any, slot: any) {
    this.Userservice.getClinicslots(this.docId, this.clinicId, date, slot).subscribe((response) => {
      if (slot == "vc") {
        if (response['details']['status']==false){
          this.morningSlotsLengthVC = 0;
          this.afternoonSlotsLengthVC = 0;
          this.eveningSlotsLengthVC = 0;
        }else{
          this.morningSlotsLengthVC = response['details'][0]['morning']['length'];
          this.afternoonSlotsLengthVC = response['details'][0]['afternoon']['length'];
          this.eveningSlotsLengthVC = response['details'][0]['evening']['length'];
        }
        this.inclinicWaitSpinner = this.vcWaitSpinner = false;
        this.morningSlotsVC = this.timeConversion(response['details'][0]['morning']);
        this.afternoonSlotsVC = this.timeConversion(response['details'][0]['afternoon']);
        this.eveningSlotsVC = this.timeConversion(response['details'][0]['evening']);
      }
    })
  }

  timeConversion(slot: any = []) {
    while (slot.length >= 1) {
      let convertedTime: any[] = [];
      for (let i = 0; i < slot['length']; i++) {
        this.time = slot[i].split(":", 3);
        var ampm = this.time[0] >= 12 ? 'PM' : 'AM';
        ampm == 'PM' && this.time[0] == 12 ? this.hours = 12 : this.hours = this.time[0] % 12;
        this.hours < 10 ? this.hours = '0' + this.hours : this.hours;
        convertedTime.push(this.hours + ':' + this.time[1] + ' ' + ampm);
      }
      return convertedTime;
    }
  }

  checkSlotType(slot1: string, slot2: string, date: any) {
    if (slot2 === '0') {
      this.availableSlotsVC(date, slot1)
    } else {
      if (this.selectedInClinic == "1") {
        this.availableSlots(date, slot1)
      } else if (this.selectedIsOnline == "1") {
        this.availableSlots(date, slot2)
      }
    }
  }

  today(slot1: string, slot2: string) {
    this.clearArraydata();
    this.datePicker = null;
    this.datePickerVC = null;
    this.dateHighlight = false;
    this.selectedIndex = -1;
    if (slot2 != '0') {
      this.apply = true;
      this.apply1 = false;
    } else {
      this.applyVC = true;
      this.apply1VC = false;
    }
    this.date = this.todayDate;
    this.checkSlotType(slot1, slot2, this.date);
  }

  todayOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowCall = false;
    this.todayCall = true;
  }

  tomorrow(slot1: string, slot2: string) {
    this.clearArraydata();
    this.datePicker = null;
    this.datePickerVC = null;
    this.dateHighlight = false;
    this.selectedIndex = -1;
    if (slot2 != '0') {
      this.apply1 = true;
      this.apply = false;
    } else {
      this.applyVC = false;
      this.apply1VC = true;
    }
    this.date = this.tomorrowDate;
    this.checkSlotType(slot1, slot2, this.date);
  }

  tomorrowOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    let today = new Date();
    this.date = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = true;
  }

  Time(index, time) {
    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.btnDisable = true;
    } else {
      this.selectedIndex = index;
      this.selectedTime = time;
      this.btnDisable = false;
    }
  }

  CallTime(index, time, slot) {
    this.slotType = slot;
    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.btnDisable = true;
    } else {
      this.selectedIndex = index;
      this.selectedTime = time;
      this.btnDisable = false;
    }
  }

  selectedDate(event: any, slot1: string, slot2: string) {
    this.clearArraydata();
    if (slot2 != '0') {
      this.datePickerVC = null;
      this.apply = false;
      this.apply1 = false;
    } else {
      this.datePicker = null;
      this.applyVC = false;
      this.apply1VC = false;
    }
    this.dateHighlight = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.checkSlotType(slot1, slot2, this.date);
  }

  selectedOnCallDate(event: any, slot: string) {
    this.dateHighlightCall = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = false;
  }

  confirmAppointment(slot1: string, slot2: string) {
    if (this.selectedTime != null) {
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
      }, 3000);
      this.appointmentDate = this.date;
      if (this.selectedTime != null && this.appointmentDate != null) {
        if (slot2 === '0') {
          this.slotType = slot1;
        } else {
          if (this.selectedInClinic == "1") {
            this.slotType = slot1;
          } else if (this.selectedIsOnline == "1") {
            this.slotType = slot2;
          }
        }
        this.router.navigate(['appointment/', this.docUrl], {
          queryParams: {
            city: this.city,
            area: this.selectedarea,
            spec: this.specs,
            slot: this.slotType,
            on: this.appointmentDate,
            clinicId: this.clinicId,
            at: this.selectedTime,
            page: this.pageNo || this.pageNumber
          }
        })
      }
    } else {
      return false;
    }
  }

  onCallAppointment() {
    if (this.selectedTime != null) {
      this.router.navigate(['appointment/', this.docUrl], {
        queryParams: {
          doctorId: this.docId,
          clinicId: this.clinicId,
          on: this.date,
          at: this.selectedTime,
          slot: this.slotType
        }
      })
    } else {
      return false;
    }
  }

  closeDetails() {
    this.docDetails = false;
    this.results = true;
  }

  mapView() {
    this.mapDisplay = true;
    this.docDetails = false;
    var window_height = $(window).height();
    var header_height = $('header').outerHeight();
    var subheader_height = $('.subheader').outerHeight();
    var req_height = window_height - header_height - subheader_height;
    $('.sr-left').hide();
    $('#sr-map').css({'display': 'block', 'margin-bottom': '40px', 'height': req_height});
    $('#listview').removeClass('hidden');
    $('#mapview').addClass('hidden');
  }

  listView() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    $('.sr-left').show();
    $('#sr-map').css({'display': 'none', 'height': 0});
    $('#mapview').removeClass('hidden');
    $('#listview').addClass('hidden');
  }

  pgcontrolsroute(pgN) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (this.router.url.indexOf('/doctors') > -1) {
      if (this.urlparameters['city'] && this.urlparameters['name'] && this.urlparameters['city'] != this.urlparameters['name']) {
        this.router.navigateByUrl(this.urlparameters['city'] + '/' + this.urlparameters['specs'] + '/' + this.urlparameters['name'] + '/doctors/' + pgN);
      } else if (this.urlparameters['city'] && this.urlparameters['specs']) {
        this.router.navigateByUrl(this.urlparameters['city'] + '/' + this.urlparameters['specs'] + '/doctors/' + pgN);
      }
    } else {
      var city = this.urlparameters.city;
      var specs = '';
      var area = '';
      if (this.urlparameters.pageNo && isNaN(this.urlparameters.pageNo)) {
        specs = this.urlparameters.pageNo;
      }
      if (this.urlparameters.specs && isNaN(this.urlparameters.specs)) {
        specs = this.urlparameters.specs;
      }
      if (this.urlparameters.name && isNaN(this.urlparameters.name)) {
        area = this.urlparameters.name;
      }
      if (this.onlyCity) {
        this.router.navigate([city, pgN])
      } else if (city != '' && specs != '' && area != '') {
        this.router.navigate([city, specs, area, pgN]);
      } else if (city != '' && specs != '') {
        this.router.navigate([city, specs, pgN]);
      }
    }
  }

  getFilterResults(type, order) {
    this.isFilters = true;
    if (type == 'fee') {
      this.filterFee = !this.filterFee;
      if (this.filterFee) {
        localStorage.setItem('fee', 'asc');
        this.sortOrder = order;
      } else {
        this.isFilters = false;
        localStorage.removeItem('fee');
        this.sortOrder = '';
      }
      this.params();
    }
    if (type == 'near') {
      this.isFilters = false;
      this.filterFee = false;
      localStorage.removeItem('fee');
      this.sortOrder = '';
      this.params();
    }
  }

  clearFilters() {
    localStorage.removeItem('fee');
    this.sortOrder = '';
    this.isFilters = false;
    this.filterNear = true;
    this.filterFee = false;
    this.params();
  }

  RelatedSpecialty(data:any) {
    this.relatedSpecialityArray = data;
  }

  viewContact(row) {
    $("#view-contact-"+row).hide();
    $("#Contact-options-"+row).show();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('userId1');
  }

}
