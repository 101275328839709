<ngx-spinner
bdColor="#fff"
size="small"
color="#FA030E "
type="ball-clip-rotate"
>
 </ngx-spinner>
<header>
    <nav class="navbar navbar-expand-lg ">
        <a class="navbar-brand" href="">
            <img src="assets/images/logo.png"  alt="GigaDocs"/>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="toggler-bar"></span>
            <span class="toggler-bar"></span>
            <span class="toggler-bar"></span>
		</button>

        <div class="collapse navbar-collapse nav-flexend" id="navbarNav" >

			<ul class="links navbar-nav mr-auto">
				<!--<a href="javascript:void(0)" class="closebtn">&times;</a> -->
                <li class="nav-item">
                    <a class="nav-link" href="#features" routerLinkActive="active">Features</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/blog">Blogs</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="diagnostic-center">Diagnostic Center</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#whyUs" routerLinkActive="active">Why Us</a>
                </li>
				<li class="nav-item">
                    <a class="nav-link" href="plans" routerLinkActive="active">Plans</a>
                </li>
			</ul>

            <ul class="links navbar-nav ">
                <li class="nav-item ">
					<!--<a class="nav-link" href="loginModal"  class="trigger-btn" data-target="#loginModal"
					    data-toggle="modal" id="login" *ngIf="notLoggedin">
						Login
					</a>-->
					<app-login *ngIf="notLoggedin" [modalId]="'loginModal'" (click)="loginCheck()" nextpage = "{{landingpage}}" scrWidth = "{{scrWidth}}"></app-login>
					<a href="{{userToken}}" class="nav-link" *ngIf="isLoggedin">Account</a>
				</li>
                <li class="nav-item">
                    <!--<a class="nav-link" href="#newModal" class="trigger-btn"  routerLinkActive="active" data-toggle="modal" id="regist" (click)="clearForm()" *ngIf="notLoggedin">
						Signup
					</a>-->
					<app-signup *ngIf="notLoggedin"></app-signup>
					<a class="nav-link" href="javascript:void(0)" (click)="logout()" *ngIf="isLoggedin">Logout</a>
				</li>

                <li class="nav-item">
                    <a class="nav-link btn btn-first" (click)='openRequestDemoModal()' href="javascript:void(0)">
						Request Demo
					</a>
                </li>
            </ul>
        </div>
    </nav>
</header>

<!-- Modal login

<div id="loginModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
	<div class="modal-dialog modal-login modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button"  #closelogin class="close" (click)="loginFormReset()" data-dismiss="modal" aria-hidden="true">
					&times;
				</button>
				<h4 class="modal-title">Login to your account</h4>
			</div>
			<div class="modal-body">
				<div class="login-modal-wrap">
					<div #alert *ngIf="loginError" class="alert alert-danger">Invalid Credentials</div>
					<div class="alert alert-success" *ngIf="validNumber">
						Successfully sent
					</div>
					<div *ngIf="sendingOtp || loginLoading || loggingIn" class="alert alert-warning">
						<span class="spinner-border spinner-border-sm"></span>
						Please wait, Processing...
					</div>
					<div class="alert alert-danger" *ngIf="validNumber == false">
						You do not have an account in Gigadocs. Please register with us
					</div>
					<div class="alert alert-danger" *ngIf="otpInvalid">
						OTP invalid
					</div>
					<div class="alert alert-success" *ngIf = "signinSuccess">Login Successfull</div>
					<form id="login-form" [formGroup]="loginForm">
						<div class="form-group required">
							<label class="control-label">Email or Mobile</label>
							<input type="text" class="form-control" placeholder="Enter Email ID or Mobile Number"  formControlName="email"
								   required="required" [ngClass]="{ 'is-invalid': (submitted||submitted1||submitted2)&&login.email.errors }">
							<div *ngIf="(submitted || submitted1 || submitted2) && login.email.errors" class="invalid-feedback">
								<div *ngIf="login.email.errors.required">Email or Mobile is required</div>
								<div class="text-danger" *ngIf="login.email.errors?.pattern">
									 *Invalid Email or Mobile number
								</div>
							</div>
						</div>
						<div class="form-group required" *ngIf="isShow">
							<label class="control-label">Password</label>
							<input type="password" class="form-control" formControlName="password" placeholder="Enter Password"
								   required="required" [ngClass]="{ 'is-invalid': submitted && login.password.errors }">

							<div *ngIf="submitted && login.password.errors" class="invalid-feedback" >
								<div *ngIf="login.password.errors.required">Password is required</div>
							</div>
						</div>
						<div *ngIf="!isShow">
							<label class="control-label">OTP<span class="text-danger">*</span></label>
							<input type="text" class="form-control" placeholder="Enter code" formControlName="loginOtp"
								   required="required" [ngClass]="{ 'is-invalid': submitted2 && login.loginOtp.errors }">
							<div *ngIf="submitted2 && login.loginOtp.errors" class="invalid-feedback">
								<div *ngIf="login.loginOtp.errors.required">OTP is required</div>
								<div class="text-danger" *ngIf="login.loginOtp.errors?.pattern && login.loginOtp.errors?.minlength
									 || login.loginOtp.errors?.maxlength ">
									*Invalid OTP
								</div>
							</div>
						</div>
						<div class="form-group" *ngIf="isShow">
							<div class="row align-items-center">
								<div class="col-sm-7">
								   <input type="button" class="btn btn-primary pl-5 pr-5" [disabled]="loggingIn"
								   		  (click)="signInUser()" value="Login">
								</div>

								 <div class="col-sm-5">
									<a href="#resetModal"data-toggle="modal" data-target="#forgotModal" #closelogin
									   (click)="resetPassword()" id="reset">
									   Reset password
									</a>
								</div>
							</div>
							<div class="text-center my-3">
								<a href="javascript:void(0)" class="text-danger" (click)="loginWithOtp('send')" >
									Login with OTP
								</a>
							</div>

						</div>
						<div class="form-group" class="mt-4" *ngIf="!isShow">
							<div class="row align-items-center">
								<div class="col-sm-7">
								   <input type="button" class="btn btn-primary pl-5 pr-5" [disabled]="loginLoading"
								   		  (click)="loginOtpSuccess()" value="Login">
								</div>
								<div class="col-sm-5">
									<a href="javascript:void(0)" data-toggle="modal" id="reset" (click)="loginWithOtp('resend')">
										Resend OTP
									</a>
								 </div>
							</div>
							<div class="text-center my-3">
								<a class="mx-auto text-danger" href="javascript:void(0)" (click)="loginWithPassword()">
									Login with Password
								</a>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer">
					<div class="mr-4"> <h6>New User!</h6>
					<p>Don't have an account yet?</p>
					</div>
					<a href="newModal" data-toggle="modal" data-target="#newModal" id="try" data-dismiss="modal">
						<button class="btn btn-primary">Sign Up Now</button>
					</a>
			</div>
		</div>
	</div>
</div> 		 -->

<!--  Registration type modal

<div  id="registModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
	<div class="modal-dialog modal-regist modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">
						Registration Type
				</h4>
				<button #closeregist type="button"  class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
			</div>
			<div class="modal-body">
				<div class="regist-modal-wrap">
					<p>Please select the registartion type from the below:</p>
						   <div class="row caption">
							   <div class="col-4">
								<img src="assets/images/user.png" class="img-center"  alt="user"/>
								<p class="text-center">
									<button #user id="user" class="btn-sm btn-info"(click)="registrationType(user)">User</button>
								</p>
							</div>
							<div class="col-4">
								<img src="assets/images/doctor-male-default.png" class="img-center"  alt="doctor"/>
								<p class="text-center">
									<button #doctor id="doctor" class="btn-sm btn-info"(click)="registrationType(doctor)">Doctor</button>
								</p>
							</div>
							<div class="col-4">
								<img src="assets/images/clinic2.png" class="img-center" alt="Clinic"/>
								<p class="text-center">
									<button #clinic id="clinic" class="btn-sm btn-info"(click)="registrationType(clinic)">Clinic</button>
								</p>
							</div>
						</div>
				</div>
			</div>
		</div>
	</div>
</div>   -->

<!--  New Registration modal

<div id="newModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-new modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">
					New Registration
				</h4>
				<button type="button" #closeNewRegist class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
			</div>
			<div class="modal-body">
				<div class="new-modal-wrap">
					<form id="new-form" [formGroup]="mobileVerficationForm" >
						<div class="row">
							<div class="col-md-10">
								<p *ngIf="show">Please enter your mobile number and verify the OTP.</p>
								<div #alert *ngIf="error" class="alert alert-danger">
									Number already Registered, Please Login.
								</div>
							</div>
							<div class="col-md-2">
								<img src="assets/images/otp.png" class="img-center " alt="Pharmacy"/>
							</div>
						</div>
						<div class="form-group required">
							<label class="font-weight-bold control-label">Mobile Number</label>
							<input type="text" class="form-control" formControlName="mobile" name="mobile"
							       placeholder="Enter your mobile number" />
							<small class="text-danger" *ngIf=" form.mobile.touched && form.mobile.errors?.required">
								*Mobile Number required
							</small>
							<small class="text-danger" *ngIf="form.mobile.touched && form.mobile.errors?.pattern ||
							       form.mobile.errors?.minlength || form.mobile.errors?.maxlength ">
								*Invalid Mobile number
							</small>
						</div>
						<div class="form-group">

						<a  (click)="mobileSubmit()"><input type="button"  data-toggle="modal"  class="btn btn-primary pl-5 pr-5"
							 value="Submit" [disabled]="!mobileVerficationForm.valid">
						</a>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer">
				<p>Already haven an account?</p>
				<a href="loginModal" data-toggle="modal" data-target="#loginModal" id="try" data-dismiss="modal" >
					<button class="btn btn-primary">Login</button>
				</a>
			</div>
		</div>
	</div>
</div>   -->

<!--  verify OTP modal

<div  id="verifyModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-verify modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" #closeVerify class="close" data-dismiss="modal" aria-hidden="true" (click)="otpModalClose()">
					&times;
				</button>
				<h4 class="modal-title">Verify Mobile</h4>
			</div>
			<div class="modal-body">
				<div class="verify-modal-wrap">
					<form id="verify-form" [formGroup]="otpVerficationForm">
						<div class="row">
							<div class="col-md-10">
								<p *ngIf="otpMsg">we have sent an OTP to your mobile number.Please verify!</p>
								<div #alert *ngIf="otperror" class="alert alert-danger">Invalid OTP</div>
							</div>
							<div class="col-md-2">
								<img src="assets/images/ic-otp.png" class="img-center " alt="Pharmacy"/>
							</div>
						</div>
						<div class="form-group required">
							<label class="font-weight-bold control-label">OTP Code</label>
							<input type="password" class="form-control" placeholder="Enter code" formControlName="otp"
									 name="otp" required="required"  [ngClass]="{ 'is-invalid': otpSubmitted && otp.otp.errors }">
							<div *ngIf = "otpSubmitted && otp" class="text-danger">
							   <small  *ngIf="otp.otp.errors?.required">*OTP required</small>
							   <small *ngIf="otp.otp.errors?.pattern || otp.otp.errors?.minlength || otp.otp.errors?.maxlength">
								   *Invalid OTP
								</small>
							</div>
						</div>
						<div class="form-group">
							<button class="btn btn-primary pl-4 pr-4" data-toggle="modal"  [disabled]="isloading2"
									(click)="otpSubmit(userType)">
								Submit
								<span *ngIf="isloading2" class="spinner-border spinner-border-sm"></span>
							</button>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer">
				<p>Didn't get an OTP?</p>
				<a  href="javascript:void(0)">Resend OTP</a>
			</div>
		</div>
	</div>
</div>  -->

<!--  Reset password modal

<div id="forgotModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-forgot modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="resetFPassword()" #closeFPassword>
                    &times;
                </button>
                <h4 class="modal-title">Forgot Password </h4>
            </div>
            <div *ngIf="erroMsg == '204'" class="alert alert-danger">Please enter registered email or mobile</div>
            <div class="modal-body">
                <div class="forgot-modal-wrap">
                    <form id="forgot-form"  [formGroup]='forgotPasswordForm'>
                        <p>Enter your registered Email ID or Mobile below.We will send you a link to reset password.</p>
                        <div class="form-group required">
                            <label class="font-weight-bold control-label">Email or Mobile</label>
                            <input type="text" class="form-control" placeholder="Enter registered 10 digit Mobile Number"
                                    formControlName="mobile" [ngClass]="{ 'is-invalid': submitClicked && forgotPasswordValue.mobile.errors }">
                            <div *ngIf="submitClicked && forgotPasswordValue.mobile.errors" class="text-danger">
                                <small *ngIf="forgotPasswordValue.mobile.errors.required">*Email or Mobile is required</small>
                                <small *ngIf="forgotPasswordValue.mobile.errors?.pattern">Enter valid Email or Mobile</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary pl-4 pr-4" data-toggle="modal"  [disabled]="isloading1"
                                    #closeFPassword (click)="fPassword()">
								Reset Password
								<span *ngIf="isloading1 == true" class="spinner-border spinner-border-sm"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <a href="#loginModal" data-toggle="modal" data-target="#loginModal" id="try" (click)="resetFPassword()" data-dismiss="modal">
                    Try logging in again
                </a>
            </div>
        </div>
    </div>
</div> -->


