import { Component, OnInit, ViewChild,NgZone,HostListener,AfterViewInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { WindowRefService } from '../window-ref.service';
declare var $: any;
import { User } from '../models';
import * as moment from 'moment';
import {Calendar} from "primeng/calendar";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {DatePipe} from "@angular/common";
import {Observable, Observer, of} from "rxjs";
import {switchMap} from "rxjs/operators";
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-appointmentdetails',
  templateUrl: './appointmentdetails.component.html',
  styleUrls: ['./appointmentdetails.component.css']
})
export class AppointmentdetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendar: Calendar;
  @ViewChild('calendarvc') calendarVC: Calendar;
  @ViewChild('calendarcall') calendarCall: Calendar;
  @ViewChild('close') close;
  // @ViewChild('focusInput') focusInput;
  // @HostListener('focusin', ['$event']) public onListenerTriggered(event: any): void {
  //   this.focusInput.nativeElement.focus();
  // }
  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '*',
    
    inputStyles: {
      'width': '35px',
      'height': '35px',
      'outline':'0',
      'border-width': '0 0 2px',
      'border-color': '#000000',
      'border-radius': '0px 0px 0px 0px',
      'font-family':'droid sans mono, consolas, monospace',
      'font-color':'#000000',
      'font-size': '30px'
    }
  };
  otp: string;
  user: User;
  city: any;
  docUrl: any;
  aptTime: any;
  aptDate: any;
  actualdate: any;
  actualtime: any;
  clinicDetails: any;
  docName: any;
  education: any;
  exp: number;
  specialities: any;
  services: any;
  clinics: any;
  speciality: string;
  area: any;
  Id: number;
  clinicName: any;
  address: string;
  consultationFee: number;
  page: any;
  otpMsg: boolean = true;
  otperror: boolean = false;
  patientDetailsForm: FormGroup;
  otpVerficationForm: FormGroup;
  error: boolean;
  errorPage: boolean;
  booking: boolean;
  docId: any;
  bookingDetails: boolean = true;
  bookingSuccess: boolean;
  @ViewChild('otpVerify ') otpVerify;
  @ViewChild('transactionSuccess') transactionSuccess;
  //@ViewChild('transactionFailed') transactionFailed;
  specialization: any;
  cityInUrl: any;
  selectedTimeSlot: any[];
  hours: any;
  appointmentId: any;
  confirmDate: string;
  urlParams: any = [];
  parameters: any = [];
  data: any[];
  public errorMsg;
  Noerror: boolean = true;
  slotType: any;
  cityVC: any;
  area1: string;
  specs1: any;
  city1: any;
  amount: number;
  orderIdGen: any;
  payment_id: any;
  signature: any;
  order_id: string;
  transactionMsg: boolean = false;
  loadingSpinner: boolean = false;
  loadingText = " ";
  submitted = false;
  errorInfo: boolean;
  patientDetails: { mobile: any; otp: any; };
  clicked = false;
  errorNotification: any;
  errorMessage: boolean;
  docLanguages: any = []
  checkedIndexs: any = [];
  docGender: string;
  clinicId: any;
  isReadonly = false;
  contentLoaded = false;
  clinicAddress: any;
  clinicCity: any;
  clinicArea: any;
  appointmentConfirmationSpinner: boolean;
  sendingOtp: boolean;
  otpSent: boolean;
  phoneNo: any;
  slotAvailable: any = 1;
  points: number;
  pointsAmountFee: number;
  usedPoints: boolean = false;
  login: boolean = false;
  vcButtonText = 'Pay and Book';
  userPoints: number;
  isFree: any = 0;

  /* for popup */
  date: any;
  dateVC: any;
  time: any;
  inClinic: boolean = false;
  isOnline: boolean = false;
  isOnCall: any = '0';
  hospitalName: any;
  clinicDoctor: any;
  morningSlots: any = [];
  afternoonSlots: any = [];
  eveningSlots: any = [];
  morningSlotsLength: any;
  afternoonSlotsLength: any;
  eveningSlotsLength: any;
  morningVCSlots: any = [];
  afternoonVCSlots: any = [];
  eveningVCSlots: any = [];
  morningVCSlotsLength: any;
  afternoonVCSlotsLength: any;
  eveningVCSlotsLength: any;
  selectedIndex: any;
  selectedVCIndex: any;
  selectedCallIndex: any;
  selectedTime: any;
  selectedVCTime: any;
  selectedCallTime: any;
  todayApply: boolean = true;
  todayApplyVC: boolean = true;
  todayCall: boolean = true;
  tomorrowApply: boolean = false;
  tomorrowApplyVC: boolean = false;
  tomorrowCall: boolean = false;
  dateHighlight: boolean = false;
  dateHighlightVC: boolean = false;
  dateHighlightCall: boolean = false;
  datePicker: Date;
  datePickerVC: Date;
  datePickerCall: Date;
  minDate: Date;
  maxDate: Date;
  btnDisable: boolean = true;
  showClinic: number;
  showOnline: number;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  familymembers: any;
  otp_isValid:boolean=true;
  inclinicSpinner: boolean;
  vcSpinner: boolean;
  patientGender: any;
  patientDob: any;
  patientName: any;

  constructor(
    private activatedroute: ActivatedRoute,
    private router: Router,
    private Userservice: UserService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private winRef: WindowRefService,
    private ngzone: NgZone,
    private datePipe: DatePipe
  ) {
  }
  ngAfterViewInit(): void {
    if(this.Userservice.userValue) {
      setTimeout(()=>{        
          this.Userservice.getFamilyMembers().subscribe(response => {
            if (response.details) {
              this.familymembers = response.details;
            }
          });
          this.Userservice.getPoints().subscribe(response => {
            if (response.details) {
              this.points = response.details.points;
              this.userPoints = this.points;
              this.Userservice.updatePoints(this.points);
            }
          });
          this.patientDetailsloggedin();          
    },600);
      
    }
  }
  ngOnInit(): void {
    if(this.Userservice.userValue) {
      this.login = true;
    }

    let today = new Date();
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.dateVC = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.minDate = new Date(today);
    this.maxDate = new Date(today.setDate(today.getDate() + 14));
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' });

    this.Userservice.setDocTitle('Book Appointment - Gigadocs');
    this.activatedroute.params.subscribe(params => {
      this.docUrl = params['docUrl']
    });

    this.activatedroute.queryParams.subscribe(params => {
      this.slotType = params['slot'];
      sessionStorage.setItem('slotType', this.slotType);
      if (this.slotType == 'oncall') {
        this.Id = params['clinicId'];
        this.docId = params['doctorId'];
        this.aptDate = new Date(params['on']).toDateString().slice(4, 15);
        this.aptTime = params['at'];
      } else {
        this.city = params['city'];
        this.area = params['area'];
        this.speciality = params['spec'];
        this.actualdate = params['on'];
        this.actualtime = moment(params['at'], ["h:mm A"]).format("HH:mm");
        this.aptDate = new Date(params['on']).toDateString().slice(4, 15);
        this.aptTime = params['at'];
        this.Id = params['clinicId'];
        this.page = params['page'];
        this.parameters.push({label: params['city']});
        this.parameters.push({label: params['spec']});
        if (params['area'] !== params['city']) {
          this.parameters.push({label: this.area});
        }
        this.parameters.push({label: params['page']});
        if (this.city) {
          this.city1 = this.city.charAt(0).toUpperCase() + this.city.slice(1);
        }
        if (this.speciality) {
          this.specs1 = this.speciality.charAt(0).toUpperCase() + this.speciality.slice(1);
        }
        if (this.area) {
          this.area1 = this.area.charAt(0).toUpperCase() + this.area.slice(1);
        }
      }
    })

    this.doctorDetails();

    this.patientDetailsForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}'),
        Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      alternatemobile: [''],
      sourceinfo: [''],
      others: [''],
      langs: [''],
    });

    this.otpVerficationForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });

    if (this.Userservice.userValue) {
      this.patientDetailsloggedin();
    }
  }

  checkSlot() {
    this.Userservice.getSlotAvailable(this.Id, this.docId, this.actualdate, this.actualtime, this.slotType).subscribe(response => {
      this.slotAvailable = response.details.status;
    });
  }

  onCheckboxChange(e, _index) {
    if (this.checkedIndexs.indexOf(_index) === -1) {
      this.checkedIndexs.push(_index);
    } else {
      let index = this.checkedIndexs.indexOf(_index);
      this.checkedIndexs.splice(index, 1);
    }
  }

  /*checkFreeAppointment() {
    let name = this.patientDetailsForm.value.name.trim();
    if (this.slotType == "vc" && this.patientDetailsForm.value.mobile.length == 10 && name && name != '') {
      this.dateConversion(this.aptDate);
      let details = {
        mobile: this.patientDetailsForm.value.mobile,
        name : name,
        email: this.patientDetailsForm.value.email,
        clinic: this.Id,
        doctor: this.docId,
        date: this.confirmDate
      }
      this.Userservice.isFreeAppointment(details).subscribe(response => {
        if (response.details.status) {
          this.isFree = response.details.status;
          this.vcButtonText = "Book Free Consultation";
          this.usedPoints = false;
          this.points = 0;
        } else {
          this.isFree = 0;
          this.vcButtonText = "Pay and Book";
          if(this.Userservice.userValue) {
            this.points = this.userPoints;
          }
        }
      });
    }
  }*/

  Error(data) {
    this.errorNotification = data;
    this.errorMessage = true;
    setTimeout(() => {
      this.errorMessage = false;
    }, 5000);
  }

  patientDetailsloggedin() {
    this.user = this.Userservice.userValue;
    this.patientDetailsForm.reset({
      name:'',
      mobile: this.user['details']['mobile'],
      email:this.user['details']['email'],
      alternatemobile:this.user['details']['alternativeMobile'],
      sourceinfo: '',
      others: '',
      langs: '',
    });
    this.isReadonly = true;
  }

  get form() {
    return this.patientDetailsForm.controls;
  }

  get otpform() {
    return this.otpVerficationForm.controls;
  }

  doctorDetails() {
    this.Userservice.getDoctorByName(this.docUrl).subscribe(res => {
      this.clinicDetails = res['details'];
      this.docId = this.clinicDetails.id;
      this.docName = this.clinicDetails.name;
      this.education = this.clinicDetails.education;
      this.specialization = this.clinicDetails.specializations.join(', ');
      let experince = this.clinicDetails['practice_start_year'];
      this.docGender = this.clinicDetails.gender;
      this.docLanguages = this.clinicDetails.languages;
      if (experince != 0) {
        let date = new Date();
        let year = date.getFullYear();
        this.exp = year - experince;
      } else {
        this.exp = 0;
      }
      this.specialities = this.clinicDetails.specializations;
      this.services = this.clinicDetails.services;
      this.clinics = this.clinicDetails.clinics;
      for (let i = 0; i <= this.clinics['length']; i++) {
        if (this.clinics[i]?.['id'] == this.Id) {
          this.consultationFee = +this.clinics[i].fee;
          this.clinicName = this.clinics[i].name;
          this.clinicAddress = this.clinics[i].address
          this.clinicArea = this.clinics[i].area;
          this.clinicCity = this.clinics[i].city;
          this.address = this.clinicAddress + ',' + ' ' + this.clinicArea + ',' + ' ' + this.clinicCity + '.';
          this.cityInUrl = this.clinics[i].city;
        }
      }
      this.contentLoaded = true;
      if (this.slotType != 'oncall') {
        this.checkSlot();
      }
    })
  }

  onSubmit() {
    this.submitted = true;
    this.loadingSpinner = true;
    this.otperror = false;
    this.otpFormReset();
    if (this.patientDetailsForm.invalid) {
      this.loadingSpinner = false;
      this.clicked = false;
      return;
    } else {
      this.clicked = true;
      sessionStorage.setItem('mobile', this.patientDetailsForm.value.mobile);
      let name = this.patientDetailsForm.value.name.substr(0,1).toUpperCase()
      let patientNameinSession = name + this.patientDetailsForm.value.name.substr(1)
      sessionStorage.setItem('name', patientNameinSession);
      sessionStorage.setItem('email', this.patientDetailsForm.value.email);
      sessionStorage.setItem('alternatemobile', this.patientDetailsForm.value.alternatemobile);
      sessionStorage.setItem('sourceinfo', this.patientDetailsForm.value.sourceinfo);
      sessionStorage.setItem('others', this.patientDetailsForm.value.others);
      let number = sessionStorage.getItem('mobile');
      var contact = {
        mobile: number,
      };
      this.mobileSubmit(contact);
    }
  }

  resendOtp() {
    this.otpSent = false;
    this.sendingOtp = true;
    if (this.Userservice.userValue) {
      this.phoneNo = this.user['details']['mobile']
    } else {
      this.phoneNo = sessionStorage.getItem('mobile')
    }
    var details = {
      mobile: this.phoneNo
    }
    this.Userservice.verifyUser(details).subscribe((res: any) => {
      this.sendingOtp = null;
      if (res.status == 200) {
        this.ngOtpInput.setValue('')
        //this.otpVerficationForm.reset();
        this.otpSent = true;
      }
    })
  }

  otpFormReset() {
    this.otpSent = false;
    this.sendingOtp = false;
    this.ngOtpInput.setValue('')
    //this.otpVerficationForm.reset();
  }

  mobileSubmit(userDetails) {
    this.Userservice.verifyUser(userDetails).subscribe((res: any) => {
      if (res.status == 200) {
        let statuscode = res.body.details.status;
        this.clicked = false;
        this.loadingSpinner = false;
        $("#otpModal").modal('show');
      }
    });
  }

  onOtpChange(otpevent) {
    if (otpevent.length === 6) {
       this.otp_isValid = false
        this.otp = otpevent
    }
    else{
      this.otp_isValid=true
    }
  }
  otpSubmit() {
    if (this.otp.length==6) {
      let number = sessionStorage.getItem('mobile');
      let code = this.otp;
      if (this.Userservice.userValue) {
        this.patientDetails = {
          mobile: this.user['details']['mobile'],
          otp: code,
        };
      } else {
        this.patientDetails = {
          mobile: number,
          otp: code,
        };
      }
      this.Userservice.otpVerfication(this.patientDetails).subscribe((res: any) => {
          if (res.status === 200) {
            this.otpVerficationForm.reset();
            this.otpVerify.nativeElement.click();
            this.selectedTimeSlot = this.slotTimeConversion(this.aptTime);
            this.dateConversion(this.aptDate)
            if (this.slotType == 'vc') {
              if (this.usedPoints) {
                if (this.pointsAmountFee == 0) {
                  this.amount = this.pointsAmountFee;
                  this.bookAppointment('1');
                } else {
                  this.amount = this.pointsAmountFee;
                  this.createRzpayOrder(this.amount);
                }
              } else if(this.isFree) {
                this.bookAppointment('1');
              } else {
                this.amount = (+this.consultationFee);
                this.createRzpayOrder(this.amount);
              }
            } else {
              this.bookAppointment('0');
            }
          } else {
            this.otpVerficationForm.reset();
            this.otpSent = false;
            this.otpMsg = false;
            this.otperror = true;
            setTimeout(() => {
              this.otperror = false;
              this.otpMsg = true;
            }, 2500);
          }
        },
        err => {
          this.errorInfo = true;
          this.errorMsg = err
        }
      );
    } else {
      return;
    }
  }

  slotTimeConversion(time) {
    let convertedTime: any[] = [];
    let slot = time.split(":", 3);
    var ampm = slot[1].split(" ", 2);
    if (ampm[1] == "AM" && slot[0] < 12) {
      this.hours = parseInt(slot[0]);
    }
    if (ampm[1] == "PM" && slot[0] < 12) {
      this.hours = parseInt(slot[0]) + 12;
    }
    if (ampm[1] == "PM" && slot[0] == 12) {
      this.hours = parseInt(slot[0]);
    }
    convertedTime.push(this.hours + ':' + slot[1]);
    return convertedTime[0];
  }

  dateConversion(date) {
    this.confirmDate = this.datePipe.transform(new Date(date),"yyyy-MM-dd");
  }

  bookAppointment(slotStatus: any) {
    this.appointmentConfirmationSpinner = true;
    this.spinner.show();
    this.loadingText = "Almost there..confirming your appointment"
    setTimeout(() => {
      this.spinner.hide();
    }, 7000);

    let patientMobile = sessionStorage.getItem('mobile');
    let patientName = sessionStorage.getItem('name');
    let patientEmail = sessionStorage.getItem('email');
    let alternateMobile = sessionStorage.getItem('alternatemobile');
    let sourceInfo = sessionStorage.getItem('sourceinfo');
    let others = sessionStorage.getItem('others');
    this.slotType = sessionStorage.getItem('slotType');
    var details;
    if (this.slotType == 'inclinic') {
       details = {
        name: patientName,
        clinic: this.Id,
        doctor: this.docId,
        email: patientEmail,
        mobile: patientMobile,
        alternativeMobile: alternateMobile,
        sourceInfo: sourceInfo + ' - ' + others,
        date: this.confirmDate,
        time: this.selectedTimeSlot,
        type: "New",
        isOnline: slotStatus,
        orderId: this.order_id,
        paymentId: this.payment_id,
        signature: this.signature,
        languages: null
      }
    } else if (this.slotType == 'vc') {
       details = {
        name: patientName,
        clinic: this.Id,
        doctor: this.docId,
        email: patientEmail,
        mobile: patientMobile,
        alternativeMobile: alternateMobile,
        sourceInfo: sourceInfo + ' - ' + others,
        date: this.confirmDate,
        time: this.selectedTimeSlot,
        type: this.isFree == 1 ? 'Follow up' : 'New',
        isOnline: slotStatus,
        razorpay_order_id: this.order_id,
        razorpay_payment_id: this.payment_id,
        razorpay_signature: this.signature,
        languages: this.checkedIndexs.toString() || null,
        points: this.usedPoints ? 1 : 0
      }
    } else if (this.slotType == 'oncall') {
      let slottime = this.aptTime.split("-");
      let from_time = moment(slottime[0], ["h:mm A"]).format("HH:mm");
      let to_time = moment(slottime[1], ["h:mm A"]).format("HH:mm");
      var onCall = {
        clinic: this.Id,
        doctor: this.docId,
        name: patientName,
        email: patientEmail,
        mobile: patientMobile,
        date: this.confirmDate,
        from_time: from_time,
        to_time: to_time
      }
    }

    if (this.slotType == 'oncall') {
      this.Userservice.addOnCallAppointment(onCall).subscribe(response => {
        let currentDate = new Date();
        let year = currentDate.getUTCFullYear();
        let month = '0' + (currentDate.getMonth() + 1).toString().slice(-2);
        let day = '0' + (currentDate.getUTCDate()).toString().slice(-2);
        this.appointmentId = 'GIGA-' + this.docId + '-' + year + month + day;
        sessionStorage.setItem('appointmentId', this.appointmentId);
        this.onCallParams();
        this.router.navigate(['doctordetails/appointmentsuccess/oncall/', this.docId]);
        this.patientDetailsForm.reset();
        this.otpVerficationForm.reset();
        this.submitted = false;
      },
      error => {
        this.errorMsg = error;
        this.errorPage = true;
        this.Noerror = false;
      });
    } else {
      this.Userservice.bookAppointment(details).subscribe(res => {
          this.appointmentId = res['details']['appointmentId'];
          this.newParams();
          sessionStorage.setItem('appointmentId', this.appointmentId);
          sessionStorage.setItem('isNew', res['details']['isNew']);
          sessionStorage.removeItem('mobile');
          sessionStorage.removeItem('name');
          sessionStorage.removeItem('email');
          sessionStorage.removeItem('alternatemobile');
          sessionStorage.removeItem('sourceinfo');
          sessionStorage.removeItem('others');
          if (res['details']['isNew'] == 1) {
            sessionStorage.setItem('userId1', res['details']['userId']);
          }
          this.patientDetailsForm.reset();
          this.otpVerficationForm.reset();
          this.submitted = false;
          if(this.Userservice.userValue) {
            if (this.slotType == 'vc' && res['details']['wallet']) {
              let remainPoints = this.points - res['details']['wallet'];
              this.Userservice.updatePoints(remainPoints);
            }
          } else {
            this.Userservice.verifyOtpLogin(this.patientDetails.mobile, this.patientDetails.otp).subscribe(res => {
              this.Userservice.afterLogin(res);
            });
          }
          this.router.navigate(['doctordetails/appointmentsuccess/', this.appointmentId]);
        },
        error => {
          this.errorMsg = error;
          this.errorPage = true;
          this.Noerror = false;
        }
      );
    }
  }

  onCallParams() {
    let details = {
      doctor: this.docName,
      clinicName: this.clinicName,
      address: this.address,
      time: this.aptTime,
      date: this.aptDate,
      specialization: this.specialization,
      exp: this.exp,
      gender : this.patientGender ? this.patientGender : "" ,
      dob: this.patientDob,
      patientname : this.patientName || sessionStorage.getItem('name')
    }
    this.activatedroute.queryParams.subscribe(params => {
      this.Userservice.changeParams(details)
    })
  }

  newParams() {
    let details = {
      //appointmentId:this.appointmentId,
      doctor: this.docName,
      city: this.city1,
      area: this.area1,
      spec: this.specs1,
      clinicName: this.clinicName,
      address: this.address,
      time: this.aptTime,
      specialization: this.specialization,
      exp: this.exp,
      date: this.aptDate,
      page: this.page,
      gender : (this.patientGender!=null && this.patientGender!= undefined) ? this.patientGender : "",
      dob: this.patientDob,
      patientname : this.patientName || sessionStorage.getItem('name')
    }
    this.activatedroute.queryParams.subscribe(params => {
      this.Userservice.changeParams(details)
    })
  }

  createRzpayOrder(data) {
    var details = {
      amount: this.amount
    }
    this.Userservice.createOrderID(details).subscribe(res => {
      this.orderIdGen = res['details']['orderId'];
      this.payWithRazor(this.orderIdGen);
    })
    //let orderidGen="order_Fx7ENZJpFUk3i7";
    //this.payWithRazor(orderidGen);
  }

  payWithRazor(val) {
    let patientMobile = sessionStorage.getItem('mobile') || '';
    let patientEmail = sessionStorage.getItem('email') || '';
    const options: any = {
      key: this.Userservice.paymentKey,
      amount: this.amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'GigaDocs', // company name or product name
      description: 'Virtual Consultation',  // product description
      image: 'https://www.gigadocs.com/assets/new/images/logo.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      prefill: {
        "email": patientEmail,
        "contact": patientMobile,
        "method": ""
      },
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      this.payment_id = response['razorpay_payment_id'];
      this.signature = response['razorpay_signature'];
      this.order_id = response['razorpay_order_id'];
      if (this.payment_id != null && this.signature != null) {
        $("#paymentStatus").modal('show');
        this.ngzone.run(() => {
          setTimeout(() => {
            //this.transactionSuccess.nativeElement.click();
            $("#paymentStatus").modal('hide');
            this.bookAppointment('1');
          }, 5000);
        })
      }
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      $("#paymentFailed").modal('show');
      this.ngzone.run(() => {
        //this.transactionMsg=true;
        //this.Noerror=false;
      })
      //  this.router.navigate(['/home']);
    });
    const razorpay = new this.winRef.nativeWindow.Razorpay(options);
    razorpay.open();
  }

  onPointsChange(e, points, fee) {
    if(e.target.checked) {
      let wallet = points / 4;
      let walletRemain = wallet - fee;
      if (walletRemain >= 0) {
        this.pointsAmountFee = 0;
        this.vcButtonText = "Book Free Consultation";
      } else {
        let remainFee = fee - wallet;
        this.pointsAmountFee = remainFee;
        this.vcButtonText = "Pay ₹" + remainFee + " and Book";
      }
      this.usedPoints = true;
    } else {
      this.usedPoints = false;
      this.vcButtonText = "Pay and Book";
    }
  }

  getPopupSlots(id) {
    this.clearArraydata('inclinic');
    this.clearArraydata('vc');
    this.Userservice.clinicDetails(this.docId).subscribe(response => {
      response['details'] = response['details'].filter(i => i.id == id);
      this.clinicDoctor = response['details'][0];
      this.clinicId = this.clinicDoctor.id;
      this.inClinic = this.clinicDoctor.isInClinic;
      this.isOnline = this.clinicDoctor.isOnline;
      this.isOnCall = this.clinicDoctor.onCall;
      this.showClinic = this.clinicDoctor.showClinicSlots;
      this.showOnline = this.clinicDoctor.showOnlineSlots;
      this.hospitalName = this.clinicDoctor.name;
      let today = this.datePipe.transform(new Date(),"yyyy-MM-dd");
      if(this.inClinic && this.showClinic) {
        //this.clearArraydata('inclinic');
        this.datePicker = null;
        this.availableSlots(today, 'inclinic');
        setTimeout(() => {
          this.calendar.value = null;
        }, 100);
      }
      if(this.isOnline && this.showOnline) {
        //this.clearArraydata('vc');
        this.datePickerVC = null;
        this.availableSlotsVC(today, 'vc');
        setTimeout(() => {
          this.calendarVC.value = null;
        }, 100);
      }
    })
  }

  availableSlots(date: any, slot: any) {
    this.Userservice.getClinicslots(this.docId, this.clinicId, date, slot).subscribe((response) => {
      if (slot == "inclinic") {
        if(response['details']['status']==false){
          // Code when No Slots avialble
          this.morningSlotsLength = 0;
          this.afternoonSlotsLength = 0;
          this.eveningSlotsLength = 0;

          this.inclinicSpinner = this.vcSpinner = false;
          this.morningSlots = [];
          this.afternoonSlots =[];
          this.eveningSlots = [];
        }else{
          // Code when Slots are avialble
          this.morningSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningSlotsLength = response['details'][0]['evening']['length'];

          this.inclinicSpinner = this.vcSpinner = false;
          this.morningSlots = this.timeConversion(response['details'][0]['morning']);
          this.afternoonSlots = this.timeConversion(response['details'][0]['afternoon']);
          this.eveningSlots = this.timeConversion(response['details'][0]['evening']);
        }
        
      }
    })
  }

  availableSlotsVC(date: any, slot: any) {
    this.Userservice.getClinicslots(this.docId, this.clinicId, date, slot).subscribe((response) => {
      if (slot == "vc") {
        if(response['details']['status']==false){
          // Code when No Slots avialble
          this.morningVCSlotsLength = 0;
          this.afternoonVCSlotsLength = 0;
          this.eveningVCSlotsLength = 0;

          this.inclinicSpinner = this.vcSpinner = false;
          this.morningVCSlots = [];
          this.afternoonVCSlots = [];
          this.eveningVCSlots = [];          
        }else{
          // Code when Slots are avialble
          this.morningVCSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonVCSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningVCSlotsLength = response['details'][0]['evening']['length'];

          this.inclinicSpinner = this.vcSpinner = false;
          this.morningVCSlots = this.timeConversion(response['details'][0]['morning']);
          this.afternoonVCSlots = this.timeConversion(response['details'][0]['afternoon']);
          this.eveningVCSlots = this.timeConversion(response['details'][0]['evening']);
        }
        
      }
    })
  }

  timeConversion(slot: any = []) {
    while (slot.length >= 1) {
      let convertedTime: any[] = [];
      for (let i = 0; i < slot['length']; i++) {
        this.time = slot[i].split(":", 3);
        var ampm = this.time[0] >= 12 ? 'PM' : 'AM';
        ampm == 'PM' && this.time[0] == 12 ? this.hours = 12 : this.hours = this.time[0] % 12;
        this.hours < 10 ? this.hours = '0' + this.hours : this.hours;
        convertedTime.push(this.hours + ':' + this.time[1] + ' ' + ampm);
      }
      return convertedTime;
    }
  }

  today(slot: string) {
    this.datePicker = null;
    this.calendar.value = null;
    this.clearArraydata(slot);
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowApply = false;
    this.todayApply = true;
    this.availableSlots(this.date, slot);
  }

  todayVC(slot: string) {
    this.datePickerVC = null;
    this.calendarVC.value = null;
    this.clearArraydata(slot);
    this.dateVC = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowApplyVC = false;
    this.todayApplyVC = true;
    this.availableSlotsVC(this.dateVC, slot);
  }

  todayOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowCall = false;
    this.todayCall = true;
  }

  tomorrow(slot: string) {
    this.datePicker = null;
    this.calendar.value = null;
    this.clearArraydata(slot);
    let today = new Date();
    this.date = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayApply = false;
    this.tomorrowApply = true;
    this.availableSlots(this.date, slot);
  }

  tomorrowVC(slot: string) {
    this.datePickerVC = null;
    this.calendarVC.value = null;
    this.clearArraydata(slot);
    let today = new Date();
    this.dateVC = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayApplyVC = false;
    this.tomorrowApplyVC = true;
    this.availableSlotsVC(this.dateVC, slot);
  }

  tomorrowOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    let today = new Date();
    this.date = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = true;
  }

  selectedDate(event: any, slot: string) {
    this.clearArraydata(slot);
    this.dateHighlight = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayApply = false;
    this.tomorrowApply = false;
    this.availableSlots(this.date, slot);
  }

  selectedVCDate(event: any, slot: string) {
    this.clearArraydata(slot);
    this.dateHighlightVC = true;
    this.dateVC = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayApplyVC = false;
    this.tomorrowApplyVC = false;
    this.availableSlotsVC(this.dateVC, slot);
  }

  selectedOnCallDate(event: any, slot: string) {
    this.dateHighlightCall = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = false;
  }

  Time(index, time, slot) {
    this.slotType = slot;
    this.selectedVCIndex = -1;
    this.selectedVCTime = null;
    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.btnDisable = true;
    } else {
      this.selectedIndex = index;
      this.selectedTime = time;
      this.btnDisable = false;
    }
  }

  VCTime(index, time, slot) {
    this.slotType = slot;
    this.selectedIndex = -1;
    this.selectedTime = null;
    if (this.selectedVCIndex == index) {
      this.selectedVCIndex = -1;
      this.selectedVCTime = null;
      this.btnDisable = true;
    } else {
      this.selectedVCIndex = index;
      this.selectedVCTime = time;
      this.btnDisable = false;
    }
  }

  CallTime(index, time, slot) {
    this.slotType = slot;
    if (this.selectedCallIndex == index) {
      this.selectedCallIndex = -1;
      this.selectedCallTime = null;
      this.btnDisable = true;
    } else {
      this.selectedCallIndex = index;
      this.selectedCallTime = time;
      this.btnDisable = false;
    }
  }

  clearArraydata(slot: string) {
    this.btnDisable = true;
    if(slot == 'inclinic') {
      this.morningSlots = [];
      this.afternoonSlots = [];
      this.eveningSlots = [];
      this.morningSlotsLength = 0;
      this.afternoonSlotsLength = 0;
      this.eveningSlotsLength = 0;
      this.inclinicSpinner = true;
      this.todayApply = true;
      this.tomorrowApply = false;
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.dateHighlight = false;
    } else if(slot == 'vc') {
      this.morningVCSlots = [];
      this.afternoonVCSlots = [];
      this.eveningVCSlots = [];
      this.morningVCSlotsLength = 0;
      this.afternoonVCSlotsLength = 0;
      this.eveningVCSlotsLength = 0;
      this.vcSpinner = true;
      this.todayApplyVC = true;
      this.tomorrowApplyVC = false;
      this.selectedVCIndex = -1;
      this.selectedVCTime = null;
      this.dateHighlightVC = false;
    }
  }

  confirmAppointment() {
    setTimeout(() => {
      this.close.nativeElement.click();
      this.router.navigate(['appointment/', this.docUrl], {
        queryParams: {
          clinicId: this.clinicId,
          on: this.slotType == "vc" ? this.dateVC : this.date,
          at: this.slotType == "vc" ? this.selectedVCTime : this.selectedTime,
          slot: this.slotType
        }
      });
      this.ngOnInit();
    }, 1000);
  }

  onCallAppointment() {
    setTimeout(() => {
      this.close.nativeElement.click();
      this.router.navigate(['appointment/', this.docUrl], {
        queryParams: {
          doctorId: this.docId,
          clinicId: this.clinicId,
          on: this.date,
          at: this.selectedCallTime,
          slot: this.slotType
        }
      })
    }, 1000);
  }

  terms() {
    this.router.navigateByUrl('home/terms');
  }

  filteredFamilyMembers$ = new Observable((observer: Observer<string>) => {
    observer.next(this.patientDetailsForm.value.name);
  }).pipe(
    switchMap((query: string) => {
      return of(this.familymembers.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1));
    })
  );

  selectedFamilyMember(event){

    let name = event.item.name.substr(0,1).toUpperCase()
    this.patientName = name + event.item.name.substr(1) // Converting name to camel case
    this.patientDetailsForm.controls['name'].setValue(this.patientName)

    this.patientGender = event.item.gender ? event.item.gender.substr(0,event.item.gender.length).toUpperCase() : "";      
    
    this.patientDob = event.item.dob ? this.datePipe.transform(event.item.dob,'dd-MM-yyyy') : ''
  }
}
