<a class="nav-link" href="#loginModal" class="trigger-btn" (click)="loginFormReset()" routerLinkActive="active" data-toggle="modal" id="login">
    Login
</a>
<!--href="{{modalOpen}}{{modalId}}"-->
<!-- Modal login -->

<div id="loginModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
	<div class="modal-dialog modal-login modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button"  #closelogin class="close" (click)="loginFormReset()" data-dismiss="modal" aria-hidden="true">
					&times;
				</button>
				<h4 class="modal-title">Login to your account</h4>
			</div>
			<div class="modal-body">
				<div class="login-modal-wrap">
					<div #alert *ngIf="loginError" class="alert alert-danger">Invalid Credentials</div>
					<div class="alert alert-success" *ngIf="validNumber && otpSent">
						OTP Successfully sent
					</div>
          <div class="alert alert-success" *ngIf="message">
            Your login is successful, Welcome to Gigadocs!
          </div>
					<div *ngIf="sendingOtp || loginLoading || loggingIn" class="alert alert-warning">
						<span class="spinner-border spinner-border-sm"></span>
						Please wait, Processing...
					</div>
					<div class="alert alert-danger" *ngIf="validNumber == false">
						You do not have an account in Gigadocs. Please register with us
					</div>
					<div class="alert alert-danger" *ngIf="otpInvalid">
						OTP invalid
					</div>
					<form id="login-form" [formGroup]="loginForm">
						<div class="form-group required">
							<label class="control-label">Email or Mobile</label>
							<input type="text" class="form-control" placeholder="Enter Email ID or Mobile Number"  formControlName="email"
								   required="required" [ngClass]="{ 'is-invalid': (submitted||submitted1||submitted2)&&login.email.errors }">
							<div *ngIf="(submitted || submitted1 || submitted2) && login.email.errors" class="invalid-feedback">
								<div *ngIf="login.email.errors.required">Email or Mobile is required</div>
								<div class="text-danger" *ngIf="login.email.errors?.pattern">
									 Invalid Email or Mobile number
								</div>
							</div>
						</div>
						<div class="form-group required" *ngIf="isShow">
							<label class="control-label">Password</label>
							<input type="password" class="form-control" formControlName="password" placeholder="Enter Password"
								   required="required" [ngClass]="{ 'is-invalid': submitted && login.password.errors }">

							<div *ngIf="submitted && login.password.errors" class="invalid-feedback" >
								<div *ngIf="login.password.errors.required">Password is required</div>
                <div *ngIf="login.password.errors?.pattern">Spaces are not allowed</div>
							</div>
						</div>
						<div *ngIf="!isShow">
							<label class="control-label">OTP<span class="text-danger">*</span></label>
							<input type="text" class="form-control" placeholder="Enter code" formControlName="loginOtp"
								   required="required" [ngClass]="{ 'is-invalid': submitted2 && login.loginOtp.errors }">
							<div *ngIf="submitted2 && login.loginOtp.errors" class="invalid-feedback">
								<div *ngIf="login.loginOtp.errors.required">OTP is required</div>
								<div class="text-danger" *ngIf="login.loginOtp.errors?.pattern && login.loginOtp.errors?.minlength
									 || login.loginOtp.errors?.maxlength ">
									Invalid OTP
								</div>
							</div>
						</div>
						<div class="form-group" *ngIf="isShow">
							<div class="row align-items-center">
								<div class="col-sm-6">
								   <input type="button" class="btn btn-primary pl-5 pr-5" [disabled]="loggingIn"
								   		  (click)="signInUser()" value="Login">
								</div>

								 <div class="col-sm-6">
									<a href="#resetModal"data-toggle="modal" data-target="#forgotModal" #closelogin
									   (click)="resetPassword()" id="reset">
									   Forgot Password ?
									</a>
								</div>
							</div>
							<div class="text-center my-3">
								<a href="javascript:void(0)" class="text-danger" (click)="loginWithOtp('send')" >
									Login with OTP
								</a>
							</div>

						</div>
						<div class="form-group" class="mt-4" *ngIf="!isShow">
							<div class="row align-items-center">
								<div class="col-7">
								   <input type="button" class="btn btn-primary pl-5 pr-5" [disabled]="loginLoading"
								   		  (click)="loginOtpSuccess()" value="Login">
								</div>
								<div class="col-5" *ngIf="hideResendBtn">
									<a href="javascript:void(0)" data-toggle="modal" id="reset" (click)="loginWithOtp('resend')">
										Resend OTP
									</a>
								 </div>
							</div>
							<div class="text-center my-3">
								<a class="mx-auto text-danger" href="javascript:void(0)" (click)="loginWithPassword()">
									Login with Password
								</a>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer">
					<div class="mr-4"> <h6>New User!</h6>
					<p>Don't have an account yet?</p>
					</div>
					<a href="newModal" data-toggle="modal" data-target="#newModal" id="try" data-dismiss="modal">
						<button class="btn btn-primary">Signup Now</button>
					</a>
			</div>
		</div>
	</div>
</div>

<!--  Reset password modal -->

<div id="forgotModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-forgot modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="resetFPassword()" #closeFPassword>
                    &times;
                </button>
                <h4 class="modal-title">Forgot Password </h4>
            </div>

            <div class="modal-body">
                <div class="forgot-modal-wrap">
                    <div *ngIf="erroMsg == '204'" class="alert alert-danger">Please enter registered email or mobile</div>
                    <div *ngIf="erroMsg == 'email'" class="alert alert-success">Email Sent Successfully.</div>
                    <form id="forgot-form"  [formGroup]='forgotPasswordForm'>
                        <p>Enter your registered Email ID or Mobile below. We will send you a link to reset password.</p>
                        <div class="form-group required">
                            <label class="font-weight-bold control-label">Email or Mobile</label>
                            <input type="text" class="form-control" placeholder="Enter Email ID or Mobile Number"
                                    formControlName="mobile" [ngClass]="{ 'is-invalid': submitClicked && forgotPasswordValue.mobile.errors }">
                            <div *ngIf="submitClicked && forgotPasswordValue.mobile.errors" class="text-danger">
                                <small *ngIf="forgotPasswordValue.mobile.errors.required">Email or Mobile is required</small>
                                <small *ngIf="forgotPasswordValue.mobile.errors?.pattern">Enter valid Email or Mobile</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary pl-4 pr-4" data-toggle="modal"  [disabled]="isloading1"
                                    #closeFPassword (click)="fPassword()">
								Reset Password
								<span *ngIf="isloading1 == true" class="spinner-border spinner-border-sm"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <a href="#loginModal" data-toggle="modal" data-target="#loginModal" id="try" (click)="resetFPassword()" data-dismiss="modal">
                    Try logging in again
                </a>
            </div>
        </div>
    </div>
</div>







