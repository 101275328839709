import {Injectable, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

export class Product {
  id: string;
  name: string;
  price: number;
  type: string;
  tests: object[];
  testNames: string;
  constructor(id: string, name: string, price: number, type: string, tests: object[], testNames: string) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.type = type;
    this.tests = tests;
    this.testNames = testNames;
  }
}

export class CartItem {
  id: string;
  name: string;
  price: number;
  type: string;
  tests: object[];
  testNames: string;
  constructor(id: string, name: string, price: number, type:string, tests: object[], testNames: string) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.type = type;
    this.tests = tests;
    this.testNames = testNames;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cartItems: CartItem[] = [];
  cartCount: number = 0;
  modalRef: BsModalRef;

  constructor(
    private modalService:BsModalService
  ) {}

  addToCart(product: Product, alertTemplate:TemplateRef<any>, offerExistsTemplate:TemplateRef<any>) {
    this.cartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (this.cartItems == null || this.cartItems.length == 0) {
      this.cartItems = [];
      this.cartItems.push(new CartItem(product.id, product.name, product.price, product.type, product.tests, product.testNames));
    } else {
      if(product.type == 'test') {
        var isTestExist = false;
        var isProfileExistForTest = false ;
        this.cartItems.forEach(item => {
          if(item.type =='profile') {
            item.tests.forEach(test => {
                if(test['code'] == product.tests[0]['code']){
                  isProfileExistForTest = true;
                }
            });
          } else if(item.type == 'test'){
            if(item.id == product.id){
              isTestExist = true;
              this.modalRef = this.modalService.show(alertTemplate);
            }
          }
        });
        if (!isProfileExistForTest) {
          this.cartItems.push(new CartItem(product.id, product.name, product.price, product.type, product.tests, product.testNames));
        }else{
          this.modalRef = this.modalService.show(alertTemplate);
        }
      } else if (product.type == 'profile') {
        var isTestExistForProfile = false;
        var isTestsExistsForProfile = false;
        this.cartItems.forEach(item => {
          if(item.type =='profile'){
            if(item.id != product.id) {
              isTestExistForProfile = true;
            }
          } else if(item.type == 'test'){
            product.tests.forEach(test => {
              if(test['code'] == item.tests[0]['code']){
                isTestsExistsForProfile = true;
                this.cartItems = this.cartItems.filter(i => i.id != item.tests[0]['code']);
              }
            });
          }
        });
        if (isTestExistForProfile || !isTestsExistsForProfile) {
          this.cartItems.push(new CartItem(product.id, product.name, product.price, product.type, product.tests, product.testNames));
        }
        if (isTestsExistsForProfile){
          this.modalRef = this.modalService.show(alertTemplate);
          this.cartItems.push(new CartItem(product.id, product.name, product.price, product.type, product.tests, product.testNames));
        }
      } else if (product.type == 'offer') {
        var isTestExistForProfile = false;
        var isTestsExistsForProfile = false;
        var isOfferExists = false;
        this.cartItems.forEach(item => {
          if(item.type == 'offer'){
            isOfferExists = true;
          } else if(item.type =='profile'){
            product.tests.forEach(profile => {
              if(profile['code'].trim() == item.id){
                isTestsExistsForProfile = true;
                this.cartItems = this.cartItems.filter(i => i.id != item.id);
              }
            });
          } else if(item.type == 'test'){
            product.tests.forEach(test => {
              if(test['code'] == item.tests[0]['code']){
                isTestsExistsForProfile = true;
                this.cartItems = this.cartItems.filter(i => i.id != item.tests[0]['code']);
              }
            });
          }
        });

        if (isOfferExists){
          this.modalRef = this.modalService.show(offerExistsTemplate);
        } else {
          if (isTestExistForProfile || !isTestsExistsForProfile) {
            this.cartItems.push(new CartItem(product.id, product.name, product.price, product.type, product.tests, product.testNames));
          }
          if (isTestsExistsForProfile){
            this.modalRef = this.modalService.show(alertTemplate);
            this.cartItems.push(new CartItem(product.id, product.name, product.price, product.type, product.tests, product.testNames));
          }
        }
      }
    }
    localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
  }

  getCartItems() {
    this.cartItems = JSON.parse(localStorage.getItem('cartItems'));
    return this.cartItems;
  }

  getCartCodes() {
    let cartCodes = [];
    this.cartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (this.cartItems != null && this.cartItems.length > 0) {
      this.cartItems.forEach(item => {
        cartCodes.push(item.id);
      });
    }
    return cartCodes;
  }

  getCartChildCodes() {
    let cartChildCodes = [];
    this.cartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (this.cartItems != null && this.cartItems.length > 0) {
      this.cartItems.forEach(item => {
        if (item.type == 'profile' || item.type == 'offer') {
          item.tests.forEach(child => {
            if (child['type'] == "PROFILE") {
              cartChildCodes.push(child['code'].trim());
            } else{
              cartChildCodes.push(child['code']);
            }
          })
        }
      });
    }
    return cartChildCodes;
  }

  removeItemByCode(id) {
    let cartItems = JSON.parse(localStorage.getItem('cartItems'));
    this.cartItems = cartItems.filter(item => item.id != id);
    localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
  }

  cartItemsCount() {
    this.cartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (this.cartItems == null) {
      this.cartCount = 0;
    } else {
      this.cartCount = this.cartItems.length;
    }
    return this.cartCount;
  }

  clearCart() {
    this.cartItems = [];
    localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
  }

}
