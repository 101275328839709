
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Input} from '@angular/core';
import { UserService } from '../service/user.service';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';

declare const initMap:any;
@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MapViewComponent implements OnInit {
  addressCoords: any=[];
  @Input() address:any;
  @Input() center: google.maps.LatLngLiteral
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow

  markers:any=[];
  infoContent = ''
  infoContent1 = ''

  constructor(
    private Userservice:UserService
  ) { }

  ngOnInit(): void {
    //this.addressLatLong();
  }

  addressLatLong() {
    //this.addressCoords.splice(0,this.addressCoords.length);
    for (let i = 0; i < 10; i++) {
      this.Userservice.getGeolocation(this.address[i].fullAddress).subscribe((response) => {
        this.addressCoords.push({
          position: {
            lat: response['body']['results'][0]['geometry']['location']['lat'],
            lng: response['body']['results'][0]['geometry']['location']['lng']
          },
          //title: 'Marker title ' + (this.markers.length + 1),
          title: 'Click me',
          info: {
            address: this.address[i].fullAddress,
            clinicName: this.address[i].clinicName
          }
        })
      })
    }
  }

  openInfo(marker: MapMarker, clinicN, clinicAdd) {
    this.infoContent = clinicN;
    this.infoContent1 = clinicAdd
    this.info.open(marker)
  }

}
