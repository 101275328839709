import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { BsModalService , BsModalRef} from 'ngx-bootstrap/modal';

declare var $:any;

@Component({
  selector: 'app-otpmodal',
  templateUrl: './otpmodal.component.html',
  styleUrls: ['./otpmodal.component.css']
})
export class OTPmodalComponent implements OnInit, OnDestroy {
  otpVerficationForm: FormGroup;
  otpSubmitted: boolean;
  isloading2:boolean;
  otpMsg:boolean=true;
  otperror: boolean=false;
  private otpVerify: Subscription;
  @ViewChild('closeVerify') closeVerify;
  userType: string;
  response = [];
  isShow: boolean = true;
  sendingOtp: boolean = false;
  otpVerified: boolean = false;
  otpSent: boolean = false;
  otpCount: number = 0;

  constructor(
    private formBuilder:FormBuilder,
    private router: Router,
    private Userservice:UserService,
    public bsModalRef: BsModalRef
    ) {
    this.otpVerficationForm = this.formBuilder.group({
      otp:['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6), Validators.maxLength(6)]],
    });
  }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem('loginType')
  }

  otpSubmit(loginType){
    this.otpSubmitted = true;
    if(this.otpVerficationForm.valid){
      this.sendingOtp = true;
        this.isloading2 = true;
        if(loginType == "new"){
            var details={
              mobile:localStorage.getItem('mobile'),
              otp:this.otpVerficationForm.value.otp,
            };
        }
        else if(loginType == "old"){
          var details={
            mobile:sessionStorage.getItem('mobileNumber'),
            otp:this.otpVerficationForm.value.otp
          };
        }
         this.otpVerify=this.Userservice.otpVerfication(details).subscribe((res:any) => {
             this.isloading2 = null;
              if(res.status===200 ){
                  this.sendingOtp = false;
                  this.Userservice.closeOtpModalComponent()
                  this.otpVerficationForm.reset();
                  if(loginType == "new"){
                    //$("#registModal").modal('show');
                    this.Userservice.openResgisterTypeModal();
                  }
                  else if(loginType == "old"){
                    sessionStorage.setItem('userId', sessionStorage.getItem('userId1'))
                    this.router.navigate(['/resetpassword']);
                    //this.forgotPasswordForm.reset();
                  }
                  this.otpVerified = true;
                  setTimeout(() => {
                    this.otpVerified = false;
                  }, 3000);
              }
              else{
                this.otpVerficationForm.reset();
                this.otpMsg=false;
                this.sendingOtp = false;
                this.otperror=true;
                setTimeout(() => {
                  this.otperror = false;
                }, 3000);
              }
            },
          );
    }
    else{
        return;
    }
  }

  otpModalClose(){
    this.otpVerficationForm.reset();
    this.otpSubmitted = null;
    this.isloading2 = null;
  }

  get otp() {
    return this.otpVerficationForm.controls;
  }

  resendOtp(loginType){
    this.sendingOtp = true;
    if(loginType == "new"){
      this.sendingOtp = true;
      var details={
        mobile:localStorage.getItem('mobile')
      };
      this.Userservice.mobileVerfication(details).subscribe((data: any) => {
        this.response = data;
        if(this.response){
          this.sendingOtp = false;
          this.otpSent = true;
          this.otpCount += 1;
          if(this.otpCount >= 3) {
            this.isShow = false;
          }
          setTimeout(() => {
            this.otpSent = false;
          }, 3000);
        }
      })
    }
    else if(loginType == "old"){
      this.Userservice.forgotPassword(sessionStorage.getItem('mobileNumber')).subscribe((data: any) => {
        this.response = data;
        if(this.response){
          this.sendingOtp = false;
          this.otpSent = true;
          this.otpCount += 1;
          if(this.otpCount >= 3) {
            this.isShow = false;
          }
          setTimeout(() => {
            this.otpSent = false;
          }, 3000);
        }
      })
    }
  }

  otpResponse(){
    this.sendingOtp = null;
    if(this.response['details']['status']){
      this.sendingOtp = false;
    }
  }

  ngOnDestroy(){
    sessionStorage.removeItem('userId1')
  }
}
