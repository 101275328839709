
<!--  Registration type modal -->


		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">
						Registration Type
				</h4>
				<button #closeregist type="button"  class="close" data-dismiss="modal" aria-hidden="true" (click)="bsModalRef.hide()">
                    &times;
                </button>
			</div>
			<div class="modal-body">
				<div class="regist-modal-wrap">
					<p>Please select the registration type from the below:</p>
						   <div class="row caption">
							   <div class="col-4">
								<img src="assets/images/user.png" class="img-center"  alt="user"/>
								<p class="text-center">
									<button #user id="user" class="btn-sm btn-info"(click)="registrationType(user)">User</button>
								</p>
							</div>
							<div class="col-4">
								<img src="assets/images/doctor-male-default.png" class="img-center" height="80" width="80" style="border-radius: 50%;" alt="doctor"/>
								<p class="text-center">
									<button #doctor id="doctor" class="btn-sm btn-info"(click)="registrationType(doctor)">Doctor</button>
								</p>
							</div>
							<div class="col-4">
								<img src="assets/images/clinic2.png" class="img-center" alt="Clinic"/>
								<p class="text-center">
									<button #clinic id="clinic" class="btn-sm btn-info"(click)="registrationType(clinic)">Clinic</button>
								</p>
							</div>
						</div>
				</div>
			</div>
		</div>
