import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, NavigationStart, NavigationEnd} from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title;
  banner:boolean;
  constructor(private router: Router, private activatedRouter: ActivatedRoute) { }
  ngOnInit(): void {
    this.router.events.subscribe(event =>{
      if (event instanceof NavigationEnd){
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
          }
    })

    let name=localStorage.getItem('alert')
    if(name){
      this.banner=false;
    }
    else{
      this.link(name);
    }
    
  }
  link(value:any){
   
    this.banner=true
  }

  
  closeBanner(){
    $('#smartabanner').slideUp(500);
    this.banner=false;
    localStorage.removeItem("device");
    let counter:any= 'yes'
    localStorage.setItem('alert',counter);
  }
  getLink(){
    let name=localStorage.getItem('device');
    localStorage.removeItem("device");
     if(name=='Android'){
      window.open('https://play.google.com/store/apps/details?id=com.gigadocs.app', "_blank");
    }else if(name=='iOS'){
       window.open('https://apps.apple.com/in/app/gigadocs/id1447077786', "_blank");
    }
    this.banner=false;
   let counter:any= 'yes'
   localStorage.setItem('alert',counter);
 }
 onActivate(event) {
  window.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'smooth' 
  });
  /*let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 16);*/
}

}
