import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'camelcase'
})
export class CamelcasePipe implements PipeTransform {

  transform(value): string {
    var camelCaseValue = "";
    if (value) {
      value = value.replace("-", " ");
      let words = this.toWords(value);
      for (let i = 0; i < words.length; i++) {
        let currentStr = words[i];
        let tempStr = currentStr.toLowerCase();
        tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
        if (i == words.length - 1) {
          camelCaseValue += tempStr;
        } else {
          camelCaseValue += tempStr + ' ';
        }
      }
    }
    return camelCaseValue;
  }

  toWords(value):string[]{
    var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
    return  value.match(regex);
  }

}

@Pipe({
  name: 'reversecase'
})
export class ReverseCamelcasePipe implements PipeTransform {

  transform(value): string {
    var camelCaseValue = "";
    if (value) {
      value = value.replace(" ", "-");
      let words = this.toWords(value);
      for (let i = 0; i < words.length; i++) {
        let currentStr = words[i];
        let tempStr = currentStr.toLowerCase();
        if (i == words.length - 1) {
          camelCaseValue += tempStr;
        } else {
          camelCaseValue += tempStr + '-';
        }
      }
    }
    return camelCaseValue;
  }

  toWords(value):string[]{
    var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
    return value.match(regex);
  }
}

@Pipe({
  name: 'commapipe'
})
export class CommaPipe implements PipeTransform {

  transform(value): string {
    var camelCaseValue = "";
    if (value) {
      for (let i: number = 0; i < value.length; i++) {
        if (i == value.length - 1) {
          camelCaseValue += value[i];
        } else {
          camelCaseValue += value[i] + ", ";
        }
      }
    }
    return camelCaseValue;
  }
}

@Pipe({name: 'ordinal'})
export class OrdinalPipe implements PipeTransform {
  constructor(
    private datePipe:DatePipe
  ) { }

  transform(value: string) {
    const ordinals: string[] = ['th','st','nd','rd'];
    let day = value.substr(8,2);
    let date = new Date(Number(value.substr(0, 4)),Number(value.substr(5, 2))-1,
      Number(value.substr(8,2)));
    let dateString = this.datePipe.transform(date,'dd MMM, y');
    let v = parseInt(day) % 100;
    let convertedDay = day.toString() + (ordinals[(v-20)%10]||ordinals[v]||ordinals[0]);
    dateString = dateString.replace(day.toString(),convertedDay);
    return dateString;
  }
}
