
<app-header></app-header>

<div class="subheader subheader-filter">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-sm-4">
        <h4 class="pg-title">Virtual Consultation</h4>
      </div>
    </div>
  </div>
</div>

<div class="booking-success">
  <div class="row">
    <div id="appointment_details" class="col-md-8">
      <div class="page-content">
        <div class="booking-result-wrap">
          <div class="container">
            <div class="confirmation-wrap">
              <div class="booking-confirmation-title">
                <h3>Thank you for choosing Gigadocs for your online consultation. <br> Please contact our support team if you have any queries.</h3>
                <p>Thank you for booking an appointment through Gigadocs!</p>
                <p>Mobile : +91 77023 00122</p>
                <p>Email : uservoice@gigadocs.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="reason_visit" class="col-md-4">
      <div class="reason">
        <div class="container">
          <div class="reason-form">
            <div class="alert alert-success" *ngIf="updated">
              Appointment Updated Successfully
            </div>
            <form [formGroup]="reasonForm">
              <div class="form-group required">
                <label>Reason for Visit</label>
                <textarea rows="4" cols="50" class="form-control" formControlName="complaint" placeholder="Enter Reason for Visit"
                          required="required" [ngClass]="{ 'is-invalid': reasonSubmitted && form.complaint.errors }"></textarea>
                <div *ngIf="(reasonSubmitted) && form.complaint.errors" class="invalid-feedback">
                  <div *ngIf="form.complaint.errors.required">Reason for Visit is required</div>
                </div>
              </div>
              <div class="form-group">
                <label>Gender</label>
                <select  class="form-control" formControlName="gender" placeholder="Select Gender">
                  <option value="">Select Gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div class="form-group">
                <label>Age</label>
                <select  class="form-control" formControlName="age" placeholder="Select Age">
                  <option value="">Select Age</option>
                  <option value="0.05">&lt; 1 Month</option>
                  <option value="0.08">1 Month</option>
                  <option value="0.17">2 Months</option>
                  <option value="0.25">3 Months</option>
                  <option value="0.33">4 Months</option>
                  <option value="0.42">5 Months</option>
                  <option value="0.50">6 Months</option>
                  <option value="0.58">7 Months</option>
                  <option value="0.67">8 Months</option>
                  <option value="0.75">9 Months</option>
                  <option value="0.83">10 Months</option>
                  <option value="0.92">11 Months</option>
                  <option value="1">1 Year</option>
                  <option value="1.5">1.5 Years</option>
                  <option value="2">2 Years</option>
                  <option value="2.5">2.5 Years</option>
                  <option value="3">3 Years</option>
                  <option value="3.5">3.5 Years</option>
                  <option value="4">4 Years</option>
                  <option value="4.5">4.5 Years</option>
                  <option value="5">5 Years</option>
                  <option value="6">6 Years</option>
                  <option value="7">7 Years</option>
                  <option value="8">8 Years</option>
                  <option value="9">9 Years</option>
                  <option value="10">10 Years</option>
                  <option value="11">11 Years</option>
                  <option value="12">12 Years</option>
                  <option value="13">13 Years</option>
                  <option value="14">14 Years</option>
                  <option value="15">15 Years</option>
                  <option value="16">16 Years</option>
                  <option value="17">17 Years</option>
                  <option value="18">18 Years</option>
                  <option value="19">19 Years</option>
                  <option value="20">20 Years</option>
                  <option value="21">21 Years</option>
                  <option value="22">22 Years</option>
                  <option value="23">23 Years</option>
                  <option value="24">24 Years</option>
                  <option value="25">25 Years</option>
                  <option value="26">26 Years</option>
                  <option value="27">27 Years</option>
                  <option value="28">28 Years</option>
                  <option value="29">29 Years</option>
                  <option value="30">30 Years</option>
                  <option value="31">31 Years</option>
                  <option value="32">32 Years</option>
                  <option value="33">33 Years</option>
                  <option value="34">34 Years</option>
                  <option value="35">35 Years</option>
                  <option value="36">36 Years</option>
                  <option value="37">37 Years</option>
                  <option value="38">38 Years</option>
                  <option value="39">39 Years</option>
                  <option value="40">40 Years</option>
                  <option value="41">41 Years</option>
                  <option value="42">42 Years</option>
                  <option value="43">43 Years</option>
                  <option value="44">44 Years</option>
                  <option value="45">45 Years</option>
                  <option value="46">46 Years</option>
                  <option value="47">47 Years</option>
                  <option value="48">48 Years</option>
                  <option value="49">49 Years</option>
                  <option value="50">50 Years</option>
                  <option value="51">51 Years</option>
                  <option value="52">52 Years</option>
                  <option value="53">53 Years</option>
                  <option value="54">54 Years</option>
                  <option value="55">55 Years</option>
                  <option value="56">56 Years</option>
                  <option value="57">57 Years</option>
                  <option value="58">58 Years</option>
                  <option value="59">59 Years</option>
                  <option value="60">60 Years</option>
                  <option value="61">61 Years</option>
                  <option value="62">62 Years</option>
                  <option value="63">63 Years</option>
                  <option value="64">64 Years</option>
                  <option value="65">65 Years</option>
                  <option value="66">66 Years</option>
                  <option value="67">67 Years</option>
                  <option value="68">68 Years</option>
                  <option value="69">69 Years</option>
                  <option value="70">70 Years</option>
                  <option value="71">71 Years</option>
                  <option value="72">72 Years</option>
                  <option value="73">73 Years</option>
                  <option value="74">74 Years</option>
                  <option value="75">75 Years</option>
                  <option value="76">76 Years</option>
                  <option value="77">77 Years</option>
                  <option value="78">78 Years</option>
                  <option value="79">79 Years</option>
                  <option value="80">80 Years</option>
                  <option value="81">81 Years</option>
                  <option value="82">82 Years</option>
                  <option value="83">83 Years</option>
                  <option value="84">84 Years</option>
                  <option value="85">85 Years</option>
                  <option value="86">86 Years</option>
                  <option value="87">87 Years</option>
                  <option value="88">88 Years</option>
                  <option value="89">89 Years</option>
                  <option value="90">90 Years</option>
                  <option value="91">91 Years</option>
                  <option value="92">92 Years</option>
                  <option value="93">93 Years</option>
                  <option value="94">94 Years</option>
                  <option value="95">95 Years</option>
                  <option value="96">96 Years</option>
                  <option value="97">97 Years</option>
                  <option value="98">98 Years</option>
                  <option value="99">99 Years</option>
                  <option value="100">100 Years</option>
                </select>
              </div>
              <div class="form-group">
                <button type="submit" (click)="saveReason()" class="btn btn-primary pull-right">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="my-appointments">
  <div class="row">
    <div class="col-md-8">
      <div class="container">
        <div class="show-appointments">
          <div class="row">
            <div class="col-md-4">
              <img class="img-responsive"  src="../../assets/images/myappointment3.png" alt="vc-steps" height="200" width="250">
            </div>
            <div class="col-md-8">
              <div class="list-myappointments">
                <h3>View the list of the all my appointments </h3>
                <div class="text-center float-left">
                  <a href="{{userToken}}" class="btn-my-appointments">My Appointments</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="container">
        <div class="how-link">
          <div class="row">
            <div class="col-md-4">
              <img class="img-responsive"  src="../../assets/images/myappointment4.png" alt="vc-steps" height="200" width="200">
            </div>
            <div class="col-md-8">
              <div class="how-works">
                <h3>Click here to know more!</h3>
                <div class="text-center">
                  <a href="javascript: void(0)" (click)="howitworks()" class="btn-how-works">How it Works?</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<a class="nav-link" hidden href="javascript:void(0)" href="#feedbackModal" class="trigger-btn" routerLinkActive="active" data-toggle="modal" id="login">
  Feedback
</a>
<!-- feedback modal -->
<div id="feedbackModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-feedback modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h4 class="modal-title">
          Rate Us!
        </h4>
      </div>
      <div class="modal-body">
        <div class="feedback-modal-wrap">
          <div class="alert alert-success" *ngIf="message!=''">
            {{message}}
          </div>
          <img src="../../assets/images/rate.png" class="img-center" height="130" width="220" alt="GigaDocs"/>
          <div class="rating">
            <input type="radio" name="rating" value="5" id="5" (click)="ratingGiven(5)"><label for="5">☆</label>
            <input type="radio" name="rating" value="4" id="4" (click)="ratingGiven(4)"><label for="4">☆</label>
            <input type="radio" name="rating" value="3" id="3" (click)="ratingGiven(3)"><label for="3">☆</label>
            <input type="radio" name="rating" value="2" id="2" (click)="ratingGiven(2)"><label for="2">☆</label>
            <input type="radio" name="rating" value="1" id="1" (click)="ratingGiven(1)"><label for="1">☆</label>
          </div>
          <form [formGroup]='feedbackForm'>
            <fieldset class="text-center">
              <div class="formrow btn-group-toggle" data-toggle="buttons">
                <label class="checklabel btn btn-light" (click)="setRow(i)" *ngFor="let feedbacks of feedback; let i = index;" multiple>
                  <input type="checkbox" id="feedback{{i}}" name="options{{i}}" autocomplete="off">
                  {{feedbacks}}
                </label>
                <label class="checklabel btn btn-light" id="others1" (click)="toggleDisplay()">
                  <input type="checkbox" name="options" id="option6" autocomplete="off">Others
                </label>
              </div>
              <div>
                <textarea [hidden] = "isShow" class="feedbackcomment" formControlName="others" placeholder="Feedback" rows="3" required="required"></textarea>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" id="formSubmitButton" class="btn btn-primary" [disabled]="!ratings || isloading" (click)="submitFeedback()">Submit</button>
        <button type="button" id="feedbackNotIntrested" class="btn btn-default" data-dismiss="modal">Not Interested</button>
      </div>
    </div>
  </div>
</div>
<app-footer lat="17.4611" long="78.355797"></app-footer>






