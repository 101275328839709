import { BrowserModule , Title } from '@angular/platform-browser';
import { ReactiveFormsModule,FormsModule } from "@angular/forms";
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './header-features/login/login.component';
import { HomeComponent } from './home/home.component';
import { UserService } from './service/user.service';
import { ClinicregComponent } from './clinicreg/clinicreg.component';
import { DoctorregComponent } from './doctorreg/doctorreg.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DoctordetailsComponent } from './doctordetails/doctordetails.component';
import { MapViewComponent } from './map-view/map-view.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ClinicdetailsComponent } from './clinicdetails/clinicdetails.component';
import { DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxSpinnerModule } from "ngx-spinner";
import { GoogleMapsModule } from '@angular/google-maps';
import { AppointmentdetailsComponent } from './appointmentdetails/appointmentdetails.component';
import { OtpComponent } from './otp/otp.component';
import { AppointmentsuccessComponent } from './appointmentsuccess/appointmentsuccess.component';
import { SidebarModule } from 'primeng/sidebar';
import { VCresultsComponent } from './vcresults/vcresults.component';
import { WindowRefService } from './window-ref.service';
import { ToastrModule } from 'ngx-toastr';
import { JwtInterceptor,ErrorInterceptor } from './helpers';
import { SecondaryheaderComponent } from './secondaryheader/secondaryheader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CookieService } from 'ngx-cookie-service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RequestDemoComponent } from './header-features/request-demo/request-demo.component';
import { OTPmodalComponent } from './header-features/otpmodal/otpmodal.component';
import { SignupComponent } from './header-features/signup/signup.component';
import { RegistrationTypeComponent } from './header-features/registration-type/registration-type.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatGridListModule } from '@angular/material/grid-list';
import { ClinicspaginationComponent } from './clinicspagination/clinicspagination.component';
import { CamelcasePipe, CommaPipe, OrdinalPipe, ReverseCamelcasePipe } from "./camelcase.pipe";
import { SitemapsComponent } from "./sitemaps/sitemaps.component";
import { ImmunizationscheduleComponent } from './immunizationschedule/immunizationschedule.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HowitworksComponent } from './howitworks/howitworks.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { StoreComponent } from './store/store.component';
import { LogoutComponent } from './logout/logout.component';
import { RescheduleComponent } from './reschedule/reschedule.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { VcsuccessComponent } from './vcsuccess/vcsuccess.component';
import { ThyrocareComponent } from './thyrocare/thyrocare.component';
import { TestdetailsComponent } from './testdetails/testdetails.component';
import { ThyrocarecartComponent } from './thyrocarecart/thyrocarecart.component';
import { ThyrocaresuccessComponent } from './thyrocaresuccess/thyrocaresuccess.component';
import { RequestpaymentComponent } from './requestpayment/requestpayment.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';

// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RegisterComponent,
    LoginComponent,
    HomeComponent,
    ClinicregComponent,
    DoctorregComponent,
    PaginationComponent,
    DoctordetailsComponent,
    MapViewComponent,
    ClinicdetailsComponent,
    AppointmentdetailsComponent,
    OtpComponent,
    AppointmentsuccessComponent,
    VCresultsComponent, SecondaryheaderComponent, ResetPasswordComponent,
    RequestDemoComponent, OTPmodalComponent, SignupComponent, RegistrationTypeComponent,
    ClinicspaginationComponent,
    CamelcasePipe, ReverseCamelcasePipe, CommaPipe, OrdinalPipe,
    SitemapsComponent,
    ImmunizationscheduleComponent,
    HowitworksComponent,
    StoreComponent,
    LogoutComponent,
    RescheduleComponent,
    TermsComponent,
    PrivacyComponent,
    VcsuccessComponent,
    ThyrocareComponent,
    TestdetailsComponent,
    ThyrocarecartComponent,
    ThyrocaresuccessComponent,
    RequestpaymentComponent,
    SubscriptionPlansComponent,

  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    AutoCompleteModule,
    ReactiveFormsModule,
    SimplebarAngularModule,
    CalendarModule,
    GoogleMapsModule,
    NgxSpinnerModule,
    ProgressSpinnerModule,
    SidebarModule,
    ToastrModule.forRoot(),
    NgbModule,
    MatButtonToggleModule,
    ModalModule.forRoot(),
    NgxSkeletonLoaderModule,
    MatGridListModule,
    ShareButtonsModule,
    ShareIconsModule,
    TypeaheadModule.forRoot(),
    NgOtpInputModule,
  ],

  providers: [UserService,DatePipe,CamelcasePipe,ReverseCamelcasePipe,CommaPipe,OrdinalPipe,WindowRefService,CookieService,Title,BsModalService,BsModalRef,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
