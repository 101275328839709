import { Component, OnInit,ViewChild,OnDestroy,Input } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from '../service/user.service';
import { HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { first } from 'rxjs/operators';
declare var $: any;
//import {AlertService} from '../service/alert.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})

export class HeaderComponent implements OnInit, OnDestroy{
  @Input('nextpage') landingpage:string; 
  @Input('scrWidth') scrWidth:number; 
  notLoggedin:boolean=true;
  isLoggedin=false;
  spin:boolean;
  userToken: string;
  userDetails: any;
  previous_values: any;
  new_values: any;

  constructor(
    private router: Router,
    private Userservice:UserService,
    private formBuilder:FormBuilder,
    private http:HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.loginCheck();
  }

  loginCheck() {
    if (this.Userservice.userValue) {
      this.notLoggedin = false;
      // this.userToken = this.Userservice.dashboardURL + 'user/dashboard/?token=' +this.Userservice.userValue['details']['userId'];
      this.isLoggedin = true;  
    }
  }

  ngOnInit(): void {this.updateUserRole();}

  logout() {
    this.Userservice.logout();
    this.notLoggedin = true;
    this.isLoggedin = false;
  }

  updateUserRole(){
    if(this.Userservice.userValue) {
        this.Userservice.roleChange().subscribe(response => {
          if (response) {
            this.userDetails = response['details']
            // Get details of user
            this.previous_values = JSON.parse(localStorage.getItem('user'))
            this.new_values = this.previous_values['user']
            if (this.userDetails['roleStatus'] == 'Yes'){            
              // Update the role changes in dictionary
              this.new_values['details']['userId'] = this.userDetails['userId']
              this.new_values['details']['userTypeId'] = this.userDetails['userTypeId']
              this.new_values['details']['role'] = this.userDetails['role']
              this.new_values['details']['roleCategory'] = this.userDetails['roleCategory']
            }
            this.new_values['details']['name'] = this.userDetails['name']
            this.new_values['details']['email'] = this.userDetails['email']
            this.new_values['details']['gender'] = this.userDetails['gender']
            this.new_values['details']['mobile'] = this.userDetails['mobile']
            this.new_values['details']['alternativeMobile'] = this.userDetails['alternativeMobile']
            // update the observable value
            this.Userservice.afterLogin(this.new_values)
            this.userToken = this.Userservice.dashboardURL + 'user/dashboard/?token=' +this.Userservice.userValue['details']['userId'];
            // When user clicks link in email for prescription then user is routed to prescriptions if loggedin
            if((this.landingpage!='' && this.landingpage == 'prescriptions') && this.Userservice.userValue['details']['role'] == 'USER'){ 
              window.location.href = this.Userservice.dashboardURL + 'user/prescriptions';        
            } 
          }
        }, 
        error => { 
          this.Userservice.logout()
        }); 
      }
  }

  ngOnDestroy(): void {
    this.updateUserRole()
  }

  openRequestDemoModal(){
    this.Userservice.openDemoModalComponent()
  }
}
