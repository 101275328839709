<ngx-spinner *ngIf="appointmentConfirmationSpinner"
  bdColor="#fff"
  size="medium"
  color="#FA030E "
  type="ball-pulse"
>
<p style="font-size: 20px; color:#FA030E ">{{loadingText}}</p>
</ngx-spinner>
<app-header></app-header>
<div #alert *ngIf="errorMessage" class="alert alert-danger ">
    <h6 class="text center">
        <i class="fas fa-exclamation-circle text-danger mr-2"></i>
        {{errorNotification}}
    </h6>
</div>

<div class="subheader subheader-filter">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-sm-4">
                <h4 class="pg-title">Book Appointment</h4>
            </div>
            <div class="col-sm-8 text-right text-left-sm"></div>
        </div>
    </div>
</div>
<div *ngIf="errorPage">
    <p>{{errorMsg}}</p>
    <p>Oops!... Something went wrong</p>
</div>
<div class="page-content" *ngIf="Noerror">
    <div class="banner-strip book-appointment-strip">
        <div class="strip-clinic-img">
            <img src="assets/images/clinicImage.png">
        </div>
        <div class="banner-strip-content">
            <div class="book-appointment-doc">
                <div class="container">
                    <div class="bd-img" *ngIf=!contentLoaded>
                        <ngx-skeleton-loader
                                appearance="circle"
                                animation="pulse"
                                [theme]="{background: '#dedfe1' ,width: '120px', height: '120px'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="bd-img" *ngIf=contentLoaded>
                        <img class="img-fluid" *ngIf="(docGender| lowercase) == 'male'" src="{{clinicDetails.image}}"
                             onerror="src='assets/images/doctor-male-default.png'" alt="{{docName}}"/>
                        <img class="img-fluid" *ngIf="(docGender| lowercase) == 'female'" src="{{clinicDetails.image}}"
                             onerror="src='assets/images/doctor-female1.png'" alt="{{docName}}"/>
                        <img width="100%" *ngIf="docGender == ''"  src="{{clinicDetails.image}}"
                             onerror="src='assets/images/doctor-male-default.png'" alt="{docName}}"/>
                    </div>
                    <div class="bd-basic-info">
                        <div>
                            <h3>Dr. {{docName | camelcase}}</h3>
                            <p>{{specialization}}<span class="ml-1" *ngIf="exp>0">({{exp}} Years of Experience)</span></p>
                            <h5><i class="ic-inr"></i>{{consultationFee}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="appointment-content">
        <div class="container">
            <div class="row">

                <div class="col-md-7">
                    <div class="appointment-datetime card-p">
                        <div class="row align-items-center">
                            <div class="col-sm-10 col-xs-10">
                                <h4 class="head-2"><i class="ic-datetime"></i> Appointment Date/Time:</h4>
                                <h3 *ngIf="slotAvailable">{{aptTime}}, {{aptDate}}</h3>
                                <p class="slotError" *ngIf="!slotAvailable">Your selected slot is unavailable. Please try to select another slot</p>
                            </div>
                            <div class="col-sm-2 col-xs-2 text-right">
                              <a data-toggle="modal" data-target="#slotModal" class="btn-edit-appointment-dt" title="Edit Date/Time"
                                 (click)="getPopupSlots(Id)"
                              ><i class="ic-edit"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                      <h4 class="head-2" *ngIf="slotType == 'inclinic'">Consultation Type : In-Clinic Visit</h4>
                      <h4 class="head-2" *ngIf="slotType == 'vc'">Consultation Type : Video Consultation</h4>
                      <h4 class="head-2" *ngIf="slotType == 'oncall'">Consultation Type : Available On Request</h4>
                    </div>
                    <div class="appointment-doc-info">
                        <h4 class="head-2">Doctor Info:</h4>
                        <div class="row">
                            <div class="col-sm-6">
                                <h6><i class="ic-education"></i> Education</h6>
                                <p *ngIf="education">{{education}}</p>
                                <p *ngIf="education==''">N/A</p>
                            </div>
                            <div class="col-sm-6">
                                <h6><i class="ic-doctor-title"></i> Specialities</h6>
                                <p *ngIf=!contentLoaded>
                                <ngx-skeleton-loader
                                    appearance="rectangle"
                                    animation="pulse"
                                    [theme]="{background: '#dedfe1' ,width: '200px', height: '50px'}">
                                </ngx-skeleton-loader>
                                </p>
                                <p *ngIf=contentLoaded>{{specialities | commapipe}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="appointment-clinic-info">
                        <h4 class="head-2">Clinic Details:</h4>
                        <div class="row">
                            <div class="col-sm-8">
                                <h5>{{clinicName}}</h5>
                                <p *ngIf=!contentLoaded>
                                    <ngx-skeleton-loader
                                    appearance="rectangle"
                                    animation="pulse"
                                    [theme]="{background: '#dedfe1' ,width: '200px', height: '50px'}">
                            </ngx-skeleton-loader>
                                </p>
                                <p *ngIf=contentLoaded>
                                    {{address}}
                                </p>
                            </div>
                            <div class="col-sm-4">
                                <div class="appointment-clinic-map"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5" *ngIf="slotAvailable">
                    <div class="appointment-pt-form">
                        <div class="card-p">
                            <h4 class="head-2">Patient/Visitor Details:</h4>
                            <form [formGroup]="patientDetailsForm" >
                                <div class="form-group required">
                                    <label class="control-label">Name</label>
                                    <input formControlName="name"
                                           [typeahead]="filteredFamilyMembers$"
                                           [typeaheadMinLength]="1"
                                           [typeaheadItemTemplate]="familyItemTemplate"
                                           (typeaheadOnSelect)="selectedFamilyMember($event)"
                                           [typeaheadOptionsLimit]="5"
                                           typeaheadOptionField="name"
                                           placeholder="Enter Patient Name"
                                           [ngClass]="{ 'is-invalid': submitted && form.name.errors }"
                                           class="form-control" autofocus>
                                    <ng-template #familyItemTemplate let-brand="item">
                                      <div class="ui-helper-clearfix">
                                        <div style="margin:0px;font-size: 13px;word-break: normal;white-space: normal;">
                                          {{brand.name | camelcase}}
                                        </div>
                                      </div>
                                    </ng-template>
                                    <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
                                        <div *ngIf="form.name.errors.required">Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group required">
                                    <label class="control-label">Mobile Number</label>
                                    <input type="text" formControlName="mobile" class="form-control" placeholder="Enter Mobile Number"
                                           [ngClass]="{ 'is-invalid': submitted && form.mobile.errors }" [readonly]="isReadonly" />
                                    <div *ngIf="submitted && form.mobile.errors" class="invalid-feedback">
                                        <div *ngIf="form.mobile.errors.required">Mobile is required</div>
                                        <div class="text-danger" *ngIf="form.mobile.errors?.pattern || form.mobile.errors?.minlength || form.mobile.errors?.maxlength ">
                                            Invalid Mobile number
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="text" formControlName="email" class="form-control"  placeholder="Enter Email Address"  [ngClass]="{ 'is-invalid': submitted && form.email.errors }" />
                                    <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
                                        <div *ngIf="form.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Alternative Phone Number</label>
                                    <input type="text" formControlName="alternatemobile" class="form-control" minlength="10" maxlength="13" placeholder="Enter Alternative Mobile Number" [ngClass]="{ 'is-invalid': submitted && form.alternatemobile.errors }" />
                                    <div *ngIf="submitted && form.alternatemobile.errors" class="invalid-feedback">
                                        <div class="text-danger" *ngIf="form.alternatemobile.value.length < 10 || form.alternatemobile.value.length > 13 ">
                                        Phone number length between 10 and 13</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="sourceinfo">How Did You Hear About the Clinic?</label>
                                    <select formControlName="sourceinfo" class="form-control" >
                                        <option value="">Select Source</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Through a Friend">Through a Friend</option>
                                        <option value="Doctor Reference">Doctor Reference</option>
                                        <option value="Online Advertisements">Online Advertisements</option>
                                        <option value="Newspaper Advertisements">Newspaper Advertisements</option>
                                        <option value="Radio Advertisements">Radio Advertisements</option>
                                        <option value="TV Advertisements">TV Advertisements</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                <div class="form-group" *ngIf="patientDetailsForm.value.sourceinfo === 'Doctor Reference'">
                                    <label class="control-label">Doctor Name</label>
                                    <input type="text" formControlName="others" class="form-control" placeholder="Enter Doctor Name"/>
                                </div>
                                <div class="form-group" *ngIf="patientDetailsForm.value.sourceinfo == 'Online Advertisements'">
                                    <label class="control-label">Website Name</label>
                                    <input type="text" formControlName="others" class="form-control" placeholder="Enter Website Url"/>
                                </div>
                                <div class="form-group" *ngIf="patientDetailsForm.value.sourceinfo == 'Others'">
                                    <label class="control-label">Others</label>
                                    <input type="text" formControlName="others" class="form-control" placeholder="Others"/>
                                </div>
                                <ng-container *ngIf="slotType =='vc'" >
                                   <div class="form-check-inline" *ngFor= "let doclanguage of docLanguages; let i=index">
                                      <label class="form-check-label" >
                                         <input type="checkbox" (change)="onCheckboxChange($event,doclanguage.id)" class="form-check-input"  [value]="doclanguage.value">{{doclanguage.name}}
                                      </label>
                                    </div>
                                </ng-container>
                                <div class="form-group" *ngIf="slotType == 'vc' && login && points > 0" >
                                  <div class="form-check-inline">
                                    <label class="form-check-label" >
                                      <input type="checkbox" (change)="onPointsChange($event, points, consultationFee)" class="form-check-input">{{points}} <span style="font-weight: 400"> allow reward points to book appointment.</span>
                                    </label>
                                  </div>
                                </div>
                                <div class="form-group" *ngIf="slotType == 'vc' && !login" >
                                  <span>Please <app-login *ngIf="!login" [modalId]="'loginModal'"></app-login> to <b>Redeem</b> earned points</span>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-secondary" type="button" data-toggle="modal" (click)="onSubmit()" [disabled]="clicked"  *ngIf="slotType =='inclinic'">Book Appointment
                                        <i class="pi pi-spin pi-spinner" style="font-size: 1rem" *ngIf="loadingSpinner"></i>
                                    </button>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-secondary" type="button" data-toggle="modal" (click)="onSubmit()"  [disabled]="clicked" *ngIf="slotType =='vc'">
                                        {{vcButtonText}}
                                        <i class="pi pi-spin pi-spinner" style="font-size: 1rem" *ngIf="loadingSpinner"></i>
                                    </button>
                                </div>
                                <div class="form-group">
                                  <button class="btn btn-secondary" type="button" data-toggle="modal" (click)="onSubmit()" [disabled]="clicked"  *ngIf="slotType =='oncall'">Request Appointment
                                    <i class="pi pi-spin pi-spinner" style="font-size: 1rem" *ngIf="loadingSpinner"></i>
                                  </button>
                                </div>
                                <p>By clicking Book Appointment, you agree to our <a (click)="terms();">Terms and Conditions.</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="slotModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-slot">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row">
          <div class="col-md-8">
            <h5 class="modal-title">{{hospitalName | camelcase}}</h5>
          </div>
          <div class="col-md-4">
            <button type="button" #close class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div id="virtual-slots" class="vc-input-group" *ngIf="isOnline && showOnline">
          <h6>Video Consultation</h6>
          <div class="sr-clinic-appointment">
            <ul class="appointment-dates">
              <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayApplyVC}" (click)="todayVC('vc')">Today</a></li>
              <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowApplyVC}" (click)="tomorrowVC('vc')">Tomorrow</a></li>
              <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate':dateHighlightVC}">
                <i class="ic-calendar mr-2"></i>
                <input type="text" readonly #calendarvc [(ngModel)]="datePickerVC" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedVCDate($event, 'vc')"
                       id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
              </li>
            </ul>
            <div class="timeslot-tabs">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" *ngIf="morningVCSlotsLength != 0">
                  <a class="nav-link" [ngClass]="{' active' : (morningVCSlotsLength != 0)}" data-toggle="tab" (click)="selectedVCIndex=-1;selectedVCTime=null" href="#morningVC">
                    <i class="ic-morning"></i> <span>Morning</span>
                  </a>
                </li>
                <li class="nav-item" *ngIf="afternoonVCSlotsLength != 0">
                  <a class="nav-link" [ngClass]="{' active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength != 0)}" data-toggle="tab" (click)="selectedVCIndex=-1;selectedVCTime=null" href="#afternoonVC">
                    <i class="ic-afternoon"></i> <span>Afternoon</span>
                  </a>
                </li>
                <li class="nav-item" *ngIf="eveningVCSlotsLength != 0">
                  <a class="nav-link" [ngClass]="{' active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0 && eveningVCSlotsLength != 0)}" data-toggle="tab" (click)="selectedVCIndex=-1;selectedVCTime=null" href="#eveningVC">
                    <i class="ic-evening"></i> <span>Evening</span>
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent" *ngIf = "(morningVCSlotsLength !=0 || afternoonVCSlotsLength !=0 || eveningVCSlotsLength !=0) && !vcSpinner">
                <div class="tab-pane" id="morningVC" [ngClass]="{' fade show active' : (morningVCSlotsLength != 0)}" *ngIf="morningVCSlotsLength != 0" role="tabpanel">
                  <div style=" display:inline-block;" *ngFor="let time of morningVCSlots;let i=index">
                    <button [ngClass]="{'selected':i==selectedVCIndex}" (click)="VCTime(i,time,'vc')">{{time}}</button>
                  </div>
                  <div *ngIf="morningVCSlotsLength === 0">NO SLOTS AVAILABLE</div>
                </div>
                <div class="tab-pane" id="afternoonVC" [ngClass]="{' fade show active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength != 0)}"  *ngIf="afternoonVCSlotsLength != 0" role="tabpanel">
                  <div  style=" display:inline-block;" *ngFor="let time of afternoonVCSlots;let i=index">
                    <button [ngClass]="{'selected':i==selectedVCIndex}" (click)="VCTime(i,time,'vc')" >{{time}}</button>
                  </div>
                  <div *ngIf="afternoonVCSlotsLength === 0">NO SLOTS AVAILABLE</div>
                </div>
                <div class="tab-pane" id="eveningVC" [ngClass]="{' fade show active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0 && eveningVCSlotsLength != 0)}" *ngIf="eveningVCSlotsLength != 0" role="tabpanel">
                  <div  style=" display:inline-block;" *ngFor="let time of eveningVCSlots;let i=index">
                    <button [ngClass]="{'selected':i==selectedVCIndex}" (click)="VCTime(i,time,'vc')">{{time}}</button>
                  </div>
                  <div *ngIf="eveningVCSlotsLength === 0">NO SLOTS AVAILABLE</div>
                </div>
              </div>
              <div class="tab-content" *ngIf="(morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0 && eveningVCSlotsLength == 0) && !vcSpinner">NO SLOTS AVAILABLE</div>
              <div class="fa-1x tab-content" *ngIf = "(morningVCSlotsLength == 0 && afternoonSlotsLength == 0 && eveningVCSlotsLength == 0 ) && vcSpinner">
                <p><i class="fas fa-circle-notch fa-spin" style ="color: #4a85ec;margin-right: 10px;"></i> Please wait...</p>
              </div>
            </div>
          </div>
        </div>
        <div id="inclinic" class="clinic-input-group" *ngIf="inClinic && showClinic">
          <h6>In-Clinic Visit</h6>
          <div class="sr-clinic-appointment">
            <ul class="appointment-dates">
              <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayApply}" (click)="today('inclinic')">Today</a></li>
              <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowApply}" (click)="tomorrow('inclinic')">Tomorrow</a></li>
              <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate': dateHighlight}">
                <i class="ic-calendar mr-2"></i>
                <input type="text" readonly #calendar [(ngModel)]="datePicker" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedDate($event, 'inclinic')"
                       id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
              </li>
            </ul>
            <div class="timeslot-tabs">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" *ngIf="morningSlotsLength != 0">
                  <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#morning">
                    <i class="ic-morning"></i> <span>Morning</span>
                  </a>
                </li>
                <li class="nav-item" *ngIf="afternoonSlotsLength != 0">
                  <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && afternoonSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#afternoon">
                    <i class="ic-afternoon"></i> <span>Afternoon</span>
                  </a>
                </li>
                <li class="nav-item" *ngIf="eveningSlotsLength != 0">
                  <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#evening">
                    <i class="ic-evening"></i> <span>Evening</span>
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent" *ngIf = "(morningSlotsLength !=0 || afternoonSlotsLength !=0 || eveningSlotsLength !=0) && !inclinicSpinner">
                <div class="tab-pane" id="morning" [ngClass]="{' fade show active' : (morningSlotsLength != 0)}" *ngIf="morningSlotsLength != 0" id="morning" role="tabpanel">
                  <div style=" display:inline-block;" *ngFor="let time of morningSlots;let i=index">
                    <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time,'inclinic')">{{time}}</button>
                  </div>
                  <div *ngIf="morningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                </div>
                <div class="tab-pane" id="afternoon" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength != 0)}" *ngIf="afternoonSlotsLength != 0" role="tabpanel">
                  <div  style=" display:inline-block;" *ngFor="let time of afternoonSlots;let i=index">
                    <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time,'inclinic')">{{time}}</button>
                  </div>
                  <div *ngIf="afternoonSlotsLength === 0">NO SLOTS AVAILABLE</div>
                </div>
                <div class="tab-pane" id="evening" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength != 0)}" *ngIf="eveningSlotsLength != 0" role="tabpanel">
                  <div  style=" display:inline-block;" *ngFor="let time of eveningSlots;let i=index">
                    <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time,'inclinic')">{{time}}</button>
                  </div>
                  <div *ngIf="eveningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                </div>
              </div>
              <div class="tab-content" *ngIf="(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0) && !inclinicSpinner">NO SLOTS AVAILABLE</div>
              <div class="fa-1x tab-content" *ngIf = "(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0) && inclinicSpinner">
                <p><i class="fas fa-circle-notch fa-spin" style ="color: #4a85ec;margin-right: 10px;"></i> Please wait...</p>
              </div>
            </div>
          </div>
        </div>
        <div id="oncall" class="clinic-input-group" *ngIf="isOnCall=='1'">
          <h6>Available On Request</h6>
          <div class="sr-clinic-appointment">
            <ul class="appointment-dates">
              <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayCall}" (click)="todayOnCall('oncall')">Today</a></li>
              <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowCall}" (click)="tomorrowOnCall('oncall')">Tomorrow</a></li>
              <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate': dateHighlightCall}">
                <i class="ic-calendar mr-2"></i>
                <input type="text" readonly #calendarcall [(ngModel)]="datePickerCall" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedOnCallDate($event, 'oncall')"
                       id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
              </li>
            </ul>
            <div class="timeslot-tabs">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#slottime">
                    <i class="ic-datetime"></i> <span>Preferred Time</span>
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="slottime" role="tabpanel">
                  <div style="display:inline-block;">
                    <button [ngClass]="{'selected':0==selectedCallIndex}" (click)="CallTime(0, '12:00 AM - 04:00 AM', 'oncall')">12:00 AM - 04:00 AM</button>
                    <button [ngClass]="{'selected':1==selectedCallIndex}" (click)="CallTime(1, '04:00 AM - 08:00 AM', 'oncall')">04:00 AM - 08:00 AM</button>
                    <button [ngClass]="{'selected':2==selectedCallIndex}" (click)="CallTime(2, '08:00 AM - 12:00 PM', 'oncall')">08:00 AM - 12:00 PM</button>
                    <button [ngClass]="{'selected':3==selectedCallIndex}" (click)="CallTime(3, '12:00 PM - 04:00 PM', 'oncall')">12:00 PM - 04:00 PM</button>
                    <button [ngClass]="{'selected':4==selectedCallIndex}" (click)="CallTime(4, '04:00 PM - 08:00 PM', 'oncall')">04:00 PM - 08:00 PM</button>
                    <button [ngClass]="{'selected':5==selectedCallIndex}" (click)="CallTime(5, '08:00 PM - 12:00 AM', 'oncall')">08:00 PM - 12:00 AM</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="isOnCall=='0'" class="btn btn-primary" [disabled]="btnDisable" (click)="confirmAppointment()">Book Appointment</button>
        <button *ngIf="isOnCall=='1'" class="btn btn-primary" [disabled]="btnDisable" (click)="onCallAppointment()">Request Appointment</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" #otpVerify id="otpModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="otp-modal-wrap">
                    <img src="assets/images/ic-otp.png" alt="OTP">
                    <h4>Verify your mobile</h4>
                    <p *ngIf="otpMsg">Enter an OTP Code sent your mobile number</p>
                    <div #alert *ngIf="sendingOtp" class="alert alert-warning">
                        <div class="spinner-border spinner-border-sm"></div>
                        Please wait..
                    </div>
                    <div #alert *ngIf="otpSent" class="alert alert-success">OTP successfully sent</div>
                    <div #alert *ngIf="otperror" class="alert alert-danger">Invalid OTP</div>
                    <div  #alert *ngIf="errorInfo" class="alert alert-danger">{{errorMsg}}</div>
                    <form id="otp-form" [formGroup]="otpVerficationForm" >
                            <div class="form-group">
                            <!-- <input #focusInput type="text" maxlength='6' id="otp" placeholder="******" formControlName="otp"/> -->
                            <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
                          </div>
                        <button class="btn btn-second" (click)="otpSubmit()" [disabled]="otp_isValid">Submit</button>
                    </form>
                    <p>
                        If you don’t receive a code?
                        <a href="javascript:void(0)" (click)="resendOtp()">Resend</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!--<div class="modal fade" #transactionsuccess id="paymentStatus"tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="otp-modal-wrap">

                    <h2 class="text-success" style="text-align: center;">Transaction Successful</h2>
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                    <p>Please wait for a while,we are confirming your appointment details.</p>


                </div>
            </div>
        </div>
    </div>
</div>-->

<div  id="paymentStatus" #transactionSuccess class="modal fade" data-backdrop="static" data-keyboard="false">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header">

				<h4 class="modal-title w-100"><i class="fas fa-check-circle text-success mr-1"></i>Success</h4>
			</div>
			<div class="modal-body">
				<p class="text-center text-success">Transaction Successful</p>
			</div>
			<div class="modal-footer">
				<p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                <p>Please wait for a while,we are confirming your appointment details.</p>
			</div>
		</div>
	</div>
</div>
<div id="paymentFailed" #transactionFailed class="modal fade" data-backdrop="static" data-keyboard="false">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header">

				<h3 class="modal-title w-100 "><i class="fas fa-exclamation-circle text-danger mr-1"></i>Alert!</h3>
			</div>
			<div class="modal-body">
				<p class="text-center text-danger">Your transaction has failed. Please go back and try again.</p>
			</div>
			<div class="modal-footer">
				<button class="btn btn-danger btn-block" data-dismiss="modal" routerLink="/home">OK</button>
			</div>
		</div>
	</div>
</div>
<app-footer  lat="17.4611" long="78.355797"></app-footer>



