<app-secondaryheader location="{{city1}}" area="{{area}}" speciality="{{specs1}}" ></app-secondaryheader>

<div class="page-wrap d-flex flex-row align-items-center" *ngIf="resultsNotFound">
  <div class="container">
    <app-sitemaps latitude="{{lat}}" longitude="{{longitude}}" city="{{city}}"></app-sitemaps>
  </div>
</div>

<div *ngIf="resultsFound">
  <div class="subheader subheader-filter">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-10">
                <ul class="filter-lists">
                    <li [ngClass]="{' active' : filterNear}"><a href="javascript: void(0)" (click)="getFilterResults('near', '')">Nearest</a></li>
                    <li [ngClass]="{' active' : filterFee}"><a href="javascript: void(0)" (click)="getFilterResults('fee', 'asc')">Consultation Fee</a></li>
                    <li *ngIf="isFilters"><a href="javascript: void(0)" (click)="clearFilters()">Clear</a></li>
                </ul>
            </div>
            <div class="col-sm-2">
                <div class="related-specialities">
                    <a href="javascript:void(0)" (click)="toggle()" class="rel-spec">Related Specialities <i class="ic-arrow-right"></i></a>
                    <div class="rs-container">
                        <ul >
                            <li *ngFor="let relatedSpeciality of relatedSpecialityArray">
                                <a href="javascript:void(0)" routerLink="/{{relatedSpeciality['url']}}">{{relatedSpeciality['lable']}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="page-content">
    <div class="search-results">
        <div class="sr-left" *ngIf="results">
            <div class="row" style="margin: 0">
                <div class="col-6" *ngIf="!contentLoaded">
                    <ngx-skeleton-loader
                    animation="pulse"
                    [theme]="{ background: '#dedfe1' , width: '100px', 'border-radius': '5', height: '15px' }"
                    ></ngx-skeleton-loader>
                </div>
                <div class="col-6" *ngIf="contentLoaded">
                    <h3 class="sr-counts" >{{pageResults['count']}}+ {{specs | camelcase}} </h3>
                </div>
                <div class="col-6">
                    <div class="related-specialities visible-mobile" style="margin-top: -5px">
                        <a href="javascript: void(0)"  (click)="toggle()" class="rel-spec">Related Specialities <i class="ic-arrow-right"></i></a>
                        <div class="rs-container">
                            <ul>
                                <li *ngFor="let relatedSpeciality of relatedSpecialityArray">
                                    <a href="javascript:void(0)" routerLink="/relatedSpeciality{{['url']}}">{{relatedSpeciality['lable']}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

             <div *ngIf="!contentLoaded">
                    <div class="emptycard" *ngFor="let item of emptyresults; let i = index">
                        <div class="first-section-wrapper">
                        <div class="avatar">
                            <ngx-skeleton-loader
                                    appearance="square"
                                    animation="pulse"
                                    [theme]="{background: '#dedfe1' ,width: '80px', height: '80px'}">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="avatar-title">
                                <div>
                                    <ngx-skeleton-loader
                                        animation="pulse"
                                        [theme]="{background: '#dedfe1',width: '200px',
                                            'border-radius': '5',height: '15px',
                                            'margin-bottom': '10px'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div>
                                    <ngx-skeleton-loader
                                    animation="pulse"
                                    [theme]="{ background: '#dedfe1' , width: '170px', 'border-radius': '5', height: '15px' }"
                                    ></ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>

             <div *ngIf="contentLoaded">
                <div class="sr-lists" >
                    <div class="sr-card" *ngFor="let item of resultsDisp | paginate: { itemsPerPage: 10,currentPage: pageNo  ,
                        totalItems: pageResults['count']};let i=index" >
                        <div class="row">
                            <div class="sr-card-left">
                                <div class="sr-img">
                                    <img class="img-fluid" *ngIf="(item.doctorGender| lowercase) == 'male'" src="{{item.doctorImage}}" onerror="src='assets/images/doctor-male-default.png'" alt="{{item.doctorName}}"/>
                                    <img class="img-fluid" *ngIf="(item.doctorGender| lowercase)== 'female'"  src="{{item.doctorImage}}"  onerror="src='assets/images/doctor-female1.png'"
                                          alt="{{item.doctorName}}"/>
                                    <img class="img-fluid" *ngIf="!item.doctorGender"  src="{{item.doctorImage}}"  onerror="src='assets/images/doctor-male-default.png'"
                                          alt="{{item.doctorName}}"/>
                                </div>
                            </div>
                            <div class="sr-card-content">
                                <div class="sr-info">
                                    <a  routerLink="/doctors/{{city}}/{{item.doctorUrl}}" class="sr-title">
                                        <h4>Dr. {{item.doctorName | camelcase}}</h4>
                                    </a>
                                    <p style="width: 100%;">
                                        <i class="ic-doctor-title"></i>
                                        <span > {{item.doctorSpecialization | commapipe}}</span>
                                    </p>
                                    <p class="hidden-mobile" *ngIf="item.doctorEducation !==''" style="width: 100%;">
                                      <i class="ic-education"></i>
                                      <span> {{item.doctorEducation}}</span>
                                    </p>
                                    <p *ngIf="item.doctorExperience" style="width: 100%;">
                                      <i class="ic-portfolio"></i>
                                      <span> {{item.doctorExperience}} Years of experience</span>
                                    </p>
                                    <p *ngIf="item.doctorLanguage.length > 0">
                                      <i class="fa fa-language" aria-hidden="true"></i>
                                      <span>{{item.doctorLanguage | commapipe}}</span>
                                    </p>
                                    <div class="sr-clinic-info">
                                        <h6>{{item.clinicName}}</h6>
                                        <span>{{item.clinicArea}}, {{item.clinicCity}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="sr-card-right">
                                <div class="sr-actions">
                                    <h5><i class="ic-rupee-indian"></i>{{item.doctorFee}}</h5>
                                    <div class="view-contact" id="view-contact-{{i}}">
                                      <a href="javascript:void(0)" (click)="viewContact(i)">
                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                        <span>Contact Clinic</span>
                                      </a>
                                    </div>
                                    <div class="Contact-options" id="Contact-options-{{i}}" style="display: none;">
                                      <a href='tel:{{item.clinicContactNumber}}' *ngIf="item.clinicContactNumber != '' && item.clinicContactNumber != null">
                                        <b>{{item.clinicContactNumber}}</b>
                                      </a>
                                      <a href='tel:{{item.clinicContactNumber}}' *ngIf="item.clinicContactNumber == '' || item.clinicContactNumber == null">
                                        <b>Not Available</b>
                                      </a>
                                      <div *ngIf="item.clinicContactNumber != '' && item.clinicContactNumber != null">
                                        <span *ngIf="item.clinicContactCall == '1' || item.clinicContactWhatsapp == '1' || item.clinicContactSms == '1'">Reach us on</span>
                                        <ul class="conatctList">
                                          <li class="phone">
                                            <a *ngIf="item.clinicContactCall == '1'" href="tel:{{item.clinicContactNumber}}"><i class="fa fa-phone" aria-hidden="true"></i></a>
                                          </li>
                                          <li class="whatsapp">
                                            <a *ngIf="item.clinicContactWhatsapp == '1'" href="https://api.whatsapp.com/send?phone={{item.clinicContactNumber}}"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                                          </li>
                                          <li class="message">
                                            <a *ngIf="item.clinicContactSms == '1'" href="sms:{{item.clinicContactNumber}}"><i class="fa fa-comments-o" aria-hidden="true"></i></a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a *ngIf="item.clinicSubscription == 1 && (item.isOnline && item.showOnlineSlots)" class="btn btn-third btn-video-appointment"
                                      routerLink="/doctors/{{city}}/{{item.doctorUrl}}">Video Consultation</a>
                                    <a *ngIf="item.clinicSubscription == 1 && ((item.isInClinic && item.showClinicSlots) || (item.isOnline && item.showOnlineSlots)) && notaResponsiveButton" class="btn btn-second btn-book-appointment"
                                      (click)="doctorDetails(i, item.clinicId, item.showClinicSlots, item.showOnlineSlots)">Book Appointment</a>
                                    <a *ngIf="item.clinicSubscription == 1  && (item.isInClinic=='1' || item.isOnline=='1') && responsiveButton" class="btn btn-second btn-book-appointment"
                                      routerLink="/doctors/{{city}}/{{item.doctorUrl}}">Book Appointment</a>
                                    <a  class="btn btn-first" *ngIf="(item.clinicSubscription == 0 || (item.clinicSubscription == 1 && item.isInClinic=='0' && item.isOnline=='0')) && notaResponsiveButton" (click)="doctorDetails(i, item.clinicId)">View Details</a>
                                    <a  class="btn btn-first" *ngIf="(item.clinicSubscription == 0 || (item.clinicSubscription == 1 && item.isInClinic=='0' && item.isOnline=='0')) && responsiveButton" routerLink="/doctors/{{city}}/{{item.doctorUrl}}">View Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pagination-controls  class="text-center"
                                  *ngIf="contentLoaded"
                                  (pageChange)="pageNo = $event"
                                  (click)="pgcontrolsroute(pageNo)"
                                  previousLabel=""
                                  nextLabel=""
                                  maxSize="6"
                                  >
            </pagination-controls>
        </div>
        <div id="sr-right" class="sr-right">

            <div id="sr-advertisement" *ngIf="mapDisplay">
              <a href="/diagnostic-center">
                <img src="assets/images/thyrocare-quick-link.png" height="475" width="475">
              </a>
            </div>

            <div id="sr-doctor-details" *ngIf="docDetails">
                <a (click)="closeDetails()" class="close close-result-details">&times;</a>
                <div class="sr-doc-info">
                    <h3>Dr. {{docName | camelcase}}</h3>
                    <a href="javascript: void(0)" routerLink="/doctors/{{clinicCity | reversecase}}/{{docUrl}}"><i class="ic-doctor"></i> View Profile</a>
                </div>
                <div class="sr-doc-services" *ngIf="docService !=''">
                    <h5>Services:</h5>
                    <ul>
                        <li *ngFor="let services of docService.slice(0,6)">{{services | camelcase}}</li>
                    </ul>
                    <a *ngIf="docService.length >= 7" routerLink="/doctors/{{clinicCity | reversecase}}/{{docUrl}}" class="abt-more">
                      More<i class="fa fa-angle-double-right" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="sr-doc-clinic">
                    <h6>{{clinicInfo.length}}
                        <span *ngIf="clinicInfo.length == 1">Clinic</span>
                        <span *ngIf="clinicInfo.length>1">Clinics</span>
                    </h6>
                    <div class="row">
                        <div class="col-sm-9">
                            <h4>{{clinicName}}</h4>
                            <p>{{clinicAddress }}</p>
                        </div>
                        <div class="col-sm-3 nopad-l depad-xs">
                            <div class="dropdown" *ngIf="clinicInfo.length>1">
                                  <a class="dropdown-toggle" href="javascript: void(0)" role="button" id="otherclinics" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Other Clinics
                                  </a>
                                  <div class="dropdown-menu" aria-labelledby="otherclinics" >
                                    <a href="javascript: void(0)" *ngFor="let clinicInfos of clinicInfo"
                                       (click)="handleChange(clinicInfos['id'])" class="dropdown-item" >{{clinicInfos['name']}}</a>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sr-working-hours">
                  <h5>Fee: <i class="ic-rupee-indian"></i>{{clinicFee}}</h5>
                </div>
                <div *ngIf="selectedIsOnline && vcWorkingHours.length > 0" class="sr-working-hours">
                  <h4>Video Consultation:</h4>
                  <div *ngFor="let vcworkingHour of vcWorkingHours" class="working-hour-row">
                    <label *ngFor="let days of vcworkingHour.days">{{days}}</label>
                    <span>{{vcworkingHour['time']}}</span>
                  </div>
                </div>
                <div *ngIf="workingHours.length > 0" class="sr-working-hours clinic-hours">
                    <h4>In-Clinic Consultation:</h4>
                    <div *ngFor="let workingHour of workingHours" class="working-hour-row">
                        <label *ngFor="let days of workingHour.days">{{days}}</label>
                        <span>{{workingHour['time']}}</span>
                    </div>
                </div>

                <div class="slider" *ngIf="!(isOnline==0 && inClinic==0)">
                    <div class="appointment-type">
                        <div class="slide-box" *ngIf="showSlider">
                        <div id="selection"></div>
                            <div class="container">
                                <div class="row">
                                    <div class="col-6">
                                        <button type="button" id="vc" class="slide-btn" (click)="video()" [disabled]="clickedVC"><span [ngClass]="{'sliderColor':vcColor}" >Video Consultation</span></button>
                                    </div>
                                    <div class="col-6">
                                        <button type="button" id="inclinic"class="slide-btn" (click)="inclinic()" [disabled]="clickedInclinic" >In-Clinic Consultation</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sr-clinic-appointment">
                          <h3><span *ngIf="showClinic==1 && showOnline==0">Available Slots for In-Clinic Consultation </span></h3>
                          <h3><span *ngIf="showOnline==1 && showClinic==0">Available Slots for Video Consultation </span></h3>
                          <h3><span *ngIf="isOnCall=='1'">Available On Request</span></h3>
                        </div>
                    </div>
                    <div id="video" class="input-group" *ngIf="VC && showOnline && isOnCall=='0'">
                        <ngx-spinner *ngIf="vcSpinner"
                            bdColor="#fff"
                            size="small"
                            color="#4a85ec"
                            type="ball-clip-rotate"
                            [fullScreen]="false"
                            >
                        </ngx-spinner>
                        <div class="sr-clinic-appointment">
                            <ul class="appointment-dates">
                                <li class="text-white mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':applyVC}"(click)="today('vc','0')">Today</a></li>
                                <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':apply1VC}"(click)="tomorrow('vc','0')">Tomorrow</a></li>
                                <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate':dateHighlight}">
                                  <i class="ic-calendar mr-2"></i>
                                  <input type="text" readonly [(ngModel)]="datePickerVC" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedDate($event,'vc','0')"
                                         id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                                </li>
                            </ul>
                            <div class="timeslot-tabs">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" *ngIf="morningSlotsLengthVC != 0">
                                        <a class="nav-link" [ngClass]="{' active' : (morningSlotsLengthVC != 0 && morningSlotsLengthVC !='loader')}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#morningVC">
                                            <i class="ic-morning"></i> <span>Morning</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" *ngIf="afternoonSlotsLengthVC != 0">
                                        <a class="nav-link" [ngClass]="{' active' : (morningSlotsLengthVC == 0 && (afternoonSlotsLengthVC != 0 && afternoonSlotsLengthVC !='loader'))}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#afternoonVC">
                                            <i class="ic-afternoon"></i> <span>Afternoon</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" *ngIf="eveningSlotsLengthVC != 0">
                                        <a class="nav-link" [ngClass]="{' active' : (morningSlotsLengthVC == 0 && afternoonSlotsLengthVC == 0 && (eveningSlotsLengthVC != 0 && eveningSlotsLengthVC !='loader'))}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#eveningVC">
                                            <i class="ic-evening"></i> <span>Evening</span>
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent" *ngIf="(morningSlotsLengthVC != 0 || afternoonSlotsLengthVC != 0 || eveningSlotsLengthVC != 0) && !vcWaitSpinner">
                                    <div class="tab-pane" id="morningVC" [ngClass]="{' fade show active' : (morningSlotsLengthVC != 0 )}" *ngIf="morningSlotsLengthVC != 0" role="tabpanel">
                                        <div style=" display:inline-block;"
                                            *ngFor="let time of morningSlotsVC;let i=index">

                                          <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time)">{{time}}</button>
                                        </div>
                                        <div *ngIf="morningSlotsLengthVC === 0">NO SLOTS AVAILABLE</div>
                                    </div>
                                    <div class="tab-pane" id="afternoonVC" [ngClass]="{' fade show active' : (morningSlotsLengthVC == 0 && afternoonSlotsLengthVC != 0)}" *ngIf="afternoonSlotsLengthVC != 0" role="tabpanel">
                                        <div  style=" display:inline-block;"
                                             *ngFor="let time of afternoonSlotsVC;let i=index">

                                          <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time)" >{{time}}</button>

                                        </div>
                                        <div *ngIf="afternoonSlotsLengthVC === 0">NO SLOTS AVAILABLE</div>
                                    </div>
                                    <div class="tab-pane" id="eveningVC" [ngClass]="{' fade show active' : (morningSlotsLengthVC == 0 && afternoonSlotsLengthVC == 0 && eveningSlotsLengthVC != 0)}" *ngIf="eveningSlotsLengthVC != 0" role="tabpanel">
                                        <div  style=" display:inline-block;" *ngFor="let time of eveningSlotsVC;let i=index">

                                          <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time)">{{time}}</button>

                                        </div>
                                        <div *ngIf="eveningSlotsLengthVC === 0">NO SLOTS AVAILABLE</div>
                                    </div>
                                </div>
                                <div class="tab-content" *ngIf="(morningSlotsLengthVC == 0 && afternoonSlotsLengthVC == 0 && eveningSlotsLengthVC == 0) && !vcWaitSpinner">NO SLOTS AVAILABLE</div>
                                <div class="fa-1x tab-content" *ngIf = "(morningSlotsLengthVC == 0 && afternoonSlotsLengthVC == 0 && eveningSlotsLengthVC == 0 ) && vcWaitSpinner">
                                  <p><i class="fas fa-circle-notch fa-spin" style ="color: #4a85ec;margin-right: 10px;"></i> Please wait...</p>
                                </div>
                            </div>
                            <div class="btn-book">
                                <button class="btn btn-second" [disabled]="btnDisable"
                                 (click)="confirmAppointment('vc','0')">Book Appointment</button>
                            </div>
                        </div>

                    </div>
                    <div id="inclinic" class="input-group" *ngIf="clinicTab && showClinic && isOnCall=='0'">
                        <ngx-spinner *ngIf="vcSpinner"
                            bdColor="#fff"
                            size="small"
                            color="#4a85ec"
                            type="ball-clip-rotate"
                            [fullScreen]="false"
                            >
                        </ngx-spinner>
                        <div class="sr-clinic-appointment">
                            <ul class="appointment-dates">
                                <li class="text-white mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':apply}"(click)="today('inclinic','vc')">Today</a></li>
                                <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':apply1}"(click)="tomorrow('inclinic','vc')">Tomorrow</a></li>
                                <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate':dateHighlight}">
                                  <i class="ic-calendar mr-2"></i>
                                  <input type="text" readonly [(ngModel)]="datePicker" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedDate($event,'inclinic','vc')"
                                         id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                                </li>
                            </ul>
                            <div class="timeslot-tabs">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" *ngIf="morningSlotsLength != 0">
                                        <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength != 0 && morningSlotsLength !='loader')}" data-toggle="tab" (click)="selectedIndex =-1;selectedTime=null" href="#morning">
                                            <i class="ic-morning"></i> <span>Morning</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" *ngIf="afternoonSlotsLength != 0">
                                        <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && (afternoonSlotsLength != 0 && afternoonSlotsLength !='loader') )}" data-toggle="tab" (click)="selectedIndex =-1;selectedTime=null" href="#afternoon">
                                            <i class="ic-afternoon"></i> <span>Afternoon</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" *ngIf="eveningSlotsLength != 0">
                                        <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && (eveningSlotsLength != 0 && eveningSlotsLength !='loader'))}" data-toggle="tab" (click)="selectedIndex =-1;selectedTime=null" href="#evening">
                                            <i class="ic-evening"></i> <span>Evening</span>
                                        </a>
                                    </li>

                                </ul>
                                <div class="tab-content" id="myTabContent" *ngIf = "(morningSlotsLength !=0 || afternoonSlotsLength !=0 || eveningSlotsLength !=0) && !inclinicWaitSpinner">
                                    <div class="tab-pane" id="morning" [ngClass]="{' fade show active' : (morningSlotsLength != 0)}" *ngIf="morningSlotsLength != 0" role="tabpanel">
                                        <div style=" display:inline-block;"
                                            *ngFor="let time of morningSlots;let i=index">

                                          <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time)">{{time}}</button>
                                        </div>
                                        <div *ngIf="morningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                                    </div>
                                    <div class="tab-pane" id="afternoon" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength != 0)}" *ngIf="afternoonSlotsLength != 0" role="tabpanel">
                                        <div  style=" display:inline-block;"
                                             *ngFor="let time of afternoonSlots;let i=index">

                                          <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time)">{{time}}</button>

                                        </div>
                                        <div *ngIf="afternoonSlotsLength === 0">NO SLOTS AVAILABLE</div>
                                    </div>
                                    <div class="tab-pane" id="evening" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength != 0)}" *ngIf="eveningSlotsLength != 0" role="tabpanel">
                                        <div  style=" display:inline-block;" *ngFor="let time of eveningSlots;let i=index">

                                          <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time)">{{time}}</button>

                                        </div>
                                        <div *ngIf="eveningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                                    </div>
                                </div>
                                <div class="tab-content" *ngIf="(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0) && !inclinicWaitSpinner">NO SLOTS AVAILABLE</div>
                                <div class="fa-1x tab-content" *ngIf = "(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0) && inclinicWaitSpinner">
                                  <p><i class="fas fa-circle-notch fa-spin" style ="color: #4a85ec;margin-right: 10px;"></i> Please wait...</p>
                                </div>
                            </div>
                            <div class="btn-book">
                                <button class="btn btn-second" [disabled]="btnDisable"
                                 (click)="confirmAppointment('inclinic','vc')">Book Appointment</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="oncall" class="input-group" *ngIf="isOnCall=='1'">
                <ngx-spinner *ngIf="vcSpinner"
                             bdColor="#fff"
                             size="small"
                             color="#FA030E "
                             type="ball-clip-rotate"
                             [fullScreen]="false"
                >
                </ngx-spinner>
                <div class="sr-clinic-appointment">
                  <h3>Available On Request: </h3>
                  <ul class="appointment-dates">
                    <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayCall}" (click)="todayOnCall('oncall')">Today</a></li>
                    <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowCall}" (click)="tomorrowOnCall('oncall')">Tomorrow</a></li>
                    <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate': dateHighlightCall}">
                      <i class="ic-calendar mr-2"></i>
                      <input type="text" readonly #calendarcall [(ngModel)]="datePickerCall" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedOnCallDate($event,'oncall')"
                             id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                    </li>
                  </ul>
                  <div class="timeslot-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#slottime">
                          <i class="ic-datetime"></i> <span>Preferred Time</span>
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="slottime" role="tabpanel">
                        <div style="display:inline-block;">
                          <button [ngClass]="{'selected':0==selectedIndex}" (click)="CallTime(0, '12:00 AM - 04:00 AM', 'oncall')">12:00 AM - 04:00 AM</button>
                          <button [ngClass]="{'selected':1==selectedIndex}" (click)="CallTime(1, '04:00 AM - 08:00 AM', 'oncall')">04:00 AM - 08:00 AM</button>
                          <button [ngClass]="{'selected':2==selectedIndex}" (click)="CallTime(2, '08:00 AM - 12:00 PM', 'oncall')">08:00 AM - 12:00 PM</button>
                          <button [ngClass]="{'selected':3==selectedIndex}" (click)="CallTime(3, '12:00 PM - 04:00 PM', 'oncall')">12:00 PM - 04:00 PM</button>
                          <button [ngClass]="{'selected':4==selectedIndex}" (click)="CallTime(4, '04:00 PM - 08:00 PM', 'oncall')">04:00 PM - 08:00 PM</button>
                          <button [ngClass]="{'selected':5==selectedIndex}" (click)="CallTime(5, '08:00 PM - 12:00 AM', 'oncall')">08:00 PM - 12:00 AM</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="btn-book">
                    <a  class="btn btn-second"
                        (click)="onCallAppointment()">Request Appointment</a>
                  </div>
                </div>
              </div>

        </div>
        </div>
    </div>
  </div>
</div>

<app-footer city="{{city1}}" area="{{area1}}" specality="{{specs}}" (data)="RelatedSpecialty($event)"></app-footer>
