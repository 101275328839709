import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { UserService } from '../service/user.service';
import * as $ from 'jquery';
import {Calendar} from "primeng/calendar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../models";
import {DatePipe} from "@angular/common";
import {CommaPipe} from "../camelcase.pipe";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";


@Component({
  selector: 'app-doctordetails',
  templateUrl: './doctordetails.component.html',
  styleUrls: ['./doctordetails.component.css']
})

export class DoctordetailsComponent implements OnInit, OnDestroy {
  @ViewChild('calendar') calendar: Calendar;
  @ViewChild('calendarvc') calendarVC: Calendar;
  @ViewChild('calendarcall') calendarCall: Calendar;
  @ViewChild('close') close;
  @ViewChild('closeProfile') closeProfile;
  @ViewChild('closeReport') closeReport;
  readMore: boolean=false;
  sendAppLinkForm: FormGroup;
  cityUrl: any;
  docUrl: any;
  data1: any;
  docName: any;
  exp: number;
  specialities: any;
  services: any;
  clinics: any = [];
  relatedSpecialityArray: any = [];
  shortLinksArray: any = [];
  clinicCount: any;
  docId: any;
  clinicId: any;
  clinicInfo: any = [];
  clinicInfoSpinner:boolean = true;
  clinicAddress: string;
  clinicName: any;
  city: any;
  specs: any;
  brand: string;
  area:any;
  speciality:any;
  list=[];
  name: string;
  data: any;
  errorNotification: any;
  errorMessage: boolean;
  imageUrl: any;
  docGender:string;
  inClinic: boolean = false;
  isOnline: boolean = false;
  isOnCall: any = '0';
  hospitalName: any;
  clinicDoctor: any;
  morningSlots: any = [];
  afternoonSlots: any = [];
  eveningSlots: any = [];
  morningSlotsLength: any;
  afternoonSlotsLength: any;
  eveningSlotsLength: any;
  morningVCSlots: any = [];
  afternoonVCSlots: any = [];
  eveningVCSlots: any = [];
  morningVCSlotsLength: any;
  afternoonVCSlotsLength: any;
  eveningVCSlotsLength: any;
  hours: any;
  time: any;
  selectedIndex: any;
  selectedVCIndex: any;
  selectedCallIndex: any;
  selectedTime: any;
  selectedVCTime: any;
  selectedCallTime: any;
  todayApply: boolean = true;
  todayApplyVC: boolean = true;
  todayCall: boolean = true;
  tomorrowApply: boolean = false;
  tomorrowApplyVC: boolean = false;
  tomorrowCall: boolean = false;
  dateHighlight: boolean = false;
  dateHighlightVC: boolean = false;
  dateHighlightCall: boolean = false;
  datePicker: Date;
  datePickerVC: Date;
  datePickerCall: Date;
  minDate: Date;
  maxDate: Date;
  slotType: any;
  date: any;
  dateVC: any;
  btnDisable: boolean = true;
  languages: any[] = [];
  submitted: boolean = false;
  claimProfileForm: FormGroup;
  reportErrorForm: FormGroup;
  isReadonly = false;
  user: User;
  message: any = '';
  isShow: boolean=false;
  sendingOtp: boolean=false;
  otpSent: boolean=false;
  validMobile: boolean=true;
  otpInvalid: boolean=false;
  otpCount: number = 0;
  isClaimed: string = '1';
  sendLink: boolean = false;
  sentLink: boolean = false;
  showClinic: number;
  showOnline: number;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  inclinicSpinner: boolean;
  vcSpinner: boolean;
  description: any;
  doctorEducation: any;

  constructor(
    private activatedroute: ActivatedRoute,
    private router: Router,
    private Userservice: UserService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private commaPipe: CommaPipe,
  ) { }

  ngAfterViewInit(){
    this.doctor();
  }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.cityUrl = params['city'];
      this.docUrl = params['docName'];
      this.shortLinks(this.docUrl);
      this.doctor();
    });
    let today = new Date();
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.dateVC = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.minDate = new Date(today);
    this.maxDate = new Date(today.setDate(today.getDate() + 14));
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' });
    this.claimProfileForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}'),
        Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      otp: ['', [Validators.required, Validators.pattern('[0-9]\\d{5}')]]
    });
    this.reportErrorForm = this.formBuilder.group({
      phonenumber: [false],
      address: [false],
      timings: [false],
      fee: [false],
      not_practice: [false],
      correct_phonenumber: [''],
      correct_address: [''],
      correct_timings: [''],
      correct_fee: [''],
      email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      others: [''],
      self_doctor: [false]
    });
    this.sendAppLinkForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}'/*"^[0-9]*$"*/),
        Validators.minLength(10), Validators.maxLength(10)]]
    });
  }

  Error(data) {
    this.errorNotification = data;
    this.errorMessage = true;
    setTimeout(() => {
      this.errorMessage = false;
    }, 5000);
  }

  RelatedSpecialty(data:any) {
    this.relatedSpecialityArray = data;
  }

  shortLinks(docUrl) {
    this.Userservice.getShortLinksByDoctor(this.docUrl).subscribe(response => {
      if (response) {
        this.shortLinksArray = response.details;
      }
    })
  }

  doctor() {
    this.Userservice.getDoctorByName(this.docUrl).subscribe(res => {
      this.data1 = res['details'];
      let speciality = this.commaPipe.transform(this.data1.specializations);
      let services = this.commaPipe.transform(this.data1.services);
      var details = {
        metaTitle: 'Dr. ' + this.data1.name + ' - ' + speciality + ' - View Fees | Gigadocs',
        ogTitle: 'Dr. ' + this.data1.name + ' - ' + speciality + ' - View Fees | Gigadocs',
        metaDescription: '',
        ogDescription: ''
      };
      if(this.data1.clinics.length > 0) {
        details.metaDescription = 'Book an appointment with Dr. ' + this.data1.name + ', ' + speciality + ' in the ' + this.data1.clinics[0].name + ', ' + this.data1.clinics[0].area + ', ' + this.data1.clinics[0].city + '. Services: ' + services;
        details.ogDescription = 'Book an appointment with Dr. ' + this.data1.name + ', ' + speciality + ' in the ' + this.data1.clinics[0].name + ', ' + this.data1.clinics[0].area + ', ' + this.data1.clinics[0].city + '. Services: ' + services;
      }
      this.Userservice.setDynamicMetaData(details);
      this.docName = this.data1.name;
      this.imageUrl = this.data1.image;
      let experince = this.data1['practice_start_year'];
      this.docGender = this.data1.gender
      if (experince != 0) {
        let date = new Date();
        let year = date.getFullYear();
        this.exp = year - experince;
      } else {
        this.exp = 0;
      }
      this.description = this.data1['description'] ? this.data1['description'] : ''
      this.doctorEducation = this.data1['education'] ? this.data1['education'] : ''
      this.languages = this.data1.languages.map(item => item.name);
      this.specialities = this.data1.specializations;
      this.services = this.data1.services;
      this.clinicCount = this.data1.clinics.length;
      if(this.clinicCount > 0) {
        this.isClaimed = this.data1.clinics[0].is_subscribed;
      }
      this.docId = this.data1.id;
      this.docUrl = this.data1.nameUrl;
      this.clinicsList();
    })
  }

  clinicsList() {
    this.Userservice.clinicDetails(this.docId).subscribe(response => {
      if (response != null && response['details'].length) {
        this.clinicInfo = response['details'];
        this.clinicInfoSpinner = false;
      }
      this.clinicInfoSpinner = false
    })
  }

  getPopupSlots(id, showClinic = 0, showOnline = 0) {
    this.clearArraydata('inclinic');
    this.clearArraydata('vc');
    this.Userservice.clinicDetails(this.docId).subscribe(response => {
      response['details'] = response['details'].filter(i => i.id == id);
      this.clinicDoctor = response['details'][0];
      this.clinicId = this.clinicDoctor.id;
      this.inClinic = this.clinicDoctor.isInClinic;
      this.isOnline = this.clinicDoctor.isOnline;
      this.isOnCall = this.clinicDoctor.onCall;
      this.showClinic = showClinic;
      this.showOnline = showOnline;
      this.hospitalName = this.clinicDoctor.name;
      let today = this.datePipe.transform(new Date(),"yyyy-MM-dd");
      if(this.inClinic && showClinic) {
        // this.clearArraydata('inclinic');
        this.datePicker = null;
        this.availableSlots(today, 'inclinic');
        setTimeout(() => {
          this.calendar.value = null;
        }, 100);
      }
      if(this.isOnline && showOnline) {
        // this.clearArraydata('vc');
        this.datePickerVC = null;
        this.availableSlotsVC(today, 'vc');
        setTimeout(() => {
          this.calendarVC.value = null;
        }, 100);
      }
    })
  }

  availableSlots(date: any, slot: any) {
    this.Userservice.getClinicslots(this.docId, this.clinicId, date, slot).subscribe((response) => {
      if (slot == "inclinic") {
        if(response['details']['status']==false){
          this.morningSlotsLength = 0;
          this.afternoonSlotsLength = 0;
          this.eveningSlotsLength = 0;

          this.inclinicSpinner = this.vcSpinner = false;
          this.morningSlots = [];
          this.afternoonSlots = [];
          this.eveningSlots = [];
        }else{
          this.morningSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningSlotsLength = response['details'][0]['evening']['length'];

          this.inclinicSpinner = this.vcSpinner = false;
          this.morningSlots = this.timeConversion(response['details'][0]['morning']);
          this.afternoonSlots = this.timeConversion(response['details'][0]['afternoon']);
          this.eveningSlots = this.timeConversion(response['details'][0]['evening']);
        }
      }
    })
  }

  availableSlotsVC(date: any, slot: any) {
    this.Userservice.getClinicslots(this.docId, this.clinicId, date, slot).subscribe((response) => {
      if (slot == "vc") {
        if(response['details']['status']==false){
          this.morningVCSlotsLength = 0;
          this.afternoonVCSlotsLength = 0;
          this.eveningVCSlotsLength = 0;

          this.vcSpinner = this.inclinicSpinner = false;
          this.morningVCSlots = [];
          this.afternoonVCSlots =[];
          this.eveningVCSlots = [];
          
        }else{
          this.morningVCSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonVCSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningVCSlotsLength = response['details'][0]['evening']['length'];

          this.vcSpinner = this.inclinicSpinner = false;
          this.morningVCSlots = this.timeConversion(response['details'][0]['morning']);
          this.afternoonVCSlots = this.timeConversion(response['details'][0]['afternoon']);
          this.eveningVCSlots = this.timeConversion(response['details'][0]['evening']);
        }
      }
    })
  }

  timeConversion(slot: any = []) {
    while (slot.length >= 1) {
      let convertedTime: any[] = [];
      for (let i = 0; i < slot['length']; i++) {
        this.time = slot[i].split(":", 3);
        var ampm = this.time[0] >= 12 ? 'PM' : 'AM';
        ampm == 'PM' && this.time[0] == 12 ? this.hours = 12 : this.hours = this.time[0] % 12;
        this.hours < 10 ? this.hours = '0' + this.hours : this.hours;
        convertedTime.push(this.hours + ':' + this.time[1] + ' ' + ampm);
      }
      return convertedTime;
    }
  }

  today(slot: string) {
    this.datePicker = null;
    this.calendar.value = null;
    this.clearArraydata(slot);
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowApply = false;
    this.todayApply = true;
    this.availableSlots(this.date, slot);
  }

  todayVC(slot: string) {
    this.datePickerVC = null;
    this.calendarVC.value = null;
    this.clearArraydata(slot);
    this.dateVC = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowApplyVC = false;
    this.todayApplyVC = true;
    this.availableSlotsVC(this.dateVC, slot);
  }

  todayOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowCall = false;
    this.todayCall = true;
  }

  tomorrow(slot: string) {
    this.datePicker = null;
    this.calendar.value = null;
    this.clearArraydata(slot);
    let today = new Date();
    this.date = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayApply = false;
    this.tomorrowApply = true;
    this.availableSlots(this.date, slot);
  }

  tomorrowVC(slot: string) {
    this.datePickerVC = null;
    this.calendarVC.value = null;
    this.clearArraydata(slot);
    let today = new Date();
    this.dateVC = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayApplyVC = false;
    this.tomorrowApplyVC = true;
    this.availableSlotsVC(this.dateVC, slot);
  }

  tomorrowOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    let today = new Date();
    this.date = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = true;
  }

  selectedDate(event: any, slot: string) {
    this.clearArraydata(slot);
    this.dateHighlight = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayApply = false;
    this.tomorrowApply = false;
    this.availableSlots(this.date, slot);
  }

  selectedVCDate(event: any, slot: string) {
    this.clearArraydata(slot);
    this.dateHighlightVC = true;
    this.dateVC = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayApplyVC = false;
    this.tomorrowApplyVC = false;
    this.availableSlotsVC(this.dateVC, slot);
  }

  selectedOnCallDate(event: any, slot: string) {
    this.dateHighlightCall = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = false;
  }

  Time(index, time, slot) {
    this.slotType = slot;
    this.selectedVCIndex = -1;
    this.selectedVCTime = null;
    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.btnDisable = true;
    } else {
      this.selectedIndex = index;
      this.selectedTime = time;
      this.btnDisable = false;
    }
  }

  VCTime(index, time, slot) {
    this.slotType = slot;
    this.selectedIndex = -1;
    this.selectedTime = null;
    if (this.selectedVCIndex == index) {
      this.selectedVCIndex = -1;
      this.selectedVCTime = null;
      this.btnDisable = true;
    } else {
      this.selectedVCIndex = index;
      this.selectedVCTime = time;
      this.btnDisable = false;
    }
  }

  CallTime(index, time, slot) {
    this.slotType = slot;
    if (this.selectedCallIndex == index) {
      this.selectedCallIndex = -1;
      this.selectedCallTime = null;
      this.btnDisable = true;
    } else {
      this.selectedCallIndex = index;
      this.selectedCallTime = time;
      this.btnDisable = false;
    }
  }

  clearArraydata(slot: string) {
    this.btnDisable = true;
    if(slot == 'inclinic') {
      this.morningSlots = [];
      this.afternoonSlots = [];
      this.eveningSlots = [];
      this.morningSlotsLength = 0;
      this.afternoonSlotsLength = 0;
      this.eveningSlotsLength = 0;
      this.inclinicSpinner = true;
      this.todayApply = true;
      this.tomorrowApply = false;
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.dateHighlight = false;
    } else if(slot == 'vc') {
      this.morningVCSlots = [];
      this.afternoonVCSlots = [];
      this.eveningVCSlots = [];
      this.morningVCSlotsLength = 0;
      this.afternoonVCSlotsLength = 0;
      this.eveningVCSlotsLength = 0;
      this.vcSpinner = true;
      this.todayApplyVC = true;
      this.tomorrowApplyVC = false;
      this.selectedVCIndex = -1;
      this.selectedVCTime = null;
      this.dateHighlightVC = false;
    }
  }
    
  confirmAppointment() {
    setTimeout(() => {
      this.close.nativeElement.click();
      this.router.navigate(['appointment/', this.docUrl], {
        queryParams: {
          clinicId: this.clinicId,
          on: this.slotType == "vc" ? this.dateVC : this.date,
          at: this.slotType == "vc" ? this.selectedVCTime : this.selectedTime,
          slot: this.slotType
        }
      })
    }, 1000);
  }

  onCallAppointment() {
    setTimeout(() => {
      this.close.nativeElement.click();
      this.router.navigate(['appointment/', this.docUrl], {
        queryParams: {
          doctorId: this.docId,
          clinicId: this.clinicId,
          on: this.date,
          at: this.selectedCallTime,
          slot: this.slotType
        }
      })
    }, 1000);
  }

  get claim() {
    return this.claimProfileForm.controls;
  }

  get report() {
    return this.reportErrorForm.controls;
  }

  viewContact(clinicId) {
    $("#view-contact-"+clinicId).hide();
    $("#Contact-options-"+clinicId).show();
  }

  checkMobile(event) {
    let mobile = event.target.value;
    let regex = /^([6-9])\d{9}$/;
    if(regex.test(mobile)) {
      this.validMobile = false;
    } else {
      this.validMobile = true;
    }
  }

  sendOtp() {
    this.sendingOtp = true;
    let data = {
      mobile: this.claimProfileForm.value.mobile
    };
    this.Userservice.sendReminderOTP(data).subscribe(response => {
      if(response.details.status) {
        this.sendingOtp = false;
        this.isShow = true;
        this.otpSent = true;
        this.otpCount += 1;
        this.message = response.details.message;
        setTimeout(() => {
          this.message = '';
        }, 3000);
      }
    });
  }

  submitClaimProfile() {
    this.submitted = true;
    if (this.claim.mobile.valid && this.claim.name.valid && this.claim.email.valid && this.claim.otp.valid) {
      this.submitted = false;
      this.sendingOtp = true;
      let data = {
        mobile: this.claimProfileForm.value.mobile,
        otp: this.claimProfileForm.value.otp
      }
      this.Userservice.verifyReminderOTP(data).subscribe(response => {
        if(response == null) {
          this.sendingOtp = false;
          this.otpInvalid = true;
          setTimeout(() => {
            this.otpInvalid = false;
          }, 3000);
        } else if(response.details.status) {
          let details = {
            name: this.claimProfileForm.value.name,
            mobile: this.claimProfileForm.value.mobile,
            email: this.claimProfileForm.value.email,
            doctorUrl: this.docUrl,
            doctorId: this.docId
          };
          this.Userservice.claimProfile(details).subscribe(response => {
            if(response.details.status) {
              this.sendingOtp = false;
              this.message = 'Thanks for your contact';
              setTimeout(() => {
                this.message = '';
                this.validMobile = true;
                this.otpSent = false;
                this.isShow = false;
                this.claimProfileForm.reset();
                this.closeProfile.nativeElement.click();
              }, 3000);
            }
          });
        }
      });
    }
  }

  onChangeCheckBox(event) {
    if(this.report.phonenumber.value){
      this.report.correct_phonenumber.setValidators([Validators.required,Validators.pattern('[6-9]\\d{9}'),
        Validators.minLength(10), Validators.maxLength(10)]);
    }else{
      this.report.correct_phonenumber.clearValidators();
      this.report.correct_phonenumber.setValue('');
    }

    if(this.report.address.value){
      this.report.correct_address.setValidators([Validators.required]);
    }else{
      this.report.correct_address.clearValidators();
      this.report.correct_address.setValue('');
    }

    if(this.report.timings.value){
      this.report.correct_timings.setValidators([Validators.required]);
    }else{
      this.report.correct_timings.clearValidators();
      this.report.correct_timings.setValue('');
    }

    if(this.report.fee.value){
      this.report.correct_fee.setValidators([Validators.required]);
    }else{
      this.report.correct_fee.clearValidators();
      this.report.correct_fee.setValue('');
    }
  }

  sendErrorReport() {
    this.submitted = true;
    if(this.reportErrorForm.valid) {
      this.submitted = false;
      this.sendingOtp = true;
      let details = {
        doctorId: this.docId,
        name: this.docName,
        email: this.reportErrorForm.value.email,
        mobile: this.reportErrorForm.value.correct_phonenumber,
        address: this.reportErrorForm.value.correct_address,
        timings: this.reportErrorForm.value.correct_timings,
        fee: this.reportErrorForm.value.correct_fee,
        others: this.reportErrorForm.value.others,
        isDoctor: this.reportErrorForm.value.self_doctor ? 1 : 0,
        practice: this.reportErrorForm.value.not_practice ? 1 : 0
      }
      this.Userservice.reportError(details).subscribe(response => {
        if (response.details.status) {
          this.sendingOtp = false;
          this.message = 'Thanks for your report';
          setTimeout(() => {
            this.message = '';
            this.reportErrorForm.reset();
            this.closeReport.nativeElement.click();
          }, 3000);
        }
      });
    }
  }

  get applink() {
    return this.sendAppLinkForm.controls;
  }

  sendAppLink() {
    this.submitted = true;
    if (this.applink.mobile.valid) {
      this.submitted = false;
      this.sendLink = true;
      let data = {
        mobile: this.sendAppLinkForm.value.mobile.toString()
      };
      this.Userservice.sendAppLink(data).subscribe(response => {
        if (response.status) {
          this.sendLink = false;
          this.sentLink = true;
          setTimeout(() => {
            this.sendAppLinkForm.reset();
            this.sendLink = false;
            this.sentLink = false;
          }, 3000);
        }
      });
    }
  }

  ngOnDestroy() {
    sessionStorage.removeItem('userId1');
  }
}
