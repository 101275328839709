import { Component, OnInit } from '@angular/core';
import {ThyrocareService} from "../service/thyrocare.service";
import {ActivatedRoute} from "@angular/router";
import {CartService, Product} from "../service/cart.service";
import {UserService} from "../service/user.service";

@Component({
  selector: 'app-testdetails',
  templateUrl: './testdetails.component.html',
  styleUrls: ['./testdetails.component.css']
})
export class TestdetailsComponent implements OnInit {

  type: any;
  code: any;
  serviceData: any;
  cartCount: number = 0;
  success: boolean = false;
  groupMap: any = {};
  cartCodes: any = [];
  cartChildCodes: any = [];

  constructor(
    private thyrocare: ThyrocareService,
    public cartService: CartService,
    private activeRoute: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    var details = {
      metaTitle: 'Diagnostic Center | Gigadocs',
      metaDescription: 'Get your Diagnostic Tests carried out from the comfort of your home',
      ogTitle: 'Diagnostic Center | Gigadocs',
      ogDescription: 'Get your Diagnostic Tests carried out from the comfort of your home',
    };
    this.userService.setDynamicMetaData(details);
    this.activeRoute.params.subscribe(params => {
      this.type = params['type'];
      this.code = params['code'];
      this.thyrocare.loginThyrocare().subscribe(response => {
        if (response.apiKey) {
          this.thyrocare.apiKey = response.apiKey;
          this.thyrocare.token = response.accessToken;
          if (this.type == 'offer') {
            this.getOffers();
          } else if (this.type == 'profile') {
            this.getPackages();
          } else if (this.type == 'test') {
            this.getTests();
          }
        }
      })
      this.cartCount = this.cartService.cartItemsCount();
      this.cartCodes = this.cartService.getCartCodes();
      this.cartChildCodes = this.cartService.getCartChildCodes();
    })
  }

  getOffers() {
    this.thyrocare.getOffers().subscribe(response => {
      if(response.master.offer) {
        let packages;
        packages = response.master.offer;
        this.serviceData = packages.filter(item => item.code == this.code)[0];
        this.serviceData.childs.forEach(item =>{
          this.groupMap[item.groupName] = this.serviceData.childs.filter(i => i.groupName == item.groupName);
        });
        delete this.groupMap["SUBSET"];
        this.success = true;
      }
    })
  }

  getPackages() {
    this.thyrocare.getPackages().subscribe(response => {
      if(response.master.profile) {
        let packages;
        packages = response.master.profile;
        this.serviceData = packages.filter(item => item.code == this.code)[0];
        this.serviceData.childs.forEach(item =>{
          this.groupMap[item.groupName] = this.serviceData.childs.filter(i => i.groupName == item.groupName);
        });
        delete this.groupMap["SUBSET"];
        this.success = true;
      }
    })
  }

  getTests() {
    this.thyrocare.getTests().subscribe(response => {
      if(response.master.tests) {
        let tests;
        tests = response.master.tests;
        this.serviceData = tests.filter(item => item.code == this.code)[0];
        this.success = true;
      }
    })
  }

  handleAddToCart(type, profile, alertTemplate, offerExistsTemplate) {
    var childs = [];
    if (type == 'profile' || type == 'offer') {
      childs = profile.childs;
    } else if (type == 'test') {
      childs =[{
        name: profile.name,
        code: profile.code,
        groupName: profile.groupName,
        type: profile.type
      }];
    }
    let product = new Product(profile.code, profile.name, profile.rate.offerRate, type, childs, profile.testNames);
    this.cartService.addToCart(product, alertTemplate, offerExistsTemplate);
    this.cartCount = this.cartService.cartItemsCount();
    this.ngOnInit();
  }

  checkTestInCart(product):boolean{
    let isIncart = false;
    if(this.cartCodes.indexOf(product) !== -1) {
      isIncart = true;
    }
    return isIncart;
  }

  checkProfileInCart(product):boolean{
    let isIncart = false;
    if(this.cartCodes.indexOf(product) !== -1) {
      isIncart = true;
    }
    return isIncart;
  }

  checkPackageInCart(product):boolean{
    let isIncart = false;
    if(this.cartCodes.indexOf(product) !== -1) {
      isIncart = true;
    }
    return isIncart;
  }

  checkTestIsIncluded(code):boolean {
    let isIncluded = false;
    if(this.cartChildCodes.indexOf(code) !== -1) {
      isIncluded = true;
    }
    return isIncluded;
  }

  checkProfileIsIncluded(code):boolean {
    let isIncluded = false;
    if(this.cartChildCodes.indexOf(code) !== -1) {
      isIncluded = true;
    }
    return isIncluded;
  }

  removeFromCart(id) {
    this.cartService.removeItemByCode(id);
    this.ngOnInit();
  }

  closeModal() {
    this.cartService.modalRef.hide();
  }

}
