
<nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="user">
    <div class="navbar-nav">
        <a class="nav-item nav-link" href="#" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
        
        <a class="nav-item nav-link mr-auto" href="javascript:void(0)" (click)="logout()">Logout</a>
    </div>
</nav>

<div class="p-4">
    <div class="container">
        <h1>Hi {{user['details']['name']}}</h1>
    </div>
</div>

