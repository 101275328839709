<app-header></app-header>
<div class="reset">
  <div class="container">
    <div class="row vdivide">
        <div class="col-md-6">
            <div class="reset-form">
                <form [formGroup] = 'resetPassForm'>
                  <div *ngIf="isLoading" class="alert alert-warning">
                    <span class="spinner-border spinner-border-sm"></span>
                    Please wait, Processing...
                  </div>
                  <div *ngIf="passwordUpdated" class="alert alert-success">
                    Password successfully updated.Please login!
                  </div>
                    <h4 class="font-weight-bold">Reset Password</h4>
                    <div class="form-group">
                        <label> New Password</label>
                        <input type="password" class="form-control" name="password" placeholder="Enter New Password"
                               required="required" formControlName="newPassword" [ngClass]="{'is-invalid': submitted && passForm.newPassword.errors}"/>
                        <div *ngIf="submitted && passForm.newPassword.errors" class="text-danger">
                          <small *ngIf="passForm.newPassword.errors.required">Enter New Password</small>
                          <small *ngIf="passForm.newPassword.errors?.minlength">Password should be minimum 6 characters</small>
                          <small *ngIf="passForm.newPassword.errors?.pattern">Spaces are not allowed</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Confirm New Password</label>
                        <input type="password" class="form-control" name="confirm_password" placeholder="Re-enter New Password"
                               formControlName="reenterPassword" required="required" [ngClass]="{'is-invalid': submitted && passForm.reenterPassword.errors}"/>
                        <div *ngIf="submitted && passForm.reenterPassword.errors" class="text-danger">
                          <small *ngIf="passForm.reenterPassword.errors.required">Enter Confirm Password</small>
                          <small *ngIf="passForm.reenterPassword.errors?.mustMatch">Password is mismatch</small>
                          <small *ngIf="passForm.reenterPassword.errors?.pattern">Spaces are not allowed</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-light btn-lg"  (click)="resetPassword()">Update Password</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-6">
					<!-- -carousel bootstrap -->
					<div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="2200">
						<!-- Indicators -->
					  <ol class="carousel-indicators">
							<li data-target="#myCarousel" data-slide-to="0" class="active"></li>
							<li data-target="#myCarousel" data-slide-to="1"></li>
							<li data-target="#myCarousel" data-slide-to="2"></li>
							</ol>
					  <div class="carousel-inner" role="listbox">
					    <div class="carousel-item active">
					      <img class="img-center"  src="../../assets/images/feature.png" alt="First slide">
					        <h5 class="text-center">Manage all records in one place.</h5>
					    </div>
					    <div class="carousel-item">
					      <img class="img-center" src="../../assets/images/feature1.png" alt="Second slide">
					         <h5 class="text-center">Manage all records in one place.</h5>
					    </div>
					    <div class="carousel-item">
					      <img class="img-center" src="../../assets/images/medical3.png"  alt="Third slide">
					  		 <h5 class="text-center">Manage all records in one place.</h5>
					    </div>
					  </div>
					  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
					    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
					    <span class="sr-only">Previous</span>
					  </a>
					  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
					    <span class="carousel-control-next-icon" aria-hidden="true"></span>
					    <span class="sr-only">Next</span>
					  </a>
					</div>
				</div>
    </div>
</div>
</div>

<div class="app-link">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <img src="../../assets/images/logo.png"  alt="GigaDocs"/>
        <p class="font-weight-bold"> Mobile App</p>
      </div>
      <div class="col-md-3">
        <a href="https://play.google.com/store/apps/details?id=com.gigadocs.app">
          <img src="../../assets/images/play-store.png"class= "w-75 h-75" alt="GigaDocs"/>
        </a>
      </div>
      <div class="col-md-3">
        <a href="https://apps.apple.com/us/app/gigadocs/id1447077786">
          <img src="../../assets/images/app-store.png" class= "w-75 h-75" alt="GigaDocs"/>
        </a>
      </div>
    </div>
  </div>
</div>
<app-footer lat="17.4611" long="78.355797"></app-footer>

