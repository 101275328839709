import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../service/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import * as $ from 'jquery';
import {GoogleMap, MapInfoWindow, MapMarker} from "@angular/google-maps";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-clinicspagination',
  templateUrl: './clinicspagination.component.html',
  styleUrls: ['./clinicspagination.component.css']
})
export class ClinicspaginationComponent implements OnInit {

  pageNo:any = 1;
  lat: any;
  long: any;
  city: any;
  area: any;
  keyword: any;
  specs: any;
  type: any = 'clinic';

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow
  center: google.maps.LatLngLiteral
  markers:any=[];
  infoContent = '';
  infoContent1 = '';
  contentLoaded=false;
  addressCoords:any=[];
  mapDisplay:boolean=false;
  results:boolean=true;
  resultsFound: boolean=false;
  resultsNotFound: boolean=false;
  notaResponsiveButton:boolean;
  responsiveButton:boolean;
  public errorMsg:any;
  resultsDisplay: any =[]
  pageResults: any =[]
  address:any = [];
  docDetails:boolean=false;
  emptyresults:any[];
  urlparameters: any;
  scrHeight:any;
  scrWidth:any;
  mobileWidth:number  = 1024;
  width:number = window.outerWidth;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.screenDimensions();
  }

  constructor(
    private userService:UserService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.emptyresults = Array(5);
    this.userService.setDocTitle('Clinics - Gigadocs');
    this.route.params.subscribe(param => {
      var re = /[-]/g;
      this.city = param['city'];
      this.keyword = param['specs'];
      this.specs = param['specs'].replace(re, ' ');
      if (param['area']) {
        this.area = param['area'];
      }
      this.params();
      this.latLong();
    });
  }

  latLong() {
    this.userService.getCoords(this.city).subscribe(response => {
      if (response.details.length > 0) {
        let record = response.details.filter(item => item.name.toLowerCase() == this.city && item.city.toLowerCase() == this.city);
        if (record.length > 0) {
          this.resultsFound = true;
          this.lat = record[0]['latitude'];
          this.long = record[0]['longitude'];
          this.updatedResults(this.pageNo);
          this.center = {
            lat: this.lat,
            lng: this.long
          }
        } else {
          this.resultsNotFound = true;
        }
      } else {
        this.resultsNotFound = true;
      }
    },
    error => {
      this.errorMsg = error;
      if (this.errorMsg) {
        this.resultsNotFound = true;
        this.resultsFound = false;
      }
    });
  }

  updatedResults(pageNo) {
    this.resultsDisplay.splice(0, this.resultsDisplay.length);
    this.address.splice(0, this.address.length);
    this.userService.getClinics(this.lat, this.long, this.specs, pageNo - 1, this.type).subscribe(response => {
      this.pageResults = response;
      this.resultsDisplay.splice(0, this.resultsDisplay.length);
      if (this.pageResults) {
        for (let i = 0; i < this.pageResults['details']['length']; i++) {
          this.resultsDisplay.push(this.pageResults['details'][i])
          this.address.push({
            clinicId: this.pageResults['details'][i]['clinicId'],
            clinicName: this.pageResults['details'][i]['clinicName'],
            fullAddress: this.pageResults['details'][i]['clinicAddress'].replace('#', '') + ','
              + this.pageResults['details'][i]['clinicArea'] + ','
              + this.pageResults['details'][i]['clinicCity']
          })
        }
        this.contentLoaded = true;
      }
    },
    error => {
      this.errorMsg = error;
      if (this.errorMsg) {
        this.resultsNotFound = true;
        this.resultsFound = false;
      }
    })
  }

  pgcontrolsroute(pageNo) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (this.city && this.area && this.city != this.area) {
      this.router.navigateByUrl(this.city + '/' + this.keyword + '/' + this.area + '/clinics/' + pageNo);
    } else if (this.city) {
      this.router.navigateByUrl(this.city + '/' + this.keyword + '/clinics/' + pageNo);
    }
  }

  listView() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    $('.sr-left').show();
    $('#sr-map').css({'display': 'none', 'height': 0});
    $('#mapview').removeClass('hidden');
    $('#listview').addClass('hidden');
  }

  screenDimensions() {
    if (this.scrWidth <= this.mobileWidth && this.scrWidth >= 767) {
      this.mapDisplay = false;
      this.results = true;
      this.listView();
    } else {
      this.mapDisplay = true;
      this.results = true;
      this.docDetails = false;
    }
    if (this.scrWidth < 766) {
      this.notaResponsiveButton = false;
      this.responsiveButton = true;
    } else {
      this.notaResponsiveButton = true;
      this.responsiveButton = false;
      $('.sr-card').removeClass('selected');
    }
  }

  params() {
    this.route.params.subscribe(params => {
      this.urlparameters = params;
      this.contentLoaded = false;
      if (this.width <= this.mobileWidth) {
        this.mapDisplay = false;
        this.results = true;
      } else {
        this.mapDisplay = true;
        this.results = true;
      }
      this.docDetails = false;
      if (this.city == 'home') {
        this.resultsNotFound = true;
        this.resultsFound = false;
      }
    })
  }

}
