import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from '../service/user.service';
import { MustMatch } from '../customFiles/must-match.validator';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-doctorreg',
  templateUrl: './doctorreg.component.html',
  styleUrls: ['./doctorreg.component.css']
})
export class DoctorregComponent implements OnInit{

  councilData: any[]=[];
  registerForm: FormGroup;
  loading=false;
  show=false;
  editable=false;

  constructor(
    private router: Router,
    private Userservice:UserService,
    private formBuilder:FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.Userservice.getMedicalCouncils().subscribe(response => {
      this.councilData = response.details;
    });
    this.registerForm = this.formBuilder.group({
      accountType :['DOCTOR', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required,Validators.email]],
      councilId: ['', [Validators.required]],
      license: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      referralCode: ['']
    });
  }

  get form() {
    return this.registerForm.controls;
  }

  onSubmit(){
    if (this.registerForm.invalid) {
      return;
    } else {
      this.loading=true;
      let number = localStorage.getItem('mobile');
      var details ={
        mobile:number,
        email: this.registerForm.value.email,
        name: this.registerForm.value.name,
        license:this.registerForm.value.license,
        accountType: this.registerForm.value.accountType,
        password: this.registerForm.value.password,
        councilId: this.registerForm.value.councilId,
        referralCode: this.registerForm.value.referralCode
      };
      this.Userservice.register(details).subscribe(response => {
        if(response.status) {
          this.toastr.success('', response.message + ' ' + response.points, {
            timeOut: 3000
          });
          this.Userservice.afterLogin(response);
          this.registerForm.reset();
          if (this.Userservice.userValue) {
            let userToken = this.Userservice.userValue['details']['userId'];
            window.location.href = this.Userservice.dashboardURL + 'user/profile/?token=' + userToken;
          }
        }
      },
      error => {
        this.toastr.error('', error, {
          timeOut: 3000
        });
        this.loading = false;
      });
    }
  }

  terms() {
    this.router.navigateByUrl('home/terms');
  }

}
