<a class="nav-link" href="#newModal" class="trigger-btn"  routerLinkActive="active" data-toggle="modal" id="regist" (click)="clearForm()">
    Signup
</a>

<!--  New Registration modal -->

<div id="newModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-new modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" #closeNewRegist (click)="clearForm()" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
				<h4 class="modal-title">
					New Registration
				</h4>
			</div>
			<div class="modal-body">
				<div class="new-modal-wrap">
					<form id="new-form" [formGroup]="mobileVerficationForm">
						<div class="row">
							<div class="col-md-10">
								<p *ngIf="show">Please enter your mobile number and verify the OTP.</p>
								<div #alert *ngIf="error" class="alert alert-danger">
									Number already Registered, Please Login.
								</div>
							</div>
							<div class="col-md-2">
								<img src="assets/images/otp.png" class="img-center " alt="Pharmacy"/>
							</div>
						</div>
						<div class="form-group required">
							<label class="font-weight-bold control-label">Mobile Number</label>
							<input type="text" class="form-control" formControlName="mobile" name="mobile"
								   placeholder="Enter your mobile number" [ngClass]="{ 'is-invalid': mobileSubmitted && form.mobile.errors }">
							<div class="text-danger" *ngIf="mobileSubmitted">
								<small *ngIf="form.mobile.errors?.required">
									*Mobile Number required
								</small>
								<small *ngIf="form.mobile.errors?.pattern || form.mobile.errors?.minlength || form.mobile.errors?.maxlength">
									*Invalid Mobile number
								</small>
							</div>
              
						</div>
						<div class="form-group">

						<a  (click)="mobileSubmit()"><input type="button"  data-toggle="modal"  class="btn btn-primary pl-5 pr-5"
							 value="Submit" [disabled]="sendingOtp">
							 <span *ngIf="sendingOtp" class="spinner-border spinner-border-sm"></span>
						</a>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer">
				<p>Already have an account?</p>
					<a href="loginModal" data-toggle="modal" data-target="#loginModal" id="try" data-dismiss="modal" (click)="clearForm()">
						<button class="btn btn-primary">Login</button>
					</a>
			</div>
		</div>
	</div>
</div>
