<div class="page-wrap d-flex flex-row align-items-center" *ngIf="resultsNotFound">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center">
        <span class="display-1 d-block">404</span>
        <div class="mb-4 lead">The page you are looking for was not found.</div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="resultsFound">

  <app-secondaryheader location="{{city}}" area="{{area}}" speciality="{{specs}}"></app-secondaryheader>

  <div class="page-content ">
    <div class="search-results">
      <div class="sr-left" *ngIf="results">
        <div class="row" style="margin: 0">
          <div class="col-6" *ngIf="!contentLoaded">
            <ngx-skeleton-loader
              animation="pulse"
              [theme]="{ background: '#dedfe1' , width: '100px', 'border-radius': '5', height: '15px' }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-12" *ngIf="contentLoaded">
            <h3 class="sr-counts" >{{pageResults['count']}}+ Clinics Named With {{specs | camelcase}} </h3>
          </div>
        </div>
        <div *ngIf="!contentLoaded">
          <div class="emptycard" *ngFor="let item of emptyresults; let i = index">
            <div class="first-section-wrapper">
              <div class="avatar">
                <ngx-skeleton-loader
                  appearance="square"
                  animation="pulse"
                  [theme]="{background: '#dedfe1' ,width: '80px', height: '80px'}">
                </ngx-skeleton-loader>
              </div>
              <div class="avatar-title">
                <div>
                  <ngx-skeleton-loader
                    animation="pulse"
                    [theme]="{background: '#dedfe1',width: '200px',
                                                'border-radius': '5',height: '15px',
                                                'margin-bottom': '10px'}">
                  </ngx-skeleton-loader>
                </div>
                <div>
                  <ngx-skeleton-loader
                    animation="pulse"
                    [theme]="{ background: '#dedfe1' , width: '170px', 'border-radius': '5', height: '15px' }"
                  ></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="contentLoaded">
          <div class="sr-lists" >
            <div class="sr-card" *ngFor="let item of resultsDisplay | paginate: { itemsPerPage: 10,currentPage: pageNo  ,
                            totalItems: pageResults['count']};let i=index" >
              <div class="row">
                <div class="sr-card-left">
                  <div class="sr-img">
                    <img class="img-fluid" src="{{item.clinicImage}}" onerror="src='assets/images/clinic-logo-2.png'"
                         alt="{{item.clinicName}}"/>
                  </div>
                </div>
                <div class="sr-card-content">
                  <div class="sr-info">
                    <a routerLink="/clinicdetails/{{city}}/{{item.clinicId}}" class="sr-title">
                      <h4>{{item.clinicName}}</h4>
                    </a>
                    <div class="sr-clinic-info">
                      <span>Available Doctors : {{item.doctors.length}}</span><br>
                      <span>{{item.clinicAddress}}</span><br>
                      <span>{{item.clinicArea}},{{item.clinicCity}}</span>
                    </div>
                  </div>
                </div>
                <div class="sr-card-right">
                  <div class="sr-actions">
                    <a class="btn btn-second" routerLink="/clinicdetails/{{city}}/{{item.clinicId}}">View Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <pagination-controls  class="text-center"
                            *ngIf="contentLoaded"
                            (pageChange)="pageNo = $event"
                            (click)="pgcontrolsroute(pageNo)"
                            previousLabel=""
                            nextLabel=""
                            maxSize="6"
      >
      </pagination-controls>
    </div>
      <!--<div class="sr-right">
        <a href="javascript:void(0)" (click)="listView()" class="btn-mapview btn-listview hidden" id="listview">
          <i class="ic-list"></i>&nbsp;
          List View
        </a>
        <div id="sr-map" *ngIf="mapDisplay">
          <ngx-skeleton-loader *ngIf="!contentLoaded"
                               appearance="square"
                               animation="pulse"
                               [theme]="{background: '#dedfe1' ,width: '100%', height: '640px'}">
          </ngx-skeleton-loader>
          <app-map-view *ngIf="contentLoaded" [address]="address" [center]="center"></app-map-view>
        </div>
      </div>-->
    </div>
  </div>

  <app-footer  city="{{city}}" area="{{city}}" specality="{{specs}}" lat="{{center.lat}}" long="{{center.lng}}"></app-footer>

</div>
