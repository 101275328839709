import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../service/user.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-thyrocaresuccess',
  templateUrl: './thyrocaresuccess.component.html',
  styleUrls: ['./thyrocaresuccess.component.css']
})
export class ThyrocaresuccessComponent implements OnInit, OnDestroy {

  url: string;
  data: string;
  datetime: any;
  address: string;
  appointmentId: any;
  refOrderId: any;
  isNew: string;

  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    if (this.userService.userValue) {
      let userToken = this.userService.userValue['details']['userId'];
      this.url = this.userService.dashboardURL + 'user/labtest?token=' + userToken;
    }
    this.isNew = sessionStorage.getItem('isNew');
    this.activatedroute.params.subscribe(params => {
      this.appointmentId = params['appointmentId'];
    })
    this.userService.currentParams.subscribe(response => {
      this.data = response;
      this.refOrderId = this.data['order_id'];
      this.datetime = this.data['datetime'];
      this.address = this.data['address'];
    });
  }

  createPassword(){
    sessionStorage.setItem('userId', sessionStorage.getItem('userId1'));
    this.router.navigate(['/resetpassword']);
  }

  ngOnDestroy(){
    sessionStorage.removeItem('userId1');
  }

}
