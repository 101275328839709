<header>
    <nav class="navbar navbar-expand-lg">
        <a class="navbar-brand" href="">
            <img src="assets/images/logo.png"  alt="GigaDocs" />
        </a>
        <div class="header-searchbar dropdown">
          <button class="searched-values dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="ic-search"></i>
            <span *ngIf="specialityInHeader!=undefined">{{specialityInHeader | camelcase}} in
              <span *ngIf="areaInHeader && areaInHeader.toLowerCase() != locationInHeader.toLowerCase()">{{areaInHeader | camelcase}}, </span>
              <span>{{locationInHeader | camelcase}}</span>
            </span>
            <span>
              <span *ngIf="specialityInHeader==undefined">Search here for more doctors </span>
            </span>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div class="search-form">
              <div class="search-location">
                <i class="ic-gps mr-3"></i>
                <input [(ngModel)]="area"
                       [typeahead]="filteredArea$"
                       (typeaheadOnSelect)="selectedArea($event)"
                       [typeaheadMinLength]="2"
                       [typeaheadItemTemplate]="areaItemTemplate"
                       [typeaheadOptionsLimit]="15"
                       typeaheadOptionField="name"
                       placeholder="Location"
                       class="searchAreaTextbox">
                <ng-template #areaItemTemplate let-brand="item">
                  <div class="ui-helper-clearfix">
                    <div style="margin:5px 5px 0 0;font-size: 15px;word-break: normal;white-space: normal;">
                      {{brand.name | camelcase}}
                    </div>
                    <div *ngIf="brand.name != brand.city" style="font-size:12px;margin-top:8px">
                      <b>{{brand.city | camelcase}}</b>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="search-keyword">
                <i class="ic-search"></i>
                <input [(ngModel)]="speciality"
                       [typeahead]="filteredSpeciality$"
                       (typeaheadOnSelect)="selectedSpec($event)"
                       [typeaheadMinLength]="2"
                       [typeaheadItemTemplate]="specItemTemplate"
                       [typeaheadOptionsLimit]="15"
                       typeaheadOptionField="name"
                       placeholder="Search Speciality, service, symptoms, clinic, doctor,  etc"
                       class="searchSpecialityTextbox">
                <ng-template #specItemTemplate let-brand="item">
                  <div class="ui-helper-clearfix">
                    <div style="float:left;margin:5px 5px 0 0;font-size: 15px;word-break: normal;white-space: normal;">
                      {{brand.name}}
                    </div>
                    <div style="font-size:12px;float:right;margin-top:8px">
                        <b *ngIf = " brand.type == 'clinic' && brand.area == 'clinic' || brand.type == 'doctor' && brand.speciality == 'doctor'" innerHTML="{{searchicon}}"></b>
                        <b *ngIf = "brand.type == 'clinic' && brand.area != 'clinic'">{{brand.area | camelcase}}</b>
                        <b *ngIf = "brand.type == 'doctor' && brand.speciality != 'doctor'">{{brand.speciality | camelcase}}</b>
                        <b *ngIf = "brand.type != 'clinic' && brand.type != 'doctor'">{{brand.type | camelcase}}</b>
                        <b *ngIf = "brand.speciality == ''">{{brand.type | camelcase}}</b>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <button class="navbar-toggler" type="button">
            <span class="toggler-bar"></span>
            <span class="toggler-bar"></span>
            <span class="toggler-bar"></span>
        </button>
        <div class="collapse navbar-collapse nav-flexend" id="navbarNav">
            <div class="drawer-top visible-mobile">

            </div>
            <ul class="navbar-nav">
                <li class="nav-item visible-mobile">
                    <a class="nav-link" href="#">Home</a>
                </li>
                <li class="nav-item visible-mobile">
                    <a class="nav-link" href="#">Features</a>
                </li>
                <li class="nav-item visible-mobile">
                    <a class="nav-link" href="#">Why Us</a>
                </li>
                <li class="nav-item ">
                    <!--<a class="nav-link" href="#loginModal" class="trigger-btn" routerLinkActive="active" data-toggle="modal" id="login" *ngIf="notLoggedin">Login</a>-->
                    <app-login *ngIf="notLoggedin" [modalId]="'loginModal'" (click)="loginCheck()"></app-login>
					<a href="{{userToken}}" class="nav-link" *ngIf="isLoggedin">Account</a>
				</li>
                <li class="nav-item">
                    <!--<a class="nav-link" href="#newModal" class="trigger-btn"  routerLinkActive="active" data-toggle="modal" id="regist" (click)="clearForm()" *ngIf="notLoggedin">Signup</a>-->
                    <app-signup *ngIf="notLoggedin"></app-signup>
					<a class="nav-link" href="javascript:void(0)" (click)="logout()" *ngIf="isLoggedin">Logout</a>
				</li>
                <li class="nav-item">
                    <a class="nav-link btn btn-first" (click)='openRequestDemoModal()' href="javascript:void(0)">
                        Request Demo
                    </a>
                </li>
            </ul>
        </div>
        <div class="drawer-overlay"></div>
    </nav>
</header>
<!-- Modal login
<div id="loginModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
    <div class="modal-dialog modal-login modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Login to your account</h4>
                <button type="button"  #closelogin class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <div class="login-modal-wrap">
                    <div #alert *ngIf="loginError" class="alert alert-danger">Invalid Credentials</div>
                    <form id="login-form" [formGroup]="loginForm">
                        <div class="form-group required">
                            <label class="control-label">Email or Mobile</label>
                            <input type="text" class="form-control" placeholder="Enter Email ID or Mobile Number"  formControlName="email" required="required">
                            <small class="text-danger" *ngIf="login.email.hasError('pattern') && (login.email.dirty || login.email.touched)">Please enter valid email/phone number</small>
                        </div>
                        <div class="form-group required">
                            <label class="control-label">Password</label>
                            <input type="password" class="form-control" formControlName="password" placeholder="Enter Password" required="required">
                            <small class="text-danger" *ngIf="login.password.touched && login.password.errors?.required">*Password required</small>
                        </div>
                        <div class="form-group">
                            <div class="row align-items-center">
                                <div class="col-sm-7">
                            <input type="button" class="btn btn-primary pl-5 pr-5" [disabled]="!loginForm.valid" (click)="signInUser()" value="Login">
                        </div>
                        <div class="col-sm-5">
                            <a href="#resetModal" data-toggle="modal" data-target="#forgotModal" id="reset">Reset password</a>
                        </div>
                        </div>

                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                    <div class="mr-4"> <h6>New User!</h6>
                    <p>Don't have an account yet?</p>
                    </div>
                    <a href="newModal" data-toggle="modal" data-target="#newModal" id="try" data-dismiss="modal" ><button class="btn btn-primary">Sign Up Now</button></a>
            </div>
        </div>
        </div>
   </div> -->

   <!--  Registration type modal
<div  id="registModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
    <div class="modal-dialog modal-regist modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                        Registration Type
                </h4>
                <button #closeregist type="button"  class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <div class="regist-modal-wrap">
                    <p>Please select the registartion type from the below:</p>
                           <div class="row caption">

                               <div class="col-4">
                                <img src="assets/images/user.png" class="img-center"  alt="user"/>
                                <p class="text-center"><button #user id="user" class="btn-sm btn-info"(click)="registrationType(user)">User</button></p>
                            </div>
                            <div class="col-4">
                                <img src="assets/images/doctor-male-default.png" class="img-center"  alt="doctor"/>
                                <p class="text-center"><button #doctor id="doctor" class="btn-sm btn-info"(click)="registrationType(doctor)">Doctor</button></p>
                            </div>
                            <div class="col-4">
                                <img src="assets/images/clinic2.png" class="img-center" alt="Clinic"/>
                                <p class="text-center"><button #clinic id="clinic" class="btn-sm btn-info"(click)="registrationType(clinic)">Clinic</button></p>
                            </div>
                        </div>	-->
                        <!--<div class="row caption">
                            <div class="col-6">
                                <img src="assets/images/pharmacy1.png" class="img-center" alt="Pharmacy" />
                                <p class="text-center"><button #pharmacy id="pharmacy" class="btn-sm btn-info"(click)="registrationType(pharmacy)">Pharmacy</button></p>
                            </div>
                            <div class="col-6">
                                <img src="assets/images/lab1.png" class="img-center" alt="Lab" />
                                <p class="text-center"><button #lab id="lab" class="btn-sm btn-info"(click)="registrationType(lab)">Lab</button></p>
                            </div>
                        </div> -->

               <!-- </div>
            </div>
        </div>
        </div>
   </div> -->

       <!--  New Registration modal
   <div id="newModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">

        <div class="modal-dialog modal-new modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                            New Registration
                    </h4>
                    <button type="button" #closeNewRegist class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="new-modal-wrap">
                        <form id="new-form" [formGroup]="mobileVerficationForm" >
                            <div class="row">
                                <div class="col-md-10">
                                    <p *ngIf="show">Please enter your mobile number and verify the OTP.</p>
                                    <div #alert *ngIf="error" class="alert alert-danger">Number already Registered, Please Login.</div>
                                </div>
                                <div class="col-md-2">
                                    <img src="assets/images/otp.png" class="img-center " alt="Pharmacy"/>
                                </div>
                            </div>
                            <div class="form-group required">
                                <label class="font-weight-bold control-label">Mobile Number</label>
                                <input type="text" class="form-control" formControlName="mobile" name="mobile" placeholder="Enter your mobile number" />
                                <small class="text-danger" *ngIf=" form.mobile.touched && form.mobile.errors?.required">*Mobile Number required</small>
                                <small class="text-danger" *ngIf="form.mobile.touched && form.mobile.errors?.pattern || form.mobile.errors?.minlength || form.mobile.errors?.maxlength ">*Invalid Mobile number</small>
                            </div>
                            <div class="form-group">

                            <a  (click)="mobileSubmit()"><input type="button"  data-toggle="modal"  class="btn btn-primary pl-5 pr-5"  value="Submit" [disabled]="!mobileVerficationForm.valid"></a>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                        <p>Already haven an account?</p>
                        <a href="loginModal" data-toggle="modal" data-target="#loginModal" id="try" data-dismiss="modal" ><button class="btn btn-primary">Login</button></a>
                </div>
            </div>
        </div>

   </div>  -->

   <!--  verify OTP modal
<div  id="verifyModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-verify modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                        Verify Mobile
                </h4>
                <button type="button" #closeVerify class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <div class="verify-modal-wrap">
                    <form id="verify-form" [formGroup]="otpVerficationForm">
                        <div class="row">
                            <div class="col-md-10">
                                <p *ngIf="otpMsg">we have sent an OTP to your mobile number.Please verify!</p>
                                <div #alert *ngIf="otperror" class="alert alert-danger">Invalid OTP</div>
                            </div>
                            <div class="col-md-2">
                                <img src="assets/images/ic-otp.png" class="img-center " alt="Pharmacy"/>
                            </div>
                        </div>
                        <div class="form-group required">
                            <label class="font-weight-bold control-label">OTP Code</label>
                            <input type="password" class="form-control" placeholder="Enter code" formControlName="otp" name="otp" required="required">
                            <small class="text-danger" *ngIf="otp.otp.touched && otp.otp.errors?.required">*OTP required</small>
                            <small class="text-danger" *ngIf="otp.otp.touched && otp.otp.errors?.pattern || otp.otp.errors?.minlength || otp.otp.errors?.maxlength">*Invalid OTP </small>
                        </div>
                        <div class="form-group">
                          <a (click)="otpSubmit()"><input type="button"   data-toggle="modal"  class="btn btn-primary pl-5 pr-5" value="Submit" [disabled]="!otpVerficationForm.valid"></a>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                    <p>Didn't get an OTP?</p>
                   <a href="">Resend OTP</a>
            </div>
        </div>
        </div>
   </div> -->
