import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../service/user.service';
import { ActivatedRoute, Router } from "@angular/router";
import {Observable, Observer, of, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, delay} from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import {CamelcasePipe} from "../camelcase.pipe";
import {HttpClient} from "@angular/common/http";
import { HostListener } from "@angular/core";

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy {
  private areas: Subscription;
  private spec: Subscription;
  searchicon:string = '<i class="gicon search"></i>';
  brand: string;
  data: any;
  lat:any = 17.4611;
  long:any = 78.355797;
  area:any = [];
  speciality:any;
  myJson:any = [];
  list=[];
  filteredAreas: any[];
  filteredSpeciality: any[];
  name: string;
  specs:string;
  city: string;
  coords=[];
  resultsFound:boolean=true;
  NotFound:boolean=false;
  docUrl: any;
  areaName: any;
  ipaddress: any;
  landingpage: any; 
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    // this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private activatedroute:ActivatedRoute, 
    private https:HttpClient,
    private Userservice:UserService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private camelcase: CamelcasePipe,
  ) {  }

  ngOnInit(): void {
    this.activatedroute.queryParams.subscribe(params => { 
      this.landingpage = params['next']; 
      if((this.landingpage != 'prescriptions') && this.Userservice.userValue == null){
        $('#popupModal').modal('show');
      }
    }) 
    this.getAreas();
    if(!localStorage.getItem('geolocation')) {
      this.getGeoLocation();
    } else{
      let geolocation = JSON.parse(localStorage.getItem('geolocation'));
      let re = /\s+/g;
      this.name = geolocation.name.toLowerCase().replace(re, "-");
      this.city = geolocation.city.toLowerCase().replace(re, "-");
      this.lat = geolocation.latitude;
      this.long = geolocation.longitude;
      this.area = geolocation.name;
    }
    this.Userservice.getIpAddress().subscribe(res => {
      this.ipaddress = res['ip'];
      if(localStorage.getItem(this.ipaddress) != 'true'){
        localStorage.setItem(this.ipaddress, 'true');
      }
    });
  }

  getGeoLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getAreaName(position);
        },
        (error) => {
          this.ipLookUp();
        }
      );
    } else {
      console.log('geolocation is not enabled on this browser')
      this.ipLookUp();
    }
  }

  getAreaName(position) {
    let currentLat = position.coords.latitude.toFixed(6);
    let currentLong = position.coords.longitude.toFixed(6);
    this.Userservice.getLocation(currentLat, currentLong).subscribe(
      response => {
        let storeLocation = {
          name: '',
          city: '',
          latitude: currentLat,
          longitude: currentLong
        };
        if(response.status == "OK") {
          let results = response.results[0].address_components;
          results.filter(item => {
            if (item.types.includes("sublocality_level_1")) {
              storeLocation.name = item.short_name;
            }
          })
          response.results.filter(item => {
            if(item.address_components[0].types.includes("plus_code")) {
              if(storeLocation.name == '') {
                storeLocation.name = item.address_components[1].short_name;
              }
              storeLocation.city = item.address_components[1].short_name;
            }
          })
          localStorage.setItem('geolocation', JSON.stringify(storeLocation));
          let re = /\s+/g;
          this.name = storeLocation.name.toLowerCase().replace(re, "-");
          this.city = storeLocation.city.toLowerCase().replace(re, "-");
          this.lat = storeLocation.latitude;
          this.long = storeLocation.longitude;
          this.area = storeLocation.name;
        }
      },
      (error) => {
        this.ipLookUp();
      }
    );
  }

  ipLookUp() {
    this.Userservice.getIpLookUp().subscribe(response => {
      let storeLocation = {
        name: response.city,
        city: response.city,
        latitude: response.latitude.toFixed(6),
        longitude: response.longitude.toFixed(6)
      };
      localStorage.setItem('geolocation', JSON.stringify(storeLocation));
      let re = /\s+/g;
      this.name = storeLocation.name.toLowerCase().replace(re, "-");
      this.city = storeLocation.city.toLowerCase().replace(re, "-");
      this.lat = storeLocation.latitude;
      this.long = storeLocation.longitude;
      this.area = storeLocation.name;
    });
  }

  getAreas() {
    this.Userservice.getArea().subscribe(result => {
      this.data = result;
      this.list = [];
      let input = this.data.details;
      for (let i = 0; i < input.length; i++) {
        this.myJson = {"name": input[i].name, "city": input[i].city};
        this.list.push(this.myJson);
      }
    })
  }

  filteredArea$ = new Observable((observer: Observer<string>) => {
    observer.next(this.area);
  }).pipe(
    switchMap((query: string) => {
      return of(this.list.filter(item => (item.name && item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        || item.city && item.city.toLowerCase().indexOf(query.toLowerCase()) !== -1)))
    })
  );

  selectedArea(evt:any) {
    var re = /\s+/g;
    this.Userservice.getCoords(evt.item.name).subscribe(response => {
      if (response.details.length > 0) {
        let record = response.details.filter(item => item.name.toLowerCase() == evt.item.name.toLowerCase() && item.city.toLowerCase() == evt.item.city.toLowerCase());
        if (record.length > 0) {
          this.lat = record[0].latitude;
          this.long = record[0].longitude;
          this.city = record[0].city.toLowerCase().replace(re, "-");
          this.name = record[0].name.toLowerCase().replace(re, "-");
          if (this.city && this.name && this.specs && this.city != this.name) {
            this.getResults(this.city, this.specs, this.name);
          } else if (this.city && this.specs) {
            this.getResults(this.city, this.specs, '');
          }
        }
      }
    });
  }

  filteredSpeciality$ = new Observable((observer: Observer<string>) => {
    observer.next(this.speciality);
  }).pipe(
    switchMap((query: string) => {
      if(query) {
        let data = this.data.details.filter(item => item.name == this.area);
        if(data.length > 0) {
          return this.https.get(
            this.Userservice.gigaUrl + 'search/autosuggestions' + '?keyword=' + query + '&latitude=' + data[0].latitude + '&longitude=' + data[0].longitude,
            this.Userservice.jsonHeader
          ).pipe(map((res: any) => {
            const response = res;
            let filtered: any[] = [];
            let result1 = Object.keys(response.details).map(function (key) {
              return response.details[key];
            });
            let str = 'doctor';
            if (str.indexOf(query.toLowerCase()) !== -1) {
              filtered.push({"id": 0, "name": "Doctor", "type": "type"});
            }
            filtered.push({"id": 0, "name": "Doctor Search with Keyword : " + query, "type": "doctor", "speciality": "doctor"});
            filtered.push({"id": 0, "name": "Clinic Search with Keyword : " + query, "type": "clinic", "area": "clinic"});
            result1.forEach(item => {
              item.forEach(i => {
                if (i.type == 'doctor') {
                  filtered.push({"id": i.id, "name": i.name, "type": i.type, "url": i.url, "speciality": i.speciality});
                } else if (i.type == 'clinic') {
                  filtered.push({"id": i.id, "name": i.name, "type": i.type, "area": i.area});
                } else {
                  filtered.push({"id": i.id, "name": i.name, "type": i.type});
                }
              });
            });
            this.filteredSpeciality = filtered;
            return this.filteredSpeciality;
          }));
        }
      }
      return of([]);
    })
  );

  selectedSpec(evt:any) {
    var re = /\s+/g;
    let name1 = evt.item.name;
    if (evt.item.type == 'doctor' && evt.item.url) {
      this.docUrl = evt.item.url;
    }
    this.specs = name1.toLowerCase().replace(re, "-");
    this.getResults(this.city, this.specs, this.name, evt.item.type, evt.item.id);
  }

  getResults(city,spec,area,type='',id='') {
    if (type == 'clinic' && area && area != city && spec.indexOf('clinic-search-with') != -1) {
      this.router.navigateByUrl(city + '/' + spec.substring(29) + '/' + area + '/clinics');
    } else if (type == 'clinic' && spec.indexOf('clinic-search-with') != -1) {
      this.router.navigateByUrl(city + '/' + spec.substring(29) + '/clinics');
    } else if (type == 'clinic') {
      this.router.navigateByUrl('clinicdetails/' + city + '/' + id);
    } else if (type == 'doctor' && area && area != city && spec.indexOf('doctor-search-with') != -1) {
      this.specs = spec.substring(29);
      this.router.navigateByUrl(city + '/' + this.specs + '/' + area + '/doctors');
    } else if (type == 'doctor' && spec.indexOf('doctor-search-with') != -1) {
      this.specs = spec.substring(29);
      this.router.navigateByUrl(city + '/' + this.specs + '/doctors');
    } else if (type == 'doctor') {
      this.router.navigateByUrl('doctors/' + city + '/' + this.docUrl);
    } else if (city && spec && area && city != area) {
      this.router.navigateByUrl(city + '/' + this.specs + '/' + this.name);
    } else if (city && spec) {
      this.router.navigateByUrl(city + '/' + this.specs);
    }
  }

  onClearArea() {
    this.name = null;
  }

  onClearSpec() {
    this.specs = null;
  }

  ngOnDestroy() {
    sessionStorage.removeItem('userId1')
  }

}
