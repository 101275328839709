
<app-header></app-header>
<div #alert *ngIf="errorMessage" class="alert alert-danger ">
    <h6 class="text center">
        <i class="fas fa-exclamation-circle text-danger mr-2"></i>
        {{errorNotification}}
    </h6>
</div>
<div class="subheader subheader-filter">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-sm-4">
                <h4 class="pg-title">Book Appointment</h4>
            </div>
            <!--<div class="col-sm-8 text-right text-left-sm">
                <ul class="pg-breadcrumb">
                    <li ><a href="#">Home</a></li>
                    <li *ngIf="city!=null"><a href="#">{{city}}</a></li>
                    <li *ngIf="specialty!=null"><a href="#">{{specialty}}</a></li>
                    <li *ngIf="area!=city && area!=null"><a href="#">{{area}}</a></li>
                    <li *ngIf="docName!=null"><a>{{docName}}</a></li>
                </ul>
            </div>-->
        </div>
    </div>
</div>

<div class="booking-success">
  <div class="row">
    <div id="appointment_details" class="col-md-8">
      <div class="page-content">
        <div class="booking-result-wrap">
          <div class="container">
            <div class="confirmation-wrap">
              <div class="booking-confirmation-title" *ngIf="!checkUrl()">
                <h3>Your appointment has been successfully booked.</h3>
                <p>Thank you for booking an appointment through Gigadocs!</p>
              </div>
              <div class="booking-confirmation-title" *ngIf="checkUrl()">
                <h3>Thank you for raising a request for an appointment through Gigadocs!</h3>
                <p>Clinic has been notified about your request and you will get a call from them shortly.</p>
              </div>
              <div class="container mt-4 text-center" *ngIf="time==null && !docId">
                <label>Appointment ID:</label>
                <h5>{{appointmentId}}</h5>
              </div>
              <div class="br-wrap" *ngIf="slotType!='oncall' && time!=null" >
                <div class="br-col">
                  <label>Appointment Date/Time:</label>
                  <h5 >{{date}},&nbsp; {{time}}</h5>
                </div>
                <div class="br-col">
                  <label>Patient Name:</label>
                  <h5>{{patientName}}</h5>
                </div>
                <div class="br-col">
                  <label>Doctor:</label>
                  <h5>Dr. {{docName | camelcase}}</h5>
                  <p>{{specialization}}</p> <br> <p *ngIf="exp>0" >{{exp}} Years of Experience</p>
                </div>
                <div class="br-col">
                  <label>Clinic:</label>
                  <h5>{{clinicName | camelcase}}</h5>
                  <p>{{clinicAddress}}</p>
                </div>
              </div>
              <div class="br-wrap" *ngIf="slotType=='oncall' && time!=null" >
                <div class="br-col">
                  <label>Appointment Date:</label>
                  <h5>{{date}}</h5>
                </div>
                <div class="br-col">
                  <label>Appointment Time:</label>
                  <h5 >{{time}}</h5>
                </div>
                <div class="br-col">
                  <label>Doctor:</label>
                  <h5>Dr. {{docName | camelcase}}</h5>
                  <p>{{specialization}}</p> <br> <p *ngIf = exp>0 > {{exp}} Years of Experience</p>
                </div>
                <div class="br-col">
                  <label>Clinic:</label>
                  <h5>{{clinicName | camelcase}}</h5>
                  <p>{{clinicAddress}}</p>
                </div>
              </div>
            </div>
            <div class="text-center" *ngIf="slotType == 'vc'">
              <div class="row">
                <div class="col-6" >
                  <a class="btn-my-appointments btn-primary text-white trigger-btn" href="#getVCLinkModal"  data-toggle="modal">
                      Get Video Consultation Link
                  </a>
                </div>
              </div>
            </div>
            <div class="text-center" *ngIf="isNew == '1'">
              <a class="btn-my-appointments btn-primary text-white" (click)='createPassword()'>Create Password</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="reason_visit" class="col-md-4" *ngIf="time != null && slotType!='oncall'">
      <div class="reason">
        <div class="container">
          <div class="reason-form">
            <div class="alert alert-success" *ngIf="updated">
              Appointment Updated Successfully
            </div>
            <form [formGroup]="reasonForm">
              <div class="form-group required">
                <label>Reason for Visit</label>
                <textarea rows="4" cols="50" class="form-control" formControlName="complaint" placeholder="Enter Reason for Visit"
                          required="required" [ngClass]="{ 'is-invalid': reasonSubmitted && form.complaint.errors }"></textarea>
                <div *ngIf="(reasonSubmitted) && form.complaint.errors" class="invalid-feedback">
                  <div *ngIf="form.complaint.errors.required">Reason for Visit is required</div>
                </div>
              </div>
              <div class="form-group">
                <label>Gender</label>
                <select  class="form-control" formControlName="gender" placeholder="Select Gender">
                  <option value="">Select Gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </select>
              </div>
              <div class="form-group">
                <label>Date of Birth</label>
                <input type="text"
                    class="form-control"
                    readonly formControlName="dob"
                    bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedDate($event)"
                    id="date" placeholder="Select Date" placement="bottom" [maxDate]="maxDate" [ngClass]="{ 'is-invalid': reasonSubmitted && form.dob.errors }"/>
                  <div style="font-size: 13px; color:#08ad4d; margin-top: 3px; " *ngIf = "dob && age > 0" ><strong>Age : {{age}} {{metrics}}</strong></div>
                  <div style="font-size: 13px; color:#08ad4d; margin-top: 3px; " *ngIf = "dob && age == 0" ><strong>Age : Below 1 {{metrics}}</strong></div>
                  <!-- <div *ngIf="reasonSubmitted && form.dob.errors" class="invalid-feedback">
                    <div *ngIf="form.dob.errors">DOB is required.</div>
                  </div> -->
              </div>
              <div class="form-group">
                <button type="submit" (click)="saveReason()" class="btn btn-primary pull-right">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="my-appointments">
  <div class="row">
      <div class="col-md-8">
          <div class="container">
              <div class="show-appointments">
                  <div class="row">
                      <div class="col-md-4">
                          <img class="img-responsive"  src="../../assets/images/myappointment3.png" alt="vc-steps" height="200" width="250">
                      </div>
                      <div class="col-md-8">
                          <div class="list-myappointments">
                              <h3>View the list of the all my appointments </h3>
                              <div class="text-center float-left">
                                  <a href="{{userToken}}" class="btn-my-appointments">My Appointments</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-md-4">
          <div class="container">
              <div class="how-link">
                  <div class="row">
                      <div class="col-md-4">
                          <img src="../../assets/images/myappointment4.png" alt="vc-steps" height="200" width="200">
                      </div>
                      <div class="col-md-8">
                          <div class="how-works">
                              <h3>Click here to know more!</h3>
                              <div class="text-center float-left">
                                  <a href="javascript: void(0)" (click)="howitworks()" class="btn-how-works">How it Works?</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </div>
  </div>
</div>

<div class="vc-steps" *ngIf="slotType == 'vc'">
  <div class="row">
    <div class="container">
      <img src="assets/images/vc.png" alt="vc-steps" height="500" width="1000">
    </div>
  </div>
</div>

<!--Get VC Link Modal-->

<div id="getVCLinkModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-forgot modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header" >
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="VCLinkFormReset()">
                     &times;
                </button>
                <h4 class="modal-title">Video Consultation Link </h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-success" *ngIf="loadingLink == false">
                    Successfully sent
                </div>
                <div *ngIf="loadingLink == true" class="alert alert-warning">
                    <span class="spinner-border spinner-border-sm"></span>
                    Loading please wait...
                </div>
                <div class="forgot-modal-wrap">
                    <form   [formGroup]='VCLinkForm'>
                        <p>Enter your  Email ID or Mobile below.We will send you a link for Video Consultation</p>
                        <div class="form-group required">
                            <label class="font-weight-bold control-label">Email or Mobile</label>
                            <input type="text" class="form-control" placeholder="Enter Email or Mobile Number"
                                    formControlName="email" [ngClass]="{ 'is-invalid': submitted && VCLinkForm1.email.errors }">
                             <div *ngIf="submitted && VCLinkForm1.email.errors" class="invalid-feedback">
                                <div *ngIf="VCLinkForm1.email.errors.required">Email or Mobile is required</div>
                                 <div class="text-danger" *ngIf="VCLinkForm1.email.errors?.pattern">*Invalid Email or Mobile number</div>
                             </div>
                        </div>
                        <button class="btn btn-primary mb-3" data-toggle="modal" [disabled]="loadingLink"  (click)="sendVCLink()">
                          submit
                          <span  *ngIf="loadingLink == true" class="spinner-border spinner-border-sm"></span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<a class="nav-link" hidden href="javascript:void(0)" href="#feedbackModal" class="trigger-btn" routerLinkActive="active" data-toggle="modal" id="login">
    Feedback
</a>
<!-- feedback modal -->
<div id="feedbackModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-feedback modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                <h4 class="modal-title">
                    Rate Us!
                </h4>
            </div>
            <div class="modal-body">
                <div class="feedback-modal-wrap">
                    <div class="alert alert-success" *ngIf="message!=''">
                      {{message}}
                    </div>
                    <img src="../../assets/images/rate.png" class="img-center" height="130" width="220" alt="GigaDocs"/>
                    <div class="rating">
                        <input type="radio" name="rating" value="5" id="5" (click)="ratingGiven(5)"><label for="5">☆</label>
                        <input type="radio" name="rating" value="4" id="4" (click)="ratingGiven(4)"><label for="4">☆</label>
                        <input type="radio" name="rating" value="3" id="3" (click)="ratingGiven(3)"><label for="3">☆</label>
                        <input type="radio" name="rating" value="2" id="2" (click)="ratingGiven(2)"><label for="2">☆</label>
                        <input type="radio" name="rating" value="1" id="1" (click)="ratingGiven(1)"><label for="1">☆</label>
                    </div>
                    <form [formGroup]='feedbackForm'>
                      <fieldset class="text-center">
                        <div class="formrow btn-group-toggle" data-toggle="buttons">
                            <label class="checklabel btn btn-light" (click)="setRow(i)" *ngFor="let feedbacks of feedback; let i = index;" multiple>
                                <input type="checkbox" id="feedback{{i}}" name="options{{i}}" autocomplete="off">
                                {{feedbacks}}
                            </label>
                            <label class="checklabel btn btn-light" id="others1" (click)="toggleDisplay()">
                                <input type="checkbox" name="options" id="option6" autocomplete="off">Others
                            </label>
                        </div>
                        <div>
                            <textarea [hidden] = "isShow" class="feedbackcomment" formControlName="others" placeholder="Feedback" rows="3" required="required"></textarea>
                        </div>
                      </fieldset>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
              <button type="submit" id="formSubmitButton" class="btn btn-primary" [disabled]="!ratings || isloading" (click)="submitFeedback()">Submit</button>
              <button type="button" id="feedbackNotIntrested" class="btn btn-default" data-dismiss="modal">Not Interested</button>
            </div>
        </div>
     </div>
</div>
<app-footer lat="17.4611" long="78.355797"></app-footer>






