
import { ActivatedRoute,Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormControl, FormBuilder, FormGroup  } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import {DatePipe} from "@angular/common";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-appointmentsuccess',
  templateUrl: './appointmentsuccess.component.html',
  styleUrls: ['./appointmentsuccess.component.css']
})
export class AppointmentsuccessComponent implements OnInit, OnDestroy{

  reasonForm: FormGroup;
  data: string;
  city: any;
  clinicName: any;
  time: any;
  specialty: any;
  clinicAddress: any;
  specialization: any;
  exp: any;
  date: any;
  appointmentId: any;
  docName: any;
  area: any;
  loggedinAccount: boolean;
  notLoggedinAccount: boolean;
  errorNotification: any;
  errorMessage: boolean;
  slotType:string;
  feedback=['Ease of searching', 'Booking Process', 'Clinic Info', 'Doctors Info', 'Online Consultation']
  selectedIndex: number;
  selectedIndexs: any=[];
  isShow = true;
  reasons: any=[];
  feedbackForm: FormGroup;
  isloading:boolean;
  VCLinkForm: FormGroup;
  loadingLink:boolean;
  submitted: boolean;
  reasonSubmitted: boolean;
  isNew: string;
  appointmentIdRepeat: any;
  ratings: any;
  updated: boolean = false;
  currentRate = '';
  closeResult: string;
  message: any = '';
  checked: boolean = false;
  docId: any;
  url: string;
  userToken: string;
  datePicker: Date;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  dob: string;
  age: any;
  maxDate: Date;
  metrics: string;
  patientName: any;
  age_in_months: string | number;

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  public setRow(_index: number) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
    } else {
      let removeIndex = this.selectedIndexs.indexOf(_index);
      this.selectedIndexs.splice(removeIndex, 1);
    }
  }

  constructor(
    private activatedroute:ActivatedRoute,
    private router:Router,
    private Userservice:UserService,
    private fb: FormBuilder,
    private cookieService: CookieService,
    private datePipe: DatePipe
  ) {
    if (this.Userservice.userValue) {
      this.userToken = this.Userservice.dashboardURL + 'user/appointment/?token=' +this.Userservice.userValue['details']['userId'];
      this.notLoggedinAccount = false;
    } else {
      this.notLoggedinAccount = true
    }
    this.feedbackForm = this.fb.group({
      others: new FormControl('',)
    });
    this.VCLinkForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,3}))$/)])
    });
    this.reasonForm = this.fb.group({
      complaint: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      dob: [''],
    });
  }

  ngOnInit(): void {
    let today = new Date();
    this.maxDate = new Date(today);
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.Userservice.setDocTitle('Booking Success - Gigadocs');
    this.slotType = sessionStorage.getItem('slotType');
    sessionStorage.removeItem('slotType');
    this.isNew = sessionStorage.getItem('isNew');
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' });
    this.url = this.router.url;
    if (this.url.indexOf("oncall") !== -1) {
      this.activatedroute.params.subscribe(params => {
        this.docId = params['docId'];
      })
      setTimeout(() => {
        this.appointmentId = sessionStorage.getItem('appointmentId');
        this.appointmentIdRepeat = this.cookieService.get('appointmentId');
        if (this.appointmentIdRepeat !== this.appointmentId) {
          $("#feedbackModal").modal('show');
          this.cookieService.set('appointmentId', this.appointmentId);
        }
      }, 2000)
    } else {
      this.activatedroute.params.subscribe(params => {
        this.appointmentId = params['appointmentId'];
      })
      setTimeout(() => {
        this.appointmentIdRepeat = this.cookieService.get('appointmentId');
        if (this.appointmentIdRepeat !== this.appointmentId) {
          $("#feedbackModal").modal('show');
          this.cookieService.set('appointmentId', this.appointmentId);
        }
      }, 2000)
    }

    this.Userservice.currentParams.subscribe(response => {
      this.data = response;
      if(this.slotType == 'oncall') {
        this.date = this.data['date'];
        this.time = this.data['time'];
        this.docName = this.data['doctor'];
        this.specialization = this.data['specialization'];
        this.clinicName = this.data['clinicName'];
        this.clinicAddress = this.data['address'];
        this.exp = this.data['exp'];
        this.patientName = this.data['patientname']
        if (this.data['dob']!=null && this.data['dob']!=undefined){
          this.reasonForm.controls['dob'].setValue(this.data['dob']); 
        }else{
          this.reasonForm.controls['dob'].setValue("");
        }
        this.reasonForm.controls['dob'].setValue(this.data['dob']);
        this.selectedDate(this.data['dob']);
        $('#appointment_details').removeClass('col-md-8');
        $('#appointment_details').addClass('col-md-12');
      } else {
        this.city = this.data['city'];
        this.clinicName = this.data['clinicName'];
        this.area = this.data['area'];
        this.time = this.data['time'];
        this.specialty = this.data['spec'];
        this.clinicAddress = this.data['address'];
        this.specialization = this.data['specialization'];
        this.docName = this.data['doctor'];
        this.exp = this.data['exp'];
        this.date = this.data['date'];
        this.patientName = this.data['patientname']
        this.reasonForm.controls['gender'].setValue(this.data['gender']);
        if (this.data['dob']!=null && this.data['dob']!=undefined){
          this.reasonForm.controls['dob'].setValue(this.data['dob']); 
        }else{
          this.reasonForm.controls['dob'].setValue("");
        }
        this.selectedDate(this.data['dob']);
        if (!this.time) {
          $('#appointment_details').removeClass('col-md-8');
          $('#appointment_details').addClass('col-md-12');
        }
      }
    })
  }

  Error(data) {
    this.errorNotification = data;
    this.errorMessage = true;
    setTimeout(() => {
      this.errorMessage = false;
    }, 5000);
  }

  submitFeedback() {
    this.isloading = true;
    if (this.ratings != null) {
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        this.reasons.push(this.feedback[this.selectedIndexs[i]]);
      }
      this.reasons.push(this.feedbackForm.value.others);
      let reasons = this.reasons.join('-');
      this.Userservice.userFeedback(this.appointmentId, this.ratings, reasons).subscribe((response: any) => {
        if (response.status) {
          this.message = response.message;
          this.isloading = false;
          setTimeout(() => {
            this.message = '';
            $("#feedbackModal").modal('hide');
            this.feedbackReset();
          }, 2000)
        }
      })
    }
  }

  VCLinkFormReset(){
    this.VCLinkForm.reset()
    this.submitted = null
  }

  get VCLinkForm1() {
    return this.VCLinkForm.controls;
  }

  sendVCLink() {
    this.submitted = true;
    if (this.VCLinkForm.valid) {
      this.loadingLink = true;
      this.Userservice.VCLink(this.appointmentId, this.VCLinkForm.get('email').value).subscribe((response: any) => {
        if (response['details']['status'] !== null && response['details']['status'] == 1) {
          this.loadingLink = false;
          setTimeout(() => {
            $("#getVCLinkModal").modal('hide');
            this.loadingLink = null;
            this.submitted = null;
            this.VCLinkFormReset();
          }, 2000)
        }
      })
    } else {
      this.loadingLink = null;
    }
  }

  checkUrl() {
    let response = false;
    if (this.router.url.indexOf('/oncall') > -1) {
      response = true;
    }
    return response;
  }

  feedbackReset(){
    this.currentRate = ''
    this.feedbackForm.reset();
    this.isloading = null;
    this.selectedIndexs.splice(0,this.selectedIndexs.length);
  }

  createPassword(){
    sessionStorage.setItem('userId', sessionStorage.getItem('userId1'));
    this.router.navigate(['/resetpassword']);
  }

  ngOnDestroy(){
    sessionStorage.removeItem('userId1')
  }

  ratingGiven(currentRating){
    this.ratings = currentRating;
  }

  get form() {
    return this.reasonForm.controls;
  }

  selectedDate(event: any) {
    if(event){      
      this.dob = event;
        let data = this.Userservice.getUserAge(this.dob)
        this.age = data['age']
        this.metrics = data['units']
        this.age_in_months = data['months']
   }else{
      this.age = 0;
      this.dob = '';
   }
  }

  saveReason() {
    this.reasonSubmitted = true;
    var details = {
      appointmentId: this.appointmentId,
      complaint: this.reasonForm.value.complaint,
      gender: this.reasonForm.value.gender,
      dob: this.datePipe.transform(this.reasonForm.value.dob,"yyyy-MM-dd"),
      age : (this.age_in_months && this.age)  ? 1 : this.age // Updating age as 1 yr for below 1 Yr patients.
    };
    if(this.form.complaint.valid) {
      this.Userservice.updateAppointment(details).subscribe(response => {
        if (response.details.status) {
          this.updated = true;
          setTimeout(() => {
            this.updated = false
          }, 2000);
        }
      });
    }
  }

  howitworks() {
    this.router.navigateByUrl('home/howitworks');
  }
}
