import { Component, OnInit } from '@angular/core';
import {UserService} from "../service/user.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {

  url: string;
  practice: boolean = false;

  constructor(
    private router: Router,
    private Userservice:UserService
  ) { }

  ngOnInit(): void {
    this.Userservice.setDocTitle("App Download Link - Gigadocs");
    this.url = this.router.url;
    if (this.url.indexOf("store/practice") !== -1) {
      this.practice = true;
    }
  }

}
