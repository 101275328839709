import { Injectable, ViewChild } from '@angular/core';
import { HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { map } from 'rxjs/operators'
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../models';
import { NgxSpinnerService } from "ngx-spinner";
import { Title, Meta } from '@angular/platform-browser';
import { BsModalService , BsModalRef} from 'ngx-bootstrap/modal';
import { RequestDemoComponent } from '../header-features/request-demo/request-demo.component';
import { OTPmodalComponent } from '../header-features/otpmodal/otpmodal.component';
import { RegistrationTypeComponent } from '../header-features/registration-type/registration-type.component';
import {DatePipe} from "@angular/common";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public locationCache = new Map();
  public userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private urlParams = new BehaviorSubject('');
  currentParams = this.urlParams.asObservable();

  private message = new BehaviorSubject('');
  currentMessage = this.message.asObservable();

  jsonHeader = {
    headers: new HttpHeaders({
      'GIGATOKEN': '4fd553484fc9e588685890fd46874cc989240136e6267c1b20751b027030f621',
      'Content-Type': 'application/json'
    })
  };
  gigaUrl = 'https://api.gigadocs.com/';
  dashboardURL = 'https://app.gigadocs.com/';
  paymentKey = 'rzp_live_CNF7BTdGhfnYXR';
  thyroApi = 'https://www.gigadocs.com/api/';
  bsModalRef: BsModalRef;
  bsModalRef1: BsModalRef;
  convertedage: number;
  metrics: string;

  constructor(
    private https:HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private metaService: Meta,
    private modalService: BsModalService,
    private datePipe: DatePipe
    ) {
    this.setDefaultMetaData();
    this.userSubject = new BehaviorSubject<User>(this.getWithExpiry());
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  setDocTitle(title:string) {
    this.titleService.setTitle(title);
  }

  setDefaultMetaData() {
    this.titleService.setTitle('Online Doctor Appointments ,Online consultation,Book Doctor Appointments and Video Consultation with Doctors | Gigadocs');
    this.metaService.updateTag({ name: 'description', content: "Book Online Doctor appointments. View doctors online and book online appointments of in clinic and online consultation. Doctors consultation can be booked online, and online consultations are available in the form of Video Consultation. Online appointments and virtual appointments can be booked by selecting available doctors online."});
    this.metaService.updateTag({ name: 'keywords', content: "Doctor’s appointment online in Pune, Doctor’s appointment online, Book doctor appointment online, Make doctor’s appointment online, Get doctor’s appointment online, Medical appointment book online in pune, Medical appointment book online, Doctor’s appointment online, Check doctor’s appointment online in pune,Check doctor’s appointment online, Find doctor’s appointment online, How to make doctor’s appointment online, How to check doctor’s appointment online, Schedule doctor appointment online, online doctor appointment, doctor appointment, Doctor Online, virtual consultation, online consultation, Doctor consultation, online Doctor consultation, Book Online Doctor"});
    this.metaService.updateTag({ name: 'og:title', content: 'Gigadocs | Your Doctor search ends here' });
    this.metaService.updateTag({ name: 'og:description', content: 'Book an appointment with the best doctors in your location and upload your prescription to get medicines delivered to your home. View list of doctors, health care services.' });
    this.metaService.updateTag({ name: 'twitter:title', content: 'Gigadocs | Your Doctor search ends here' });
    this.metaService.updateTag({ name: 'twitter:description', content: 'Book an appointment with the best doctors in your location and upload your prescription to get medicines delivered to your home. View list of doctors, health care services.' });
    this.metaService.updateTag({ itemprop: 'description', content: 'Book an appointment with the best doctors in your location and upload your prescription to get medicines delivered to your home. View list of doctors, health care services.'});
  }

  setDynamicMetaData(details) {
    this.titleService.setTitle(details.metaTitle);
    this.metaService.updateTag({ name: 'description', content: details.metaDescription });
    this.metaService.updateTag({ name: 'og:title', content: details.ogTitle });
    this.metaService.updateTag({ name: 'og:description', content: details.ogDescription });
    this.metaService.updateTag({ name: 'twitter:title', content: details.ogTitle });
    this.metaService.updateTag({ name: 'twitter:description', content: details.ogDescription });
    if (details.metaKeywords) {
      this.metaService.updateTag({ name: 'keywords', content: details.metaKeywords });
    }
  }

  getArea(): Observable<any> {
    const locationFromCache = this.locationCache.get(URL);
    if (locationFromCache) {
      return of(locationFromCache);
    }
    const response = this.https.get<any>(this.gigaUrl + 'search/suggestionsarea/',
      this.jsonHeader);
    response.subscribe(data => this.locationCache.set(URL, data));
    return response;
  }

  getSpec(): Observable<any> {
    return this.https.get(
      this.gigaUrl + 'search/suggestions',
      this.jsonHeader)
      .pipe(map(this.extractData)
    );
  }

  getAutoSuggestions(query, lat, long): Observable<any> {
    return this.https.get(
      this.gigaUrl + 'search/autosuggestions' + '?keyword=' + query + '&latitude=' + lat + '&longitude=' + long,
      this.jsonHeader)
      .pipe(map(this.extractData)
    );
  }

  changeParams(params:any) {
    this.urlParams.next(params)
  }

  register(info): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'register/',
      info
    );
  }

  mobileVerfication(num) {
    return this.https.post(
      this.gigaUrl + 'signup/mobileverification/',
      num,
      {observe: 'response'}
    )
  }

  otpVerfication(number) {
    return this.https.post(
      this.gigaUrl + 'user/mobileverification/',
      number,
      {observe: 'response'}
    )
  }

  verifyUser(num) {
    return this.https.post(
      this.gigaUrl + 'user/sendotp/',
      num,
      {observe: 'response'}
    )
  }

  getSearchresults(specs:string, lat:number, longitude:number, pageNo, isDoctors:boolean, onlyCity:boolean, order:string){
    if(isDoctors) {
      if (onlyCity) {
        if (order != '') {
          var searchurl = this.gigaUrl + 'searchdoctor/?keyword=' + specs + '&latitude=' + lat
            + '&longitude=' + longitude + '&page=' + pageNo + '&fee=' + order;
        } else {
          var searchurl = this.gigaUrl + 'searchdoctor/?keyword=' + specs + '&latitude=' + lat
            + '&longitude=' + longitude + '&page=' + pageNo;
        }
      } else {
        if (order != '') {
          var searchurl = this.gigaUrl + 'searchdoctor/?keyword=' + specs + '&latitude=' + lat
            + '&longitude=' + longitude + '&type=doctor&page=' + pageNo + '&fee=' + order;
        } else {
          var searchurl = this.gigaUrl + 'searchdoctor/?keyword=' + specs + '&latitude=' + lat
            + '&longitude=' + longitude + '&type=doctor&page=' + pageNo;
        }
      }
      return this.https.get(
        searchurl,
        this.jsonHeader
      ).pipe(catchError(this.errorHandler));
    } else {
      if(order != '') {
        var searchurl = this.gigaUrl + 'searchdoctor/?keyword=' + specs + '&latitude=' + lat
          + '&longitude=' + longitude + '&page=' + pageNo + '&fee=' + order;
      } else {
        var searchurl = this.gigaUrl + 'searchdoctor/?keyword=' + specs + '&latitude=' + lat
          + '&longitude=' + longitude + '&page=' + pageNo;
      }
      return this.https.get(
        searchurl,
        this.jsonHeader
      ).pipe(catchError(this.errorHandler));
    }
  }

  getCoords(area:string): Observable<any> {
    if (area != '') {
      return this.https.get(
        this.gigaUrl + 'search/suggestionsarea/'
        + '?keyword=' + area,
        this.jsonHeader
      ).pipe(
        catchError(this.errorHandler)
      );
    }
  }

  getGeolocation(address:string) {
    return this.https.get(
      'https://maps.googleapis.com/maps/api/geocode/json'
      +'?key=' + 'AIzaSyCV5pSKczNIvKssFVY4fp_M8IK7dg1CU98'
      +'&address=' + address,
      {observe: 'response'}
    )
  }

  getDoctorByName(name:string){
    return this.https.get(
      this.gigaUrl + 'user/doctordetails/' +name,
      this.jsonHeader
    )
  }

  clinicDetails(docId){
    return this.https.get(
      this.gigaUrl + 'doctor/clinics/'
       +'?doctorId='+docId,
       this.jsonHeader
    )
  }

  getClinicslots(doctorId,clinicId,date,type){
    return this.https.get(
      this.gigaUrl + 'doctor/timeslots/'
      +'?doctor='+ doctorId
      + '&clinic=' + clinicId
      +'&date=' + date
      + '&slotType=' + type,
      this.jsonHeader
    )
  }

  bookAppointment(details):Observable<any> {
    return this.https.post(
      this.gigaUrl + 'user/appointments/',
      details,
      this.jsonHeader
    ).pipe(
      catchError(this.errorHandler)
    );
  }

  errorHandler(error:HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  getVCDocList(keyword: string, pageNo:number, order:string) {
    if(keyword == '' || keyword == undefined) {
      if (order != '') {
        var searchurl = this.gigaUrl + 'vc/searchdoctor/' + '?page=' + pageNo + '&fee=' + order;
      } else {
        var searchurl = this.gigaUrl + 'vc/searchdoctor/' + '?page=' + pageNo;
      }
      return this.https.get(
        searchurl,
        this.jsonHeader
      )
    } else {
      if (order != '') {
        var searchurl = this.gigaUrl + 'vc/searchdoctor/' + '?keyword=' + keyword + '&page=' + pageNo + '&fee=' + order;
      } else {
        var searchurl = this.gigaUrl + 'vc/searchdoctor/' + '?keyword=' + keyword + '&page=' + pageNo;
      }
      return this.https.get(
        searchurl,
        this.jsonHeader
      )
    }
  }

  createOrderID(details) {
    return this.https.post(
      this.gigaUrl + 'payment/orderid/',
      details, this.jsonHeader
    ).pipe(
      catchError(this.errorHandler)
    );
  }

  signIn(userDetails) {
    return this.https.post<User>(
      this.gigaUrl + 'login/', userDetails)
      .pipe(map(user => {
          if (user && user['details']['userId']) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.afterLogin(user)
          }
        }),
        catchError(this.errorHandler)
      );
  }

  afterLogin(user) {
    this.setWithExpiry(user);
    this.userSubject.next(user);
    return user;
  }

  setWithExpiry(value) {
    const item = {
      user: value
    }
    localStorage.setItem('user', JSON.stringify(item));
  }

  getWithExpiry() {
    const itemStr = localStorage.getItem('user');
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    return item.user;
  }

  updateWithExpiry(key, value) {
    const itemStr = localStorage.getItem('user');
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    item.user.details[key] = value;
    const user = {
      user: item.user
    }
    localStorage.setItem('user', JSON.stringify(user));
  }

  logout() {
    let jsonHeader = {
      headers: new HttpHeaders({
        'GIGATOKEN': this.userValue['details']['userId'],
        'Content-Type': 'application/json',
      })
    };
    return this.https.delete(
      this.gigaUrl + 'login/',
      jsonHeader
    ).subscribe(response => {
      localStorage.removeItem('user');
      sessionStorage.clear();
      this.userSubject.next(null);
      this.router.navigate(['']);
    });
  }

  redirectlogout() {
    let jsonHeader = {
      headers: new HttpHeaders({
        'GIGATOKEN': this.userValue['details']['userId'],
        'Content-Type': 'application/json',
      })
    };
    return this.https.delete(
      this.gigaUrl + 'login/',
      jsonHeader
    );
  }

  updatePoints(points) {
    this.updateWithExpiry('points', points);
  }

  userFeedback(appointmentId:number, rating:any, reasons:string) {
    return this.https.post(
      this.gigaUrl + 'user/feedback/',
      {
        'appointmentId': appointmentId,
        'rating': rating,
        'reason': reasons
      },
      this.jsonHeader
    ).pipe(
      catchError(this.errorHandler)
    );
  }

  VCLink(appointmentId:number, email:string) {
    return this.https.post(
      this.gigaUrl + 'user/appointments/virtual/notify/',
      {
        'appointmentId': appointmentId,
        'email': email
      },
      this.jsonHeader,
    )
  }

  sendOtpForLogin(mobile:any) {
    return this.https.post(
      this.gigaUrl + 'login/otp/',
      {'mobile': mobile}
    )
  }

  verifyOtpLogin(mobile:any, otp:number) {
    return this.https.post<User>(
      this.gigaUrl + 'login/otp/verification/',
      {
        'mobile': mobile,
        'otp': otp
      }
    )//.pipe(map(user => {this.afterLogin(user)}));
  }

  forgotPassword(mobile:any) {
    return this.https.post(
      this.gigaUrl + 'user/resetpassword/',
      {'mobile': mobile},
      {observe: 'response'}
    )
  }

  updatePassword(userId:any, password:string, emailCode:string) {
    return this.https.post(
      this.gigaUrl + 'user/createpassword/',
      {
        'userId': userId,
        'password': password,
        'emailCode': emailCode
      },
      {observe: 'response'}
    )
  }

  openDemoModalComponent() {
    this.bsModalRef = this.modalService.show(RequestDemoComponent,
      {
        class: 'modal-dialog-centered'
      }
    );
  }

  closeDemoModalComponent() {
    this.bsModalRef.hide()
  }
  requestDemo(details) {
    return this.https.post(
      this.gigaUrl + 'demo/request/',
      details,
      this.jsonHeader
    )
  }
  getQuotePageVisit(details): Observable<any> {
    let jsonHeader = {
      headers: new HttpHeaders({
        //'GIGATOKEN': this.userValue['details']['userId'],
        'GIGATOKEN':this.userValue ? this.userValue['details']['userId'] : this.jsonHeader.headers.get('GIGATOKEN'),
        'Content-Type': 'application/json',
      })
    };
    return this.https.post(
      this.gigaUrl + 'user/quote/request/',
      details,
      jsonHeader
    )
  }
  getQuoteFormVisit(details): Observable<any> {
    let jsonHeader = {
      headers: new HttpHeaders({
        //'GIGATOKEN': this.userValue['details']['userId'],
        'GIGATOKEN':this.userValue ? this.userValue['details']['userId'] : this.jsonHeader.headers.get('GIGATOKEN'),
        'Content-Type': 'application/json',
      })
    };
    return this.https.put(
      this.gigaUrl + 'user/quote/request/' + details.userVisitId + '/',
      details,
      jsonHeader
    )
  }
  getQuoteRequest(details): Observable<any> {
    let jsonHeader = {
      headers: new HttpHeaders({
        //'GIGATOKEN': this.userValue['details']['userId'],
        'GIGATOKEN':this.userValue ? this.userValue['details']['userId'] : this.jsonHeader.headers.get('GIGATOKEN'),
        'Content-Type': 'application/json',
      })
    };
    return this.https.put(
      this.gigaUrl + 'user/quote/request/' + details.userVisitId + '/',
      details,
      jsonHeader
    )
  }

  openOtpModalComponent() {
    this.bsModalRef1 = this.modalService.show(OTPmodalComponent,
      {
        class: 'modal-dialog-centered'
      }
    );
  }

  closeOtpModalComponent() {
    this.bsModalRef1.hide()
  }

  openResgisterTypeModal() {
    this.bsModalRef = this.modalService.show(RegistrationTypeComponent);
  }

  closeResgisterTypeComponent() {
    this.bsModalRef.hide()
  }

  dynamicFooter(specs:string , lat:number , longitude:number) {
    if (specs != '' && lat && longitude) {
      return this.https.get(
        this.gigaUrl + 'footer/sitemap/'
        + '?keyword=' + specs
        + '&latitude=' + lat
        + '&longitude=' + longitude,
        this.jsonHeader
      )
    }
  }

  getClinicDetails(id): Observable<any> {
    return this.https.get(
      this.gigaUrl + 'search/clinic/' + id + '/',
      this.jsonHeader
    )
  }

  getClinics(lat, long, keyword, pageNo, type): Observable<any> {
    return this.https.get(
      this.gigaUrl + 'searchdoctor?latitude=' + lat + '&longitude=' + long + '&keyword=' + keyword + '&page=' + pageNo + '&type=' + type,
      this.jsonHeader
    )
  }

  getSlotAvailable(clinic, doctor, date, time, type): Observable<any> {
    return this.https.get(
      this.gigaUrl + 'appointment/slot/availability/?clinic=' + clinic + '&doctor=' + doctor + '&date=' + date + '&time=' + time + '&slotType=' + type,
      this.jsonHeader
    )
  }

  changeMessage(message: string) {
    this.message.next(message)
  }

  updateAppointment(details): Observable<any> {
    let jsonHeader = {
      headers: new HttpHeaders({
        //'GIGATOKEN': this.userValue['details']['userId'],
        'GIGATOKEN':this.userValue ? this.userValue['details']['userId'] : this.jsonHeader.headers.get('GIGATOKEN'),
        'Content-Type': 'application/json',
      })
    };
    return this.https.put(
      this.gigaUrl + 'user/appointments/' + details.appointmentId + '/',
      details,
      jsonHeader
    )
  }

  getImmunizationSchedule(data): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'user/immunization/chart/',
      data,
      this.jsonHeader
    )
  }

  getFamilyMembers(): Observable<any> {
    let jsonHeader = {
      headers: new HttpHeaders({
        'GIGATOKEN': this.userValue['details']['userId'],
        'Content-Type': 'application/json',
      })
    };
    return this.https.get(
      this.gigaUrl + 'user/familymembers/',
      jsonHeader
    )
  }

  sendReminderOTP(data): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'user/sendotp/',
      data,
      this.jsonHeader
    )
  }

  verifyReminderOTP(data): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'user/mobileverification/',
      data,
      this.jsonHeader
    )
  }

  setReminder(data): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'user/immunization/reminder/',
      data,
      this.jsonHeader
    )
  }

  getLocation(lat, long): Observable<any> {
    let GOOGLE_MAP_KEY = "AIzaSyCV5pSKczNIvKssFVY4fp_M8IK7dg1CU98";
    return this.https.get(
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + long + '&key=' + GOOGLE_MAP_KEY
    )
  }

  getIpLookUp(): Observable<any> {
    return this.https.get('https://ipapi.co/json/');
  }

  addOnCallAppointment(data): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'user/appointments/oncall/',
      data,
      this.jsonHeader
    )
  }

  claimProfile(data): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'doctor/profile/claim/',
      data,
      this.jsonHeader
    )
  }

  reportError(data): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'doctor/profile/report/',
      data,
      this.jsonHeader
    )
  }

  sendAppLink(data: { mobile: any }): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'user/share/app/',
      data,
      this.jsonHeader
    )
  }

  getShortLinksByDoctor(url): Observable<any> {
    return this.https.get(
      this.gigaUrl + 'user/speciality/suggestions/' + url,
      this.jsonHeader
    )
  }

  noResults(lat, long): Observable<any> {
    if (lat && long) {
      return this.https.get(
        this.gigaUrl + 'search/sitemap/?latitude=' + lat + '&longitude=' + long,
        this.jsonHeader
      )
    }
  }

  getAppointmentDetails(appointmentId): Observable<any> {
    return this.https.get(
      this.gigaUrl + 'user/appointments/' + appointmentId,
      this.jsonHeader
    )
  }

  isFreeAppointment(details): Observable<any> {
    return this.https.post(
      this.gigaUrl + 'payment/appointment/isfree/',
      details,
      this.jsonHeader
    )
  }

  getPoints(): Observable<any> {
    let jsonHeader = {
      headers: new HttpHeaders({
        'GIGATOKEN': this.userValue['details']['userId'],
        'Content-Type': 'application/json',
      })
    };
    return this.https.get(
      this.gigaUrl + 'user/dashboard/',
      jsonHeader
    )
  }

  roleChange(): Observable<any> {
    let jsonHeader = {
      headers: new HttpHeaders({
        'GIGATOKEN': this.userValue['details']['userId'],
        'Content-Type': 'application/json',
      })
    };
    return this.https.get(
      '/role/user/roleswitch/change/',
      jsonHeader
    )
  } 

  getMedicalCouncils(): Observable<any> {
    return this.https.get(
      this.gigaUrl + 'doctor/medicalcouncil/'
    )
  }

  saveOrderData(orderInfo): Observable<any> {
    if (this.userValue == null) {
      var jsonHeader = this.jsonHeader;
    } else {
      var jsonHeader = {
        headers: new HttpHeaders({
          'GIGATOKEN': this.userValue['details']['userId'],
          'Content-Type': 'application/json',
        })
      };
    }
    return this.https.post(
      this.gigaUrl + 'user/thyrocare/appointments/',
      orderInfo,
      jsonHeader
    )
  }

  getIpAddress(): Observable<any> {
    return this.https.get(
      'https://api.ipify.org/?format=json'
    ).pipe();
  }

  getUserAge(date){
    var today = moment()
    var dob = moment(date,'DD-MM-YYYY')
    let years = moment().diff(dob, 'years')
    if (years == 0){
      this.convertedage = moment().diff(dob,'months');
      let months = new Number(this.convertedage/12).toPrecision(1); 
      this.metrics = this.convertedage > 1 ? 'Months' : 'Month'
    }else{
      this.convertedage = years
      this.metrics = this.convertedage > 1 ? 'Years' : 'Year'
    }
    let details = {
      months : years == 0 ? new Number(this.convertedage/12).toPrecision(1): 0,
      age : this.convertedage,
      units: this.metrics
    }
    return details
  }
  getSubscriptionPlans(): Observable<any> {
    return this.https.get(
      this.gigaUrl + 'subscription/details/',
      this.jsonHeader
    )
  }
}
