<app-header></app-header>
<div class="container">
    <div class="mt-5">
        <div class="grey-box">
            <div class="row">
                <div class="col-sm-12 col-md-4">
                        <img src="../../assets/images/plan2.png" class="img-responsive subs-img" />
                </div>
                <div class="col-sm-12 col-md-8">
                    <h2 class="subs-heading mt-2">Subscription plans to suit your requirements</h2>
                    <div class="subs-para">We are happy to bring you our subscription plans, each one is carefully crafted to ensure you get the 
                        best out of Gigadocs. Each plan gradually increases in features to ensure that you can upgrade your subscription plan as 
                        per your usage and your familiarity with the product. We are listing out our feature list below to help you decide which 
                        plan will suit your clinic the most. Please feel free to reach out to us if you require more assistance in finalizing the 
                        plan!
                    </div>
                    <button type="button" class="btn btn-getQuote col-md-3 p-2 mt-3" (click)='openGetQuoteModal(template)' href="javascript:void(0)">SUBSCRIBE</button>
                </div>
            </div>
        </div>

     
    </div>
</div>
<div class="main-block mb-5 table-responsive">
    <div class="container">
        <div class="mt-5">
           

         
            <div class="row  mt-5 ">
                <div class="col-md-3"></div>
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-3 bg-{{ plansJSON[i]?.name }}" *ngFor="let i of objectKeys( plansJSON)">
                            {{ plansJSON[i]?.name  }}
                        </div>
                    </div>
            
                </div>
            </div>
        </div>
      <div class=" grey-box1 text-center">
        <div class="row  ">
            <div class="col-md-3 subs-title">
                <h3>Our Subscription Plans</h3>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-3"  *ngFor="let i of objectKeys( plansJSON)">
                        <h4 class="main-text"><i class="fa fa-rupee"></i>{{ plansJSON[i]?.price }} <span class="sub-text">/ {{ plansJSON[i]?.validity }}</span></h4>
                    </div>
                </div>
        
            </div>
        </div>
      </div>
    
      <div class="  grey-box text-center" *ngFor="let i of objectKeys(featureJSON)">
        <div class="row  " >
            <div class="col-md-3 subs-title">
                <h4><a href="javascript:void(0)" data-toggle="tooltip" placement="right" ngbTooltip="{{ featureJSON[i].tooltip }}">{{ featureJSON[i].feature }}</a></h4>
                
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-3 ">
                        <h4 *ngIf="(featureJSON[i].freemium != 'True' && featureJSON[i].freemium != 'False')">{{ featureJSON[i].freemium }}</h4>
                        <h4 *ngIf="(featureJSON[i].freemium == 'True')"><i class="fa fa-check" style="font-size:24px;color: green;"></i></h4>
                        <h4 *ngIf="(featureJSON[i].freemium == 'False')"><i class="fa fa-remove" style="font-size:24px;color: red;"></i></h4>
                    </div>
                    <div class="col-md-3">
                        <h4 *ngIf="(featureJSON[i].silver != 'True' && featureJSON[i].silver != 'False')">{{ featureJSON[i].silver }}</h4>
                        <h4 *ngIf="(featureJSON[i].silver == 'True')"><i class="fa fa-check" style="font-size:24px;color: green;"></i></h4>
                        <h4 *ngIf="(featureJSON[i].silver == 'False')"><i class="fa fa-remove" style="font-size:24px;color: red;"></i></h4>
                    </div>
                    <div class="col-md-3">
                        <h4  *ngIf="(featureJSON[i].gold != 'True' && featureJSON[i].gold != 'False')">{{ featureJSON[i].gold }}</h4>
                        <h4 *ngIf="(featureJSON[i].gold == 'True')"><i class="fa fa-check" style="font-size:24px;color: green;"></i></h4>
                        <h4 *ngIf="(featureJSON[i].gold == 'False')"><i class="fa fa-remove" style="font-size:24px;color: red;"></i></h4>
                    </div>
                    <div class="col-md-3">
                        <h4  *ngIf="(featureJSON[i].platinum != 'True' && featureJSON[i].platinum != 'False')">{{ featureJSON[i].platinum }}</h4>
                        <h4 *ngIf="(featureJSON[i].platinum == 'True')"><i class="fa fa-check" style="font-size:24px;color: green;"></i></h4>
                        <h4 *ngIf="(featureJSON[i].platinum == 'False')"><i class="fa fa-remove" style="font-size:24px;color: red;"></i></h4>
                    </div>
                </div>
        
            </div>
        </div>
      </div>   
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
       <h4 class="modal-title pull-left">Get Quote</h4>
       <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">×</span>
       </button>
    </div>
    <div class="modal-body">
        <div class="login-modal-wrap">
            <div *ngIf="getQuoteSent" class="text-center">
                <i class="fa fa-check-circle f-60 color-green" aria-hidden="true"></i><br>
                <b>Thank you!</b> <br>
                Your details have been submitted, <br>
                Our team will contact you shortly
            </div>
            <div *ngIf="getQuoteSentError" class="text-center">
                <i class="fa fa-times-circle f-60 color-red" aria-hidden="true"></i>
                Sorry! <br>
                Your details have not been submitted, Please try again
                
            </div>
            <form #login="ngForm" [formGroup]="getQuoteForm" class="mx-2" *ngIf="!getQuoteSent">
                <!-- <div class="alert alert-warning" *ngIf="isLoading">
                    <span class="spinner-border spinner-border-sm"></span>
                     Please wait, Processing...
                </div> -->
               
                <div class="row" >
                    <div class="form-group required col-md-6 p-1">
                        <label class="control-label">Name</label>
                        <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" 
                               [ngClass]="{ 'is-invalid': submitted && quoteForm.name.errors }">
                        <div *ngIf="submitted && quoteForm.name.errors">
                            <small *ngIf="quoteForm.name.errors.required" class="text-danger">Name is required</small>
                            <small *ngIf="quoteForm.name.errors?.minlength" class="text-danger">Name should be minimum 3 characters</small>
                        </div>       
                    </div>
                    <div class="form-group required col-md-6 p-1">
                        <label class="control-label">Mobile</label>
                        <input type="text" class="form-control" placeholder="Enter Number" formControlName="phone"
                               [ngClass]="{'is-invalid': submitted && quoteForm.phone.errors}">
                        <div *ngIf="submitted && quoteForm.phone.errors">
                            <small *ngIf="quoteForm.phone.errors.required" class="text-danger">Mobile number is required</small>
                            <small *ngIf="quoteForm.phone.errors?.minlength || quoteForm.phone.errors?.maxlength || quoteForm.phone.errors.pattern" class="text-danger">
                                Enter a valid mobile number
                            </small>
                        </div>         
                    </div>
                </div>
                
                <div class="form-group required">
                    <label class="control-label">Email</label>
                    <input type="text" class="form-control" placeholder="Enter Email" formControlName="email"
                        [ngClass]="{'is-invalid': submitted && quoteForm.email.errors}">
                    <div *ngIf="submitted && quoteForm.email.errors">
                        <small *ngIf="quoteForm.email.errors.required" class="text-danger">Email is required</small>
                        <small *ngIf="quoteForm.email.errors.pattern" class="text-danger">Enter a valid email</small>
                    </div>    
                </div>
                <div class="row">
                    <div class="form-group required col-md-6 p-1">
                        <label class="control-label">City</label>
                        <input type="text" class="form-control" placeholder="Enter City" formControlName='city'
                               [ngClass]="{'is-invalid': submitted && quoteForm.city.errors}">
                        <div *ngIf="submitted && quoteForm.city.errors">
                            <small *ngIf="quoteForm.city.errors.required" class="text-danger">City name is required</small>
                            <small *ngIf="quoteForm.city.errors.pattern" class="text-danger">Enter a valid city</small>
                        </div>             
                    </div>
                    <div class="form-group col-md-6 p-1">
                        <label class="control-label">Speciality</label>
                        <input type="text" class="form-control" placeholder="Enter Speciality" formControlName='speciality'>
                                   
                    </div>
                    
                </div>
                <div class="row">
                    <div class="form-group required col-md-6 p-1">
                        <label class="control-label">Clinic Name</label>
                        <input type="text" class="form-control" placeholder="Enter Clinic Name" formControlName='clinicname'
                               [ngClass]="{'is-invalid': submitted && quoteForm.clinicname.errors}">
                        <div *ngIf="submitted && quoteForm.clinicname.errors">
                            <small *ngIf="quoteForm.clinicname.errors.required" class="text-danger">Clinic name is required</small>
                            
                        </div>             
                    </div>
                    <div class="form-group required col-md-6 p-1">
                        <label class="control-label">Subscription Plans</label>
                        <select class="form-control" formControlName='plans' [ngClass]="{'is-invalid': submitted && quoteForm.plans.errors}">
                            <option value="" [selected]="isSelected" disabled>Select an option</option>
                            <option value="silver">Silver</option>
                            <option value="gold">Gold</option>
                            <option value="platinum">Platinum</option>
                        </select>
                        <div *ngIf="submitted && quoteForm.plans.errors">
                            <small *ngIf="quoteForm.plans.errors.required" class="text-danger">Subscription plan is required</small>
                            
                        </div>  
                    </div>
                    
                </div>
                
                <div class="form-group">
                    <div class="row align-items-center">
                        <div class="col-sm-7 p-0">
                            <input type="button" class="btn btn-primary pl-5 pr-5" value="Submit" [disabled]="isLoading" (click)="submitGetQuote()">
                        </div>
                    </div>
                </div>
                
            </form>                 
        </div>
    </div>   
    <div class="modal-footer">
       <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
    </div>
 </ng-template>
<script>
    $(document).ready(function(){
      $('[data-toggle="tooltip"]').tooltip();   
    });
</script>
<app-footer lat="17.4611" long="78.355797"></app-footer>
