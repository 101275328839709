import { Component, OnInit,Input , OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UserService } from '../service/user.service';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit,OnChanges  {
  @Output() data:EventEmitter<any> =new EventEmitter<any>();
  @Input('city') city:string;
  @Input('area') area:string;
  @Input('lat') lat:any;
  @Input('long') longitude:any;
  @Input('specality') specality:string;
  footerResults: any;
  citySiteMap: [];
  serviceSiteMap: [];
  specialitySiteMap:[];
  specsCheck: string;
  latCheck: any;
  longitudeCheck: any;
  footerLoading: boolean;

  constructor(
    public userService:UserService,
    private router: Router,
    private activatedroute: ActivatedRoute
  ) { }

  ngOnChanges(element:SimpleChanges){
    var re = /[-]/g;
    this.activatedroute.params.subscribe(params => {
      if(params['name'] && isNaN(params['name'])) {
        let area = params['name'].replace(re, ' ');
        this.latLong(area);
      } else if(params['city'] && isNaN(params['city'])) {
        let city = params['city'].replace(re, ' ');
        this.latLong(city);
      } else {
        this.dynamicFooters();
      }
    });
  }

  ngOnInit(): void {
    this.dynamicFooters();
  }

  latLong(city) {
    this.userService.getCoords(city).subscribe(response => {
      if (response.details.length > 0) {
        let record = response.details.filter(item => item.name ? item.name.toLowerCase() == city : city && item.city ? item.city.toLowerCase() == this.city.toLowerCase(): this.city.toLowerCase());
        if (record.length > 0) {
          this.lat = record[0]['latitude'];
          this.longitude = record[0]['longitude'];
          this.dynamicFooters();
        }
      }
    });
  }

  dynamicFooters() {
    this.footerLoading = true;
    this.specsCheck = this.specality;
    this.latCheck = this.lat;
    this.longitudeCheck = this.longitude;
    if(!this.specality) {
      this.specality = 'dentist';
    }
    if(this.specality && this.lat && this.longitude){
      this.userService.dynamicFooter(this.specality, this.lat, this.longitude).subscribe(data => {
        this.footerResults = data['details'];
        this.citySiteMap = this.footerResults['citySiteMap'];
        this.serviceSiteMap = this.footerResults['serviceSiteMap'];
        this.specialitySiteMap = this.footerResults['specialitySiteMap'];
        this.data.emit(this.serviceSiteMap);
      })
    }
  }

  gotoVaccination() {
    this.router.navigateByUrl('home/immunizationschedule');
  }
}
