import { UserService } from '../service/user.service';
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, of, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap} from 'rxjs/operators';
import {logger} from "codelyzer/util/logger";
import {Component, OnInit, ViewChild} from "@angular/core";
import {Calendar} from "primeng/calendar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../models";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-clinicdetails',
  templateUrl: './clinicdetails.component.html',
  styleUrls: ['./clinicdetails.component.css']
})
export class ClinicdetailsComponent implements OnInit {
  @ViewChild('calendar') calendar: Calendar;
  @ViewChild('calendarvc') calendarVC: Calendar;
  @ViewChild('calendarcall') calendarCall: Calendar;
  @ViewChild('close') close;
  readMore: boolean=false;
  sendAppLinkForm: FormGroup;
  clinicData: any;
  errorMessage: boolean;
  errorNotification: any;
  id: any;
  inClinic: boolean = false;
  isOnline: boolean = false;
  isOnCall: any = '0';
  doctorName: any;
  clinicDoctor: any;
  morningSlots: any = [];
  afternoonSlots: any = [];
  eveningSlots: any = [];
  morningSlotsLength: any;
  afternoonSlotsLength: any;
  eveningSlotsLength: any;
  morningVCSlots: any = [];
  afternoonVCSlots: any = [];
  eveningVCSlots: any = [];
  morningVCSlotsLength: any;
  afternoonVCSlotsLength: any;
  eveningVCSlotsLength: any;
  hours: any;
  time: any;
  selectedIndex: any;
  selectedVCIndex: any;
  selectedCallIndex: any;
  selectedTime: any;
  selectedVCTime: any;
  selectedCallTime: any;
  todayApply: boolean = true;
  todayApplyVC: boolean = true;
  todayCall: boolean = true;
  tomorrowApply: boolean = false;
  tomorrowApplyVC: boolean = false;
  tomorrowCall: boolean = false;
  dateHighlight: boolean = false;
  dateHighlightVC: boolean = false;
  dateHighlightCall: boolean = false;
  datePicker: Date;
  datePickerVC: Date;
  datePickerCall: Date;
  minDate: Date;
  maxDate: Date;
  slotType: any;
  date: any;
  dateVC: any;
  docId: any;
  docUrl: any;
  btnDisable: boolean = true;
  relatedSpecialityArray: any = [];
  submitted: boolean = false;
  isReadonly = false;
  user: User;
  message: any = '';
  sendLink: boolean = false;
  sentLink: boolean = false;
  showClinic: number;
  showOnline: number;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  inclinicSpinner: boolean;
  vcSpinner: boolean;
  clinicImage: any;
  clinicDescription: any;

  constructor(
    private userService:UserService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {  }

  ngOnInit() {
    this.route.params.subscribe(param =>{
      this.id = param['id'];
    })
    this.clinicDetails(this.id);
    let today = new Date();
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.dateVC = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.minDate = new Date(today);
    this.maxDate = new Date(today.setDate(today.getDate() + 14));
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' });
    this.sendAppLinkForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}'/*"^[0-9]*$"*/),
        Validators.minLength(10), Validators.maxLength(10)]]
    });
  }

  RelatedSpecialty(data:any) {
    this.relatedSpecialityArray = data;
  }

  clinicDetails(id) {
    this.userService.getClinicDetails(id).subscribe(response => {
      debounceTime(500);
      distinctUntilChanged();
      this.clinicData = response.details[0];
      this.clinicImage = this.clinicData['clinicImage']
      this.clinicDescription = this.clinicData['clinicDescription']
      var details = {
        metaTitle: this.clinicData.clinicName + ' - ' + this.clinicData.clinicArea + ' - View Fees | Gigadocs',
        metaDescription: 'Book an appointment with ' + this.clinicData.clinicName + ', ' + this.clinicData.clinicArea + ', ' + this.clinicData.clinicCity,
        ogTitle: this.clinicData.clinicName + ' - ' + this.clinicData.clinicArea + ' - View Fees | Gigadocs',
        ogDescription: 'Book an appointment with ' + this.clinicData.clinicName + ', ' + this.clinicData.clinicArea + ', ' + this.clinicData.clinicCity,
      };
      this.userService.setDynamicMetaData(details);
      this.clinicData.doctors.forEach(doctor => {
        let exp = 0;
        let experince = doctor['experience'];
        if (experince && experince != '') {
          let date = new Date();
          let year = date.getFullYear();
          exp = year - parseInt(experince);
        }
        let languages = [];
        if(doctor.language.length > 0) {
          languages = doctor.language.map(item => item.name);
          doctor['language'] = languages;
        }
        doctor['exp'] = exp;
      });
    });
  }

  Error(data){
    this.errorNotification=data;
    this.errorMessage=true;
    setTimeout(() => {
      this.errorMessage=false;
    }, 5000);
  }

  getPopupSlots(nameUrl, showClinic = 0, showOnline = 0) {
    this.clearArraydata('inclinic');
    this.clearArraydata('vc');
    this.userService.getDoctorByName(nameUrl).subscribe(response => {
      let doctorData = response['details']['clinics'].filter(i => i.id == this.id);
      this.clinicDoctor = response['details'];
      this.docId = this.clinicDoctor.id;
      this.docUrl = this.clinicDoctor.nameUrl;
      this.inClinic = doctorData[0].isInClinic;
      this.isOnline = doctorData[0].isOnline;
      this.isOnCall = doctorData[0].onCall;
      this.showClinic = showClinic;
      this.showOnline = showOnline;
      this.doctorName = this.clinicDoctor.name;
      let today = this.datePipe.transform(new Date(),"yyyy-MM-dd");
      if(this.inClinic && showClinic) {
        //this.clearArraydata('inclinic');
        this.datePicker = null;
        this.availableSlots(today, 'inclinic');
        setTimeout(() => {
          this.calendar.value = null;
        }, 100);
      }
      if(this.isOnline && showOnline) {
        //this.clearArraydata('vc');
        this.datePickerVC = null;
        this.availableSlotsVC(today, 'vc');
        setTimeout(() => {
          this.calendarVC.value = null;
        }, 100);
      }
    })
  }

  availableSlots(date: any, slot: any) {
    this.userService.getClinicslots(this.docId, this.id, date, slot).subscribe((response) => {
      if (slot == "inclinic") {
        if(response['details']['status']==false){
          this.morningSlotsLength = 0;
          this.afternoonSlotsLength = 0;
          this.eveningSlotsLength = 0;

          this.inclinicSpinner =  this.vcSpinner = false;
          this.morningSlots = [];
          this.afternoonSlots = [];
          this.eveningSlots =[];
        }else{
          this.morningSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningSlotsLength = response['details'][0]['evening']['length'];

          this.inclinicSpinner =  this.vcSpinner = false;
          this.morningSlots = this.timeConversion(response['details'][0]['morning']);
          this.afternoonSlots = this.timeConversion(response['details'][0]['afternoon']);
          this.eveningSlots = this.timeConversion(response['details'][0]['evening']);
        }
      }
    })
  }

  availableSlotsVC(date: any, slot: any) {
    this.userService.getClinicslots(this.docId, this.id, date, slot).subscribe((response) => {
      if (slot == "vc") {
        if(response['details']['status']==false){
            this.morningVCSlotsLength = 0;
            this.afternoonVCSlotsLength = 0;
            this.eveningVCSlotsLength = 0;

            this.inclinicSpinner =  this.vcSpinner = false;
            this.morningVCSlots = [];
            this.afternoonVCSlots = [];
            this.eveningVCSlots = [];
        }else{
          this.morningVCSlotsLength = response['details'][0]['morning']['length'];
          this.afternoonVCSlotsLength = response['details'][0]['afternoon']['length'];
          this.eveningVCSlotsLength = response['details'][0]['evening']['length'];

          this.inclinicSpinner =  this.vcSpinner = false;
          this.morningVCSlots = this.timeConversion(response['details'][0]['morning']);
          this.afternoonVCSlots = this.timeConversion(response['details'][0]['afternoon']);
          this.eveningVCSlots = this.timeConversion(response['details'][0]['evening']);
        }
        
      }
    })
  }

  timeConversion(slot: any = []) {
    while (slot.length >= 1) {
      let convertedTime: any[] = [];
      for (let i = 0; i < slot['length']; i++) {
        this.time = slot[i].split(":", 3);
        var ampm = this.time[0] >= 12 ? 'PM' : 'AM';
        ampm == 'PM' && this.time[0] == 12 ? this.hours = 12 : this.hours = this.time[0] % 12;
        this.hours < 10 ? this.hours = '0' + this.hours : this.hours;
        convertedTime.push(this.hours + ':' + this.time[1] + ' ' + ampm);
      }
      return convertedTime;
    }
  }

  today(slot: string) {
    this.datePicker = null;
    this.calendar.value = null;
    this.clearArraydata(slot);
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowApply = false;
    this.todayApply = true;
    this.availableSlots(this.date, slot);
  }

  todayVC(slot: string) {
    this.datePickerVC = null;
    this.calendarVC.value = null;
    this.clearArraydata(slot);
    this.dateVC = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowApplyVC = false;
    this.todayApplyVC = true;
    this.availableSlotsVC(this.dateVC, slot);
  }

  todayOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    this.date = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.tomorrowCall = false;
    this.todayCall = true;
  }

  tomorrow(slot: string) {
    this.datePicker = null;
    this.calendar.value = null;
    this.clearArraydata(slot);
    let today = new Date();
    this.date = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayApply = false;
    this.tomorrowApply = true;
    this.availableSlots(this.date, slot);
  }

  tomorrowVC(slot: string) {
    this.datePickerVC = null;
    this.calendarVC.value = null;
    this.clearArraydata(slot);
    let today = new Date();
    this.dateVC = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayApplyVC = false;
    this.tomorrowApplyVC = true;
    this.availableSlotsVC(this.dateVC, slot);
  }

  tomorrowOnCall(slot: string) {
    this.datePickerCall = null;
    this.dateHighlightCall = false;
    this.calendarCall.value = null;
    let today = new Date();
    this.date = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)),"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = true;
  }

  selectedDate(event: any, slot: string) {
    this.clearArraydata(slot);
    this.dateHighlight = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayApply = false;
    this.tomorrowApply = false;
    this.availableSlots(this.date, slot);
  }

  selectedVCDate(event: any, slot: string) {
    this.clearArraydata(slot);
    this.dateHighlightVC = true;
    this.dateVC = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayApplyVC = false;
    this.tomorrowApplyVC = false;
    this.availableSlotsVC(this.dateVC, slot);
  }

  selectedOnCallDate(event: any, slot: string) {
    this.dateHighlightCall = true;
    this.date = this.datePipe.transform(event,"yyyy-MM-dd");
    this.todayCall = false;
    this.tomorrowCall = false;
  }

  Time(index, time, slot) {
    this.slotType = slot;
    this.selectedVCIndex = -1;
    this.selectedVCTime = null;
    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.btnDisable = true;
    } else {
      this.selectedIndex = index;
      this.selectedTime = time;
      this.btnDisable = false;
    }
  }

  VCTime(index, time, slot) {
    this.slotType = slot;
    this.selectedIndex = -1;
    this.selectedTime = null;
    if (this.selectedVCIndex == index) {
      this.selectedVCIndex = -1;
      this.selectedVCTime = null;
      this.btnDisable = true;
    } else {
      this.selectedVCIndex = index;
      this.selectedVCTime = time;
      this.btnDisable = false;
    }
  }

  CallTime(index, time, slot) {
    this.slotType = slot;
    if (this.selectedCallIndex == index) {
      this.selectedCallIndex = -1;
      this.selectedCallTime = null;
      this.btnDisable = true;
    } else {
      this.selectedCallIndex = index;
      this.selectedCallTime = time;
      this.btnDisable = false;
    }
  }

  clearArraydata(slot: string) {
    this.btnDisable = true;
    if(slot == 'inclinic') {
      this.morningSlots = [];
      this.afternoonSlots = [];
      this.eveningSlots = [];
      this.morningSlotsLength = 0;
      this.afternoonSlotsLength = 0;
      this.eveningSlotsLength = 0;
      this.inclinicSpinner =true;
      this.todayApply = true;
      this.tomorrowApply = false;
      this.selectedIndex = -1;
      this.selectedTime = null;
      this.dateHighlight = false;
    } else if(slot == 'vc') {
      this.morningVCSlots = [];
      this.afternoonVCSlots = [];
      this.eveningVCSlots = [];
      this.morningVCSlotsLength = 0;
      this.afternoonVCSlotsLength = 0;
      this.eveningVCSlotsLength = 0;
      this.vcSpinner = true;
      this.todayApplyVC = true;
      this.tomorrowApplyVC = false;
      this.selectedVCIndex = -1;
      this.selectedVCTime = null;
      this.dateHighlightVC = false;
    }
  }

  confirmAppointment() {
    setTimeout(() => {
      this.close.nativeElement.click();
      this.router.navigate(['appointment/', this.docUrl], {
        queryParams: {
          clinicId: this.id,
          on: this.slotType == "vc" ? this.dateVC : this.date,
          at: this.slotType == "vc" ? this.selectedVCTime : this.selectedTime,
          slot: this.slotType
        }
      })
    }, 1000);
  }

  onCallAppointment() {
    setTimeout(() => {
      this.close.nativeElement.click();
      this.router.navigate(['appointment/', this.docUrl], {
        queryParams: {
          doctorId: this.docId,
          clinicId: this.id,
          on: this.date,
          at: this.selectedCallTime,
          slot: this.slotType
        }
      })
    }, 1000);
  }

  get applink() {
    return this.sendAppLinkForm.controls;
  }

  sendAppLink() {
    this.submitted = true;
    if (this.applink.mobile.valid) {
      this.submitted = false;
      this.sendLink = true;
      let data = {
        mobile: this.sendAppLinkForm.value.mobile.toString()
      };
      this.userService.sendAppLink(data).subscribe(response => {
        if (response.status) {
          this.sendLink = false;
          this.sentLink = true;
          setTimeout(() => {
            this.sendAppLinkForm.reset();
            this.sendLink = false;
            this.sentLink = false;
          }, 3000);
        }
      });
    }
  }

}
