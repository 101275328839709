
<div class="page-wrap d-flex flex-row align-items-center" *ngIf="NotFound">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center">
                <span class="display-1 d-block">404</span>
                <div class="mb-4 lead">The page you are looking for was not found.</div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner
  bdColor="#fff"
  size="medium"
  color="#FA030E"
  type="ball-pulse"
>
<p style="font-size: 20px; color:#FA030E"> Loading...</p>
</ngx-spinner>
<div *ngIf="resultsFound">

    <app-header nextpage = "{{landingpage}}" scrWidth = "{{scrWidth}}"></app-header>

 <div class="search-page">
     <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10 search">
                <div class="ban-search">
                    <div class="s-left">
                        <div class="locate-icon">
                            <i class="gicon location"></i>
                        </div>
                        <div class="l-txt">
                          <input [(ngModel)]="area"
                                 [typeahead]="filteredArea$"
                                 (typeaheadOnSelect)="selectedArea($event)"
                                 [typeaheadMinLength]="2"
                                 [typeaheadItemTemplate]="areaItemTemplate"
                                 [typeaheadOptionsLimit]="15"
                                 typeaheadOptionField="name"
                                 placeholder="Location"
                                 class="searchAreaTextbox">
                          <ng-template #areaItemTemplate let-brand="item">
                            <div class="ui-helper-clearfix">
                              <div style="margin:5px 5px 0 0;font-size: 15px;word-break: normal;white-space: normal;">
                                {{brand.name | camelcase}}
                              </div>
                              <div *ngIf="brand.name != brand.city" style="font-size:12px;margin-top:3px">
                                <b>{{brand.city | camelcase}}</b>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                    </div>
                    <div class="s-right">
                        <div class="search-icon">
                            <i class="gicon search"></i>
                        </div>
                        <div class="search-txt">
                          <input [(ngModel)]="speciality"
                                 [typeahead]="filteredSpeciality$"
                                 (typeaheadOnSelect)="selectedSpec($event)"
                                 [typeaheadMinLength]="2"
                                 [typeaheadItemTemplate]="specItemTemplate"
                                 [typeaheadOptionsLimit]="15"
                                 typeaheadOptionField="name"
                                 placeholder="Search Speciality, service, symptoms, clinic, doctor,  etc"
                                 class="searchSpecialityTextbox" autofocus>
                          <ng-template #specItemTemplate let-brand="item">
                            <div class="ui-helper-clearfix">
                              <div style="float:left;margin:5px 5px 0 0;font-size: 15px;word-break: normal;white-space: normal;">
                                {{brand.name}}
                              </div>
                              <div style="font-size:12px;float:right;margin-top:10px">
                                <b *ngIf = " brand.type == 'clinic' && brand.area == 'clinic' || brand.type == 'doctor' && brand.speciality == 'doctor'" innerHTML="{{searchicon}}"></b>
                                <b *ngIf = "brand.type == 'clinic' && brand.area != 'clinic'">{{brand.area | camelcase}}</b>
                                <b *ngIf = "brand.type == 'doctor' && brand.speciality != 'doctor'">{{brand.speciality | camelcase}}</b>
                                <b *ngIf = "brand.type != 'clinic' && brand.type != 'doctor'">{{brand.type | camelcase}}</b>
                                <b *ngIf = "brand.speciality == ''">{{brand.type | camelcase}}</b>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="homepage">
     <div class="container-slider">
        <div id="mycarousel" class="carousel slide carousel-fade" data-interval="8000" data-ride="carousel">
                 <!--Indicators-->
            <ol class="carousel-indicators">
                <li data-target="#mycarousel" data-slide-to="0" class="active"></li>
                <li data-target="#mycarousel" data-slide-to="1"></li>
                <li data-target="#mycarousel" data-slide-to="2"></li>
                <li data-target="#mycarousel" data-slide-to="3"></li>
                <li data-target="#mycarousel" data-slide-to="4"></li>
            </ol>
              <!--/.Indicators-->
            <div class="carousel-inner" >
                <div class="carousel-item active">
                    <div class="banner">
                        <img src="assets/images/slide1.png"class="w-100 img-responsive" alt="Virtual Consultation">
                    </div>
                    <div class ="carousel-caption text-left">
                        <h2 class="animate__animated animate__slideInDown pb-3">Why travel when you are unwell? Meet a Doctor online!</h2>
                        <p class=" font-italic animate__animated animate__zoomIn" style="animation-delay:1s">“Gigadocs brings you online consultations. Now meet a specialist within the privacy of your home.”</p>
                        <p>
                          <a [routerLink]="['/virtualconsultation/doctors']">Consult Now</a>
                          <a target="_blank" class="how-it-works" [routerLink]="['/home/howitworks']">How It Works?</a>
                        </p>
                    </div>
                </div>
                <div class="carousel-item ">
                  <div class="banner">
                    <img src="assets/images/lab1.jpg"class="w-100 img-responsive" alt="Diagnostic Center">
                  </div>
                  <div class ="carousel-caption text-left">
                    <h2 class="animate__animated animate__backInDown pb-2">"Now get your Diagnostic test from home"</h2>
                    <p class="animate__animated animate__fadeInLeft font-italic" style="animation-delay:1s">“You can now book Diagnostic tests through Gigadocs at the best prices. Select a comfortable time for home sample collection and we are good to go!”</p>
                    <a href="/diagnostic-center">Click Here</a>
                  </div>
                </div>
                <div class="carousel-item ">
                  <div class="banner">
                    <img src="assets/images/slide7.jpg"class="w-100  img-responsive" alt="virtual Consultation">
                  </div>
                  <div class ="carousel-caption text-left">
                    <h2 class="animate__animated animate__backInDown pb-2">Keeping a tab on your entire family's healthcare has never been easier! </h2>
                    <p class="animate__animated animate__fadeInLeft font-italic  " style="animation-delay:1s">“Maintain your family's vitals, health records, prescriptions, appointments at your fingertips!”</p>
                    <a [routerLink]="['/store']">Download Now</a>
                  </div>
                </div>
                <div class="carousel-item ">
                  <div class="banner">
                    <img src="assets/images/slide8.jpg"class="w-100 img-responsive"  alt="Know More">
                  </div>
                  <div class ="carousel-caption text-left">
                    <h2 class="animate__animated animate__backInDown pb-2 ">"Are you keeping a track of your Kid's Immunization Schedule?"</h2>
                    <p class="animate__animated animate__fadeInLeft font-italic  " style="animation-delay:1s">“Get timely reminders, click here to know more!”</p>
                    <a [routerLink]="['/home/immunizationschedule']">View Vaccination Chart</a>
                  </div>
                </div>
                <div class="carousel-item ">
                  <div class="banner">
                    <img src="assets/images/slide3.jpeg"class="w-100 img-responsive bannerImage" alt="...">
                  </div>
                  <div class ="carousel-caption text-left">
                    <h3 class="animate__animated animate__backInDown pb-2">Covid-19 risk is everywhere outside your house. Eliminate the risk with Virtual Consultation!</h3>
                    <p class="animate__animated animate__fadeInRight font-italic pt-3" style="animation-delay:1s">“Gigadocs brings you online consultations. Now meet a specialist within the privacy of your home.”</p>
                    <p>
                      <a [routerLink]="['/virtualconsultation/doctors']">Consult Now</a>
                    </p>
                  </div>
                </div>
                <div class="carousel-item ">
                  <div class="banner">
                    <img src="assets/images/slide9.jpg"class="w-100 img-responsive" alt="virtual Consultation">
                  </div>
                  <div class ="carousel-caption text-left">
                    <h2 class="animate__animated animate__backInDown pb-2">"Missing out on your child's Vaccination dates?"</h2>
                    <p class="animate__animated animate__fadeInLeft font-italic" style="animation-delay:1s">“Immunization Chart is now enabled with Reminders!”</p>
                    <a [routerLink]="['/home/immunizationschedule']">View Vaccination Chart</a>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="diagnostic">
  <div class="container">
    <h3 class="font-weight-bold text-dark text-uppercase text-heddings">Diagnostic Center</h3>
    <div class="test-info">
      <div class="row">
        <div class="col-md-6">
          <img src="assets/images/center.png" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-md-6">
          <div class="thyro-content">
            <h3 class="text-center pt-4 mt-5">Get your Diagnostic Tests carried out from the comfort of your home.</h3>
            <p class="text-center pt-2">To know more about the tests and also the availability of Home Sample Collections please click here.</p>
            <div class="test-list">
              <a type="button" href="/diagnostic-center" class="btn btn-primary btn-lg">Click Here</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section id="features">
    <div class="container">
        <h4 class="font-weight-bold text-dark text-uppercase text-heddings">Features</h4>
        <h2 class="font-weight-bolder text-primary ">From Appointments to Health Record Maintenance,<br>Complete healthcare management in one app.</h2>
        <div class="screen-slider">
            <img src="assets/images/screen.png" class="img-fluid mx-auto d-block">
        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6 services-content">
                <span class="services-icon icon1"></span>
                <div class="services-body">
                    <h4 class="services-title font-weight-bold text-body">
                        Health Records
                    </h4>
                    <p class="services-text">
                        Digitally store the health records of your complete family. Built on AWS with special emphasis on Cyber Security, managing your family’s health was never easier or safer.
                    </p>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 services-content">
                <span class="services-icon icon2"></span>
                <div class="services-body">
                    <h4 class="services-title font-weight-bold text-body">
                        Digital Prescription
                    </h4>
                    <p class="services-text">
                        Access to Medicines just became easier and eco-friendly. No more headaches of filing and saving your prescriptions.
                    </p>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 services-content">
                <span class="services-icon icon3"></span>
                <div class="services-body">
                    <h4 class="services-title font-weight-bold text-body">
                        Vitals
                    </h4>
                    <p class="services-text">
                        Keep a tab on your health by updating and storing your Vitals regularly. You can also choose to share this info with your doctor, on real time basis.
                    </p>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 services-content">
                <span class="services-icon icon4"></span>
                <div class="services-body">
                    <h4 class="services-title font-weight-bold text-body">
                        Analytics
                    </h4>
                    <p class="services-text">
                        Be it the general graph of your health or the intricate functioning of a Clinic. Our advanced and in-depth Analytics converts normal data to useful information to give you that edge to stay ahead.
                    </p>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 services-content">
                <span class="services-icon icon5"></span>
                <div class="services-body">
                    <h4 class="services-title font-weight-bold text-body">
                        Expense Management
                    </h4>
                    <p class="services-text">
                        Finally an app that let you analyze and manage expenses related to a Clinic. Be it a recurring expense or a one time spend, keeping a tab and managing costs is just a click away.
                    </p>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 services-content">
                <span class="services-icon"></span>
                <div class="services-body">
                    <h4 class="services-title font-weight-bold text-body">
                        Appointments & Calendar
                    </h4>
                    <p class="services-text">
                        State-of-the-art search engine helps you get an appointment with the best doctors, at a click of a button. The equally advanced Calendar is customized for each doctor.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="aboutUs">
    <div class="container">
        <h4 class="font-weight-bold text-body text-uppercase text-heddings">About Us</h4>
        <h2 class="font-weight-bolder text-primary ">A thought being put into action, <br>is a vision being realized.</h2>
        <div class="row about-us-content">
            <div class="col-md-6">
                <img src="assets/images/about-us.png" class="img-fluid mx-auto d-block">
            </div>
            <div class="col-md-6">
                <p>
                    The name Gigadocs is an amalgamation of the words, Giga meaning a Million and Docs meaning Documents. The ultimate goal of Gigadocs is to create a Healthcare Eco-system that pertains and serves every aspect of healthcare. Be it a simple doctor consultation or maintaining the complete family’s health records, including navigating the complicated insurance policies, Gigadocs strives to be a one stop shop that blankets over all aspects of healthcare, the smart way of course!!
                </p>
                <h3 class="font-weight-bolder text-primary text-heddings">
                    Vision and Mission
                </h3>
                <p>
                    To create and provide a strong, reliable and supportive database for doctors and clinics and contributing to a world fueled by good health.
                </p>
                <a type="button"  href="newModal" data-toggle="modal" data-target="#newModal" id="try" data-dismiss="modal" class="btn btn-danger">Signup Now</a>
            </div>
        </div>
    </div>
</section>
<section class="whyUs" id="whyUs">
    <div class="container">
        <div class="row about-us-content">
            <div class="col-md-7">
                <h4 class="font-weight-bold text-body text-uppercase text-heddings">Why Gigadocs</h4>
                <h3 class="font-weight-bolder text-primary text-heddings">Bringing tomorrow’s advanced future, to today’s present healthcare!!</h3>
                <p>
                    Gigadocs has been specifically designed to integrate with your lifestyle. It adapts seamlessly and makes the access to healthcare easier whenever possible. It provides a comprehensive search facility that lets you pick and choose what kind of healthcare you need. Be it doctors with specific specialty or local pharmacies, Gigadocs helps you to get connected to the right person and all this can be done within a selected location with just a click.
                </p>
                <!-- <blockquote class="blockquote">
                    <p class="font-weight-bold text-body text-heddings">Allianz Partners</p>
                    <p>Throughout our engagement we continue to be impressed with Infermedica’s involvement and the level of provided support.</p>
                    <footer class="blockquote-footer text-primary">Dr. Matthias Kuss, Head of Health Innovation</footer>
                </blockquote> -->
            </div>
            <div class="col-md-5">
                <img src="assets/images/why.png" class="img-fluid mx-auto d-block" height="300" width="300">
            </div>

        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row ">
            <div class="col-md-6 advantages-body">
                <span class="services-icon icon7"></span>
                <div class="services-body">
                    <p class="services-title font-weight-bold text-body">
                        <small class="font-weight-bold">Advantages for</small><br>Users
                    </p>
                    <ul>
                        <li>Find Doctors</li>
                        <li>Online Appointment Booking</li>
                        <li>Manage Family Health Records</li>
                        <li>Store and Update Vitals</li>
                        <li>Digital Prescriptions</li>
                        <li>Integrated Healthcare App</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 advantages-body">
                <span class="services-icon icon8"></span>
                <div class="services-body">
                    <p class="services-title font-weight-bold text-body">
                        <small class="font-weight-bold">Advantages for</small><br>Clinics
                    </p>
                    <ul>
                        <li>Create Online Presence</li>
                        <li>Reduce Patient Wait Time</li>
                        <li>Convert to Paperless Clinic</li>
                        <li>Individual Calendar for Doctors</li>
                        <li>Inventory Management</li>
                        <li>Expense Management</li>
                        <li>Advanced Analytics</li>
                        <li>Seamless interaction with Patients</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row ">
            <div class="col-md-6 d-none d-sm-block">
                <img src="assets/images/mobile-app.png" class="img-fluid mx-auto d-block mobile-app-img">
            </div>
            <div class="col-md-6 app-content">
                <h4 class="font-weight-bold text-body text-uppercase text-heddings">Mobile</h4>
                <h3 class="font-weight-bolder text-primary text-heddings">Download the <br>Gigadocs app</h3>
                <p>
                    Gigadocs has been specifically designed to integrate with your lifestyle. It adapts seamlessly and makes the access to healthcare easier whenever possible.
                </p>
                <div class="row">
                    <div class="col-md-6">
                        <a href="https://play.google.com/store/apps/details?id=com.gigadocs.app" target="_blank"><img src="assets/images/play-store.png" class="img-fluid mx-auto d-block"></a>
                    </div>
                    <div class="col-md-6">
                        <a href="https://apps.apple.com/in/app/gigadocs/id1447077786" target="_blank"><img src="assets/images/app-store.png" class="img-fluid mx-auto d-block"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
<div id="popupModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-popup modal-dialog-centered" role="document">
    <div class="modal-content">
      <a href="/diagnostic-center">
        <img src="/assets/images/thyrocare-popup.gif" class="thyrocare-image" height="400" alt="GigaDocs">
      </a>
    </div>
  </div>
</div>
<app-footer lat="{{lat}}" long="{{long}}"></app-footer>
