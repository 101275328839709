
<!---
<a href="javascript:void(0)" class="btn-mapview btn-listview hidden" id="listview"><i class="ic-list"></i>&nbsp; List View</a>
<div id="sr-map" style="height: 100%">
    <div id="map" style="width: 100%; height: 100%"></div>
</div>
-->
<google-map  [center]="center" [zoom]="12.5" 
                        height="640px"
                        width="100%">
                        <map-marker
                            #markerElem="mapMarker"
                            *ngFor="let marker of addressCoords"
                            [position]="marker.position"
                            (mapClick)="openInfo(markerElem, marker.info.clinicName, marker.info.address )"                               
                            [title]="marker.title"
                        >
                        </map-marker>
                        <map-info-window>
                            <h5>{{ infoContent }}</h5>
                            <p>{{infoContent1}}</p>
                        </map-info-window>
</google-map>




