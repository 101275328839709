<ngx-spinner
  bdColor="#fff"
  size="medium"
  color="#FA030E "
  type="ball-pulse"
>
<p style="font-size: 20px; color:#FA030E">Please wait do not refresh the page.</p>
</ngx-spinner>

<app-header></app-header>

<div class="container-main">
    <div class="row vdivide">
        <div class="col-md-6">
            <div class="signup-form pt-5">
                <form  [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <h4 class="font-weight-bold">New User Registration</h4>
                    <!--<p class="hint-text">Are you a doctor or have pharmacy or lab?</p> -->

                    <div class="form-group" *ngIf="show">
                      <label >Account Type</label>
                      <input type="text" class="form-control" formControlName="accountType" name="accountType" readonly />
                    </div>
                    <div class="form-group">
                         <label>Full Name</label>
                        <input type="text" class="form-control" formControlName="name" name="name" placeholder="Enter your full name"  />

                          <small class="text-danger" *ngIf="form.name.touched && form.name.errors?.required">*Name required</small>

                    </div>
                      <div class="form-group">
                         <label>Email Address</label>
                        <input type="email" class="form-control"  formControlName="email" name="email" placeholder="Enter your email address" />
                        <small class="text-danger" *ngIf="form.email.touched && form.email.errors?.required">*Email required</small>
                        <small class="text-danger" *ngIf="form.email.touched && form.email.errors?.email">*Invalid Email</small>

                    </div>
                <!----
                    <div class="form-group">
                      <label>Mobile Number</label>
                     <input type="text" class="form-control"  formControlName="mobile" name="mobile" placeholder="Enter your mobile number" />
                     <small class="text-danger" *ngIf="form.mobile.touched && form.mobile.errors?.required">*Mobile Number required</small>
                     <small class="text-danger" *ngIf="form.mobile.touched && form.mobile.errors?.pattern || form.mobile.errors?.minlength || form.mobile.errors?.maxlength ">*Invalid Mobile number</small>

                 </div>
                -->
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control"  formControlName="password" name="password" placeholder="Enter new password" required="required"/>
                        <small class="text-danger" *ngIf="form.password.touched && form.password.errors?.required">*Password required</small>
                        <small class="text-danger" *ngIf="form.password.touched && form.password.errors?.minlength">*Password atleast 6 characters.</small>
                        <small class="text-danger" *ngIf="form.password.touched && form.password.errors?.pattern">Spaces are not allowed</small>
                    </div>
                    <div class="form-group">
                        <label>Referral Code</label>
                        <input type="text" class="form-control" formControlName="referralCode" name="referralCode" placeholder="Referral Code" />
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-light btn-lg mr-2" [disabled]="!registerForm.valid">Sign Up</button>

                    </div>

                </form>
                <p class="hint">By clicking Sign Up, you agree to our <a (click)="terms();">Terms and conditions</a></p>
            </div>
        </div>
        <div class="col-md-5">
            <!-- -carousel bootstrap -->
            <div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="2200">
                <!-- Indicators -->
              <ol class="carousel-indicators">
                    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>
                    <li data-target="#myCarousel" data-slide-to="2"></li>
                    </ol>
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img class="img-center"  src="assets/images/medical3.png" alt="First slide">
                    <h5 class="text-center">Manage all records in one place.</h5>
                </div>
                <div class="carousel-item">
                  <img class="img-center" src="assets/images/medical3.png" alt="Second slide">
                     <h5 class="text-center">Manage all records in one place.</h5>
                </div>
                <div class="carousel-item">
                  <img class="img-center" src="assets/images/medical3.png" alt="Third slide">
                       <h5 class="text-center">Manage all records in one place.</h5>
                </div>
              </div>
              <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
        </div>
    </div>
</div>
<app-footer lat="17.4611" long="78.355797"></app-footer>
