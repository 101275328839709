import {Component, HostListener, NgZone, OnInit, ViewChild,AfterViewInit} from '@angular/core';
import {CartService} from "../service/cart.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ThyrocareService} from "../service/thyrocare.service";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {DatePipe} from "@angular/common";
import {UserService} from "../service/user.service";
import {User} from "../models";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import * as moment from 'moment';
import {WindowRefService} from "../window-ref.service";
import {Observable, Observer, of} from "rxjs";
import {switchMap} from "rxjs/operators";
declare var $: any;

@Component({
  selector: 'app-thyrocarecart',
  templateUrl: './thyrocarecart.component.html',
  styleUrls: ['./thyrocarecart.component.css']
})
export class ThyrocarecartComponent implements OnInit, AfterViewInit {

  @ViewChild('otpVerify ') otpVerify;
  @ViewChild('transactionSuccess') transactionSuccess;
  @ViewChild('focusInput') focusInput;
  patientDob: string;
  age: any;
  metrics: string;
  age_in_months: string | number;
  otp_isValid: boolean;
  otp: any;
  age_forThyrocare: number;
  // @HostListener('focusin', ['$event']) public onListenerTriggered(event: any): void {
  //   this.focusInput.nativeElement.focus();
  // }

  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '*',
    
    inputStyles: {
      'width': '35px',
      'height': '35px',
      'outline':'0',
      'border-width': '0 0 2px',
      'border-color': '#000000',
      'border-radius': '0px 0px 0px 0px',
      'font-family':'droid sans mono, consolas, monospace',
      'font-color':'#000000',
      'font-size': '30px'
    }
  };
  cartItems = [];
  cartTotal:number = 0;
  submitted = false;
  isReadonly = false;
  color = '';
  checkPin: boolean = false;
  servicablePin: boolean = false;
  message = '';
  messageBool: boolean = false;
  minDate = new Date();
  maxDate: Date;
  maxDatedob: Date;
  datePicker: Date;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  bsConfigDOB: Partial<BsDatepickerConfig>;
  slotTimings: any = [];
  slotsEmpty: boolean = false;
  user: User;
  patientDetailsForm: FormGroup;
  otpVerficationForm: FormGroup;
  otpMsg: boolean = true;
  otperror: boolean = false;
  sendingOtp: boolean;
  otpSent: boolean;
  errorInfo: boolean;
  public errorMsg;
  patientDetails: { mobile: any; otp: any; };
  phoneNo: any;
  hours: any;
  appointmentId: any;
  leadId: any;
  aptDateTime: any;
  aptAddress: any;
  appointmentConfirmationSpinner: boolean;
  loadingText = " ";
  serviceCharges = 0;

  points: number;
  usedPoints: boolean = false;
  login: boolean = false;
  userPoints: number;
  lessPoints: boolean = false;
  minPoints = 400;
  discount: number = 0;
  pointsUsed: number = 0;
  actualTotal: number = 0;

  orderIdGen: any;
  payment_id: any;
  signature: any;
  order_id: string;
  familymembers: any;

  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    private thyrocare: ThyrocareService,
    private cartService: CartService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private winRef: WindowRefService,
    private ngzone: NgZone,
    private datePipe: DatePipe
  ) { }

  ngAfterViewInit(): void {
    if(this.userService.userValue) {
      setTimeout(()=>{        
          this.userService.getFamilyMembers().subscribe(response => {
            if (response.details) {
              this.familymembers = response.details;
            }
          });
          this.userService.getPoints().subscribe(response => {
            if (response.details) {
              this.points = response.details.points;
              this.userPoints = this.points;
              this.userService.updatePoints(this.points);
              if (this.points >= this.minPoints) {
                this.lessPoints = false;
              } else {
                this.lessPoints = true;
              }
            }
          });
          this.patientDetailsloggedin();          
    },600);
    }
  }
  ngOnInit(): void {
    var details = {
      metaTitle: 'Diagnostic Center | Gigadocs',
      metaDescription: 'Get your Diagnostic Tests carried out from the comfort of your home',
      ogTitle: 'Diagnostic Center | Gigadocs',
      ogDescription: 'Get your Diagnostic Tests carried out from the comfort of your home',
    };
    this.userService.setDynamicMetaData(details);

    if(this.userService.userValue) {
      this.login = true;
    }
    
    this.thyrocare.loginThyrocare().subscribe(response => {
      if (response.apiKey) {
        this.thyrocare.apiKey = response.apiKey;
        this.thyrocare.token = response.accessToken;
        this.calcCartTotal();
      }
    });
    this.loadCartItems();
    let today = new Date();
    this.minDate = new Date(today);
    this.maxDatedob = new Date(today)
    this.maxDate = new Date(today.setDate(today.getDate() + 7));
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' });
    this.patientDetailsForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}'),
        Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      pincode: ['', Validators.required],
      address: ['', Validators.required],
      slotdate: ['', Validators.required],
      slottime: ['', Validators.required],
    });

    this.otpVerficationForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });

    if (this.userService.userValue) {
      this.patientDetailsloggedin();
    }
  }

  loadCartItems(){
    let items = this.cartService.getCartItems();
    this.cartItems = items;
    this.cartTotal = 0;
    if(this.cartItems != null && this.cartItems.length > 0) {
      this.cartItems.forEach(item => {
        this.cartTotal += Number.parseInt(item.price);
      });
    }
    //this.actualTotal = this.cartTotal;
  }

  patientDetailsloggedin() {
    this.user = this.userService.userValue;
    this.patientDetailsForm.reset({
      name: '',
      mobile: this.user['details']['mobile'],
      email: this.user['details']['email'],
      dob : '',
      //age: '',
      gender: '',
      pincode: '',
      address: '',
      slotdate: '',
      slottime: '',
    });
    this.isReadonly = true;
  }

  removeFromCart(id) {
    this.cartService.removeItemByCode(id);
    this.cartItems = this.cartService.getCartItems();
    this.calcCartTotal();
    //this.onPointsChange(this.usedPoints);
  }

  calcCartTotal() {
    var prodString = "";
    var rateString = "";
    if (this.cartItems != null && this.cartItems.length > 0) {
      this.cartItems.forEach(item => {
        if (item.type == 'test' || item.type == 'offer') {
          prodString += item.id + ',';
        } else {
          prodString += item.name + ',';
        }
        rateString += item.price + ',';
      });
    }
    var apiData = {
      "Products":prodString,
      "Rates":rateString,
      "ClientType":"PUBLIC",
      "BenCount":"1",
      "Report":"1",
      "Discount":"",
      "Coupon":""
    };
    this.thyrocare.viewDSACart(apiData).subscribe(response => {
      if(response.response == "Success") {
        this.cartTotal = response.payable;
        this.serviceCharges = response.chcCharges;
      } else {
        this.cartTotal = 0;
        if (this.cartItems != null && this.cartItems.length > 0) {
          this.cartItems.forEach(item => {
            this.cartTotal += Number.parseInt(item.price);
          });
        }
      }
    });
    this.actualTotal = this.cartTotal;
  }

  get form() {
    return this.patientDetailsForm.controls;
  }

  pincodeAvailability(event) {
    let pincode = event.target.value;
    if (pincode.length == 6) {
      this.thyrocare.getPincodeAvailability(pincode).subscribe(response => {
        this.checkPin = false;
        if (response.status == "Y") {
          this.servicablePin = true;
          this.checkPin = true;
          this.color = 'green';
          this.message = 'Yes! We serve this pincode';
          setTimeout(() => {
            this.checkPin = false;
            this.message = '';
          }, 5000);
          if (this.patientDetailsForm.value.slotdate) {
            this.getSlotTimes();
          }
        } else if (response.status == "N") {
          this.servicablePin = false;
          this.checkPin = true;
          this.color = 'red';
          this.message = 'Sorry, we are not serving in this Pincode as of now.';
        }
      })
    }
  }

  getSlotTimes() {
    let pincode = this.patientDetailsForm.value.pincode;
    let date = this.datePipe.transform(this.patientDetailsForm.value.slotdate,"yyyy-MM-dd");
    if (pincode.length == 6 && date) {
      this.thyrocare.getSlots(pincode, date).subscribe(response => {
        if (response.lSlotDataRes != null) {
          if (date === this.datePipe.transform(new Date(),'yyyy-MM-dd')) {
            this.slotTimings = response.lSlotDataRes.filter(item => {
              if (moment(item.slot.split(' - ')[0], 'hh:mm').isAfter(moment(new Date()))) {
                return item;
              }
            })
          } else {
            this.slotTimings = response.lSlotDataRes;
          }
          if (this.slotTimings.length == 0) {
            this.slotsEmpty = true;
          } else {
            this.slotsEmpty = false;
          }
        }
      });
    }
  }

  counter() {
    let array=[];
    for(let i:number=5; i<= 99 ;i++){
      array.push(i);
    }
    return array;
  }

  onSubmit() {
    this.submitted = true;
    this.otperror = false;
    this.otpFormReset();
    if (this.patientDetailsForm.valid && this.servicablePin) {
      sessionStorage.setItem('mobile', this.patientDetailsForm.value.mobile);
      sessionStorage.setItem('email', this.patientDetailsForm.value.email);
      this.mobileSubmit();
    }
  }

  mobileSubmit() {
    var contact = {
      mobile: this.patientDetailsForm.value.mobile,
    };
    this.userService.verifyUser(contact).subscribe((res: any) => {
      if (res.status == 200) {
        $("#otpModal").modal('show');
      }
    });
  }

  onOtpChange(otpevent) {
    if (otpevent.length === 6) {
       this.otp_isValid = false
        this.otp = otpevent
    }
    else{
      this.otp_isValid=true
    }
  }

  otpSubmit() {
    if (this.otp.length == 6) {
      let number = this.patientDetailsForm.value.mobile;
      let code = this.otp;
      if (this.userService.userValue) {
        this.patientDetails = {
          mobile: this.user['details']['mobile'],
          otp: code,
        };
      } else {
        this.patientDetails = {
          mobile: number,
          otp: code,
        };
      }
      this.userService.otpVerfication(this.patientDetails).subscribe((res: any) => {
          if (res.status === 200) {
            this.otpVerficationForm.reset();
            this.otpVerify.nativeElement.click();
            this.createRzpayOrder();
          } else {
            //this.otpVerficationForm.reset();
            this.ngOtpInput.setValue('')
            this.otpMsg = false;
            this.otperror = true;
            setTimeout(() => {
              this.otperror = false;
              this.otpMsg = true;
            }, 2500);
          }
        },
        err => {
          this.errorInfo = true;
          this.errorMsg = err
        }
      );
    } else {
      return;
    }
  }

  resendOtp() {
    this.otpSent = null;
    this.sendingOtp = true;
    if (this.userService.userValue) {
      this.phoneNo = this.user['details']['mobile'];
    } else {
      this.phoneNo = this.patientDetailsForm.value.mobile;
    }
    var details = {
      mobile: this.phoneNo
    }
    this.userService.verifyUser(details).subscribe((res: any) => {
      this.sendingOtp = null;
      if (res.status == 200) {
        this.ngOtpInput.setValue('')
        // this.otpVerficationForm.reset();
        this.otpSent = true;
      }
    })
  }

  otpFormReset() {
    this.otpSent = null;
    this.sendingOtp = null;
    this.ngOtpInput.setValue('')
    // this.otpVerficationForm.reset();
  }

  bookAppointment() {
    this.appointmentConfirmationSpinner = true;
    this.spinner.show();
    this.loadingText = "Almost there..confirming your appointment"
    var productStr = '';
    var cart_items = [];
    var reportCode = '';
    this.cartItems = this.cartService.getCartItems();
    if (this.cartItems != null && this.cartItems.length > 0) {
      this.cartItems.forEach(item => {
        if (item.type == 'test') {
          productStr += item.id + ',';
        } else {
          if (item.type == 'offer') {
            reportCode = item.id;
          }
          productStr += item.testNames + ',';
        }
        cart_items.push({name: item.name, code: item.id, amount: item.price})
      });
    }
    var date = this.datePipe.transform(this.patientDetailsForm.value.slotdate,"yyyy-MM-dd");
    var timeArr = this.patientDetailsForm.value.slottime.split('-');
    var datetime = this.datePipe.transform(date + ' ' + timeArr[0], 'yyyy-MM-dd h:mm a');
    let apiData = {
      ApiKey: this.thyrocare.apiKey,
      OrderId: this.generateOrderId(),
      Gender: this.patientDetailsForm.value.gender == 'M' ? 'male' : 'female',
      Address: this.patientDetailsForm.value.address,
      Pincode: this.patientDetailsForm.value.pincode,
      Product: productStr.slice(0,productStr.length-1),
      Mobile: this.patientDetailsForm.value.mobile,
      Email: this.patientDetailsForm.value.email,
      ServiceType: 'H',
      OrderBy: 'DSA,PRL',
      Remarks: '',
      ReportCode: reportCode,
      Rate: Number(this.cartTotal),
      HC: 0,
      ApptDate: datetime,
      Passon: 0.0,
      Reports: 'N',
      RefCode: '9581710006',
      PayType: 'POSTPAID',
      BenCount: '1',
      BenDataXML: '<NewDataSet><Ben_details><Name>'+ this.patientDetailsForm.value.name +'</Name><Age>'+ this.age_forThyrocare +'</Age><Gender>'+ this.patientDetailsForm.value.gender +'</Gender></Ben_details></NewDataSet>'
    };
    this.thyrocare.bookAppointment(apiData).subscribe(thyroResponse => {
      if (thyroResponse.orderNo != null) {
        let orderInfo =  {
          name: this.patientDetailsForm.value.name,
          mobile: this.patientDetailsForm.value.mobile,
          email: this.patientDetailsForm.value.email,
          gender: this.patientDetailsForm.value.gender == 'M' ? 'Male' : 'Female',
          dob:  this.datePipe.transform(this.patientDetailsForm.value.dob,"yyyy-MM-dd"),
          //age: this.patientDetailsForm.value.age,
          age: (this.age_in_months && this.age) ? 1 : this.age,
          lead_id: thyroResponse.orderResponseDetails.postOrderDataResponse[0].leadId,
          amount: thyroResponse.customerRate,
          order_id: thyroResponse.orderNo,
          ref_order_id: thyroResponse.refOrderId,
          slot_date: date,
          slot_time: timeArr[0].slice(0, timeArr[0].length-1) + ':00',
          address: this.patientDetailsForm.value.address,
          pincode: this.patientDetailsForm.value.pincode,
          order_items: cart_items,
          razorpay_order_id: this.order_id,
          razorpay_payment_id: this.payment_id,
          razorpay_signature: this.signature,
          points: 0,
          discount: 0
        };
        this.appointmentId = thyroResponse.orderNo;
        this.leadId = thyroResponse.orderResponseDetails.postOrderDataResponse[0].leadId;
        this.aptDateTime = this.datePipe.transform(date + ' ' + timeArr[0], 'd MMM, yyyy h:mm a');
        this.aptAddress = this.patientDetailsForm.value.address + ', ' + this.patientDetailsForm.value.pincode;
        this.userService.saveOrderData(orderInfo).subscribe(gigaResponse => {
          if (gigaResponse.status) {
            this.newParams();
            if (gigaResponse['details']['isNew'] == 1) {
              sessionStorage.setItem('userId1', gigaResponse['details']['userId']);
            }
            if(this.userService.userValue) {
              if (gigaResponse['details']['wallet']) {
                let remainPoints = this.points - gigaResponse['details']['wallet'];
                this.userService.updatePoints(remainPoints);
              }
            } else {
              this.userService.verifyOtpLogin(this.patientDetails.mobile, this.patientDetails.otp).subscribe(loginResponse => {
                this.userService.afterLogin(loginResponse);
              });
            }
            sessionStorage.removeItem('mobile');
            sessionStorage.removeItem('email');
            this.cartService.clearCart();
            this.patientDetailsForm.reset();
            this.spinner.hide();
            this.router.navigate(['diagnostic-center/success/', this.appointmentId]);
          }
        });
      } else {
        this.message = thyroResponse.response;
        this.messageBool = true;
        setTimeout(() => {
          this.messageBool = false;
          this.message = '';
        }, 10000);
        this.spinner.hide();
      }
    });
  }

  generateOrderId():string {
    var orderId = 'GIGA';
    let string = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let str_8 = this.shuffle(string).substr(0, 4);
    orderId += this.datePipe.transform(new Date(), "yyyyMMdd") + str_8;
    return orderId;
  }

  shuffle(string) {
    var parts = string.split('');
    for (var i = parts.length; i > 0;) {
      var random = parseInt(String(Math.random() * i));
      var temp = parts[--i];
      parts[i] = parts[random];
      parts[random] = temp;
    }
    return parts.join('');
  }

  newParams() {
    let details = {
      order_id: this.leadId,
      address: this.aptAddress,
      datetime: this.aptDateTime
    }
    this.activatedroute.queryParams.subscribe(params => {
      this.userService.changeParams(details)
    })
  }

  createRzpayOrder() {
    var details = {
      amount: this.actualTotal
    }
    this.userService.createOrderID(details).subscribe(res => {
      this.orderIdGen = res['details']['orderId'];
      this.payWithRazor(this.orderIdGen);
    })
  }

  payWithRazor(val) {
    let patientMobile = sessionStorage.getItem('mobile') || '';
    let patientEmail = sessionStorage.getItem('email') || '';
    const options: any = {
      key: this.userService.paymentKey,
      amount: this.actualTotal, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'GigaDocs', // company name or product name
      description: 'Thyrocare Order',  // product description
      image: 'https://www.gigadocs.com/assets/new/images/logo.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      prefill: {
        "email": patientEmail,
        "contact": patientMobile,
        "method": ""
      },
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      this.payment_id = response['razorpay_payment_id'];
      this.signature = response['razorpay_signature'];
      this.order_id = response['razorpay_order_id'];
      if (this.payment_id != null && this.signature != null) {
        $("#paymentStatus").modal('show');
        this.ngzone.run(() => {
          setTimeout(() => {
            //this.transactionSuccess.nativeElement.click();
            $("#paymentStatus").modal('hide');
            this.bookAppointment();
          }, 5000);
        })
      }
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      $("#paymentFailed").modal('show');
      this.ngzone.run(() => {
        //this.transactionMsg=true;
        //this.Noerror=false;
      })
      //  this.router.navigate(['/home']);
    });
    const razorpay = new this.winRef.nativeWindow.Razorpay(options);
    razorpay.open();
  }

  onPointsChange(e) {
    if((e == true || e.target.checked == true) && this.points >= this.minPoints) {
      let wallet = this.points / 4;
      let walletRemain = wallet - this.cartTotal;
      if (walletRemain >= 0) {
        this.discount = this.cartTotal;
        this.cartTotal = 0;
        this.pointsUsed = this.discount * 4;
      } else {
        let remainFee = this.cartTotal - wallet;
        this.cartTotal = remainFee;
        this.discount = wallet;
        this.pointsUsed = this.discount * 4;
      }
      this.usedPoints = true;
    } else {
      this.usedPoints = false;
      this.discount = 0;
      this.calcCartTotal();
    }
  }

  filteredFamilyMembers$ = new Observable((observer: Observer<string>) => {
    observer.next(this.patientDetailsForm.value.name);
  }).pipe(
    switchMap((query: string) => {
      return of(this.familymembers.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1));
    })
  );

  selectedFamilyMember(evt:any) {
    let query = evt.item.name.toLowerCase();
    let patient = this.familymembers.filter(item => item.name.toLowerCase() == query.toLowerCase());
    if (patient.length > 0) {
      //let age = patient[0].age;
      let gender = patient[0].gender
      let dob = patient[0].dob;
      if (dob != "") {
        let new_dob_format = this.datePipe.transform(dob,"dd-MM-yyyy") 
        this.patientDetailsForm.controls['dob'].setValue(new_dob_format);
      } else {
        this.patientDetailsForm.controls['dob'].setValue('');
        this.selectedDate('') // Making Age 0 when dob is null.
      }
      // this.patientDetailsForm.controls['dob'].setValue(dob);
      if (gender && gender.toLowerCase() == 'male') {
        this.patientDetailsForm.controls['gender'].setValue('M');
      } else if (gender && gender.toLowerCase() == 'female') {
        this.patientDetailsForm.controls['gender'].setValue('F');
      } else {
        this.patientDetailsForm.controls['gender'].setValue('');
      }
    } else {
      this.patientDetailsForm.controls['dob'].setValue('');
      this.selectedDate('')  // Making Age 0 when dob is null.
      this.patientDetailsForm.controls['gender'].setValue('');
    }
  }

  clear(evt:any) {
    let query = evt.target.value;
    if (query == "") {
      this.patientDetailsForm.controls['dob'].setValue('');
      this.selectedDate('') // Making Age 0 when dob is null.
      this.patientDetailsForm.controls['gender'].setValue('');
    }
  }

  selectedDate(event: any) {
    if(event){
        this.patientDob =event;
          let data = this.userService.getUserAge(this.patientDob)
          this.age = data['age']
          this.metrics = data['units']
          this.age_in_months =  data['months']

          this.age_forThyrocare = (this.age_in_months && this.age) ? 1 : this.age
     }else{
        this.age = 0;
        this.patientDob = '';
     }
  }

}
