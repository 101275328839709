<div class="bg" *ngIf="!practice">
  <div class="hero">
    <div class="link text-center">
      <img src="assets/images/logo.png" class="center-block logo" alt="GigaDocs"/>
      <div class="box">
        <span>Hello User</span>
        <p>Maintaining your entire family's Healthcare is just a click away!</p>
      </div>
      <div class="app-link">
        <a href="https://play.google.com/store/apps/details?id=com.gigadocs.app"><img src="assets/images/play-store.png" class="sh-store-image img-responsive center-block"alt="GigaDocs"/></a>
        <a href="https://apps.apple.com/us/app/gigadocs/id1447077786"><img src="assets/images/app-store.png"  class="sh-store-image img-responsive center-block"alt="GigaDocs"/></a>
      </div>
    </div>
  </div>
</div>

<div class="bg" *ngIf="practice">
  <div class="hero">
    <div class="link text-center">
      <img src="assets/images/logo.png" class="center-block logo" alt="GigaDocs"/>
      <div class="box">
        <span>Hello User</span>
        <p>Manage your entire clinic in an effective and efficient way!</p>
      </div>
      <div class="app-link">
        <a href="https://play.google.com/store/apps/details?id=com.datappsinfotech.appgigadocs"><img src="assets/images/play-store.png" class="sh-store-image img-responsive center-block"alt="GigaDocs"/></a>
        <a href="https://apps.apple.com/us/app/gigadocs-practice/id1344497712"><img src="assets/images/app-store.png"  class="sh-store-image img-responsive center-block"alt="GigaDocs"/></a>
      </div>
    </div>
  </div>
</div>
