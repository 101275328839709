<app-header></app-header>

<div #alert *ngIf="errorMessage" class="alert alert-danger "><h6 class="text center"><i class="fas fa-exclamation-circle text-danger mr-2"></i>{{errorNotification}}</h6></div>

<div class="page-content">
    <div class="banner-strip">
        <div class="strip-clinic-img">
            <img src="assets/images/clinicImage.png">
        </div>
        <div class="banner-strip-content">
          <div class="social">
            <span><i class="ic-share"></i>  Share profile</span>
            <div class="social-links">
              <share-buttons [theme]="'circles-dark'"
                             [include]="['facebook','twitter','whatsapp','sms','email']"
                             [show]="5"
                             [title]="'Share Doctor Profile'"
                             [tags]="'profile'"
              ></share-buttons>
            </div>
          </div>
        </div>
    </div>
    <div class="doctor-profile">
        <div class="container container-fluid-sm">
            <div class="row mt-row">
                <div class="col-md-3 mt-value hidden-xs">
                    <div class="card-p nopad">
                        <div class="doc-img">
                            <img width="100%" class="img-fluid" *ngIf="(docGender| lowercase) == 'male'" src="{{imageUrl}}" onerror="src='assets/images/doctor-male-default.png'" alt="{{data1.name}}"/>
                            <img width="100%" class="img-fluid" *ngIf="(docGender| lowercase)== 'female'" src="{{imageUrl}}"  onerror="src='assets/images/doctor-female1.png'"
                                 alt="{{data1.name}}"/>
                            <img width="100%" *ngIf="docGender == ''"  src="{{imageUrl}}"  onerror="src='assets/images/doctor-male-default.png'"
                                 alt="{{data1.name}}"/>
                        </div>
                        <div class="doc-info">
                            <h4>About</h4>
                            <div *ngIf="description">
                              {{(readMore) ? description : description | slice:0:120}}<span *ngIf="!readMore">...</span>
                              <a class="more-button" (click)="readMore=!readMore">{{!readMore ? 'Show more' : 'Show less' }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="gigadoc-mobile-app">
                      <h5>Get Gigadocs Mobile App</h5>
                      <div *ngIf="sendLink" class="alert alert-warning">
                        <span class="spinner-border spinner-border-sm"></span>
                        Please wait, Processing...
                      </div>
                      <div class="alert alert-success" *ngIf="sentLink">
                        App Link Shared Successfully
                      </div>
                      <form id="send-app-link" [formGroup]="sendAppLinkForm">
                        <div class="form-group required">
                          <input type="text" class="form-control" placeholder="Enter Mobile Number" formControlName="mobile"
                                 required="required" [ngClass]="{ 'is-invalid': (submitted) && applink.mobile.errors }">
                          <div *ngIf="(submitted) && applink.mobile.errors" class="invalid-feedback">
                            <div *ngIf="applink.mobile.errors.required">Mobile is required</div>
                            <div *ngIf="applink.mobile.errors?.pattern" class="text-danger">
                              Invalid Mobile number
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-first" (click)="sendAppLink()">Send App Link</button>
                      </form>
                    </div>
                    <div class="other-suggestions gigadoc-mobile-app" *ngIf="shortLinksArray.length > 0">
                      <h5>Suggested Links</h5>
                      <ul>
                        <li *ngFor="let link of shortLinksArray">
                          <a href="javascript:void(0)" routerLink="/{{cityUrl}}/{{link | reversecase}}">{{link | camelcase}} in {{cityUrl | camelcase}}</a>
                        </li>
                      </ul>
                    </div>
                </div>
                <div class="col-md-9 mt-value">
                    <div class="doc-intro">
                        <div  class="doc-intro-img visible-xs">
                            <img width="100%" class="img-fluid" *ngIf="(docGender| lowercase) == 'male'" src="{{imageUrl}}" onerror="src='assets/images/doctor-male-default.png'" alt="{{data1.name}}"/>
                            <img width="100%" class="img-fluid" *ngIf="(docGender| lowercase)== 'female'" src="{{imageUrl}}"  onerror="src='assets/images/doctor-female1.png'"
                                 alt="{{data1.name}}"/>
                            <img width="100%" *ngIf="docGender == ''"  src="{{imageUrl}}"  onerror="src='assets/images/doctor-male-default.png'"
                                 alt="{{data1.name}}"/>
                        </div>
                        <div class="doc-intro-content">
                            <h2>
                               Dr. {{docName | camelcase}}
                              <span class="verified" *ngIf="docGender != ''"> {{docGender | camelcase}}</span>
                              <span class="verified" *ngIf="isClaimed=='0'">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                                <a href="claimProfileModal" class="claimprofile" data-target="#claimProfileModal" data-toggle="modal" data-dismiss="modal">
                                  Claim this profile
                                </a>
                              </span>
                              <span class="verified" *ngIf="isClaimed=='1'">
                                <i class="ic-verified"></i>
                                <span class="hidden-xs">Verified</span>
                              </span>
                            </h2>
                            <p>{{specialities | commapipe}}</p>
                            <span class="verified">
                              <i class="fa fa-exclamation-circle claimprofile" aria-hidden="true"></i>
                              <a href="reportErrorModal" class="claimprofile" data-toggle="modal" data-target="#reportErrorModal" data-dismiss="modal">
                                Report an Error
                              </a>
                            </span>
                            <!--<div class="star-rating">
                                <span class="star-rate"><span style="width: 60%"></span></span>
                                <span class="doc-feedback">(453 Feedbacks)</span>
                            </div>-->
                        </div>
                    </div>
                    <div class="doc-info visible-xs">
                        <h4>About</h4>
                        <div *ngIf="description">
                          {{(readMore) ? description : description | slice:0:120}}<span *ngIf="!readMore">...</span>
                          <a class="more-button" (click)="readMore=!readMore">{{!readMore ? 'Show more' : 'Show less' }}</a>
                        </div>
                    </div>
                    <div class="doc-specification">
                        <div class="card-p">
                          <div class="row">
                            <div class="col-sm-5">
                              <h5><i class="fa fa-graduation-cap" aria-hidden="true"></i> Education</h5>
                              <p *ngIf="doctorEducation">{{doctorEducation}}</p>
                              <p *ngIf="!doctorEducation">N/A</p>
                            </div>
                            <div class="col-sm-4">
                              <h5><i class="fa fa-briefcase" aria-hidden="true"></i> Experience</h5>
                              <p *ngIf="exp > 0">{{exp}} years</p>
                              <p *ngIf="exp==0">N/A</p>
                            </div>
                            <div class="col-sm-3">
                              <h5><i class="fa fa-language" aria-hidden="true"></i> Languages</h5>
                              <p *ngIf="languages.length > 0">{{languages | commapipe}}</p>
                              <p *ngIf="languages.length==0">N/A</p>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="doc-specification">
                      <div class="card-p">
                        <div class="row">
                          <div class="col-sm-12">
                            <h5><i class="fa fa-stethoscope" aria-hidden="true"></i> Services/related-specialities</h5>
                            <ul class="servicesList">
                              <li *ngFor="let service of services">{{service | camelcase}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="doc-profile-tabs">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#clinics">Clinics ({{clinicCount}})</a>
                            </li>
                            <!--<li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#reviews">Reviews (230)</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#awards">Awards & Acheivements</a>
                            </li>-->
                        </ul>
                        <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="clinics" role="tabpanel" >
                            <div *ngIf="clinicInfo.length == 0 && clinicInfoSpinner == false" class="no-clinics">
                              This Doctor is Not Associated with any Clinic
                            </div>
                            <div *ngIf="clinicInfo.length == 0  && clinicInfoSpinner == true" class="doc-clinics">
                              <div class="dc-row" >
                                <div class="row">
                                  <div class="col-lg-2 col-md-3 col-xs-4">
                                    <div class="dc-img">
                                      <ngx-skeleton-loader
                                            appearance="square"
                                            animation="pulse"
                                            [theme]="{background: '#F9F6F5' , height: '80px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </div>
                                  <div class="col-lg-7 col-md-9 col-xs-8">
                                    <div class="dc-clinic-info">
                                      <ngx-skeleton-loader
                                          animation="pulse"
                                          [theme]="{background: '#F9F6F5', width: '200px',
                                                  'border-radius': '5',height: '15px',
                                                  'margin-bottom': '10px'}">
                                      </ngx-skeleton-loader>
                                    </div> 
                                    <div class="sr-working-hours">
                                      <ngx-skeleton-loader
                                          animation="pulse"
                                          [theme]="{ background: '#F9F6F5' , width: '170px', 'border-radius': '5', height: '15px' }">
                                      </ngx-skeleton-loader>
                                    </div>                                           
                                  </div>                                        
                                </div>
                              </div>
                            </div>                              
                            <div *ngIf="clinicInfo.length > 0  && clinicInfoSpinner == false" class="doc-clinics">
                                <div class="dc-row" *ngFor="let clinic of clinicInfo; let i = index">
                                    <div class="row">
                                        <div class="col-lg-2 col-md-3 col-xs-4">
                                            <div class="dc-img">
                                                <img class="img-fluid" src="{{clinic.image}}" onerror="src='assets/images/clinic-logo-2.png'">
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-md-9 col-xs-8">
                                            <div class="dc-clinic-info">
                                                <a routerLink="/clinicdetails/{{clinic.city | reversecase}}/{{clinic.id}}" class="sr-title">
                                                  <h4>{{clinic.name}}</h4>
                                                </a>
                                                <p >
                                                    {{clinic.address}}, {{clinic.area}}, {{clinic.city}}
                                                </p>
                                            </div>
                                            <div class="sr-working-hours">
                                              <div *ngIf="clinic.isOnline && clinic.vc_working_hours.length > 0">
                                                <h4>Video Consultation:</h4>
                                                <div *ngFor="let days of clinic.vc_working_hours" class="working-hour-row">
                                                  <label>{{days['days']}}</label>
                                                  <span>{{days['time']}}</span>
                                                </div>
                                              </div>
                                              <div *ngIf="clinic.working_hours.length > 0">
                                                <h4>In-Clinic Visit:</h4>
                                                <div *ngFor="let days of clinic.working_hours" class="working-hour-row">
                                                  <label>{{days['days']}}</label>
                                                  <span>{{days['time']}}</span>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                        <div class="sr-actions col-lg-3 col-md-9 offset-tab-3 col-xs-">
                                          <div class="sr-consulation-fees">
                                            <h5><i class="fa fa-inr"></i>{{clinic.fee}}</h5>
                                          </div>
                                          <div class="view-contact" id="view-contact-{{i}}">
                                            <a href="javascript:void(0)" (click)="viewContact(i)">
                                              <i class="fa fa-phone" aria-hidden="true"></i>
                                              <span>Contact Clinic</span>
                                            </a>
                                          </div>
                                          <div class="Contact-options" id="Contact-options-{{i}}" style="display: none;">
                                            <a href='tel:{{clinic.contactNumber}}' *ngIf="clinic.contactNumber != '' && clinic.contactNumber != null">
                                              <b>{{clinic.contactNumber}}</b>
                                            </a>
                                            <a href='tel:{{clinic.contactNumber}}' *ngIf="clinic.contactNumber == '' || clinic.contactNumber == null">
                                              <b>Not Available</b>
                                            </a>
                                            <div *ngIf="clinic.contactNumber != '' && clinic.contactNumber != null">
                                              <span *ngIf="clinic.contactCall == '1' || clinic.contactWhatsapp == '1' || clinic.contactSms == '1'">Reach us on</span>
                                              <ul class="conatctList">
                                                <li class="phone">
                                                  <a *ngIf="clinic.contactCall == '1'" href="tel:{{clinic.contactNumber}}"><i class="fa fa-phone" aria-hidden="true"></i></a>
                                                </li>
                                                <li class="whatsapp">
                                                  <a *ngIf="clinic.contactWhatsapp == '1'" href="https://api.whatsapp.com/send?phone={{clinic.contactNumber}}"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                                                </li>
                                                <li class="message">
                                                  <a *ngIf="clinic.contactSms == '1'" href="sms:{{clinic.contactNumber}}"><i class="fa fa-comments-o" aria-hidden="true"></i></a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <a *ngIf="clinic.is_subscribed=='1' && clinic.onCall=='1'" data-toggle="modal"
                                              data-target="#slotModal" class="btn btn-second btn-on-request trigger-btn" (click)="getPopupSlots(clinic.id)"
                                          >Available On Request</a>
                                          <a *ngIf="clinic.is_subscribed=='1' && ((clinic.showClinicSlots && clinic.isInClinic) || (clinic.showOnlineSlots && clinic.isOnline))" data-toggle="modal"
                                              data-target="#slotModal" class="btn btn-second btn-book-appointment trigger-btn" (click)="getPopupSlots(clinic.id, clinic.showClinicSlots, clinic.showOnlineSlots)"
                                          >Book Appointment</a>
                                        </div>
                                    </div>
                                </div>
                            </div>                             
                          </div>
                            <!--<div class="tab-pane fade" id="reviews" role="tabpanel">
                                Reviews & Feedbacks
                            </div>
                            <div class="tab-pane fade" id="awards" role="tabpanel">
                                Awards & Acheivements
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="slotModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-slot">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row">
              <div class="col-md-8">
                <h5 class="modal-title">{{hospitalName}}</h5>
              </div>
              <div class="col-md-4">
                <button type="button" #close class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div id="virtual-slots" class="vc-input-group" *ngIf="isOnline && showOnline">
              <h6>Video Consultation</h6>
              <div class="sr-clinic-appointment">
                <ul class="appointment-dates">
                  <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayApplyVC}" (click)="todayVC('vc')">Today</a></li>
                  <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowApplyVC}" (click)="tomorrowVC('vc')">Tomorrow</a></li>
                  <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate':dateHighlightVC}">
                    <i class="ic-calendar mr-2"></i>
                    <input type="text" readonly #calendarvc [(ngModel)]="datePickerVC" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedVCDate($event, 'vc')"
                           id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                  </li>
                </ul>
                <div class="timeslot-tabs">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" *ngIf="morningVCSlotsLength != 0">
                      <a class="nav-link" [ngClass]="{' active' : (morningVCSlotsLength != 0)}" data-toggle="tab" (click)="selectedVCIndex=-1;selectedVCTime=null" href="#morningVC">
                        <i class="ic-morning"></i> <span>Morning</span>
                      </a>
                    </li>
                    <li class="nav-item" *ngIf="afternoonVCSlotsLength != 0">
                      <a class="nav-link" [ngClass]="{' active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength != 0)}" data-toggle="tab" (click)="selectedVCIndex=-1;selectedVCTime=null" href="#afternoonVC">
                        <i class="ic-afternoon"></i> <span>Afternoon</span>
                      </a>
                    </li>
                    <li class="nav-item" *ngIf="eveningVCSlotsLength != 0">
                      <a class="nav-link" [ngClass]="{' active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0 && eveningVCSlotsLength != 0)}" data-toggle="tab" (click)="selectedVCIndex=-1;selectedVCTime=null" href="#eveningVC">
                        <i class="ic-evening"></i> <span>Evening</span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent" *ngIf = "(morningVCSlotsLength !=0 || afternoonVCSlotsLength !=0 || eveningVCSlotsLength !=0) && !vcSpinner">
                    <div class="tab-pane" id="morningVC" [ngClass]="{' fade show active' : (morningVCSlotsLength != 0)}" *ngIf="morningVCSlotsLength != 0" role="tabpanel">
                      <div style=" display:inline-block;" *ngFor="let time of morningVCSlots;let i=index">
                        <button [ngClass]="{'selected':i==selectedVCIndex}" (click)="VCTime(i,time,'vc')">{{time}}</button>
                      </div>
                      <div *ngIf="morningVCSlotsLength === 0">NO SLOTS AVAILABLE</div>
                    </div>
                    <div class="tab-pane" id="afternoonVC" [ngClass]="{' fade show active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength != 0)}"  *ngIf="afternoonVCSlotsLength != 0" role="tabpanel">
                      <div  style=" display:inline-block;" *ngFor="let time of afternoonVCSlots;let i=index">
                        <button [ngClass]="{'selected':i==selectedVCIndex}" (click)="VCTime(i,time,'vc')" >{{time}}</button>
                      </div>
                      <div *ngIf="afternoonVCSlotsLength === 0">NO SLOTS AVAILABLE</div>
                    </div>
                    <div class="tab-pane" id="eveningVC" [ngClass]="{' fade show active' : (morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0 && eveningVCSlotsLength != 0)}" *ngIf="eveningVCSlotsLength != 0" role="tabpanel">
                      <div  style=" display:inline-block;" *ngFor="let time of eveningVCSlots;let i=index">
                        <button [ngClass]="{'selected':i==selectedVCIndex}" (click)="VCTime(i,time,'vc')">{{time}}</button>
                      </div>
                      <div *ngIf="eveningVCSlotsLength === 0">NO SLOTS AVAILABLE</div>
                    </div>
                  </div>
                  <div class="tab-content" *ngIf="(morningVCSlotsLength == 0 && afternoonVCSlotsLength == 0 && eveningVCSlotsLength == 0) && !vcSpinner">NO SLOTS AVAILABLE</div>
                  <div class="fa-1x tab-content" *ngIf = "(morningVCSlotsLength == 0 || afternoonSlotsLength == 0 || eveningVCSlotsLength == 0 ) && vcSpinner">
                    <p><i class="fas fa-circle-notch fa-spin" style ="color: #4a85ec;margin-right: 10px;"></i> Please wait...</p>
                  </div>
                </div>
              </div>
            </div>
            <div id="inclinic" class="clinic-input-group" *ngIf="inClinic && showClinic">
              <h6>In-Clinic Visit</h6>
              <div class="sr-clinic-appointment">
                <ul class="appointment-dates">
                  <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayApply}" (click)="today('inclinic')">Today</a></li>
                  <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowApply}" (click)="tomorrow('inclinic')">Tomorrow</a></li>
                  <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate': dateHighlight}">
                    <i class="ic-calendar mr-2"></i>
                    <input type="text" readonly #calendar [(ngModel)]="datePicker" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedDate($event, 'inclinic')"
                           id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                  </li>
                </ul>
                <div class="timeslot-tabs">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" *ngIf="morningSlotsLength != 0">
                      <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength != 0)}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#morning">
                        <i class="ic-morning"></i> <span>Morning</span>
                      </a>
                    </li>
                    <li class="nav-item" *ngIf="afternoonSlotsLength != 0">
                      <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && afternoonSlotsLength != 0 )}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#afternoon">
                        <i class="ic-afternoon"></i> <span>Afternoon</span>
                      </a>
                    </li>
                    <li class="nav-item" *ngIf="eveningSlotsLength != 0">
                      <a class="nav-link" [ngClass]="{' active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength != 0 )}" data-toggle="tab" (click)="selectedIndex=-1;selectedTime=null" href="#evening">
                        <i class="ic-evening"></i> <span>Evening</span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent" *ngIf = "(morningSlotsLength !=0 || afternoonSlotsLength !=0 || eveningSlotsLength !=0) && !inclinicSpinner">
                    <div class="tab-pane" id="morning" [ngClass]="{' fade show active' : (morningSlotsLength != 0 )}" *ngIf="morningSlotsLength != 0" id="morning" role="tabpanel">
                      <div style=" display:inline-block;" *ngFor="let time of morningSlots;let i=index">
                        <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time,'inclinic')">{{time}}</button>
                      </div>
                      <div *ngIf="morningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                    </div>
                    <div class="tab-pane" id="afternoon" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength != 0)}" *ngIf="afternoonSlotsLength != 0" role="tabpanel">
                      <div  style=" display:inline-block;" *ngFor="let time of afternoonSlots;let i=index">
                        <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time,'inclinic')">{{time}}</button>
                      </div>
                      <div *ngIf="afternoonSlotsLength === 0">NO SLOTS AVAILABLE</div>
                    </div>
                    <div class="tab-pane" id="evening" [ngClass]="{' fade show active' : (morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength != 0)}" *ngIf="eveningSlotsLength != 0" role="tabpanel">
                      <div  style=" display:inline-block;" *ngFor="let time of eveningSlots;let i=index">
                        <button [ngClass]="{'selected':i==selectedIndex}" (click)="Time(i,time,'inclinic')">{{time}}</button>
                      </div>
                      <div *ngIf="eveningSlotsLength === 0">NO SLOTS AVAILABLE</div>
                    </div>
                  </div>
                  <div class="tab-content" *ngIf="(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0) && !inclinicSpinner">NO SLOTS AVAILABLE</div>
                  <div class="fa-1x tab-content" *ngIf = "(morningSlotsLength == 0 && afternoonSlotsLength == 0 && eveningSlotsLength == 0) && inclinicSpinner">
                    <p><i class="fas fa-circle-notch fa-spin" style ="color: #4a85ec;margin-right: 10px;"></i> Please wait...</p>
                  </div>
                </div>
              </div>
            </div>
            <div id="oncall" class="clinic-input-group" *ngIf="isOnCall=='1'">
              <h6>Available On Request</h6>
              <div class="sr-clinic-appointment">
                <ul class="appointment-dates">
                  <li><a href="javascript: void(0)" [ngClass]="{'selectedButton':todayCall}" (click)="todayOnCall('oncall')">Today</a></li>
                  <li class="mr-2 mb-2"><a href="javascript: void(0)" [ngClass]="{'selectedButton':tomorrowCall}" (click)="tomorrowOnCall('oncall')">Tomorrow</a></li>
                  <li class="datePicker mr-2 mb-2" [ngClass]="{'selectedDate': dateHighlightCall}">
                    <i class="ic-calendar mr-2"></i>
                    <input type="text" readonly #calendarcall [(ngModel)]="datePickerCall" bsDatepicker [bsConfig]="bsConfig" (ngModelChange)="selectedOnCallDate($event, 'oncall')"
                           id="date" placeholder="Select Date" placement="bottom" [minDate]="minDate" [maxDate]="maxDate" />
                  </li>
                </ul>
                <div class="timeslot-tabs">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-toggle="tab" href="#slottime">
                        <i class="ic-datetime"></i> <span>Preferred Time</span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="slottime" role="tabpanel">
                      <div style="display:inline-block;">
                        <button [ngClass]="{'selected':0==selectedCallIndex}" (click)="CallTime(0, '12:00 AM - 04:00 AM', 'oncall')">12:00 AM - 04:00 AM</button>
                        <button [ngClass]="{'selected':1==selectedCallIndex}" (click)="CallTime(1, '04:00 AM - 08:00 AM', 'oncall')">04:00 AM - 08:00 AM</button>
                        <button [ngClass]="{'selected':2==selectedCallIndex}" (click)="CallTime(2, '08:00 AM - 12:00 PM', 'oncall')">08:00 AM - 12:00 PM</button>
                        <button [ngClass]="{'selected':3==selectedCallIndex}" (click)="CallTime(3, '12:00 PM - 04:00 PM', 'oncall')">12:00 PM - 04:00 PM</button>
                        <button [ngClass]="{'selected':4==selectedCallIndex}" (click)="CallTime(4, '04:00 PM - 08:00 PM', 'oncall')">04:00 PM - 08:00 PM</button>
                        <button [ngClass]="{'selected':5==selectedCallIndex}" (click)="CallTime(5, '08:00 PM - 12:00 AM', 'oncall')">08:00 PM - 12:00 AM</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button *ngIf="isOnCall=='0'" class="btn btn-primary" [disabled]="btnDisable" (click)="confirmAppointment()">Book Appointment</button>
            <button *ngIf="isOnCall=='1'" class="btn btn-primary" [disabled]="btnDisable" (click)="onCallAppointment()">Request Appointment</button>
          </div>
        </div>
      </div>
    </div>
    <div id="claimProfileModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
      <div class="modal-dialog modal-reminder modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" #closeProfile class="close" data-dismiss="modal" aria-hidden="true">
            &times;
            </button>
            <h5 class="modal-title">Claim Profile</h5>
          </div>
          <div class="modal-body">
            <div class="login-modal-wrap">
              <div *ngIf="sendingOtp" class="alert alert-warning">
                <span class="spinner-border spinner-border-sm"></span>
                Please wait, Processing...
              </div>
              <div class="alert alert-danger" *ngIf="otpInvalid">
                Incorrect OTP
              </div>
              <div class="alert alert-success" *ngIf="message!=''">
                {{message}}
              </div>
              <form id="profile-form" [formGroup]="claimProfileForm">
                <div class="form-group required">
                  <label class="control-label">Full Name</label>
                  <input type="text" class="form-control" placeholder="Enter Full Name" formControlName="name"
                         required="required" [ngClass]="{'is-invalid': (submitted) && claim.name.errors}">
                  <div *ngIf="(submitted) && claim.name.errors" class="invalid-feedback">
                    <div *ngIf="claim.name.errors.required">Full Name is required</div>
                  </div>
                </div>
                <div class="form-group required">
                  <label class="control-label">Mobile</label>
                  <input type="text" class="form-control" placeholder="Enter Mobile Number" formControlName="mobile"
                         (keyup)="checkMobile($event)" required="required" [ngClass]="{'is-invalid': (submitted) && claim.mobile.errors}">
                  <div *ngIf="(submitted) && claim.mobile.errors" class="invalid-feedback">
                    <div *ngIf="claim.mobile.errors.required">Mobile is required</div>
                    <div *ngIf="claim.mobile.errors?.pattern" class="text-danger">
                      Invalid Mobile number
                    </div>
                  </div>
                </div>
                <div class="form-group required">
                  <label class="control-label">Email</label>
                  <input type="email" class="form-control" placeholder="Enter Email" formControlName="email">
                  <small class="text-danger" *ngIf="claim.email.touched && claim.email.errors?.email">Please enter a valid email address</small>
                </div>
                <div class="form-group required" *ngIf="isShow">
                  <label class="control-label">OTP</label>
                  <input type="text" class="form-control" placeholder="Enter code" formControlName="otp"
                         required="required" [ngClass]="{'is-invalid': (submitted) && claim.otp.errors}">
                  <div *ngIf="(submitted) && claim.otp.errors" class="invalid-feedback">
                    <div *ngIf="claim.otp.errors.required">OTP is required</div>
                    <div *ngIf="claim.otp.errors?.pattern" class="text-danger">
                      Invalid OTP
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <a *ngIf="isShow && otpCount < 4" href="javascript: void(0)" (click)="sendOtp()">Resend OTP</a>
            <div class="row align-items-center">
              <div class="col-sm-7" *ngIf="!otpSent">
                <input type="button" [disabled]="validMobile" (click)="sendOtp()" class="btn btn-primary" value="Send OTP">
              </div>
              <div class="col-sm-7" *ngIf="otpSent">
                <input type="button" class="btn btn-primary" (click)="submitClaimProfile()" value="Submit">
              </div>
              <div class="col-sm-5">
                <button type="button" #closeProfile class="btn btn-default pull-right closeBtn" data-dismiss="modal" aria-hidden="true">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="reportErrorModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" #closeReport class="close" data-dismiss="modal" aria-hidden="true">
            &times;
            </button>
            <h5 class="modal-title">What's wrong?</h5>
          </div>
          <div class="modal-body">
            <div class="report-modal-wrap">
              <div *ngIf="sendingOtp" class="alert alert-warning">
                <span class="spinner-border spinner-border-sm"></span>
                Please wait, Processing...
              </div>
              <div class="alert alert-success" *ngIf="message!=''">
                {{message}}
              </div>
              <form id="report-form" [formGroup]="reportErrorForm">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label class="report-labels">
                      <input type="checkbox" formControlName="phonenumber" (change)="onChangeCheckBox($event)">&nbsp;
                      Phone Number
                    </label>
                  </div>
                  <div class="col-md-6 form-group">
                    <label class="report-labels">
                      <input type="checkbox" formControlName="address" (change)="onChangeCheckBox($event)">&nbsp;
                      Address
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label class="report-labels">
                      <input type="checkbox" formControlName="timings" (change)="onChangeCheckBox($event)">&nbsp;
                      Timings
                    </label>
                  </div>
                  <div class="col-md-6 form-group">
                    <label class="report-labels">
                      <input type="checkbox" formControlName="fee" (change)="onChangeCheckBox($event)">&nbsp;
                      Consultation Fee
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <label class="report-labels">
                      <input type="checkbox" formControlName="not_practice">&nbsp;
                      Not Practicing anymore
                    </label>
                  </div>
                </div>
                <div id="phoneNumberDiv" class="form-group required" *ngIf="report.phonenumber.value">
                  <label class="control-label">Correct Phone Number</label>
                  <input type="text" class="form-control" formControlName="correct_phonenumber" required="required"
                         [ngClass]="{ 'is-invalid': (submitted) && report.correct_phonenumber.errors }">
                  <div *ngIf="(submitted) && report.correct_phonenumber.errors" class="invalid-feedback">
                    <div *ngIf="report.correct_phonenumber.errors.required">Correct Phone Number is required</div>
                    <div class="text-danger" *ngIf="report.correct_phonenumber.errors?.pattern || report.correct_phonenumber.errors?.minlength || report.correct_phonenumber.errors?.maxlength">
                      Invalid Phone number</div>
                  </div>
                </div>
                <div id="addressDiv" class="form-group required" *ngIf="report.address.value">
                  <label class="control-label">Correct Address</label>
                  <input type="text" class="form-control" formControlName="correct_address" required="required"
                         [ngClass]="{ 'is-invalid': (submitted) && report.correct_address.errors }">
                  <div *ngIf="(submitted) && report.correct_address.errors" class="invalid-feedback">
                    <div *ngIf="report.correct_address.errors.required">Correct Address is required</div>
                  </div>
                </div>
                <div id="timingsDiv" class="form-group required" *ngIf="report.timings.value">
                  <label class="control-label">Correct Timings</label>
                  <input type="text" class="form-control" formControlName="correct_timings" required="required"
                         [ngClass]="{ 'is-invalid': (submitted) && report.correct_timings.errors }">
                  <div *ngIf="(submitted) && report.correct_timings.errors" class="invalid-feedback">
                    <div *ngIf="report.correct_timings.errors.required">Correct Timings is required</div>
                  </div>
                </div>
                <div id="feeDiv" class="form-group required" *ngIf="report.fee.value">
                  <label class="control-label">Correct Consultation Fee</label>
                  <input type="text" class="form-control" formControlName="correct_fee" required="required"
                         [ngClass]="{ 'is-invalid': (submitted) && report.correct_fee.errors }">
                  <div *ngIf="(submitted) && report.correct_fee.errors" class="invalid-feedback">
                    <div *ngIf="report.correct_fee.errors.required">Correct Consultation Fee is required</div>
                  </div>
                </div>
                <div class="form-group required">
                  <label class="control-label">Your Email</label>
                  <input type="email" class="form-control" formControlName="email" required="required"
                         [ngClass]="{ 'is-invalid': (submitted) && report.email.errors }">
                  <div *ngIf="(submitted) && report.email.errors" class="invalid-feedback">
                    <div *ngIf="report.email.errors.required">Email is required</div>
                    <div class="text-danger" *ngIf="report.email.errors?.pattern">
                      Invalid Email</div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label">More Details</label>
                  <textarea class="form-control" rows="5" formControlName="others"></textarea>
                </div>
                <div class="form-group">
                  <label class="control-label">
                    <input type="checkbox" formControlName="self_doctor" >&nbsp;
                    I am a Doctor
                  </label>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row align-items-center">
              <div class="col-sm-7">
                <input type="button" class="btn btn-primary" (click)="sendErrorReport()" value="Report">
              </div>
              <div class="col-sm-5">
                <button type="button" #closeReport class="btn btn-default pull-right closeBtn" data-dismiss="modal" aria-hidden="true">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<app-footer lat="17.4611" long="78.355797" (data)="RelatedSpecialty($event)"></app-footer>
