import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../service/user.service";
import {CookieService} from "ngx-cookie-service";

declare var $: any;
@Component({
  selector: 'app-vcsuccess',
  templateUrl: './vcsuccess.component.html',
  styleUrls: ['./vcsuccess.component.css']
})
export class VcsuccessComponent implements OnInit {

  updated: boolean = false;
  reasonForm: FormGroup;
  reasonSubmitted: boolean;
  feedbackForm: FormGroup;
  ratings: any;
  appointmentId: any;
  appointmentIdRepeat: any;
  feedback=['Ease of searching', 'Booking Process', 'Clinic Info', 'Doctors Info', 'Online Consultation']
  selectedIndex: number;
  selectedIndexs: any=[];
  isShow = true;
  reasons: any=[];
  isloading:boolean;
  message: any = '';
  userToken: string;

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  public setRow(_index: number) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
    } else {
      let removeIndex = this.selectedIndexs.indexOf(_index);
      this.selectedIndexs.splice(removeIndex, 1);
    }
  }

  constructor(
    private activatedroute:ActivatedRoute,
    private router:Router,
    private Userservice:UserService,
    private fb: FormBuilder,
    private cookieService: CookieService
  ) {
    if (this.Userservice.userValue) {
      this.userToken = this.Userservice.dashboardURL + 'user/appointment/?token=' +this.Userservice.userValue['details']['userId'];
    }
    this.reasonForm = this.fb.group({
      complaint: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      age: ['', [Validators.required]],
    });
    this.feedbackForm = this.fb.group({
      others: new FormControl('',)
    });
  }

  ngOnInit(): void {
    this.Userservice.setDocTitle('Virtual Consultation Success - Gigadocs');
    this.activatedroute.params.subscribe(params => {
      this.appointmentId = params['channel'].slice(-14);
    })
    setTimeout(() => {
      this.appointmentIdRepeat = this.cookieService.get('appointmentId');
      if (this.appointmentIdRepeat !== this.appointmentId) {
        $("#feedbackModal").modal('show');
        this.cookieService.set('appointmentId', this.appointmentId);
      }
    }, 2000);
  }

  get form() {
    return this.reasonForm.controls;
  }

  saveReason() {
    this.reasonSubmitted = true;
    var details = {
      appointmentId: this.appointmentId,
      complaint: this.reasonForm.value.complaint,
      gender: this.reasonForm.value.gender,
      age: this.reasonForm.value.age
    };
    if(this.form.complaint.valid) {
      this.Userservice.updateAppointment(details).subscribe(response => {
        if (response.details.status) {
          this.updated = true;
          setTimeout(() => {
            this.updated = false
          }, 2000);
        }
      });
    }
  }

  submitFeedback() {
    this.isloading = true;
    if (this.ratings != null) {
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        this.reasons.push(this.feedback[this.selectedIndexs[i]]);
      }
      this.reasons.push(this.feedbackForm.value.others);
      let reasons = this.reasons.join('-');
      this.Userservice.userFeedback(this.appointmentId, this.ratings, reasons).subscribe((response: any) => {
        if (response.status) {
          this.message = response.message;
          this.isloading = false;
          setTimeout(() => {
            this.message = '';
            $("#feedbackModal").modal('hide');
            this.feedbackReset();
          }, 2000)
        }
      })
    }
  }

  feedbackReset(){
    this.feedbackForm.reset();
    this.isloading = null;
    this.selectedIndexs.splice(0,this.selectedIndexs.length);
  }

  ratingGiven(currentRating){
    this.ratings = currentRating;
  }

  howitworks() {
    this.router.navigateByUrl('home/howitworks');
  }

}
