<app-header></app-header>

<section id="" class="">
  <div class="test-info">
    <div class="container">
      <div class="row" *ngIf="success">
        <div *ngIf="type == 'profile'" class="col-md-3">
          <img src="{{serviceData.imageLocation}}" onerror="src='assets/images/thyro_logo.png'" class="" alt="" height="" width="280">
        </div>
        <div class="col-md-6">
          <div class="container">
            <div class="included-details-list">
              <div class="details-list">
                <h4 class="pt-4 font-weight-bold">{{serviceData.name}}</h4>
                <p>Category : {{serviceData.category}}</p>
              </div>
              <hr>
              <p *ngIf="type == 'profile' || type == 'offer'" class="includes-list pt-2">Includes {{serviceData.testCount}} Test(s)</p>
              <section id="how-to">
                <div *ngIf="type == 'profile' || type == 'offer'" class="panel-group" id="superaccordion">
                  <div *ngFor="let group of groupMap | keyvalue let i=index" class="panel">
                    <div class="panel-heading parent">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#superaccordion" href="#collapse{{i}}" aria-expanded="false">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="included-test-name">
                              <p class="mb-0">
                                {{group.key}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div id="collapse{{i}}" class="panel-collapse collapse">
                      <div class="panel-body">
                        <div class="inside-body">
                          <ol *ngIf="type == 'profile' || type == 'offer'" class="list-group vertical-steps">
                            <li *ngFor="let test of group.value" class="list-group-item">
                              <span>{{test.name}} - ({{test.type}})</span>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="selected-package">
            <div class="pacakage-details">
              <div class="package-section">Selected {{type | camelcase}}</div>
              <div class="package-name">
                <div class="">
                  <div class="font-weight-bold">{{serviceData.name}}</div>
                  <div class="package-button">Includes {{serviceData.testCount}} Test(s)</div>
                  <span class="top-test-price">₹{{serviceData.rate.offerRate}}</span>
                </div>
              </div>
              <div class="book-package" *ngIf="type == 'test'">
                <a type="button" *ngIf="!checkTestInCart(serviceData.code) && !checkTestIsIncluded(serviceData.code)" class="btn btn-primary btn-lg btn-block text-center" (click)="handleAddToCart(type, serviceData, alertTemplate, offerExistsTemplate)">
                  <i class="fas fa-shopping-cart"></i> Add to Cart
                </a>
                <a *ngIf="checkTestIsIncluded(serviceData.code) && !checkTestInCart(serviceData.code)" type="button" class="includedBtn btn btn-primary btn-lg btn-block text-center">
                  <i class="fas fa-check-circle"></i> Included</a>
                <div class="mb-2" *ngIf="checkTestInCart(serviceData.code)">
                  <a type="button" href="diagnostic-center/cart" class="addedCartBtn btn btn-primary btn-lg btn-block text-center">
                    <i class="fas fa-check-circle"></i> Go to Cart
                  </a>&nbsp;
                  <a type="button" (click)="removeFromCart(serviceData.code)" class="removeBtn btn btn-danger btn-lg text-center">
                    <i class="fas fa-times-circle"></i>
                  </a>
                </div>
                <a type="button" href="/diagnostic-center/cart" class="btn btn-primary btn-lg btn-block text-center">
                  <i class="fas fa-shopping-cart"></i> View Cart({{cartCount}})
                </a>
              </div>
              <div class="book-package" *ngIf="type == 'profile'">
                <a type="button" *ngIf="!checkProfileInCart(serviceData.code) && !checkProfileIsIncluded(serviceData.code)" class="btn btn-primary btn-lg btn-block text-center" (click)="handleAddToCart(type, serviceData, alertTemplate, offerExistsTemplate)">
                  <i class="fas fa-shopping-cart"></i> Add to Cart
                </a>
                <a *ngIf="checkProfileIsIncluded(serviceData.code) && !checkProfileInCart(serviceData.code)" type="button" class="includedBtn btn btn-primary btn-lg btn-block text-center">
                  <i class="fas fa-check-circle"></i> Included</a>
                <div class="mb-2" *ngIf="checkProfileInCart(serviceData.code)">
                  <a type="button" href="diagnostic-center/cart" class="addedCartBtn btn btn-primary btn-lg btn-block text-center">
                    <i class="fas fa-check-circle"></i> Go to Cart
                  </a>&nbsp;
                  <a type="button" (click)="removeFromCart(serviceData.code)" class="removeBtn btn btn-danger btn-lg text-center">
                    <i class="fas fa-times-circle"></i>
                  </a>
                </div>
                <a type="button" href="/diagnostic-center/cart" class="btn btn-primary btn-lg btn-block text-center">
                  <i class="fas fa-shopping-cart"></i> View Cart({{cartCount}})
                </a>
              </div>
              <div class="book-package" *ngIf="type == 'offer'">
                <a type="button" *ngIf="!checkPackageInCart(serviceData.code)" class="btn btn-primary btn-lg btn-block text-center" (click)="handleAddToCart(type, serviceData, alertTemplate, offerExistsTemplate)">
                  <i class="fas fa-shopping-cart"></i> Add to Cart
                </a>
                <div class="mb-2" *ngIf="checkPackageInCart(serviceData.code)">
                  <a type="button" href="diagnostic-center/cart" class="addedCartBtn btn btn-primary btn-lg btn-block text-center">
                    <i class="fas fa-check-circle"></i> Go to Cart
                  </a>&nbsp;
                  <a type="button" (click)="removeFromCart(serviceData.code)" class="removeBtn btn btn-danger btn-lg text-center">
                    <i class="fas fa-times-circle"></i>
                  </a>
                </div>
                <a type="button" href="/diagnostic-center/cart" class="btn btn-primary btn-lg btn-block text-center">
                  <i class="fas fa-shopping-cart"></i> View Cart({{cartCount}})
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #alertTemplate>
  <div class="modal-content">
    <div class="modal-header">
      <h4>Alert</h4>
    </div>
    <div class="modal-body">
      The diagnostic test that you have selected is also available in one or more diagnostic test packages that you have already selected. Hence this test will not be listed in your cart.
    </div>
    <div class="modal-footer text-center">
      <a (click)="closeModal()" class="closeModal btn btn-primary" data-dismiss="modal" aria-hidden="true">Ok</a>
    </div>
  </div>
</ng-template>

<ng-template #offerExistsTemplate>
  <div class="modal-content">
    <div class="modal-header">
      <h4>Alert</h4>
    </div>
    <div class="modal-body">
      You can book only one package at a time. You have already added one package to cart.
    </div>
    <div class="modal-footer text-center">
      <a (click)="closeModal()" class="closeModal btn btn-primary" data-dismiss="modal" aria-hidden="true">Ok</a>
    </div>
  </div>
</ng-template>

<app-footer lat="17.4611" long="78.355797"></app-footer>
