<app-header></app-header>

<div class="thyro-search">
  <div class="col-md-7">
    <div class="thyro-logo">
      <img src="assets/images/thyro_logo.png" width="230" height="50">
    </div>
  </div>
</div>

<div *ngIf="cartItems.length == 0" id="emptyservices" class="emptyservices">
  <div class="container text-center">
    <p>Cart is empty. Please add tests to proceed.</p>
    <img src="assets/images/cart.png" width="150" height="130">
    <div class="col-md-12 buttonDiv form-group">
      <a type="button" href="diagnostic-center" class="btn btn-primary btn-lg">Search Tests</a>
    </div>
  </div>
</div>

<div *ngIf="cartItems.length > 0" id="cartservices" class="cartservices">
  <div class="cart-info">
    <div class="container">
      <div class="row cart-title">
        <h4 class="col-md-12">Checkout</h4>
        <div class="wp-block-table features-title col-md-7">
          <div class="col-md-12 addMore">
            <a href="diagnostic-center" class="btn btn-primary btn-sm pull-right"> + Add More Tests</a>
          </div>
          <table>
            <tbody>
              <tr>
                <th>S NO</th>
                <th>TEST CODE</th>
                <th>TEST</th>
                <th>PRICE</th>
                <th></th>
              </tr>
              <tr *ngFor="let item of cartItems;let i=index" id="remove-from-cart" >
                <td>{{i + 1}}</td>
                <td>{{item.id}}</td>
                <td><a href="/diagnostic-center/{{item.type}}/{{item.id}}">{{item.name}}</a></td>
                <td class="text-right">₹ {{item.price}}</td>
                <td>
                    <a class="text-danger remove-from-cart" (click)="removeFromCart(item.id)">
                      <i class="fas fa-times-circle"></i></a>
                </td>
              </tr>
              <!--<tr *ngIf="usedPoints">
                <td colspan="3" class="text-right">Points Used {{pointsUsed}} of {{points}}</td>
                <td class="text-right"> - ₹ {{discount}}</td>
                <td></td>
              </tr>-->
              <tr *ngIf="serviceCharges > 0">
                <td colspan="3" class="text-right service-charge">Service Charges<i class="fa fa-info-circle" title="Service Charges are applicable for Cart Amount Rs.500 or Less"></i></td>
                <td class="text-right">₹ {{serviceCharges}}</td>
                <td></td>
              </tr>
              <tr>
                <td colspan="3" class="text-right">Total Amount</td>
                <td class="text-right">₹ {{cartTotal}}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-5 thyrocareForm">
          <div class="alert alert-danger" *ngIf="messageBool">{{message}}</div>
          <form [formGroup]="patientDetailsForm" >
            <div class="row">
              <div class="col-md-6 form-group required">
                <label class="control-label">Patient/Visitor's Name</label>
                <input formControlName="name"
                       [typeahead]="filteredFamilyMembers$"
                       (typeaheadOnSelect)="selectedFamilyMember($event)"
                       [typeaheadItemTemplate]="familyItemTemplate"
                       [typeaheadOptionsLimit]="5"
                       (keyup)="clear($event)"
                       typeaheadOptionField="name"
                       placeholder="Enter Patient Name"
                       [ngClass]="{ 'is-invalid': submitted && form.name.errors }"
                       class="form-control" autofocus>
                <ng-template #familyItemTemplate let-brand="item">
                  <div class="ui-helper-clearfix">
                    <div style="margin:0px;font-size: 13px;word-break: normal;white-space: normal;">
                      {{brand.name | camelcase}}
                    </div>
                  </div>
                </ng-template>
                <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
                  <div *ngIf="form.name.errors.required">Name is required</div>
                </div>
              </div>
              <div class="col-md-6 form-group required">
                <label class="control-label">Mobile Number</label>
                <input type="text" formControlName="mobile" class="form-control" placeholder="Enter Mobile Number"
                       [ngClass]="{ 'is-invalid': submitted && form.mobile.errors }" [readonly]="isReadonly" />
                <div *ngIf="submitted && form.mobile.errors" class="invalid-feedback">
                  <div *ngIf="form.mobile.errors.required">Mobile is required</div>
                  <div class="text-danger" *ngIf="form.mobile.errors?.pattern || form.mobile.errors?.minlength || form.mobile.errors?.maxlength ">
                    Invalid Mobile number
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group required">
                <label class="control-label">Email Address</label>
                <input type="text" formControlName="email" class="form-control" placeholder="Enter Email Address" [ngClass]="{ 'is-invalid': submitted && form.email.errors }" />
                <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
                  <div *ngIf="form.email.errors.required">Email is required</div>
                  <div class="text-danger" *ngIf="form.email.errors?.pattern">Email must be a valid email address</div>
                </div>
              </div>
              <div class="col-md-6 form-group required">
                <label class="control-label">Date of Birth</label>
                <input type="text" formControlName="dob" readonly class="form-control" bsDatepicker [bsConfig]="bsConfig" placeholder="Select DOB" placement="bottom"
                      [maxDate]="maxDatedob" [ngClass]="{ 'is-invalid': submitted && form.dob.errors }" (ngModelChange)="selectedDate($event)"/>
                <div style="font-size: 13px; color:#08ad4d; margin-top: 3px; " *ngIf = "patientDob && age > 0" ><strong>Age : {{age}} {{metrics}}</strong></div>
                <div style="font-size: 13px; color:#08ad4d; margin-top: 3px; " *ngIf = "patientDob && age == 0" ><strong>Age : Below 1 {{metrics}}</strong></div>
                <div *ngIf="submitted && form.dob.errors" class="invalid-feedback">
                  <div *ngIf="form.dob.errors">DOB is required.</div>
                </div>
              </div>
              <div class="col-md-6 form-group required">
                <label class="control-label">Gender</label><br>
                <label class="radio-inline"><input formControlName="gender" type="radio" name="gender" value="M"> Male</label>&nbsp;
                <label class="radio-inline"><input formControlName="gender" type="radio" name="gender" value="F"> Female</label>
                <div *ngIf="submitted && form.gender.errors" class="gender-invalid">
                  <div *ngIf="submitted && form.gender.errors?.required">Gender is required.</div>
                </div>
              </div>
              <div class="col-md-6 form-group required">
                <label class="control-label">Pincode</label>
                <input type="text" (keyup)="pincodeAvailability($event)" formControlName="pincode" pattern="[1-9]\d{5}"
                       [ngClass]="{ 'is-invalid': submitted && form.pincode.errors }" placeholder="Enter Pincode" class="form-control">
                <div *ngIf="submitted && form.pincode.errors" class="invalid-feedback">
                  <div *ngIf="form.pincode.errors.required">Pincode is required.</div>
                  <div class="text-danger" *ngIf="form.pincode.errors?.pattern">
                    Enter only 6 digits
                  </div>
                </div>
                <p class="pincodeMessage" [ngStyle]="{ 'color': color}" *ngIf="checkPin">{{message}}</p>
              </div>
              <div class="col-md-12 form-group required">
                <label class="control-label">Address</label>
                <textarea formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.address.errors }" placeholder="Enter Address" minlength="25"></textarea>
                <div *ngIf="submitted && form.address.errors" class="invalid-feedback">
                  <div *ngIf="form.address.errors">Address is required.</div>
                </div>
              </div>
              <div class="col-md-6 form-group required">
                <label class="control-label">Slot Date</label>
                <input type="text" formControlName="slotdate" (ngModelChange)="getSlotTimes()" class="form-control" bsDatepicker [bsConfig]="bsConfig" placeholder="Select Slot Date" placement="top"
                       [minDate]="minDate" [maxDate]="maxDate" [ngClass]="{ 'is-invalid': submitted && form.slotdate.errors }"/>
                <div *ngIf="submitted && form.slotdate.errors" class="invalid-feedback">
                  <div *ngIf="form.slotdate.errors">Slot Date is required.</div>
                </div>
              </div>
              <div class="col-md-6 form-group required">
                <label class="control-label">Slot Time</label>
                <select class="form-control" formControlName="slottime" [ngClass]="{ 'is-invalid': submitted && form.slottime.errors }">
                  <option value="">Select Slot Time</option>
                  <option *ngFor="let time of slotTimings;" value="{{time.slot}}">{{time.slot}}</option>
                </select>
                <div *ngIf="submitted && form.slottime.errors" class="invalid-feedback">
                  <div *ngIf="form.slottime.errors">Slot Time is required.</div>
                </div>
                <div *ngIf="slotsEmpty" class="slots-error">No Slots Available</div>
              </div>
              <!--<div class="col-md-12 form-group" *ngIf="login && points > 0" >
                <div class="form-check-inline">
                  <label class="form-check-label" >
                    <input type="checkbox" [disabled]="lessPoints" (change)="onPointsChange($event)" class="form-check-input">{{points}}
                    <span style="font-weight: 400"> allow reward points to book appointment.</span>
                  </label>
                </div>
                <span class="points-text text-danger">
                  <i class="fas fa-info-circle" aria-hidden="true"></i> A minimum of 400 points will be required to book Diagnoistic Tests through Gigadocs.
                </span>
              </div>
              <div class="col-md-12 form-group" *ngIf="!login" >
                <span>Please <app-login *ngIf="!login" [modalId]="'loginModal'"></app-login> to <b>Redeem</b> earned points</span>
              </div>-->
              <div class="clearfix"></div>
              <div class="col-md-12 buttonDiv form-group">
                <button type="submit" (click)="onSubmit()" class="btn btn-blue pull-right">Book Appointment</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" #otpVerify id="otpModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="otp-modal-wrap">
          <img src="assets/images/ic-otp.png" alt="OTP">
          <h4>Verify your mobile</h4>
          <p *ngIf="otpMsg">Enter an OTP Code sent your mobile number</p>
          <div #alert *ngIf="sendingOtp" class="alert alert-warning">
            <div class="spinner-border spinner-border-sm"></div>
            Please wait..
          </div>
          <div #alert *ngIf="otpSent" class="alert alert-success">OTP successfully sent</div>
          <div #alert *ngIf="otperror" class="alert alert-danger">Invalid OTP</div>
          <div #alert *ngIf="errorInfo" class="alert alert-danger">{{errorMsg}}</div>
          <form id="otp-form" [formGroup]="otpVerficationForm" >
            <div class="form-group">
              <!-- <input #focusInput type="text" maxlength='6' id="otp" placeholder="******" formControlName="otp"/> -->
              <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            </div>
            <button class="btn btn-second" (click)="otpSubmit()" [disabled]="otp_isValid">Submit</button>
          </form>
          <p>
            If you don’t receive a code?
            <a href="javascript:void(0)" (click)="resendOtp()">Resend</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div  id="paymentStatus" #transactionSuccess class="modal fade" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header">

        <h4 class="modal-title w-100"><i class="fas fa-check-circle text-success mr-1"></i>Success</h4>
      </div>
      <div class="modal-body">
        <p class="text-center text-success">Transaction Successful</p>
      </div>
      <div class="modal-footer">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        <p>Please wait for a while,we are confirming your appointment details.</p>
      </div>
    </div>
  </div>
</div>

<div id="paymentFailed" #transactionFailed class="modal fade" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header">

        <h3 class="modal-title w-100 "><i class="fas fa-exclamation-circle text-danger mr-1"></i>Alert!</h3>
      </div>
      <div class="modal-body">
        <p class="text-center text-danger">Your transaction has failed. Please go back and try again.</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger btn-block" data-dismiss="modal" routerLink="/home">OK</button>
      </div>
    </div>
  </div>
</div>

<app-footer lat="17.4611" long="78.355797"></app-footer>
