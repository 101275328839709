import { Component, OnInit } from '@angular/core';
import {ThyrocareService} from "../service/thyrocare.service";
import {Observable, Observer, of} from "rxjs";
import {switchMap} from "rxjs/operators";
import {Router} from "@angular/router";
import {CartService, Product} from "../service/cart.service";
import {UserService} from "../service/user.service";

@Component({
  selector: 'app-thyrocare',
  templateUrl: './thyrocare.component.html',
  styleUrls: ['./thyrocare.component.css']
})
export class ThyrocareComponent implements OnInit {

  myJson: any;
  offerData: any;
  packageData: any;
  testData: any;
  service: any;
  searchData: any = [];
  product: Product[] = [];
  cartCount: number = 0;
  message = '';
  cartCodes: any = [];
  cartChildCodes: any = [];

  constructor(
    private router: Router,
    private thyrocare: ThyrocareService,
    public cartService: CartService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    var details = {
      metaTitle: 'Diagnostic Center | Gigadocs',
      metaDescription: 'Get your Diagnostic Tests carried out from the comfort of your home',
      ogTitle: 'Diagnostic Center | Gigadocs',
      ogDescription: 'Get your Diagnostic Tests carried out from the comfort of your home',
    };
    this.userService.setDynamicMetaData(details);
    this.thyrocare.loginThyrocare().subscribe(response => {
      if (response.apiKey) {
        this.thyrocare.apiKey = response.apiKey;
        this.thyrocare.token = response.accessToken;
        this.getOffers();
        this.getPackages();
        this.getTests();
      }
    });
    this.cartCount = this.cartService.cartItemsCount();
    this.cartCodes = this.cartService.getCartCodes();
    this.cartChildCodes = this.cartService.getCartChildCodes();
  }

  getOffers() {
    this.thyrocare.getOffers().subscribe(response => {
      if(response.master.offer) {
        this.offerData = response.master.offer;
        for (let i = 0; i < this.offerData.length; i++) {
          this.myJson = {"code": this.offerData[i].code, "name": this.offerData[i].name, "type": 'offer'};
          this.searchData.push(this.myJson);
        }
      }
    })
  }

  getPackages() {
    this.thyrocare.getPackages().subscribe(response => {
      if(response.master.profile) {
        this.packageData = response.master.profile;
        for (let i = 0; i < this.packageData.length; i++) {
          this.myJson = {"code": this.packageData[i].code, "name": this.packageData[i].name, "type": 'profile'};
          this.searchData.push(this.myJson);
        }
      }
    })
  }

  getTests() {
    this.thyrocare.getTests().subscribe(response => {
      if(response.master.tests) {
        this.testData = response.master.tests;
        for (let i = 0; i < this.testData.length; i++) {
          this.myJson = {"code": this.testData[i].code, "name": this.testData[i].name, "type": 'test'};
          this.searchData.push(this.myJson);
        }
      }
    })
  }

  filteredService$ = new Observable((observer: Observer<string>) => {
    observer.next(this.service);
  }).pipe(
    switchMap((query: string) => {
      return of(this.searchData.filter(item => (item.name && item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)))
    })
  );

  selectedService(event:any) {
    let type = event.item.type;
    let code = event.item.code;
    this.service = event.item.name;
    this.router.navigateByUrl('diagnostic-center/' + type + '/' + code);
  }

  handleAddToCart(type, profile, alertTemplate, offerExistsTemplate) {
    var childs = [];
    if (type == 'profile' || type == 'offer') {
      childs = profile.childs;
    } else if (type == 'test') {
      childs =[{
        name: profile.name,
        code: profile.code,
        groupName: profile.groupName,
        type: profile.type
      }];
    }
    let product = new Product(profile.code, profile.name, profile.rate.offerRate, type, childs, profile.testNames);
    this.cartService.addToCart(product, alertTemplate, offerExistsTemplate);
    this.cartCount = this.cartService.cartItemsCount();
    this.ngOnInit();
  }

  checkTestInCart(product):boolean{
    let isIncart = false;
    if(this.cartCodes.indexOf(product) !== -1) {
      isIncart = true;
    }
    return isIncart;
  }

  checkProfileInCart(product):boolean{
    let isIncart = false;
    if(this.cartCodes.indexOf(product) !== -1) {
      isIncart = true;
    }
    return isIncart;
  }

  checkPackageInCart(product):boolean{
    let isIncart = false;
    if(this.cartCodes.indexOf(product) !== -1) {
      isIncart = true;
    }
    return isIncart;
  }

  checkTestIsIncluded(code):boolean {
    let isIncluded = false;
    if(this.cartChildCodes.indexOf(code) !== -1) {
      isIncluded = true;
    }
    return isIncluded;
  }

  checkProfileIsIncluded(code):boolean {
    let isIncluded = false;
    if(this.cartChildCodes.indexOf(code) !== -1) {
      isIncluded = true;
    }
    return isIncluded;
  }

  removeFromCart(id) {
    this.cartService.removeItemByCode(id);
    this.ngOnInit();
  }

  closeModal() {
    this.cartService.modalRef.hide();
  }

}
